import { PlatformType } from "~/src/util/platforms";
import { ObjectValues, withPfp } from "~/src/util/reusables";
import { Media, Team } from "~/src/api/withApi";

export type LinkedInArticle = {
  title: string;
  description: string;
  source: string;
};
interface AdContext {
  dscStatus?: string;
  dscName?: string;
  dscAdType?: "VIDEO" | "CAROUSEL" | "STANDARD";
  isDsc?: boolean;
  dscAdAccount?: string;
}
type ContentCallToActionLabel =
  | "APPLY"
  | "DOWNLOAD"
  | "VIEW_QUOTE"
  | "LEARN_MORE"
  | "SIGN_UP"
  | "SUBSCRIBE"
  | "REGISTER"
  | "JOIN"
  | "ATTEND"
  | "REQUEST_DEMO"
  | "SEE_MORE";

export const LinkedInReactionType = {
  LIKE: "LIKE", // "Like" in the UI
  PRAISE: "PRAISE", // "Celebrate" in the UI
  MAYBE: "MAYBE", // "Curious" in the UI – DEPRECATED
  EMPATHY: "EMPATHY", // "Love" in the UI
  INTEREST: "INTEREST", // "Insightful" in the UI
  APPRECIATION: "APPRECIATION", // "Support" in the UI
  ENTERTAINMENT: "ENTERTAINMENT", // "Funny" in the UI
} as const;
export type LinkedInReactionType = ObjectValues<typeof LinkedInReactionType>;
interface ILinkedInOrganization {
  name: string;
  id: number;
  organizationUrn: string;
  vanityName: string;
  profilePictureUrl?: string;
  authorUrn?: string;
}
export interface ILinkedInMember {
  id: string;
  name: string;
  vanityName: string;
  country: string;
  profilePictureUrl?: string;
  authorUrn?: string;
}
type ILinkedInPost = {
  id: string;
  visibility: string;
  lifecycleState: string;
  reshareContext?: any;
  author: string;
  content?: any; // (optional) additional post information, including *.multiImage.images = [], *.media = {}, *.article = {}, *.carousel.cards = [{}],
  commentary?: string; // the "caption"
  originalUrnId?: string;
  createdAt: number;
  lastModifiedAt: number;
  publishedAt: number;
  adContext?: AdContext;
  contentLandingPage?: string;
  contentCallToActionLabel?: ContentCallToActionLabel;
  distribution?: any;
  isReshareDisabledByAuthor?: boolean;
};
export type LinkedInPostResponse = Omit<
  ILinkedInPost,
  "author" | "id" | "commentary" | "lastModifiedAt" | "distribution"
> & {
  urnId: string;
  body?: string;
  lastUpdatedAt: number;
  feedDistribution?: "MAIN_FEED" | "NONE";
  media?: Media[];
  reactions?: Record<LinkedInReactionType, number>;
  totalReactions?: number;
  totalComments?: number;
  commentsEnabled?: boolean;
  author?: ILinkedInMember | ILinkedInOrganization;
  parent?: Omit<LinkedInPostResponse, "parent">;
};

export const getActiveAccountForPlatform = (
  workspace: Team,
  platform: PlatformType,
) => {
  let lastIngested: number | undefined, pfp: string, name: string, id: string;
  let key = "";
  let secret = "";
  const currentPlatform: any = workspace?.[platform] ?? {};

  const {
    accounts,
    selectedAccount,
    orgs,
    secret: topLevelSecret,
    key: topLevelKey,
    id: topLevelId,
  } = currentPlatform;
  switch (platform) {
    case PlatformType.Facebook:
      if (
        selectedAccount !== undefined &&
        accounts &&
        Array.isArray(accounts) &&
        accounts[selectedAccount]
      ) {
        pfp = accounts[selectedAccount].pfp;
        name = accounts[selectedAccount].name;
        id = accounts[selectedAccount].id;
        key = accounts[selectedAccount].key;
        lastIngested =
          workspace?.ingestedProfiles?.[PlatformType.Facebook]?.[id];
      }
      break;
    case PlatformType.Instagram:
      if (
        selectedAccount !== undefined &&
        accounts &&
        Array.isArray(accounts) &&
        accounts[selectedAccount]
      ) {
        pfp = accounts[selectedAccount].profile_picture_url;
        name = accounts[selectedAccount].username;
        key = topLevelKey;
        id = accounts[selectedAccount]?.id?.toString();
        lastIngested =
          workspace?.ingestedProfiles?.[PlatformType.Instagram]?.[id];
      }
      break;
    case PlatformType.LinkedIn:
      if (
        selectedAccount !== undefined &&
        orgs &&
        Array.isArray(orgs) &&
        orgs[selectedAccount]
      ) {
        pfp = orgs[selectedAccount].pfp;
        name = orgs[selectedAccount].name;
        id = orgs[selectedAccount].id;
        key = topLevelKey;
        lastIngested =
          workspace?.ingestedProfiles?.[PlatformType.LinkedIn]?.[
            `urn:li:organization:${id}`
          ];
      } else {
        pfp = currentPlatform?.pfp;
        name = currentPlatform?.name;
        id = topLevelId;
        key = topLevelKey;
        lastIngested =
          workspace?.ingestedProfiles?.[PlatformType.LinkedIn]?.[
            `urn:li:person:${id}`
          ];
      }
      break;
    case PlatformType.Twitter:
      pfp = currentPlatform?.pfp;
      name = currentPlatform?.name;
      id = currentPlatform?.key?.split("-")?.[0];
      key = topLevelKey;
      secret = topLevelSecret;
      break;
  }

  return {
    name: name,
    pfp: withPfp(pfp, name),
    id: id,
    lastIngested,
    key,
    secret,
  };
};

export const isPlatformAccountPostsIngested = (
  workspace: Team,
  platform: PlatformType,
  accountId: string,
) => !!workspace?.ingestedProfiles?.[platform]?.[accountId];

// TODO: Extend to other platforms than Instagram if necessary...
export const getPlatformContentType = (raw: any) =>
  ({
    AD: "post",
    FEED: "post",
    STORY: "story",
    REELS: "reel",
  }?.[raw?.media_product_type] ?? "post");
