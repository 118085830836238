import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";
import { Input } from "~/src/primitives/input";
import { Button } from "~/src/primitives/button";
import { ArrowRight, User, UserCheck } from "lucide-react";
import { UserAccount } from "~/src/api/auth.api";
import { username, withPfp, withTooltip } from "~/src/util/reusables";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/src/primitives/popover";
import UserSearch from "./UserSearch";
import { OrganizationContext } from "../../Organization";
import { Separator } from "~/src/primitives/separator";

interface CreateApprovalWorkflowModalProps {
  show: boolean;
  onCreate: (name: string, approvers: string[]) => void;
  onHide: () => void;
}

const CreateApprovalWorkflowModal = ({
  show,
  onCreate,
  onHide,
}: CreateApprovalWorkflowModalProps) => {
  const organization = useContext(OrganizationContext);
  const [name, setName] = useState<string>();
  const [approvers, setApprovers] = useState<Partial<UserAccount>[]>([]);

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-row items-center justify-center">
            <div>
              <UserCheck className="mr-2 h-4 w-4" />
            </div>
            <div>Create an approval workflow</div>
          </div>
        </DialogHeader>
        <div>
          <div className="flex flex-row items-center justify-start space-x-4">
            <div className="text-xs font-bold">Name:</div>
            <div>
              <Input
                placeholder="My Approval Workflow"
                spellCheck={false}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>

          <Separator className="my-6" />

          <div className="flex flex-row items-center space-x-4">
            <div className="text-xs font-bold">Flow: </div>
            <div className="flex grow flex-row items-center space-x-2">
              {approvers?.map((user) => (
                <div
                  className="flex shrink-0 flex-row items-center"
                  key={user._id}
                >
                  {withTooltip(
                    <img
                      src={withPfp(user?.pfp, username(user))}
                      crossOrigin="anonymous"
                      className="h-8 w-8 rounded-full border border-white shadow-md"
                    />,
                    user.email,
                  )}

                  <ArrowRight className="ml-2 h-4 w-4" />
                </div>
              ))}

              <Popover>
                <PopoverTrigger>
                  <div className="flex h-8 w-8 cursor-pointer flex-row items-center justify-center rounded-full border border-white bg-gray-200 shadow-lg hover:bg-gray-300">
                    <User className="h-4 w-4" />
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <UserSearch
                    seats={organization?.seats}
                    selected={approvers?.map((e) => e._id)}
                    onSelect={(user) => {
                      const tmp = [...approvers];
                      tmp.push(user);
                      setApprovers(tmp);
                    }}
                    onDeselect={(user) => {
                      let tmp = [...approvers];
                      tmp = tmp.filter((e) => e._id !== user._id);
                      setApprovers(tmp);
                    }}
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" size="sm" onClick={onHide}>
            Close
          </Button>
          <Button
            variant="default"
            size="sm"
            disabled={!name}
            onClick={() => {
              if (name) {
                onCreate(
                  name,
                  approvers.map((e) => e._id),
                );
                onHide();
              }
            }}
          >
            Create
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateApprovalWorkflowModal;
