import React from "react";
import API from "~/src/api/withApi";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/src/primitives/dialog";
import { Input } from "~/src/primitives/input";
import { Button } from "~/src/primitives/button";
import { Cog, Plus } from "lucide-react";
import { WorkspaceManagement } from "~/src/api/team.api";
import { Role } from "~/src/api/auth.api";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/src/primitives/popover";
import { Separator } from "~/src/primitives/separator";
import UserSearch from "./UserSearch";
import { useDispatch } from "react-redux";
import { setUser } from "~/src/reducers/user";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "~/src/primitives/select";
import { approvalWorkflowFromId } from "../Approval";
import { setLoading, setSuccess } from "~/src/reducers/toolkit";
import { withPfp } from "~/src/util/reusables";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Load from "../../../../partials/Load/Load";

interface WorkspaceSettingsModalProps {
  show: boolean;
  workspace_id?: string;
  onHide: () => void;
}

const WorkspaceSettingsModal = ({
  show,
  workspace_id,
  onHide,
}: WorkspaceSettingsModalProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { data: workspace, isLoading: isWorkspaceLoading } =
    useQuery<WorkspaceManagement>({
      queryKey: ["workspace", workspace_id],
      queryFn: () => API.getWorkspaceManagement(workspace_id),
      enabled: !!workspace_id && show,
    });

  const updateNameMutation = useMutation({
    mutationFn: (newName: string) =>
      API.updateOrganizationWorkspace(workspace_id, { name: newName }),
    onSuccess: () => {
      dispatch(setSuccess("Name updated successfully"));
      queryClient.invalidateQueries({ queryKey: ["workspace", workspace_id] });
      API.getSession("?update=true").then(({ user }) => {
        dispatch(setUser(user));
      });
    },
  });

  const onDeleteWorkspace = async (workspaceId: string) =>
    API.deleteOrganizationWorkspace(workspaceId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => {
        dispatch(setUser(user));
        queryClient.invalidateQueries({
          queryKey: ["workspace", workspace_id],
        });
      });

  const addUserToWorkspace = (userId: string, workspaceId: string) => {
    API.addUserToOrganizationWorkspace(workspaceId, userId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => {
        dispatch(setUser(user));
        queryClient.invalidateQueries({
          queryKey: ["workspace", workspace_id],
        });
      });
  };

  const removeUserFromWorkspace = (userId: string, workspaceId: string) => {
    API.removeUserFromOrganizationWorkspace(workspaceId, userId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => {
        dispatch(setUser(user));
        queryClient.invalidateQueries({
          queryKey: ["workspace", workspace_id],
        });
      });
  };

  const onApplyApprovalWorkflowToWorkspace = (
    workspaceId: string,
    approvalWorkflowId: string,
  ) =>
    API.applyApprovalWorkflowToWorkspace(workspaceId, approvalWorkflowId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => {
        dispatch(setUser(user));
        queryClient.invalidateQueries({
          queryKey: ["workspace", workspace_id],
        });
      });

  const onRemoveApprovalWorkflowFromWorkspace = (workspaceId: string) =>
    API.removeApprovalWorkflowFromWorkspace(workspaceId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => {
        dispatch(setUser(user));
        queryClient.invalidateQueries({
          queryKey: ["workspace", workspace_id],
        });
      });

  if (isWorkspaceLoading) return <Load />;

  return (
    <Dialog
      open={show}
      onOpenChange={(open) => {
        if (!open) {
          onHide();
        }
      }}
    >
      {isWorkspaceLoading ? (
        <DialogContent>
          {" "}
          <DialogHeader className="sr-only">
            <DialogTitle>Workspace Settings</DialogTitle>
          </DialogHeader>
          <Load className="min-h-96" />
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="sr-only">Workspace Settings</DialogTitle>
            <div className="flex flex-row items-center justify-center">
              <div>
                <Cog className="mr-2 h-4 w-4" />
              </div>
              <div>
                <span className="font-lighter">
                  Settings for{" "}
                  <span className="font-bold">{workspace?.name}</span>
                </span>
              </div>
            </div>
          </DialogHeader>

          <div className="my-4">
            <div className="mb-6 flex flex-row items-center justify-start space-x-2">
              <div className="mx-4 text-xs font-bold">Name:</div>
              <div className="flex gap-2">
                <Input
                  spellCheck={false}
                  defaultValue={workspace?.name}
                  placeholder="Enter workspace name"
                  onChange={(e) => {
                    if (e.target.value && e.target.value !== workspace?.name) {
                      updateNameMutation.mutate(e.target.value);
                    }
                  }}
                />
              </div>
            </div>

            <Separator className="my-6" />

            <div className="flex flex-row items-center justify-start space-x-2">
              <div className="mx-4 text-xs font-bold">Access Control:</div>
              <div className="flex flex-row flex-wrap items-center justify-start space-x-2">
                {workspace?.organization?.owner && (
                  <img
                    src={withPfp(
                      workspace?.organization?.owner?.pfp,
                      `${workspace?.organization?.owner?.firstname} ${workspace?.organization?.owner?.lastname}`,
                    )}
                    className="h-8 w-8 rounded-full shadow-lg"
                    crossOrigin="anonymous"
                  />
                )}
                {workspace?.users?.map((e) => (
                  <Popover key={e._id}>
                    <PopoverTrigger>
                      <div>
                        <img
                          src={withPfp(e.pfp, `${e?.firstname} ${e?.lastname}`)}
                          className="h-8 w-8 rounded-full shadow-lg"
                          crossOrigin="anonymous"
                        />
                      </div>
                    </PopoverTrigger>
                    <PopoverContent>
                      <div className="text-xs font-bold">{e.email}</div>
                      {e.organizationRole !== Role.SUPER_ADMIN && (
                        <>
                          <Separator className="my-3" />
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              removeUserFromWorkspace(e._id, workspace._id);
                            }}
                          >
                            Revoke Access
                          </Button>
                        </>
                      )}
                    </PopoverContent>
                  </Popover>
                ))}

                <Popover>
                  <PopoverTrigger>
                    <div>
                      <div className="flex h-8 w-8 cursor-pointer flex-row items-center justify-center rounded-full bg-primary text-white shadow-lg transition-colors hover:bg-gray-600">
                        <Plus className="h-4 w-4" />
                      </div>
                    </div>
                  </PopoverTrigger>
                  <PopoverContent>
                    <UserSearch
                      seats={workspace?.organization?.seats}
                      selected={workspace?.users?.map((u) => u._id)}
                      includeSuperAdmins
                      onDeselect={(user) =>
                        removeUserFromWorkspace(user._id, workspace._id)
                      }
                      onSelect={(user) =>
                        addUserToWorkspace(user._id, workspace._id)
                      }
                    />
                  </PopoverContent>
                </Popover>
              </div>
            </div>

            {workspace?.organization?.approvalWorkflows?.length > 0 && (
              <>
                <Separator className="my-6" />
                <div className="flex flex-row items-center justify-start space-x-2">
                  <div className="mx-4 text-xs font-bold">
                    Approval Workflow:
                  </div>
                  <Select
                    defaultValue={workspace?.approvalWorkflow?._id ?? null}
                    onValueChange={async (v) => {
                      dispatch(setLoading("Updating approval workflow..."));
                      await onRemoveApprovalWorkflowFromWorkspace(
                        workspace._id,
                      ).then(
                        () =>
                          v &&
                          onApplyApprovalWorkflowToWorkspace(workspace._id, v),
                      );
                      dispatch(setSuccess("Updated!"));
                    }}
                  >
                    <SelectTrigger className="w-[200px]">
                      <SelectValue>
                        {approvalWorkflowFromId(
                          workspace?.approvalWorkflow?._id,
                          workspace?.organization as any,
                        )?.name ?? <strong>None</strong>}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value={null} key={"NULLKEY"}>
                          <strong>None</strong>
                        </SelectItem>
                        <SelectSeparator />
                        {workspace?.organization?.approvalWorkflows?.map(
                          (awf) => (
                            <SelectItem value={awf?._id} key={awf?._id}>
                              {awf?.name}
                            </SelectItem>
                          ),
                        )}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </>
            )}
          </div>

          <DialogFooter className="sm:justify-between">
            {workspace?._id !== workspace?.organization?.owner?.defaultTeam && (
              <Button
                variant="destructive"
                size="sm"
                onClick={() => {
                  onDeleteWorkspace(workspace?._id).then(() => onHide());
                }}
              >
                Delete Workspace
              </Button>
            )}
            <Button variant="outline" size="sm" onClick={onHide}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default WorkspaceSettingsModal;
