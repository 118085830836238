import { createSlice } from "@reduxjs/toolkit";
import { UserAccount } from "../api/withApi";

export type UserStateSlice = UserAccount;

const initialState: UserStateSlice = {
  email: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      const payload = action.payload;
      return { ...payload };
    },
    // Set the timestamp for the last sync of a paid meta resource
    // NOTE: Attempted use in campaigns on 11/12/24, but it was causing a re-render loop since user gets checked in CheckSession and redirected....
    // Example: dispatch(setSyncTimestamp({ key: "paidMetaAdCampaignsLastSyncTimestamp", timestamp: dayjs().valueOf() }));
    setSyncTimestamp(
      state,
      action: { payload: { key: string; timestamp: number } },
    ) {
      const { key, timestamp } = action.payload;
      if (state.hub) {
        return {
          ...state,
          hub: {
            ...state.hub,
            [key]: timestamp,
          },
        };
      } else if (state.workspace) {
        return {
          ...state,
          workspace: {
            ...state.workspace,
            [key]: timestamp,
          },
        };
      }

      return state;
    },
  },
});

const { actions, reducer } = userSlice;
export const { setUser, setSyncTimestamp } = actions;
export default reducer;
