import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "~/src/store";
import Load from "../Load/Load";
import { Otherwise } from "./Otherwise";

interface CheckManifestProps {
  perm: string;
  children: React.ReactNode | React.ReactNode[];
  cta?: React.ReactNode;
}

const CheckManifest = ({ perm, children, cta }: CheckManifestProps) => {
  const user = useSelector((state: RootState) => state.user);

  if (!user) return <Load />;

  return <>{user.manifest?.[perm] ? children : cta}</>;
};

export const withManifest = (
  node: React.ReactNode,
  perm: string,
  cta?: React.ReactNode,
) => {
  if (!cta) cta = <Otherwise perm={perm} />;

  return (
    <CheckManifest perm={perm} cta={cta}>
      {node}
    </CheckManifest>
  );
};

export default CheckManifest;
