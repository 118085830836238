import React, { createContext, useEffect, useState } from "react";
import API, { Team, UserAccount } from "~/src/api/withApi";
import { useSelector } from "react-redux";
import { Cog, Ghost, Lock, UserCheck, UserCog2, Workflow } from "lucide-react";
import { RootState } from "~/src/store";
import { Tabs, TabsList, TabsTrigger } from "~/src/primitives/tabs";
import { TabsContent } from "@radix-ui/react-tabs";
import BasicSettings from "./components/BasicSettings";
import UserSeats from "./components/UserSeats";
import Workspaces from "./components/Workspaces";
import Guests from "./components/Guests";
import { OrganizationType } from "~/src/api/organization.api";
import { Alert } from "~/src/primitives/alert";
import { Link } from "react-router-dom";
import { useBounce } from "~/src/gates";
import Approval from "./components/Approval";

export const OrganizationContext = createContext<
  OrganizationType & {
    owner: Partial<UserAccount>;
    workspaces: Partial<Team>[];
  }
>(undefined);

const Organization = () => {
  const user = useSelector((state: RootState) => state.user);
  const [organization, setOrganization] = useState<
    OrganizationType & {
      owner: Partial<UserAccount>;
      workspaces: Partial<Team>[];
    }
  >();

  useBounce(user);

  const getOrganization = () =>
    API.getOrganization()
      .then((data) => data.json())
      .then(setOrganization);

  useEffect(() => {
    getOrganization();
  }, [user]);

  return (
    <OrganizationContext.Provider value={organization}>
      <div className="overflow-y-auto p-8">
        <div className="mb-4 flex flex-row items-center justify-start text-2xl font-bold">
          <div>Organization</div>
        </div>

        {organization?.locked && (
          <Alert className="mb-4 p-3">
            <div className="mb-3 flex flex-row items-center space-x-4">
              <Lock className="block h-4 w-4" />
              <div className="text-sm">
                Your organization has been locked - please ensure your{" "}
                <Link to="/billing" className="text-blue-600">
                  subscription level
                </Link>{" "}
                includes organization access.
              </div>
            </div>
            <div className="mb-2 text-sm">
              While your organization is locked:
            </div>
            <ul className="ml-4 text-xs">
              <li>
                {" "}
                - Users you&lsquo;ve assigned seats to will not be able to log
                in.
              </li>
              <li>
                {" "}
                - You will not be able to access any organization workspaces.
              </li>
            </ul>
          </Alert>
        )}

        {!organization?.locked && (
          <Tabs defaultValue="Settings">
            <TabsList>
              <TabsTrigger value="Settings">
                <Cog className="mr-2 h-4 w-4" /> Settings
              </TabsTrigger>
              {user.manifest.manage_organization && (
                <>
                  <TabsTrigger value="Seats">
                    <UserCog2 className="mr-2 h-4 w-4" /> User Seats
                  </TabsTrigger>
                  <TabsTrigger value="Workspaces">
                    <Workflow className="mr-2 h-4 w-4" /> Workspaces
                  </TabsTrigger>
                  <TabsTrigger value="Approval">
                    <UserCheck className="mr-2 h-4 w-4" /> Approval
                  </TabsTrigger>
                  <TabsTrigger value="guests">
                    <Ghost className="mr-2 h-4 w-4" /> Guests
                  </TabsTrigger>
                </>
              )}
            </TabsList>

            <TabsContent value="Settings">
              <BasicSettings organization={organization} />
            </TabsContent>
            {user.manifest.manage_organization && (
              <>
                <TabsContent value="Seats">
                  <UserSeats />
                </TabsContent>
                <TabsContent value="Workspaces">
                  <Workspaces />
                </TabsContent>
                <TabsContent value="Approval">
                  <Approval />
                </TabsContent>
                <TabsContent value="guests">
                  <Guests />
                </TabsContent>
              </>
            )}
          </Tabs>
        )}
      </div>
    </OrganizationContext.Provider>
  );
};

export default Organization;
