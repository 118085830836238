import { CreditCard, Users } from "lucide-react";
import { Link } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "~/src/store";
import { Alert } from "~/src/primitives/alert";
import { capitalize } from "lodash";

export const Otherwise = ({ perm }: { perm: string }) => {
  const user = useSelector((state: RootState) => state.user);

  const icon = user?.manifest?.manage_organization ? (
    <Users className="mr-2 h-4 w-4" />
  ) : (
    <CreditCard className="mr-2 h-4 w-4" />
  );
  const copy = user?.manifest?.view_organization ? (
    <div>You should convince your admin to grant you access...</div>
  ) : (
    <div>
      <Link to="/billing" className="text-blue-600">
        Upgrade your account
      </Link>{" "}
      to access <strong>{perm}</strong>
    </div>
  );

  return (
    <div className="flex flex-col items-center justify-center space-y-6 py-10">
      <div className="flex flex-row items-center text-xl">
        {icon}
        <div>
          You cannot access <strong>{capitalize(perm)}</strong>
        </div>
      </div>
      <div className="text-sm">{copy}</div>
      <a
        href="mailto:hello@flamel.ai"
        className="text-sm font-semibold text-primary hover:underline"
      >
        Contact support <span aria-hidden="true">&rarr;</span>
      </a>
    </div>
  );
};

export const OtherwiseCard = ({
  perm,
  also,
}: {
  perm: string;
  also?: string;
}) => {
  const user = useSelector((state: RootState) => state.user);

  const icon = user?.manifest?.manage_organization ? (
    <Users className="mr-2 h-4 w-4" />
  ) : (
    <CreditCard className="mr-2 h-4 w-4" />
  );
  const copy = user?.manifest?.view_organization ? (
    <>
      Ask your admin for access to <strong>{perm}</strong>
    </>
  ) : (
    <>
      <Link to="/billing" className="text-blue-600">
        Upgrade your account
      </Link>{" "}
      to access <strong>{capitalize(perm)}</strong>
    </>
  );

  return (
    <Alert className="flex flex-row space-x-2 px-10 py-4">
      <div>{icon}</div>
      <div className="text-xs">
        {also ?? ""} {copy}
      </div>
    </Alert>
  );
};
