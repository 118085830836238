import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import API from "../../api/withApi";

import AuthLayout from "~/src/layouts/AuthLayout";
import { Button } from "~/src/primitives/button";
import { useToast } from "~/src/primitives/use-toast";

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState<string>();
  const { toast } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleVerifyEmail = (email: string) =>
    API.requestMagicLink(email).then((data) => data.json());

  useEffect(() => {
    if (searchParams && searchParams.get("email"))
      setEmail(searchParams.get("email"));
  }, [searchParams]);

  const handleEmailSentToast = () => {
    toast({
      title: "Success!",
      description: `We've sent a verification link to ${email}.`,
    });
  };

  const handleErrorToast = () => {
    toast({
      title: "Error!",
      description: "There's been a problem - Please try again later.",
      variant: "destructive",
    });
  };

  useEffect(() => {
    if (email) {
      setIsLoading(true);
      handleVerifyEmail(email)
        .then(handleEmailSentToast)
        .catch(handleErrorToast);
      setIsLoading(false);
    }
  }, [email]);

  return (
    <AuthLayout
      heading="Verify Your Account"
      description={`We've sent a verification link to ${email}.`}
      className="overflow-hidden"
    >
      <div className="grid gap-4 overflow-hidden">
        <Button
          disabled={isLoading}
          onClick={() => {
            setIsLoading(true);
            handleVerifyEmail(email)
              .then(handleEmailSentToast)
              .catch(handleErrorToast);
            setIsLoading(false);
          }}
        >
          {isLoading && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mr-2 h-4 w-4 animate-spin"
            >
              <path d="M21 12a9 9 0 1 1-6.219-8.56" />
            </svg>
          )}
          Resend a Link
        </Button>
        <Button
          disabled={isLoading}
          variant="outline"
          onClick={() => (window.location.href = "/api/auth/account/logout")}
        >
          Log Out
        </Button>
      </div>
    </AuthLayout>
  );
};

export default VerifyEmail;
