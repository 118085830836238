import React from "react";

const GiphyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#ECEFF1"
      d="M5 1.25a.75.75 0 0 0-.75.75v20c0 .414.336.75.75.75h14a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75h-6.25V2a.75.75 0 0 0-.75-.75H5Z"
    />
    <path
      fill="#A071FF"
      d="M19 8.25a.75.75 0 0 1 .75.75v13a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Z"
    />
    <path
      fill="#8BC94D"
      d="M5 1.25a.75.75 0 0 1 .75.75v20a.75.75 0 0 1-1.5 0V2A.75.75 0 0 1 5 1.25Z"
    />
    <path fill="#9065E5" d="M19.75 12v10a.75.75 0 0 1-1.5 0V12h1.5Z" />
    <path fill="#7DB545" d="M5.75 12v10a.75.75 0 0 1-1.5 0V12h1.5Z" />
    <path
      fill="#FFC44D"
      d="M4.25 2A.75.75 0 0 1 5 1.25h7a.75.75 0 0 1 .75.75v6.25H19a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75V2.75H5A.75.75 0 0 1 4.25 2Z"
    />
    <path
      fill="#EE4D4D"
      d="M12 1.25a.75.75 0 0 0-.75.75v7c0 .414.336.75.75.75h7a.75.75 0 0 0 .75-.75V6a.75.75 0 0 0-.75-.75h-1.25V4a.75.75 0 0 0-.75-.75h-1.25V2a.75.75 0 0 0-.75-.75h-3Z"
    />
    <path
      fill="#45B0E5"
      d="M4.25 22a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z"
    />
    <path
      fill="#D64545"
      d="M15.5 1.441V9.75H12a.75.75 0 0 1-.75-.75V2a.75.75 0 0 1 .75-.75h3c.192 0 .367.072.5.191Z"
    />
  </svg>
);
export default GiphyIcon;
