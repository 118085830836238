import React, { useEffect, useRef, useState } from "react";
import API from "~/src/api/withApi";
import { useSelector, useDispatch } from "react-redux";
import { Role, addToHistory, setLoading } from "~/src/reducers/luna";
import { RootState } from "~/src/store";
import { cn } from "~/src/util/reusables";
import { Textarea } from "~/src/primitives/textarea";
import { ChevronsDown, ChevronsUp, Moon } from "lucide-react";
import BarLoader from "react-spinners/BarLoader";
import FadeIn from "~/src/partials/Transitions/FadeIn";

const Luna = () => {
  const [open, setOpen] = useState<boolean>();

  return (
    <div
      className={cn(
        "absolute shadow-2xl transition-all duration-300",
        !open &&
          "bottom-0 right-0 z-[2000] flex h-[40px] w-[200px] cursor-pointer flex-row items-center justify-center rounded-tl-lg bg-primary hover:bg-primary/80",
        open && "bottom-4 right-4 z-[2002] h-[500px] w-[300px] rounded-xl",
      )}
      onClick={() => !open && setOpen(true)}
    >
      {!open ? (
        <div className="flex items-center gap-1 p-3 text-primary-foreground">
          <ChevronsUp className="size-4" />
          <div className="text-xs font-semibold uppercase tracking-wide">
            Chat with Luna
          </div>
          <Moon className="h-3 w-3 fill-muted-foreground" />
        </div>
      ) : (
        <>
          <div className="absolute left-0 top-0 z-0 h-full w-full rounded-xl bg-primary-foreground/60 backdrop-blur-xl"></div>
          <ChevronsDown
            className="absolute right-2 top-2 z-50 h-6 w-6 cursor-pointer rounded-full bg-white p-1 text-primary shadow-sm transition-colors hover:bg-primary hover:text-white"
            onClick={() => setOpen(false)}
          />
        </>
      )}

      {open && <LunaChat />}
    </div>
  );
};

const LunaChat = () => {
  const dispatch = useDispatch();
  const luna = useSelector((state: RootState) => state.luna);

  const pseudo = useRef<HTMLDivElement>();
  const [pendingChat, setPendingChat] = useState<string>("");

  useEffect(() => {
    if (pseudo?.current && luna?.history) {
      pseudo.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pseudo, luna?.history]);

  const invokeLuna = async (chat: string) => {
    dispatch(setLoading(true));
    const nextChat = {
      content: chat,
      role: Role.USER,
    };
    dispatch(addToHistory(nextChat));

    setTimeout(() => pseudo?.current?.scrollIntoView({ behavior: "smooth" }));

    await API.invokeChat([...(luna?.history ?? []), nextChat])
      .then((data) => data.json())
      .then((data) => data?.message)
      .then((data) => dispatch(addToHistory(data)))
      .then(() => {
        setTimeout(() =>
          pseudo?.current?.scrollIntoView({ behavior: "smooth" }),
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addToHistory({
            role: Role.ASSISTANT,
            content:
              "I'm having some trouble answering you right now, please try again later.",
          }),
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <FadeIn show timeout={250} className="relative h-full w-full">
      <div className="relative h-full w-full overflow-y-auto px-2 pb-[200px] pt-[50px]">
        {luna?.history.map((e, i) => (
          <div
            className={cn(
              "my-2 flex flex-row",
              e.role === Role.ASSISTANT ? "justify-start" : "justify-end",
            )}
            key={`assistant-resp-${i}`}
          >
            <div
              className={cn(
                "max-w-[70%] animate-fadein select-text rounded-md px-3 py-2 text-xs shadow-md",
                e.role === Role.ASSISTANT
                  ? "bg-gray-600 text-white"
                  : "bg-gray-200 text-black",
              )}
            >
              {e.content}
            </div>
          </div>
        ))}

        {luna?.loading && (
          <div className="my-2 flex flex-row justify-start">
            <div className="animate-fadein rounded-md bg-gray-600 px-3 py-2 text-white shadow-md">
              <BarLoader color="white" />
            </div>
          </div>
        )}

        <div ref={pseudo}></div>
      </div>

      <Textarea
        className="absolute bottom-2 right-2 w-[calc(100%-1rem)] backdrop-blur-sm"
        placeholder="What can I help you with today?"
        value={pendingChat}
        onChange={(e) => setPendingChat(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            invokeLuna(pendingChat);
            setPendingChat("");
          }
        }}
      />
    </FadeIn>
  );
};

export default Luna;
