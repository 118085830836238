import React from "react";
import Notifications from "./Notifications";
import Logo from "./Logo";
import DesktopNav from "./DesktopNav";
import UserMenu from "./UserMenu";
import MobileNav from "./MobileNav";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Switcher from "./Switcher";
import { cn } from "../../util/reusables";
export default function Navbar() {
  const user = useSelector((state: RootState) => state.user);

  const isPaidMode = user?.module === "paid";

  return (
    <header
      className={cn(
        "relative z-50 flex h-fit w-full items-center justify-between gap-4 p-4 ring-2 ring-inset ring-black/5 backdrop-blur",
        isPaidMode ? "bg-[#093C3E] ring-0" : "bg-secondary/80",
      )}
      draggable="false"
    >
      <div className="flex flex-row items-center gap-4">
        <Logo className="hidden sm:block" isPaidMode={isPaidMode} />
        {!user?.embed &&
          (user?.hub?._id ? (
            <span
              className={cn(
                "hidden text-2xs font-semibold uppercase tracking-wider xl:flex",
                isPaidMode ? "text-blue-400" : "text-blue-500",
              )}
            >
              Hub
            </span>
          ) : (
            <span
              className={cn(
                "hidden text-2xs font-semibold uppercase tracking-wider xl:flex",
                isPaidMode ? "text-orange-400" : "text-orange-500",
              )}
            >
              Workspace
            </span>
          ))}
        <Switcher />
      </div>

      <div className="flex flex-row items-center gap-2">
        <DesktopNav isPaidMode={isPaidMode} />
        {/* TODO: Add hub level notifications... */}
        {!user?.hub?._id && <Notifications isPaidMode={isPaidMode} />}
        <UserMenu />
        <MobileNav isPaidMode={isPaidMode} />
      </div>
    </header>
  );
}
