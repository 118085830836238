import React, { useState, useEffect, useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
  getFilteredRowModel,
  Column,
  getPaginationRowModel,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/src/primitives/table";
import {
  getPagination,
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "~/src/primitives/pagination";
import { Input } from "~/src/primitives/input";
import {
  Search,
  MoreHorizontal,
  ChevronDown,
  Edit,
  InfoIcon,
  ArrowUp,
  ArrowDown,
  ArrowUpDown,
  Plus,
  Cog,
  X,
  MapPin,
  Mail,
  BadgeCheck,
} from "lucide-react";
import {
  cn,
  NewSocialToLogo,
  pluralize,
  TooltipButton,
} from "~/src/util/reusables";
import { Checkbox } from "~/src/primitives/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/src/primitives/dropdown-menu";
import { ALL_PLATFORMS } from "~/src/util/platforms";
import API, {
  Location,
  LocationFormData,
  locationSchema,
  OrganizationType,
  sanitizeEmail,
  Team,
  UserAccount,
} from "~/src/api/withApi";
import { ScrollArea, ScrollBar } from "../../../../primitives/scroll-area";
import { setError, setSuccess } from "../../../../reducers/toolkit";
import { useDispatch } from "react-redux";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../../../primitives/hover-card";
import { Button } from "../../../../primitives/button";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { HubContext } from "../../../../layouts/HubLayout";
import { convertPropertyToReadableFormat } from "../../../Admin/components/lib/data-table";
import { MultiSelect, MultiSelectItem } from "@tremor/react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "~/src/primitives/alert-dialog";
import LocationDetailsForm from "./LocationDetailsForm";
import WorkspaceSettingsModal from "../../../Organization/components/components/WorkspaceSettingsModal";
import Load from "../../../../partials/Load/Load";
import { ZodString, ZodUnion } from "zod";
import capitalize from "lodash/capitalize";
import { AxiosError } from "axios";
import dayjs from "../../../../util/dayjs";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../primitives/dialog";

type LocationEntry = {
  _id: string;
  name: string;
  organization_name: string;
  seats: string[];
  inviteExpiration?: string;
  facebook?: any;
  instagram?: any;
  linkedin?: any;
  twitter?: any;
  location?: LocationFormData & { _id: string };
  selected?: boolean;

  /**
   * Quick access to all orgs
   */
  orgs?: Array<{ _id: string; name: string; owner_email: string }>;
};

interface EditableCellProps {
  getValue: () => any;
  row: Row<LocationEntry>;
  column: Column<LocationEntry>;
  table: {
    options: {
      meta?: {
        updateData: (
          teamId: string,
          locationId: string,
          columnId: string,
          value: any,
        ) => void;
      };
    };
  };
  defaultIsEditing?: boolean;
}
const EditableCell = React.memo(
  ({
    getValue,
    row,
    column,
    table,
    defaultIsEditing = false,
  }: EditableCellProps) => {
    const [, setSearchParams] = useSearchParams();
    const initialValue = getValue() ?? "";
    const [value, setValue] = useState(initialValue);
    const [isEditing, setIsEditing] = useState(defaultIsEditing);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const onBlur = useCallback(() => {
      (table.options.meta as any)?.updateData(
        row.original._id,
        row.original?.location?._id,
        column.id,
        value,
      );
      setSearchParams((prev) => {
        prev.delete("tid");
        prev.delete("col");
        return prev;
      });
      setIsEditing(false);
    }, [table, row.index, column, value]);

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    }, []);

    return (
      <div className="group relative">
        {isEditing ? (
          <Input
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className="h-8 min-w-48 text-center text-sm"
            autoFocus
          />
        ) : (
          <div className="flex h-8 min-w-48 items-center justify-center text-center text-sm">
            {value || (
              <i className="rounded-md border border-dashed bg-primary/[0.02] px-1 py-0.5 text-2xs text-muted-foreground">
                Empty
              </i>
            )}
          </div>
        )}
        {!isEditing && (
          <Edit
            className="absolute right-2 top-1/2 h-4 w-4 -translate-y-1/2 transform cursor-pointer opacity-0 group-hover:opacity-100"
            onClick={() => setIsEditing(true)}
          />
        )}
      </div>
    );
  },
);

EditableCell.displayName = "EditableCell";

const createColumns = (
  selectedLocations: string[],
  onSelect: (locations: Record<string, boolean>) => void,
  handleSelectAll: () => void,
  setManageWorkspace?: (teamId: string) => void,
  setManageLocation?: (teamId: string) => void,
  onInvite?: (teamId: string) => void,
  focusColumnKey?: string,
  focusLocationId?: string,
): ColumnDef<LocationEntry, any>[] => [
  {
    id: "select",
    header: ({ table }) => {
      const allSelected = table
        .getFilteredRowModel()
        .rows.every((row) => selectedLocations.includes(row.id));
      return (
        <TooltipButton
          className="mr-2 normal-case tracking-normal"
          text={allSelected ? "Deselect all?" : "Select all?"}
        >
          <Checkbox
            checked={allSelected}
            onCheckedChange={() => handleSelectAll()}
            aria-label={allSelected ? "Deselect all" : "Select all"}
          />
        </TooltipButton>
      );
    },
    cell: ({ row }) => (
      <div className="flex justify-center">
        <Checkbox
          checked={selectedLocations.includes(row.id)}
          onCheckedChange={(value) => onSelect?.({ [row.id]: !!value })}
          aria-label="Select location"
        />
      </div>
    ),
    enableSorting: true,
    enableHiding: true,
  },
  {
    accessorKey: "name",
    enableSorting: true,
    enableHiding: true,
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className={cn(column.getIsSorted() && "font-semibold", "h-full")}
        >
          Name
          {column.getIsSorted() === "asc" ? (
            <ArrowUp className="ml-2 size-3 shrink-0" />
          ) : column.getIsSorted() === "desc" ? (
            <ArrowDown className="ml-2 size-3 shrink-0" />
          ) : (
            <ArrowUpDown className="ml-2 size-3 shrink-0" />
          )}
        </Button>
      );
    },
  },
  {
    accessorKey: "organization_name",
    enableSorting: true,
    enableHiding: true,
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className={cn(column.getIsSorted() && "font-semibold", "h-full")}
        >
          Organization
          {column.getIsSorted() === "asc" ? (
            <ArrowUp className="ml-2 size-3 shrink-0" />
          ) : column.getIsSorted() === "desc" ? (
            <ArrowDown className="ml-2 size-3 shrink-0" />
          ) : (
            <ArrowUpDown className="ml-2 size-3 shrink-0" />
          )}
        </Button>
      );
    },
  },
  {
    id: "actions",
    enableHiding: true,
    enableSorting: true,
    cell: ({ row }) => (
      <div className="flex justify-center">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => setManageLocation(row.original._id)}
            >
              <MapPin className="mr-2 size-4" />
              Location Settings
            </DropdownMenuItem>
            {/* <DropdownMenuItem
              onClick={() => navigator.clipboard.writeText(row.original._id)}
            >
              <Copy className="mr-2 size-4" />
              Copy location ID
            </DropdownMenuItem> */}
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={() => setManageWorkspace(row.original._id)}
            >
              <Cog className="mr-2 size-4" />
              Workspace Settings
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    ),
    header: "Actions",
  },
  {
    id: "platforms",
    cell: ({ row }) => (
      <div className="flex items-center justify-center gap-1">
        {ALL_PLATFORMS.map(
          (p) =>
            row?.original?.[p] && (
              <NewSocialToLogo key={p} platform={p} className="h-5 w-5" />
            ),
        )}
        {!ALL_PLATFORMS.some((p) => row?.original?.[p]) && (
          <i className="rounded-md border border-dashed bg-primary/[0.02] px-1 py-0.5 text-2xs text-muted-foreground">
            None
          </i>
        )}
      </div>
    ),
    enableSorting: true,
    enableHiding: true,
    header: "Platforms",
  },
  {
    accessorKey: "requiresOnboarding",
    cell: ({ getValue, row }) => {
      const requiresOnboarding = getValue();
      const isAdminInOrg = row.original.seats?.includes(
        row.original?.location?.admin_email,
      );
      const locationFields = row.original.location;
      const requiredFields = [
        "admin_name",
        "admin_email",
        "address",
        "city",
        "state",
        "zip",
        "phone_number",
      ];
      const allFieldsFilled =
        locationFields &&
        requiredFields.every(
          (field) =>
            locationFields[field] !== null &&
            locationFields[field] !== undefined &&
            locationFields[field] !== "",
        );
      const isFunctionallyVerified =
        !requiresOnboarding && isAdminInOrg && allFieldsFilled;

      return (
        <div className="flex items-center justify-center">
          {isFunctionallyVerified ? (
            <TooltipButton text="This location is verified">
              <span className="flex size-4 items-center justify-center gap-1 rounded-full bg-accent-blue text-2xs text-primary-foreground">
                <BadgeCheck className="size-6 shrink-0" />
              </span>
            </TooltipButton>
          ) : (
            <TooltipButton
              text={
                isAdminInOrg
                  ? "Please complete verification by filling out all location fields"
                  : "Send an invitation email to this location"
              }
            >
              <div className="flex items-center gap-2">
                <Button
                  variant="outline"
                  size="tiny"
                  className="rounded-full"
                  onClick={() => onInvite(row.original._id)}
                  disabled={isAdminInOrg}
                >
                  {!isAdminInOrg && <Mail className="mr-2 h-4 w-4" />}
                  {isAdminInOrg ? "Admin Accepted Invite!" : "Send Invite"}
                </Button>
                {row.original?.inviteExpiration && (
                  <span className="text-2xs text-muted-foreground">
                    {row.original.inviteExpiration}
                  </span>
                )}
              </div>
            </TooltipButton>
          )}
        </div>
      );
    },
    enableSorting: true,
    enableHiding: true,
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        className={cn(column.getIsSorted() && "font-semibold", "h-full")}
      >
        Status
        {column.getIsSorted() === "asc" ? (
          <ArrowUp className="ml-2 size-3 shrink-0" />
        ) : column.getIsSorted() === "desc" ? (
          <ArrowDown className="ml-2 size-3 shrink-0" />
        ) : (
          <ArrowUpDown className="ml-2 size-3 shrink-0" />
        )}
      </Button>
    ),
    sortingFn: (rowA, rowB) => {
      const valueA = rowA.getValue("requiresOnboarding");
      const valueB = rowB.getValue("requiresOnboarding");
      if (valueA === valueB) return 0;
      return valueA ? -1 : 1; // true values come first
    },
  },
  ...(Object.entries(locationSchema.shape)
    .filter(([k]) => k !== "name")
    .map(([key, value]) => {
      // NOTE: I am using ZodUnion for optional types that are either a string or an empty string...
      if (value instanceof ZodString || value instanceof ZodUnion) {
        return {
          id: key,
          accessorFn: (row) => row?.location?.[key],
          cell: (props) => (
            <EditableCell
              {...props}
              defaultIsEditing={
                key === focusColumnKey &&
                focusLocationId === props.row.original._id
              }
            />
          ),
          enableSorting: true,
          enableHiding: true,
          header: ({ column }) => (
            <Button
              variant="ghost"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
              className={cn(column.getIsSorted() && "font-semibold", "h-full")}
            >
              {convertPropertyToReadableFormat(key)}
              {column.getIsSorted() === "asc" ? (
                <ArrowUp className="ml-2 size-3 shrink-0" />
              ) : column.getIsSorted() === "desc" ? (
                <ArrowDown className="ml-2 size-3 shrink-0" />
              ) : (
                <ArrowUpDown className="ml-2 size-3 shrink-0" />
              )}
            </Button>
          ),
        };
      } else if (key === "hours") {
        return {
          id: key,
          accessorFn: (row) => row?.location?.[key],
          cell: ({ getValue }) => {
            0;
            const hours = getValue();
            const days = [
              "sunday",
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
            ];
            return hours ? (
              <div className="flex flex-row gap-2 text-xs">
                {days.map((day) => (
                  <div key={day} className="flex flex-col items-center">
                    <span
                      className={cn(
                        "flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-muted-foreground font-semibold text-primary-foreground",
                        hours[day].is_open && "bg-accent-blue",
                      )}
                    >
                      {capitalize(day.slice(0, 2))}
                    </span>
                    {hours[day].is_open ? (
                      <span className="text-2xs">
                        {hours[day].open.split(" ")[0]}-
                        {hours[day].close.split(" ")[0]}
                      </span>
                    ) : (
                      <span className="text-2xs text-muted-foreground">
                        Closed
                      </span>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <i className="rounded-md border border-dashed bg-primary/[0.02] px-1 py-0.5 text-2xs text-muted-foreground">
                Empty
              </i>
            );
          },
          enableSorting: false,
          enableHiding: true,
          header: convertPropertyToReadableFormat(key),
        };
      } else {
        // For non-string types, we'll use a different cell renderer
        return {
          id: key,
          accessorFn: (row) => row?.location?.[key],
          cell: ({ getValue }) => <div>{JSON.stringify(getValue())}</div>,
          enableSorting: false,
          enableHiding: true,
          header: convertPropertyToReadableFormat(key),
        };
      }
    }) as any),
];

type LocationsTableProps = {
  focusLocationId?: string;
  focusColumnKey?: string;
};
export default function LocationsTable({
  focusLocationId,
  focusColumnKey,
}: LocationsTableProps) {
  const dispatch = useDispatch();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const { hub, getHub } = useOutletContext<HubContext>() ?? {};
  const [open, setOpen] = useState<boolean>(false);
  const [manageWorkspace, setManageWorkspace] = useState<string | null>(null);
  const [manageLocation, setManageLocation] = useState<string | undefined>(
    undefined,
  );

  const { data, isLoading } = useQuery<LocationEntry[]>({
    queryKey: ["hubOrgs", hub._id],
    queryFn: async () => {
      const orgs = await API.getHubOrgs(hub._id);
      const formatted =
        orgs?.flatMap((org: Partial<OrganizationType>) =>
          (org?.workspaces as Team[])?.map((workspace: Partial<Team>) => {
            const adminEmail = workspace?.location?.admin_email;
            const inviteTimestamp =
              adminEmail && org.inviteTimestamps?.[sanitizeEmail(adminEmail)];

            return {
              ...workspace,
              seats: org.seats?.map((s) => s.email),
              inviteExpiration: inviteTimestamp
                ? dayjs(inviteTimestamp).add(1, "week").isBefore(dayjs())
                  ? "Invite Expired"
                  : `Expires ${dayjs(inviteTimestamp).add(1, "week").fromNow()}`
                : undefined,
              organization_name:
                org.name ||
                `${
                  (org.owner as UserAccount)?.firstname ||
                  (org.owner as UserAccount)?.email
                }'s Organization`,
              orgs: orgs.map((org) => ({
                _id: org._id,
                name: org.name,
                owner_email: org.owner?.email,
              })),
            };
          }),
        ) ?? [];

      const visibility = {
        organization_name: !formatted.every(
          (team) => team.organization_name === formatted[0]?.organization_name,
        ),
      };
      setColumnVisibility((prev) => ({ ...prev, ...visibility }));

      return formatted as LocationEntry[];
    },
    enabled: !!hub._id,
  });

  const connectWorkspaceMutation = useMutation({
    mutationFn: (ids: string[]) => API.bindHubToManyWorkspaces(hub._id, ids),
    onSuccess: () => {
      getHub();
      dispatch(setSuccess("Locations saved!"));
    },
    onError: (err) => console.error(err),
  });

  const onSelect = (toggledTeams: Record<string, boolean>) => {
    const locationResults = Object.entries(toggledTeams).reduce(
      (newObj, [key, val]) => {
        if (val) {
          newObj.addOrgs.push(key);
        } else {
          newObj.removeOrgs.push(key);
        }
        return newObj;
      },
      { addOrgs: [], removeOrgs: [] },
    );
    const { addOrgs, removeOrgs } = locationResults;
    const updatedSelectedTeamIds = [
      ...hub.bound.map((b) => b._id).filter((w) => !removeOrgs.includes(w)),
      ...addOrgs,
    ];
    connectWorkspaceMutation.mutate(updatedSelectedTeamIds);
  };

  const upsertLocationMutation = useMutation({
    mutationFn: ({
      teamId,
      locationData,
    }: {
      teamId: string;
      locationData: Partial<Location>;
    }) => API.upsertLocation(teamId, locationData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["hubOrgs", hub._id] });
      queryClient.invalidateQueries({ queryKey: ["workspaces"] });
      dispatch(setSuccess("Location auto-saved"));
    },
  });

  const handleSelectAll = () => {
    const allSelected = table
      .getFilteredRowModel()
      .rows.every((row) => selectedTeams.includes(row.id));
    const allLocations = table.getFilteredRowModel().rows.reduce((acc, row) => {
      if (!allSelected && !selectedTeams.includes(row.id)) {
        acc[row.id] = true;
      } else if (allSelected) {
        acc[row.id] = false;
      }
      return acc;
    }, {});
    onSelect?.(allLocations);
  };

  const inviteAdminMutation = useMutation({
    mutationFn: (teamId: string) => API.inviteLocationAdmin(teamId),
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries({ queryKey: ["hubOrgs", hub._id] });
      queryClient.invalidateQueries({ queryKey: ["workspaces"] });
      dispatch(setSuccess(message ?? "Invitation sent successfully"));
    },
    onError: (error: AxiosError<{ message: string }>) => {
      dispatch(
        setError(error?.response?.data?.message ?? "Failed to send invitation"),
      );
    },
  });
  const onInvite = (teamId: string) => {
    inviteAdminMutation.mutate(teamId);
  };

  const queryClient = useQueryClient();

  const selectedTeams = hub.bound.map((b) => b._id);

  const table = useReactTable({
    data: data,
    columns: createColumns(
      selectedTeams,
      onSelect,
      handleSelectAll,
      setManageWorkspace,
      setManageLocation,
      onInvite,
      focusColumnKey,
      focusLocationId,
    ),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: "includesString",
    state: { sorting, pagination, globalFilter, columnVisibility },
    getRowId: (row) => row._id,
    onColumnVisibilityChange: setColumnVisibility,
    meta: {
      updateData: (
        teamId: string,
        locationId: string,
        columnId: string,
        value: string,
      ) => {
        const update = { [columnId]: value, _id: locationId };
        // Trigger the mutation to update the backend
        upsertLocationMutation.mutate({
          teamId,
          locationData: update,
        });
      },
    },
  });

  const { pageIndex, pageSize } = pagination;
  const rowCount = data?.length;
  const totalPages = Math.ceil(rowCount / pageSize);
  const pageNumbering: ("..." | number)[] = getPagination(
    pageIndex + 1,
    totalPages,
  );

  if (isLoading || !hub) return <Load />;

  return (
    <div className="overflow-hidde flex h-full max-h-full min-h-[400px] w-full flex-col gap-2 p-2 pb-6">
      <div className="flex h-auto w-full items-center justify-between px-1">
        <div className="flex w-fit items-center justify-between gap-4">
          <h1 className="text-center text-2xl font-bold">Locations</h1>

          <HoverCard>
            <HoverCardTrigger asChild>
              <Button variant="outlineFill" size="tinycircle">
                <InfoIcon className="size-4" />
              </Button>
            </HoverCardTrigger>
            <HoverCardContent className="text-xs">
              All of the locations for <strong>{hub.name}</strong> that have
              workspaces in Flamel.ai. When creating organic posts or paid ads,
              this information informs <i>dynamic content personalization</i> to
              generate location-specific content.
            </HoverCardContent>
          </HoverCard>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-3">
            {onSelect && (
              <div className="flex-1 text-sm text-accent-blue">
                {selectedTeams?.length} of {pluralize(data?.length, "location")}{" "}
                selected
              </div>
            )}
          </div>
          <div className="relative">
            <Search className="absolute left-3 top-1/2 z-20 h-4 w-4 -translate-y-1/2 transform text-muted-foreground" />
            <Input
              placeholder="Filter locations..."
              value={globalFilter ?? ""}
              onChange={(e) => setGlobalFilter(e.target.value)}
              className="max-w-sm pl-10 pr-4"
            />
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild disabled={data?.length < 25}>
              <Button
                variant="outline"
                className="text-xs"
                disabled={data?.length < 25}
              >
                {pagination.pageSize} per page{" "}
                <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {[25, 50, 75, 100].map((pageSize) => (
                <DropdownMenuItem
                  key={pageSize}
                  onClick={() =>
                    setPagination((prev) => {
                      const newPageSize = pageSize;
                      const totalPages = Math.ceil(data?.length / newPageSize);
                      const newPageIndex =
                        prev.pageIndex >= totalPages ? 0 : prev.pageIndex;
                      return { pageIndex: newPageIndex, pageSize: newPageSize };
                    })
                  }
                >
                  {pageSize} per page
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          <MultiSelect
            className="max-w-xs"
            value={table
              .getAllColumns()
              .filter((column) => column.getIsVisible())
              .map((column) => column.id)}
            onValueChange={(value) => {
              table.getAllColumns().forEach((column) => {
                column.toggleVisibility(value.includes(column.id));
              });
            }}
          >
            {table.getAllColumns().map((column) => (
              <MultiSelectItem key={column.id} value={column.id}>
                <span className="flex items-center gap-1">
                  {convertPropertyToReadableFormat(column.id)}
                </span>
              </MultiSelectItem>
            ))}
          </MultiSelect>
          <AlertDialog
            open={open || !!manageLocation}
            onOpenChange={(open) => {
              setOpen(open);
              if (!open) setManageLocation(undefined);
            }}
          >
            <AlertDialogTrigger asChild>
              <Button>
                <Plus className="size-4" />
                New Location
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="flex h-full max-h-[90%] max-w-[90%] flex-col gap-4 bg-muted p-2">
              <AlertDialogHeader className="relative h-fit pt-2 sm:text-center">
                <AlertDialogTitle>
                  {manageLocation
                    ? `Manage Location - ${
                        data?.find((l) => l._id === manageLocation)?.name
                      }`
                    : "Create New Location"}
                </AlertDialogTitle>
                <Button
                  variant="outlineFill"
                  size="tiny"
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpen(false);
                    setManageLocation(undefined);
                  }}
                  className="absolute right-0 top-1/2 -translate-y-1/2"
                >
                  <X className="h-4 w-4" id="close-modal" />
                  <span className="sr-only">Close</span>
                </Button>
              </AlertDialogHeader>
              <LocationDetailsForm
                teamId={manageLocation}
                onSuccess={() =>
                  getHub().then(() => {
                    queryClient.invalidateQueries({
                      queryKey: ["hubOrgs", hub._id],
                    });
                    queryClient.invalidateQueries({ queryKey: ["workspaces"] });
                    setOpen(false);
                    setManageLocation(undefined);
                  })
                }
                organizations={data?.[0]?.orgs}
              />
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>
      <ScrollArea className="h-auto max-w-full rounded-md border">
        <ScrollBar orientation="horizontal" className="bg-gray-200" />
        <ScrollBar orientation="vertical" className="bg-gray-200" />
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                className="border-b border-gray-200 bg-gray-50"
              >
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    className="px-6 py-3 text-center font-medium tracking-wider text-gray-500"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, i) => (
                <TableRow
                  key={row.id}
                  data-state={selectedTeams.includes(row.id) && "selected"}
                  className={cn(
                    "transition-colors hover:bg-gray-100",
                    i % 2 === 0 ? "" : "bg-gray-50",
                  )}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-900"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={table.getAllColumns().length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ScrollArea>

      {data?.length > pagination.pageSize && (
        <Pagination className="h-fit">
          <PaginationContent className="w-full justify-between gap-4 px-6 py-3">
            <div className="text-sm text-gray-700">
              {table.getFilteredRowModel().rows.length > 0 && (
                <>
                  Showing{" "}
                  <span className="font-medium">
                    {Math.min(
                      pageIndex * pageSize + 1,
                      table.getFilteredRowModel().rows.length,
                    )}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {Math.min(
                      (pageIndex + 1) * pageSize,
                      table.getFilteredRowModel().rows.length,
                    )}
                  </span>{" "}
                  of{" "}
                </>
              )}
              <span className="font-medium">
                {table.getFilteredRowModel().rows.length}
              </span>{" "}
              results
            </div>
            <div className="flex items-center gap-1 p-2">
              <PaginationItem>
                <PaginationPrevious
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                />
              </PaginationItem>
              {pageNumbering.map((num) => {
                if (num === "...")
                  return (
                    <PaginationItem key={num}>
                      <PaginationEllipsis />
                    </PaginationItem>
                  );

                return (
                  <PaginationItem key={num}>
                    <PaginationLink
                      isActive={num === pageIndex + 1}
                      onClick={() =>
                        setPagination((curr) => ({
                          ...curr,
                          pageIndex: num - 1,
                        }))
                      }
                      disabled={
                        (num < pageIndex + 1 && !table.getCanPreviousPage()) ||
                        (num > pageIndex + 1 && !table.getCanNextPage())
                      }
                    >
                      {num}
                    </PaginationLink>
                  </PaginationItem>
                );
              })}
              <PaginationItem>
                <PaginationNext
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                />
              </PaginationItem>
            </div>
          </PaginationContent>
        </Pagination>
      )}
      <WorkspaceSettingsModal
        show={!!manageWorkspace}
        onHide={() => {
          queryClient.invalidateQueries({ queryKey: ["hubOrgs", hub._id] });
          queryClient.invalidateQueries({ queryKey: ["workspaces"] });
          setManageWorkspace(null);
        }}
        workspace_id={manageWorkspace}
      />
    </div>
  );
}

export function LocationTableDialog({
  open,
  onOpenChange,
  focusLocationId,
  focusColumnKey,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  focusLocationId?: string;
  focusColumnKey?: string;
}) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="flex h-full flex-col gap-4 bg-muted p-2 sm:max-h-full sm:max-w-full">
        <DialogHeader className="relative h-fit pt-2 sm:text-center">
          <DialogTitle>Manage Locations</DialogTitle>
        </DialogHeader>
        <LocationsTable
          focusLocationId={focusLocationId}
          focusColumnKey={focusColumnKey}
        />
      </DialogContent>
    </Dialog>
  );
}
