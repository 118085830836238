import React, { ComponentPropsWithoutRef } from "react";

const CelebrateIcon = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMinYMin slice"
  >
    <defs>
      <mask id="a" x={1} y={1} maskUnits="userSpaceOnUse">
        <circle cx={12} cy={12} r={11} fill="#fff" />
      </mask>
    </defs>
    <path
      fill="none"
      d="M12 0a12 12 0 0 1 12 12 12 12 0 0 1-12 12A12 12 0 0 1 0 12 12 12 0 0 1 12 0z"
    />
    <circle cx={12} cy={12} r={11} fill="#6dae4f" />
    <circle cx={12} cy={12} r={11} fill="#6dae4f" />
    <g mask="url(#a)">
      <path
        fill="#dcf0cb"
        fillRule="evenodd"
        d="m19.86 15-.71-.53s-.29-2.82-.8-3.36a9.23 9.23 0 0 1-1.91-3.75c-.24-.83-.41-1.12-1.16-1.14a1.14 1.14 0 0 0-1 1.26 8.47 8.47 0 0 0 .1 1.13 16.13 16.13 0 0 0 .9 2.89l-.28-.22-6.88-5.2a1.18 1.18 0 0 0-1.74.11 1.11 1.11 0 0 0-.17.92 1.14 1.14 0 0 0 .58.74l3.54 2.66 1.06.8-5.66-4.26a1.18 1.18 0 0 0-.89-.33 1.17 1.17 0 0 0-.84.44 1.11 1.11 0 0 0-.17.92 1.1 1.1 0 0 0 .57.74l3.54 2.66 2.12 1.6-4.6-3.46a1.11 1.11 0 0 0-1.9 1 1.1 1.1 0 0 0 .57.74l3.54 2.66 1.77 1.33-3.54-2.63a1.18 1.18 0 0 0-.9-.35 1.19 1.19 0 0 0-.84.41 1.12 1.12 0 0 0-.19.94 1.15 1.15 0 0 0 .57.77L11 19.38a4.31 4.31 0 0 0 3.28.79l1.06.8a12.33 12.33 0 0 0 2.48-2.57 17.72 17.72 0 0 0 2-3.4z"
      />
      <path fill="#93d870" fillRule="evenodd" d="M15.61 11.76 14.55 11" />
      <path
        fill="none"
        stroke="#165209"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.1 13.94c-.11-.83-.19-3.31-.57-3.71a6.71 6.71 0 0 1-2.09-2.92c-.24-.83-.41-1.12-1.16-1.14a1.14 1.14 0 0 0-1 1.26 8.47 8.47 0 0 0 .1 1.13 20.26 20.26 0 0 0 .9 3.06L8.12 6.25a1.16 1.16 0 0 0-1.74.11A1.16 1.16 0 0 0 6.79 8l3.54 2.68 1.06.8-5.66-4.26a1.18 1.18 0 0 0-.89-.33 1.15 1.15 0 0 0-.84.44 1.12 1.12 0 0 0-.17.92A1.14 1.14 0 0 0 4.4 9l3.54 2.66 2.12 1.6-4.6-3.47a1.14 1.14 0 0 0-.89-.34 1.17 1.17 0 0 0-.85.44 1.11 1.11 0 0 0-.17.92 1.14 1.14 0 0 0 .58.74l3.54 2.67 1.77 1.33-3.54-2.66a1.14 1.14 0 0 0-.9-.33 1.11 1.11 0 0 0-1 1.38 1.16 1.16 0 0 0 .57.76L11 19.38a4.31 4.31 0 0 0 3.28.79"
      />
      <path
        fill="#ddf6d1"
        fillRule="evenodd"
        d="m22.78 15.48-.7-.53s-.3-2.82-.81-3.36a9.35 9.35 0 0 1-1.91-3.75C19.12 7 19 6.72 18.2 6.7a1.08 1.08 0 0 0-.76.42 1.12 1.12 0 0 0-.24.88 8.47 8.47 0 0 0 .1 1.13c.28 1.45.58 2.65.62 2.72l-6.77-5.09a1.18 1.18 0 0 0-1.74.11 1.11 1.11 0 0 0-.17.92 1.14 1.14 0 0 0 .58.74l3.53 2.66 1.07.8-5.66-4.25a1.18 1.18 0 0 0-.9-.35 1.08 1.08 0 0 0-1 1.38 1.19 1.19 0 0 0 .59.73L11 12.17l2.13 1.59-4.64-3.46a1.17 1.17 0 0 0-.9-.33 1.19 1.19 0 0 0-.85.44 1.15 1.15 0 0 0-.16.92 1.14 1.14 0 0 0 .58.74l3.53 2.66 1.77 1.33-3.53-2.66a1.14 1.14 0 0 0-1.71.07 1.12 1.12 0 0 0-.19.94 1.16 1.16 0 0 0 .57.76l6.33 4.74a7.09 7.09 0 0 0 3.1.94 9.75 9.75 0 0 0 1.24.65 5.07 5.07 0 0 0 3.19-2 7.61 7.61 0 0 0 1.32-4.02z"
      />
      <path
        fill="none"
        stroke="#165209"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m7.79 2.72.35 1.56m4.61.35-1.31.92m-.7-3.12-1 2.48"
      />
      <path
        fill="#231f20"
        fillRule="evenodd"
        d="m7.79 2.72.35 1.56m4.61.35-1.31.92m-.7-3.12-1 2.48M7.79 2.72l.35 1.56m4.61.35-1.31.92m-.7-3.12-1 2.48"
      />
      <path
        fill="none"
        d="M7.59 13.8c.89.69 7 5.39 7.68 5.64a3.28 3.28 0 0 0 2.31 0 2.54 2.54 0 0 0 .74-.48m-.26-11.14a18.86 18.86 0 0 0 .69 3.79"
      />
      <path
        fill="none"
        stroke="#165209"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M22.71 15.48A3.24 3.24 0 0 1 22 14.3c-.08-.33-.1-.67-.17-1a3.57 3.57 0 0 0-.56-1.7 9.35 9.35 0 0 1-1.91-3.75C19.12 7 19 6.72 18.2 6.7a1.08 1.08 0 0 0-.76.42 1.12 1.12 0 0 0-.24.88 8.47 8.47 0 0 0 .1 1.13c.28 1.45.58 2.65.62 2.72l-6.77-5.09a1.18 1.18 0 0 0-1.74.11 1.11 1.11 0 0 0-.17.92 1.14 1.14 0 0 0 .58.74l3.53 2.66 1.07.8-5.66-4.25a1.18 1.18 0 0 0-.9-.35 1.08 1.08 0 0 0-1 1.38 1.19 1.19 0 0 0 .59.73L11 12.17l2.13 1.59-4.64-3.46a1.17 1.17 0 0 0-.9-.33 1.19 1.19 0 0 0-.85.44 1.15 1.15 0 0 0-.16.92 1.14 1.14 0 0 0 .58.74l3.53 2.66 1.77 1.33-3.53-2.66a1.14 1.14 0 0 0-1.71.07 1.12 1.12 0 0 0-.19.94 1.16 1.16 0 0 0 .57.76l6.33 4.74a4.12 4.12 0 0 0 1.78.77c.28.06.58.08.91.15a4.41 4.41 0 0 1 1.37.45 1.29 1.29 0 0 0 1 .08 5.85 5.85 0 0 0 2.77-2 5.67 5.67 0 0 0 1.1-3.12 1.34 1.34 0 0 0-.15-.76z"
      />
    </g>
  </svg>
);
export default CelebrateIcon;
