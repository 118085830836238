import axios from "axios";
import { Presets } from "../reducers/toolkit";
import { PlatformType } from "../util/platforms";
import { withQueryParams } from "../util/reusables";
import { Tag } from "../views/Post/components/ImageTagger";
import { UserAccount } from "./auth.api";
import { Campaign } from "./campaign.api";
import { Guest } from "./guest.api";
import { Hub } from "./hub.api";
import { LocationAccountInfo } from "./locations.api";
import { Media } from "./media.api";
import { ApprovalWorkflow } from "./organization.api";
import { Team } from "./team.api";

export type OverridesForPlatform = {
  caption?: string;
  prompt?: string;
  presets?: Presets;
  platformContentType?: string;
  mediaMetadata?: {
    _id: string;
    altText: string;
    accountTags: string[];
  }[];
  metadata?: any;
};

export interface PortfolioItem {
  owner?: string;
  _id?: string;
  campaign?: string | Campaign;
  media?: Media[] | string[] | (string | Media)[];
  description?: string;
  caption?: string;
  metadata?: any;
  prompt?: string;
  /**
   * @deprecated - use `accounts` instead
   */
  platform?: PlatformType;
  /**
   * @deprecated Use `overrides` instead to support multiple platforms + content type combos...
   */
  platformContentType?: string;
  presets?: Presets;
  posts?: string[] | Post[];
  larvalPosts?: string[] | LarvalPost[];
  team?: string;
  hub?: string;
  updatedAt?: string;
  createdAt?: string;
  overrides?: Record<PlatformType, OverridesForPlatform>;
  alt?: Record<string /* mediaId */, string>;
  accountTags?: Record<PlatformType, Record<string, Tag[]>>;
  accounts?: LocationAccountInfo[];
  name?: string;
  topics?: string[];
}

export interface LarvalPost {
  _id: string;
  portfolioItem: PortfolioItem;
  approvalWorkflow?: ApprovalWorkflow;
  awaitingApproval?: string;
  approvalOverrides?: {
    awaitingApprovalBy: string;
    overriddenBy: string;
  }[];
  approvedByGuests?: string[] | any[];
  deniedByGuests?: string[] | any[];
  comments?: Comment[];
  userAccount: string;
  team?: string | Team;
  campaign?: string | Campaign;
  isDraft?: boolean;
  scheduled: {
    timestamp?: number;
  };
  broadcast?: string;
  broadcastParentHub?: string;

  // Copied Post Data
  caption?: string;
  metadata?: any;
  media?: Media[];
  platform: PlatformType;
  /**
   * @deprecated Use `overrides` instead to support multiple platforms + content type combos...
   */
  platformContentType?: string;
  prompt?: string;
  presets?: Presets;
  overrides?: Record<PlatformType, OverridesForPlatform>;
  alt?: Record<string /* mediaId */, string>;
  accountTags?: Record<PlatformType, Record<string, Tag[]>>;
}

export interface Post<T = Record<string, any>> {
  _id: string;
  userAccount?: string;
  portfolioItem?: PortfolioItem;
  style?: string;
  tone?: string;
  audience?: string;
  platformPostId?: string;
  link: string;
  createdAt?: string;
  updatedAt?: string;
  raw?: T;
  isExternal?: boolean;
  tags?: string[];
  sentiment?: string;
  comments?: any[];

  // Post Related
  caption?: string;
  media?: Media[];
  platform: PlatformType;
  platformContentType?: string;
  prompt?: string;
  broadcastParentHub?: string | Hub;
  broadcast?: string | LarvalPost;
  campaign?: string | Campaign;

  // Approval Workflow Related
  approvalWorkflow?: string | ApprovalWorkflow;
  awaitingApproval?: string | UserAccount;
  approvalOverrides?: Array<{
    awaitingApprovalBy: string;
    overriddenBy: string;
  }>;
  approvedByGuests?: string[] | Guest[];
  deniedByGuests?: string[] | Guest[];
}

export interface Comment {
  _id: string;
  user: Partial<UserAccount>;
  guest: Partial<Guest>;
  comment: string;
  createdAt: Date;
}

const createPortfolioItem = (portfolioItem: PortfolioItem) =>
  fetch(`/api/portfolio`, {
    method: "POST",
    body: JSON.stringify({ ...portfolioItem }),
    headers: {
      "Content-Type": "application/json",
    },
  });

export const updatePortfolioItem = async (_id: string, diff: any) =>
  (await axios.patch(`/api/portfolio`, { _id, diff })).data;

export const setPortfolioItemMedia = async (_id: string, mediaIds: string[]) =>
  (
    await axios.post<PortfolioItem>(`/api/portfolio/media`, {
      id: _id,
      mediaIds,
    })
  ).data;

export const setLarvalPostMedia = async (_id: string, mediaIds: string[]) =>
  (
    await axios.post(`/api/calendar/media`, {
      id: _id,
      mediaIds,
    })
  ).data;

const deletePortfolioItem = (portfolioItem: PortfolioItem) =>
  fetch(`/api/portfolio/${portfolioItem._id}`, {
    method: "DELETE",
  });

const getPortfolioItem = async (_id: string) =>
  (await axios.get(`/api/portfolio/${_id}`)).data;

const getPopulatedPortfolioItems = async (hubId?: string) =>
  (
    await axios.get(
      withQueryParams("/api/portfolio", { withLive: true, hub: hubId }),
    )
  ).data;

const duplicatePortfolioItem = (portfolioItem: PortfolioItem) =>
  fetch(`/api/portfolio/${portfolioItem._id}/duplicate`, {
    method: "POST",
  });

const getDrafts = async (hubId: string | undefined, limit?: number) =>
  (
    await axios.get("/api/portfolio/drafts", {
      params: {
        hubId,
        limit,
      },
    })
  ).data;

export interface PortfolioAPI {
  createPortfolioItem: (
    portfolioItem: Partial<PortfolioItem>,
  ) => Promise<Response>;
  deletePortfolioItem: (portfolioItem: PortfolioItem) => Promise<Response>;
  getPortfolioItem: (_id?: string) => Promise<PortfolioItem>;
  getPopulatedPortfolioItems: (hubId?: string) => Promise<PortfolioItem[]>;
  duplicatePortfolioItem: (portfolioItem: PortfolioItem) => Promise<Response>;
  getDrafts: (
    hubId: string | undefined,
    limit?: number,
  ) => Promise<PortfolioItem[]>;
}

export default {
  createPortfolioItem,
  deletePortfolioItem,
  getPortfolioItem,
  getPopulatedPortfolioItems,
  duplicatePortfolioItem,
  getDrafts,
} as PortfolioAPI;
