import { UserAccount } from "./auth.api";
import { PortfolioItem } from "./portfolio.api";
import { Team } from "./team.api";

export interface Campaign {
  _id?: string;
  userAccount?: string | UserAccount;
  team?: string | Team;
  portfolioItems?: string[] | PortfolioItem[];
  name: string;
  color: string;
  start: string;
  end: string;
}

const createCampaign = (campaign: Campaign) =>
  fetch(`/api/campaigns/`, {
    method: "POST",
    body: JSON.stringify(campaign),
    headers: {
      "Content-Type": "application/json",
    },
  });

const getCampaigns = () => fetch(`/api/campaigns`);

const getCampaign = (id: string) => fetch(`/api/campaigns/${id}`);

const updateCampaign = (campaignId: string, diff: any) =>
  fetch(`/api/campaigns/${campaignId}`, {
    method: "PATCH",
    body: JSON.stringify(diff),
    headers: {
      "Content-Type": "application/json",
    },
  });

const deleteCampaign = (campaignId: string) =>
  fetch(`/api/campaigns/${campaignId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });

export interface CampaignAPI {
  createCampaign: (campaign: Campaign) => Promise<Response>;
  getCampaigns: () => Promise<Response>;
  getCampaign: (id: string) => Promise<Response>;
  updateCampaign: (campaignId: string, diff: any) => Promise<Response>;
  deleteCampaign: (campaignId: string) => Promise<Response>;
}

export default {
  createCampaign,
  getCampaigns,
  getCampaign,
  updateCampaign,
  deleteCampaign,
};
