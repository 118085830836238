import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import userReducer from "./reducers/user";
import toolkitReducer from "./reducers/toolkit";
import lunaReducer from "./reducers/luna";
import analyticsReducer from "./reducers/analytics";

import analyticsApi from "./api/analytics.api";

const userPersistConfig = {
  key: "user",
  storage: storage,
};
const persistedUserReducer = persistReducer(userPersistConfig, userReducer);

const toolkitPersistConfig = {
  key: "toolkit",
  storage: storage,
  blacklist: [
    "preset",
    "pendingTimestamp",
    "campaign",
    "larvalPost",
    "portfolioItem",
    "withPlatform",
    "selectedAccounts",
  ],
};
const persistedToolkitReducer = persistReducer(
  toolkitPersistConfig,
  toolkitReducer,
);

const analyticsPersistConfig = {
  key: "analytics",
  storage: storage,
  blacklist: ["selectedPlatforms", "dateRangeValue"],
};
const persistedAnalyticsReducer = persistReducer<
  ReturnType<typeof analyticsReducer>
>(analyticsPersistConfig, analyticsReducer);

// const lunaPersistConfig = {
//   key: "luna",
//   storage: storage,
// };
// const persistedLunaReducer = persistReducer(lunaPersistConfig, lunaReducer);

export const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    toolkit: persistedToolkitReducer,
    // luna: persistedLunaReducer,
    luna: lunaReducer,
    analytics: persistedAnalyticsReducer,
    [analyticsApi.reducerPath]: analyticsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat([thunk])
      .concat(analyticsApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);
