import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Module } from "../../api/auth.api";
import { RootState } from "../../store";

export default function Error() {
  const user = useSelector((state: RootState) => state.user);
  const basePath = user?.module === Module.Paid ? "/paid" : "/";

  // This fixes the issue where the user is redirected to the 404 page when switching focused context (hub to workspace, etc.)
  return <Navigate to={basePath} />;

  // return (
  //   <div className="grid min-h-full place-items-center bg-background px-6 py-24 sm:py-32 lg:px-8">
  //     <div className="text-center">
  //       <p className="text-base font-semibold text-orange-500">404</p>
  //       <h1 className="mt-4 text-3xl font-bold tracking-tight text-primary sm:text-5xl">
  //         Page not found
  //       </h1>
  //       <p className="mt-6 text-base leading-7 text-secondary-foreground">
  //         Sorry, we couldn&apos;t find the page you&apos;re looking for.
  //       </p>
  //       <div className="mt-10 flex items-center justify-center gap-x-6">
  //         <Button asChild>
  //           <Link
  //             to={user?.hub ? `/hub/${user?.hub?._id}${basePath}` : basePath}
  //             className="flex gap-2"
  //           >
  //             Go back home
  //           </Link>
  //         </Button>

  //         <a
  //           href="mailto:hello@flamel.ai"
  //           className="text-sm font-semibold text-primary hover:underline"
  //         >
  //           Contact support <span aria-hidden="true">&rarr;</span>
  //         </a>
  //       </div>
  //     </div>
  //   </div>
  // );
}
