import axios from "axios";
import { MailchimpTag } from "../util/chimp";
import { ObjectValues } from "../util/reusables";
import { Hub } from "./hub.api";
import { OrganizationType } from "./organization.api";
import { Team } from "./team.api";

export const Role = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  EDITOR: "EDITOR",
  COMMENTER: "COMMENTER",
} as const;

export type Role = ObjectValues<typeof Role>;

export interface Manifest {
  view_media: boolean;
  edit_media: boolean;
  calendar: boolean;
  edit: boolean;
  design: boolean;
  campaigns: boolean;
  ai: boolean;
  view_organization: boolean;
  manage_organization: boolean;
  organization_owner: boolean;
  full_social: boolean;
  manage_socials: boolean;
  manage_workspace: boolean;
  integrations: boolean;
  analytics: boolean;
  reports: boolean;
  subscription: boolean;
  brand: boolean;
  approval: boolean;
  trial: boolean;
  brain: boolean;
  admin: boolean;
  inbox: boolean;
  sign_out: boolean;
  paid: boolean;
}

/**
 * The sub-app options that the user is focused on.
 * Allow us to support a collection of tools in one platform
 */
export const Module = {
  Organic: "organic",
  Paid: "paid",
} as const;
export type Module = ObjectValues<typeof Module>;

export interface UserAccount {
  _id?: string;
  module?: Module; // defaults to organic
  email: string;
  token?: string;
  password?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  pfp?: string;
  fromSSO?: boolean;
  organization?: OrganizationType;
  organizationRole?: Role;
  defaultTeam?: string;

  // Both scopes saved on the session...
  workspace?: Team;
  hub?: Hub;

  approve?: string[];
  managedBy?: string;
  accountenabled?: boolean;
  subscription?: {
    customerId: string;
    subscriptionId: string;
    plan: string;
    interval: string;
    tier?: string;
    seats?: string;
    workspaces?: number;
  };
  teams?: string[] | Partial<Team>[];
  hubs?: string[] | Partial<Hub>[]; // Hubs this user is an admin of...
  createdAt?: string;
  updatedAt?: string;
  metadata?: {
    industry?: string;
    silenceApprovalWorkflows?: boolean;
    [key: string]: any;
  };
  canva?: {
    userId: string;
    brandId: string;
  };
  pseudo?: boolean;
  manifest?: Manifest;

  embed?: boolean;
  embedBaseUrl?: string;

  // Override system settings
  overrides?: {
    icon: string;
    logo: string;
    poweredByLink: string;
  };
}

const registerUser = (user: UserAccount, tags?: MailchimpTag[]) => {
  const registerUrl = new URL("/api/auth/account/user", window.location.origin);
  tags.forEach((t) => registerUrl.searchParams.append("tags", t));
  return fetch(registerUrl, {
    method: "POST",
    body: JSON.stringify(user),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const deleteUser = () =>
  fetch("/api/auth/account/user", {
    method: "DELETE",
  });

const setUserMetadata = (metadata: { [key: string]: any }) =>
  fetch(`/api/auth/account/user/metadata`, {
    method: "POST",
    body: JSON.stringify({
      metadata,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const updateUser = (update: any) =>
  fetch(`/api/auth/account/user`, {
    method: "PATCH",
    body: JSON.stringify(update),
    headers: {
      "Content-Type": "application/json",
    },
  });

const loginUser = (email: string, password: string) =>
  fetch(`/api/auth/account/user/login`, {
    method: "POST",
    body: JSON.stringify({ email, password }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const resetPassword = (password: string) =>
  fetch(`/api/auth/account/user/password`, {
    method: "PATCH",
    body: JSON.stringify({
      password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const requestMagicLink = (email: string) =>
  fetch(`/api/auth/account/user/magic-link`, {
    method: "POST",
    body: JSON.stringify({
      email,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const loginWithMagicLink = (token: string) =>
  fetch(`/api/auth/account/user/magic-link?magicToken=${token}`);

const getUser = () => fetch("/api/auth/account/user");

export const getSession = (options?: string) =>
  fetch(`/api/auth/account/session${options ?? ""}`).then((data) =>
    data.json(),
  );

const getSessionJwt = () =>
  fetch(`/api/auth/account/session/jwt`).then((data) => data.text());

const allowMarketingEmails = (notify: boolean) =>
  fetch(`/api/auth/account/allow-marketing-emails`, {
    method: "POST",
    body: JSON.stringify({
      notify,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const allowApprovalWorkflowEmails = (notify: boolean) =>
  fetch(`/api/auth/account/allow-approval-workflow-emails`, {
    method: "POST",
    body: JSON.stringify({
      notify,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());

const createBugReport = (report: string) =>
  fetch(`/api/bug`, {
    method: "POST",
    body: JSON.stringify({ report }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const updateBrandSettings = (update: any) =>
  fetch(`/api/auth/account/user/brands`, {
    method: "POST",
    body: update, //not stringified on purpose
    headers: {
      "Content-Type": "application/json",
    },
  });

const updateWorkspace = async (workspaceId: string) =>
  (await axios.patch(`/api/auth/account/workspace?workspaceId=${workspaceId}`))
    .data;

const updateHubSession = async (hubId: string) =>
  (await axios.patch(`/api/auth/account/hub?hubId=${hubId}`)).data;

export const updateUserModule = async (module: Module) => {
  const response = await axios.patch<UserAccount>("/api/auth/account/module", {
    module,
  });
  return response.data;
};

export interface AuthAPI {
  registerUser: (user: UserAccount, tags?: MailchimpTag[]) => Promise<Response>;
  deleteUser: () => Promise<Response>;
  setUserMetadata: (metadata: { [key: string]: any }) => Promise<Response>;
  updateUser: (update: any) => Promise<Response>;
  loginUser: (email: string, password: string) => Promise<Response>;
  resetPassword: (password: string) => Promise<Response>;
  requestMagicLink: (email: string) => Promise<Response>;
  loginWithMagicLink: (token: string) => Promise<Response>;
  getUser: () => Promise<Response>;
  getSession: (query?: string) => Promise<any>;
  getSessionJwt: () => Promise<string>;
  allowMarketingEmails: (notify?: boolean) => Promise<Response>;
  allowApprovalWorkflowEmails: (notify: boolean) => Promise<UserAccount>;
  createBugReport: (report: string) => Promise<Response>;
  updateBrandSettings: (update: any) => Promise<Response>;
  updateWorkspace: (
    workspaceId: string,
  ) => Promise<{ _id: string; name: string }>;
  updateHubSession: (hubId: string) => Promise<{ _id: string; name: string }>;
  updateUserModule: (module: Module) => Promise<UserAccount>;
}

export default {
  registerUser,
  deleteUser,
  setUserMetadata,
  updateUser,
  loginUser,
  resetPassword,
  requestMagicLink,
  loginWithMagicLink,
  getUser,
  getSession,
  getSessionJwt,
  allowMarketingEmails,
  allowApprovalWorkflowEmails,
  createBugReport,
  updateBrandSettings,
  updateWorkspace,
  updateHubSession,
  updateUserModule,
} as AuthAPI;
