import axios from "axios";
import { B64 } from "../util/loaders";
import { UserAccount } from "./auth.api";
import { Team } from "./team.api";

export interface Template {
  _id: string;
  name: string;
  description?: string;
  notes?: string;
  uri?: string;
  editor: string;
  thumbnail: string;
  scope: "personal" | "team" | "official";
  userAccount?: string | UserAccount;
  teams?: string[] | Team[];
  team: string; // The authoring team
  path?: string[];
  createdAt?: string;
  updatedAt?: string;
}

export interface OfficialTemplate {
  _id: string;
  name: string;
  editor: string;
  thumbnail: string;
  type: "template" | "sticker";
  description?: string;
  notes?: string;
  uri?: string;
  path?: string[];
  createdAt?: string;
  updatedAt?: string;
}

const getTemplates = async (hubId?: string) => {
  const params = hubId ? { hubId } : {};
  const response = await axios.get<Template[]>("/api/templates", { params });
  return response.data;
};

const getTemplateById = (id: string): Promise<Template | OfficialTemplate> =>
  fetch(`/api/templates/${id}`).then((res) => res.json());

const getOfficialTemplates = async () => {
  const result = await axios.get(`/api/global-assets/template`);
  return result.data;
};

const createTemplate = (
  template: {
    name: string;
    editor: string;
    description?: string;
    notes?: string;
    path?: string[];
    uri?: string;
    hubId: string;
  },
  b64: B64,
) =>
  fetch(`/api/templates`, {
    method: "POST",
    body: JSON.stringify({ template, b64 }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const createOfficialTemplate = (
  template: Partial<OfficialTemplate>,
  b64: B64,
) =>
  fetch(`/api/global-assets/template`, {
    method: "POST",
    body: JSON.stringify({ template, b64 }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const deleteTemplate = (templateId: string) =>
  fetch(`/api/templates/${templateId}`, {
    method: "DELETE",
  });

const deleteGlobalTemplate = (id: string) =>
  fetch(`/api/global-assets/template/${id}`, {
    method: "DELETE",
  });

const updateTemplate = (templateId: string, diff: any, b64?: B64) =>
  fetch(`/api/templates/${templateId}`, {
    method: "PATCH",
    body: JSON.stringify({ diff, b64 }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const deleteTemplateFolder = async (
  templateIds: string[],
  pseudoPaths: string[][],
  hubId?: string,
) => {
  const params = new URLSearchParams();
  if (hubId) params.append("hubId", hubId);
  templateIds.forEach((id) => params.append("templateId", id));
  pseudoPaths.forEach((pseudo) => params.append("pseudo", pseudo.join("@@@")));

  const response = await axios.delete(`/api/templates/bulk`, {
    params,
    paramsSerializer: (params) => params.toString(),
  });
  return response.data;
};

const changeTemplateFolder = async (
  t: Template | OfficialTemplate,
  path: string[],
  hubId?: string,
) => {
  const changeUrl = `/api/templates/${t._id}/path`;
  const params = hubId ? { hubId } : {};

  const response = await axios.patch<Template | OfficialTemplate>(
    changeUrl,
    { path },
    { params },
  );
  return response.data;
};

export interface TemplateAPI {
  getTemplates: (hubId?: string) => Promise<Template[]>;
  getTemplateById: (id: string) => Promise<Template | OfficialTemplate>;
  getOfficialTemplates: () => Promise<OfficialTemplate[]>;
  createTemplate: (
    template: {
      name: string;
      editor: string;
      path?: string[];
      hubId?: string;
      description?: string;
      notes?: string;
      uri?: string;
    },
    b64: B64,
  ) => Promise<Response>;
  createOfficialTemplate: (
    template: Partial<OfficialTemplate>,
    b64: B64,
  ) => Promise<Response>;
  deleteTemplate: (templateId: string) => Promise<Response>;
  deleteGlobalTemplate: (id: string) => Promise<Response>;
  updateTemplate: (
    templateId: string,
    diff: any,
    b64?: B64,
  ) => Promise<Response>;
  deleteTemplateFolder: (
    templateIds: string[],
    pseudoPaths: string[][],
    hubId?: string,
  ) => Promise<Response>;
  changeTemplateFolder: (
    t: Template,
    path: string[],
    hubId?: string,
  ) => Promise<Template>;
}

export default {
  getTemplates,
  getTemplateById,
  getOfficialTemplates,
  createTemplate,
  createOfficialTemplate,
  deleteTemplate,
  deleteGlobalTemplate,
  updateTemplate,
  deleteTemplateFolder,
  changeTemplateFolder,
} as TemplateAPI;
