import { Info, Mail } from "lucide-react";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { cmsApi } from "../../api/cms.api";
import { Button } from "../../primitives/button";
import ReactMarkdown from "react-markdown";
import { TooltipButton } from "../../util/reusables";

export default function AlertBanner() {
  const { data: alertBanner } = useQuery({
    queryKey: ["cms"],
    queryFn: cmsApi.getAlertBanner,
  });

  if (!alertBanner?.isActive) {
    return null;
  }

  return (
    <div className="flex w-full flex-col items-center justify-between gap-2 gap-x-6 bg-primary px-6 py-2.5 text-center text-xs text-white sm:px-3.5 md:flex-row">
      <div className="flex w-64 items-center justify-center md:justify-start">
        <strong className="whitespace-nowrap text-sm font-semibold">
          {alertBanner?.title}
        </strong>
      </div>
      <div className="w-full grow text-balance">
        <ReactMarkdown
          linkTarget="_blank"
          className="prose prose-invert max-w-none text-xs prose-a:underline prose-a:hover:opacity-90"
        >
          {alertBanner?.content}
        </ReactMarkdown>
      </div>
      <div className="flex w-64 items-center justify-center gap-2 md:justify-end">
        {alertBanner?.support?.message && (
          <TooltipButton text={alertBanner?.support?.message}>
            <Button variant="ghost" size="tinycircle">
              <Info className="size-3" />
            </Button>
          </TooltipButton>
        )}
        {alertBanner?.support?.email && (
          <Button variant="outlineFill" size="xs" asChild>
            <a
              href={`mailto:${alertBanner?.support?.email}`}
              className="flex items-center gap-1"
            >
              <Mail className="size-3" />
              Support
            </a>
          </Button>
        )}
      </div>
    </div>
  );
}
