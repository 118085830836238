import { ObjectValues } from "../util/reusables";

import axios from "axios";

export const PseudostructureType = {
  media: "media",
  template: "template",
  concept: "concept",
  copy: "copy",
} as const;
export type PseudostructureType = ObjectValues<typeof PseudostructureType>;
export type IPseudostructures = {
  [key in PseudostructureType]: string[][];
};

const getPseudostructures = async (hubId?: string) => {
  const pseudosUrl = new URL("/api/foldering", window.location.origin);
  if (hubId) pseudosUrl.searchParams.set("hubId", hubId);
  const results = await axios.get<IPseudostructures>(pseudosUrl.toString());
  return results.data;
};

const createPseudostructure = (
  type: PseudostructureType,
  path: string[],
  hubId?: string,
) =>
  fetch(`/api/foldering${hubId ? "?hubId=" + hubId : ""}`, {
    method: "POST",
    body: JSON.stringify({
      [type]: path,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const deletePseudostructure = (
  type: PseudostructureType,
  path: string[],
  hubId?: string,
) =>
  fetch(`/api/foldering${hubId ? "?hubId=" + hubId : ""}`, {
    method: "DELETE",
    body: JSON.stringify({
      [type]: path,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

export interface FolderingAPI {
  getPseudostructures: (hubId?: string) => Promise<IPseudostructures>;
  createPseudostructure: (
    type: PseudostructureType,
    path: string[],
    hubId?: string,
  ) => Promise<Response>;
  deletePseudostructure: (
    type: PseudostructureType,
    path: string[],
    hubId?: string,
  ) => Promise<Response>;
}

export default {
  getPseudostructures,
  createPseudostructure,
  deletePseudostructure,
} as FolderingAPI;
