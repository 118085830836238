import axios from "axios";

export interface ICmsAlertBanner {
  title: string;
  content: string;
  isActive: boolean;
  support?: {
    email: string;
    message: string;
  };
}

export interface ICmsData {
  alertBanner: ICmsAlertBanner;
  _id: string;
}

export const cmsApi = {
  /**
   * Fetches the CMS singleton data
   * @returns Promise containing CMS data
   */
  getCms: async (): Promise<ICmsData> => {
    const { data } = await axios.get<ICmsData>("/api/cms");
    return data;
  },

  getAlertBanner: async (): Promise<ICmsAlertBanner> => {
    const { data } = await axios.get<ICmsData>("/api/cms");
    return data?.alertBanner;
  },

  /**
   * Updates the CMS singleton data
   * @param updates Partial CMS data to update
   * @returns Promise containing updated CMS data
   */
  updateCms: async (updates: Partial<ICmsData>): Promise<ICmsData> => {
    const { data } = await axios.put<ICmsData>("/api/cms", updates);
    return data;
  },
};
