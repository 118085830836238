const BASE_URL = "/api/canva";

const completeCanvaConfiguration = (
  canva_user_token: string,
  state: string,
  nonce: string,
  isManual = false,
) =>
  window.location.replace(
    `${BASE_URL}/configuration/complete?canva_user_token=${canva_user_token}&state=${state}&nonce=${nonce}&isManual=${isManual}`,
  );

const deleteCanvaConfiguration = (userId: string) =>
  fetch(`${BASE_URL}/configuration/delete/internal`, {
    method: "PATCH",
    body: JSON.stringify({
      userId,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

export interface CanvaAPI {
  completeCanvaConfiguration: (
    canva_user_token: string,
    state: string,
    nonce: string,
    completeCanvaConfiguration: boolean,
  ) => void;
  deleteCanvaConfiguration: (userId: string) => Promise<Response>;
}

export default {
  completeCanvaConfiguration,
  deleteCanvaConfiguration,
} as CanvaAPI;
