import { ObjectValues } from "./reusables";

// NOTE: Make sure to keep in sync with backend
export const MailchimpTag = {
  // Journey
  Trial: "Trial",
  Demo: "Demo",
  Free: "Free",
  Member: "Org Member",
  Paid: "Paid",
  Churned: "Churned",

  // Tier
  Professional: "Professional Tier",
  Team: "Team Tier",
  Growth: "Growth Tier",

  // Source
  Franchisee: "Franchisee",
  Franchisor: "Franchisor",
  Agency: "Agency",
  Enterprise: "Enterprise",
  General: "Main Landing",
} as const;
export type MailchimpTag = ObjectValues<typeof MailchimpTag> | string;

const GeneralEntryTags = [
  MailchimpTag.General,
  MailchimpTag.Free,
  MailchimpTag.Trial,
];
export const getMailchimpEntryTagFromPathname = (
  pathname: string,
): MailchimpTag[] =>
  ({
    "/franchisee": [
      MailchimpTag.Franchisee,
      MailchimpTag.Free,
      MailchimpTag.Trial,
    ],
    "/franchisor": [MailchimpTag.Franchisor, MailchimpTag.Demo],
    "/agency": [MailchimpTag.Agency, MailchimpTag.Demo],
    "/": GeneralEntryTags,
  }?.[pathname] ?? GeneralEntryTags);
