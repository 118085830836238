const getUploads = () => fetch(`/api/uploads`);

const createUpload = (name: string, tags: string[], mimeType: string) =>
  fetch(`/api/uploads/`, {
    method: "POST",
    body: JSON.stringify({
      mimeType,
      name,
      tags,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const attatchUploadMedia = (uploadId: string, blob: File) => {
  const fed = new FormData();
  fed.append("file", blob);

  return fetch(`/api/uploads/${uploadId}/multipart`, {
    method: "POST",
    body: fed,
  });
};

const updateUpload = (uploadId: string, diff: any) =>
  fetch(`/api/uploads/${uploadId}`, {
    method: "PATCH",
    body: JSON.stringify(diff),
    headers: {
      "Content-Type": "application/json",
    },
  });

const deleteUpload = (uploadId: string) =>
  fetch(`/api/uploads/${uploadId}`, {
    method: "DELETE",
  });

const getUniqueUploadTags = () => fetch(`/api/uploads/tags`);

const getUploadByTag = (tag: string) => fetch(`/api/uploads/tag/${tag}`);

const streamUpload = (uploadId: string) =>
  fetch(`/api/upload/stream/${uploadId}`);

export interface UploadAPI {
  getUploads: () => Promise<Response>;
  createUpload: (
    name: string,
    tags: string[],
    mimeType: string,
  ) => Promise<Response>;
  attatchUploadMedia: (uploadId: string, blob: File) => Promise<Response>;
  updateUpload: (uploadId: string, diff: any) => Promise<Response>;
  deleteUpload: (uploadId: string) => Promise<Response>;
  getUniqueUploadTags: () => Promise<Response>;
  getUploadByTag: (tag: string) => Promise<Response>;
  streamUpload: (uploadId: string) => Promise<Response>;
}

export default {
  getUploads,
  createUpload,
  attatchUploadMedia,
  updateUpload,
  deleteUpload,
  getUniqueUploadTags,
  getUploadByTag,
  streamUpload,
} as UploadAPI;
