import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "~/src/util/reusables";

const buttonVariants = cva(
  "inline-flex items-center gap-1 justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed ring-offset-background",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/80",
        constructive:
          "bg-green-600 text-destructive-foreground hover:bg-green-600/80",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/80",
        outlineFill:
          "border border-input text-accent-foreground bg-background hover:bg-background/80",
        outline:
          "border border-input hover:bg-accent hover:text-accent-foreground",
        outlinePaid: "border border-[#0D4949] text-white hover:bg-[#0D4949]/80",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        warning:
          "border border-yellow-700 text-yellow-700 hover:bg-yellow-700 hover:text-white transition-all",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        ghostDark:
          "text-accent/90 hover:bg-accent-foreground hover:text-accent ring-offset-foreground hover:bg-white/10",
        transparent: "hover:bg-transparent hover:text-current",
        link: "underline-offset-4 hover:underline text-primary",
      },
      size: {
        default: "h-10 py-2 px-4",
        tiny: "rounded px-[4px] py-[2px] text-xs",
        tinycircle: "rounded-full px-[2px] py-[2px] text-xs h-min",
        xs: "rounded px-[8px] py-[4px] text-xs",
        sm: "h-9 px-3 rounded-md",
        lg: "h-11 px-8 rounded-md",
        icon: "h-10 w-10 rounded-md",
        "icon-sm": "h-8 w-8 rounded-md",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
