import { Outlet, useOutletContext, useSearchParams } from "react-router-dom";
import React from "react";
import { Hub } from "~/src/api/hub.api";
import { DateRangePickerValue } from "@tremor/react";
import dayjs from "../util/dayjs";
import { PlatformType } from "../util/platforms";
import { HubContext } from "./HubLayout";

export type AnalyticsContext = {
  hub: Hub;
  dateRange: DateRangePickerValue;
  setDateRange: (dr: DateRangePickerValue) => void;
};

export default function AnalyticsLayout() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { hub } = useOutletContext<HubContext>() ?? {};

  const dateRange: DateRangePickerValue = {
    from: dayjs(
      searchParams.get("from") ??
        dayjs().subtract(31, "day").format("YYYY-MM-DD"),
    ).toDate(),
    to: dayjs(
      searchParams.get("to") ?? dayjs().subtract(1, "day").format("YYYY-MM-DD"),
    ).toDate(),
    selectValue: searchParams.get("selectValue"),
  };
  const setDateRange = (dr: DateRangePickerValue) => {
    setSearchParams((params) => {
      params.set("from", dayjs(dr.from).format("YYYY-MM-DD"));
      params.set("to", dayjs(dr.to).format("YYYY-MM-DD"));
      if (dr.selectValue) {
        params.set("selectValue", dr.selectValue);
      } else {
        params.delete("selectValue");
      }

      return params;
    });
  };

  return (
    <div className="flex min-h-full flex-1 flex-col gap-4 overflow-auto p-2 [&>*:last-child]:pb-8">
      <Outlet
        context={
          {
            hub,
            dateRange,
            setDateRange,
          } as AnalyticsContext
        }
      />
    </div>
  );
}

export const configByPlatform = {
  [PlatformType.Facebook]: {
    border: "border-[#1A77F180]",
    text: "text-[#1A77F1]",
    likeField: "reactions",
    commentField: "comments",
    shareField: "shares",
    shareField2: undefined,
    permalink: "permalink_url",
    impressionsField: "post_impressions",
  },
  [PlatformType.Instagram]: {
    border: "border-[#D400C180]",
    text: "text-[#DD00AF]",
    likeField: "likes",
    commentField: "comments",
    shareField: "shares",
    shareField2: undefined,
    permalink: "permalink",
    impressionsField: "impressions",
  },
  [PlatformType.LinkedIn]: {
    border: "border-[#0E61B980]",
    text: "text-[#0E61B9]",
    likeField: "likeCount",
    commentField: "commentCount",
    shareField: "shareCount",
    shareField2: undefined,
    permalink: "permalink",
    impressionsField: "impressionCount",
  },
  [PlatformType.Twitter]: {
    border: "border-[#00000080]",
    text: "text-[#060606]",
    likeField: "like_count",
    commentField: "reply_count",
    shareField: "retweet_count",
    shareField2: "quote_count",
    permalink: "permalink",
    impressionsField: "impression_count",
  },
};
