import axios from "axios";
import {
  Box,
  Briefcase,
  CreditCard,
  Home,
  LucideIcon,
  Megaphone,
  MessagesSquare,
  MousePointer,
  ThumbsUp,
  Vote,
} from "lucide-react";
import { z } from "zod";
import { ObjectValues } from "../../../util/reusables";
import { MetaAdLabel } from "./adlabels.api";

export const SpecialAdCategory = {
  Credit: "CREDIT",
  Employment: "EMPLOYMENT",
  Housing: "HOUSING",
  SocialIssuesElectionsPolitics: "ISSUES_ELECTIONS_POLITICS",
} as const;
export type SpecialAdCategory = ObjectValues<typeof SpecialAdCategory>;

export const PaidCampaignStatus = {
  ACTIVE: "ACTIVE",
  ARCHIVED: "ARCHIVED",
  DELETED: "DELETED",
  PAUSED: "PAUSED",
} as const;
export type PaidCampaignStatus = ObjectValues<typeof PaidCampaignStatus>;

export const PaidCampaignObjective = {
  Awareness: "OUTCOME_AWARENESS",
  Traffic: "OUTCOME_TRAFFIC",
  Engagement: "OUTCOME_ENGAGEMENT",
  Leads: "OUTCOME_LEADS",
  AppPromotion: "OUTCOME_APP_PROMOTION",
  Sales: "OUTCOME_SALES",
} as const;
export type PaidCampaignObjective = ObjectValues<typeof PaidCampaignObjective>;

export const IMetaAdCampaign = z.object({
  _id: z.string().describe("Mongo Object ID"),
  id: z.string().describe("Campaign's ID"),
  name: z.string().describe("Campaign's name"),
  objective: z
    .enum(Object.values(PaidCampaignObjective) as [string, ...string[]])
    .describe("Campaign's objective"),
  status: z
    .enum(Object.values(PaidCampaignStatus) as [string, ...string[]])
    .describe("Campaign status"),
  special_ad_categories: z
    .array(
      z.enum([
        "CREDIT",
        "EMPLOYMENT",
        "HOUSING",
        "ISSUES_ELECTIONS_POLITICS",
        "NONE",
        "ONLINE_GAMBLING_AND_GAMING",
      ]),
    )
    .describe("The campaign's Special Ad Category"),
  account_id: z
    .string()
    .describe("ID of the ad account that owns this campaign"),

  // Optional fields
  adlabels: z
    .array(MetaAdLabel)
    .optional()
    .describe("Ad Labels associated with this campaign"),
  bid_strategy: z
    .enum([
      "LOWEST_COST_WITHOUT_CAP",
      "LOWEST_COST_WITH_BID_CAP",
      "COST_CAP",
      "LOWEST_COST_WITH_MIN_ROAS",
    ])
    .optional()
    .describe(
      "Bid strategy for this campaign when you enable campaign budget optimization and when you use AUCTION as your buying type",
    ),
  boosted_object_id: z
    .string()
    .optional()
    .describe("The Boosted Object this campaign has associated, if any"),
  brand_lift_studies: z
    .array(z.any())
    .optional()
    .describe("Automated Brand Lift V2 studies for this ad set"),
  budget_rebalance_flag: z
    .boolean()
    .optional()
    .describe(
      "Whether to automatically rebalance budgets daily for all the adsets under this campaign",
    ),
  budget_remaining: z.string().optional().describe("Remaining budget"),
  buying_type: z
    .string()
    .optional()
    .describe(
      "Buying type, possible values are AUCTION (default) or RESERVED (for reach and frequency ads)",
    ),
  campaign_group_active_time: z
    .string()
    .optional()
    .describe("Active running length of Campaign Groups"),
  can_create_brand_lift_study: z
    .boolean()
    .optional()
    .describe(
      "If we can create a new automated brand lift study for the ad set",
    ),
  can_use_spend_cap: z
    .boolean()
    .optional()
    .describe("Whether the campaign can set the spend cap"),
  configured_status: z
    .enum(["ACTIVE", "PAUSED", "DELETED", "ARCHIVED"])
    .optional()
    .describe("Campaign status"),
  created_time: z.string().datetime().optional().describe("Created Time"),
  daily_budget: z.string().optional().describe("Daily budget"),
  execution_options: z
    .array(z.enum(["validate_only", "include_recommendations"]))
    .optional()
    .describe("Execution options for the campaign"),
  effective_status: z
    .enum([
      "ACTIVE",
      "PAUSED",
      "DELETED",
      "ARCHIVED",
      "IN_PROCESS",
      "WITH_ISSUES",
    ])
    .optional()
    .describe("Effective status of the campaign"),
  has_secondary_skadnetwork_reporting: z
    .boolean()
    .optional()
    .describe("has_secondary_skadnetwork_reporting"),
  is_budget_schedule_enabled: z
    .boolean()
    .optional()
    .describe("Whether budget scheduling is enabled for the campaign group"),
  is_skadnetwork_attribution: z
    .boolean()
    .optional()
    .describe(
      "When set to true Indicates that the campaign will include SKAdNetwork, iOS 14+",
    ),
  issues_info: z
    .array(z.any())
    .optional()
    .describe("Issues for this campaign that prevented it from deliverying"),
  last_budget_toggling_time: z
    .string()
    .datetime()
    .optional()
    .describe("Last budget toggling time"),
  lifetime_budget: z
    .string()
    .optional()
    .describe("The lifetime budget of the campaign"),
  pacing_type: z
    .array(z.string())
    .optional()
    .describe("Defines pacing type of the campaign"),
  promoted_object: z
    .any()
    .optional()
    .describe("The object this campaign is promoting across all its ads"),
  smart_promotion_type: z
    .enum(["GUIDED_CREATION", "AUTOMATED_SHOPPING_ADS", "SMART_APP_PROMOTION"])
    .optional()
    .describe("Smart Promotion Type"),
  source_campaign: z
    .any()
    .optional()
    .describe("The source campaign that this campaign is copied from"),
  source_campaign_id: z
    .string()
    .optional()
    .describe("The source campaign id that this campaign is copied from"),
  /** @deprecated Use 'special_ad_categories' instead. */
  special_ad_category: z
    .string()
    .optional()
    .describe(
      "The campaign's Special Ad Category. One of HOUSING, EMPLOYMENT, CREDIT, or NONE.",
    ),
  special_ad_category_country: z
    .array(z.string())
    .optional()
    .describe("Country field for Special Ad Category"),
  spend_cap: z.string().optional().describe("A spend cap for the campaign"),
  start_time: z
    .string()
    .datetime()
    .optional()
    .describe("Start time for the campaign"),
  stop_time: z
    .string()
    .datetime()
    .optional()
    .describe("Stop time for the campaign"),
  topline_id: z.string().optional().describe("Topline ID"),
  updated_time: z.string().datetime().optional().describe("Updated Time"),
  primary_attribution: z.string().optional().describe("primary_attribution"),
  recommendations: z
    .array(z.any())
    .optional()
    .describe("Recommendations for the campaign"),
  team: z.string().optional().describe("The team associated with the campaign"),
  hub: z.string().optional().describe("The hub associated with the campaign"),
  adsetsLastSyncTimestamp: z
    .number()
    .optional()
    .describe("Last sync timestamp for adsets"),
});
export type IMetaAdCampaign = z.infer<typeof IMetaAdCampaign> & {
  objective: PaidCampaignObjective;
  adset_count: number;
};

export const CreateCampaignSchema = IMetaAdCampaign.pick({
  name: true,
  objective: true,
  status: true,
  special_ad_categories: true,
  special_ad_category_country: true,
  buying_type: true,
  adlabels: true,
  // start_time: true,
  // stop_time: true,
}).extend({
  name: z.string().min(1, "Campaign name is required"),
});

export type CreateCampaignData = z.infer<typeof CreateCampaignSchema>;

export const checkMetaAccountStatus = async (): Promise<{
  success: boolean;
  error?: string;
  message: string;
  route?: string;
  cta?: string;
}> => {
  const response = await axios.get("/api/paid/campaigns/meta/status");
  return response.data;
};

export const createPaidCampaign = async (
  campaignData: CreateCampaignData,
): Promise<IMetaAdCampaign> => {
  const response = await axios.post<IMetaAdCampaign>(
    "/api/paid/campaigns",
    campaignData,
  );
  return response.data;
};

export const updatePaidCampaign = async (
  campaignId: string,
  updateData: Partial<CreateCampaignData>,
): Promise<IMetaAdCampaign> => {
  const response = await axios.patch<IMetaAdCampaign>(
    `/api/paid/campaigns/${campaignId}`,
    updateData,
  );
  return response.data;
};

export const updatePaidCampaignStatus = async (
  campaignId: string,
  on: boolean,
): Promise<IMetaAdCampaign> => {
  const response = await axios.patch<IMetaAdCampaign>(
    `/api/paid/campaigns/${campaignId}/status`,
    { on },
  );
  return response.data;
};

export const getPaidCampaign = async (
  campaignId: string,
): Promise<IMetaAdCampaign> => {
  const response = await axios.get<IMetaAdCampaign>(
    `/api/paid/campaigns/${campaignId}`,
  );
  return response.data;
};

export const listPaidCampaigns = async (): Promise<IMetaAdCampaign[]> => {
  const response = await axios.get<IMetaAdCampaign[]>("/api/paid/campaigns");
  return response.data;
};

export const deletePaidCampaign = async (
  campaignId: string,
): Promise<{ message: string }> => {
  const response = await axios.delete<{ message: string }>(
    `/api/paid/campaigns/${campaignId}`,
  );
  return response.data;
};

export const syncFacebookPaidCampaigns = async (): Promise<{
  campaigns: {
    matched: number;
    modified: number;
    upserted: number;
  };
  adsets: {
    matched: number;
    modified: number;
    upserted: number;
  };
  ads: {
    matched: number;
    modified: number;
    upserted: number;
  };
  adcreatives: {
    matched: number;
    modified: number;
    upserted: number;
  };
  timestamp: number;
}> => {
  const response = await axios.post<{
    campaigns: {
      matched: number;
      modified: number;
      upserted: number;
    };
    adsets: {
      matched: number;
      modified: number;
      upserted: number;
    };
    ads: {
      matched: number;
      modified: number;
      upserted: number;
    };
    adcreatives: {
      matched: number;
      modified: number;
      upserted: number;
    };
    timestamp: number;
  }>("/api/paid/campaigns/sync");
  return response.data;
};

export const OBJECTIVES: Record<PaidCampaignObjective, ObjectiveDetails> = {
  [PaidCampaignObjective.Awareness]: {
    icon: Megaphone,
    label: "Awareness",
    color: "bg-pink-100",
    description:
      "The Awareness objective is designed to maximize the visibility of your brand, product, or service. It aims to reach as many people as possible within your target audience and make them aware of your offerings. This objective is ideal for businesses looking to build brand recognition and familiarity among potential customers.",
    shortDescription:
      "Reach the largest number of people who are likely to remember your ad.",
    disabled: true,
  },
  [PaidCampaignObjective.Traffic]: {
    icon: MousePointer,
    label: "Traffic",
    color: "bg-yellow-100",
    description:
      "The Traffic objective focuses on driving more visitors to your website or app. It's designed to increase the number of clicks on your ads, leading users to your desired destination. This objective is perfect for businesses wanting to boost website visits, app downloads, or engagement with specific landing pages.",
    shortDescription:
      "Send people to a destination like your website, app or Shop.",
  },
  [PaidCampaignObjective.Engagement]: {
    icon: ThumbsUp,
    label: "Engagement",
    color: "bg-purple-100",
    description:
      "The Engagement objective aims to increase interactions with your content, such as likes, comments, shares, and overall participation. It's ideal for businesses looking to build a community around their brand, increase social media presence, or encourage audience participation in campaigns or events.",
    shortDescription:
      "Find people likely to interact with your business online, and take actions like starting a conversation or commenting on posts.",
    disabled: true,
  },
  [PaidCampaignObjective.Leads]: {
    icon: MessagesSquare,
    label: "Leads",
    color: "bg-blue-100",
    description:
      "The Leads objective is tailored to generate potential customer information. It focuses on collecting contact details or other valuable data from interested individuals. This objective is great for businesses looking to build a database of prospective clients for future marketing or sales efforts.",
    shortDescription:
      "Find people interested in your business who are likely to share their contact information.",
  },
  [PaidCampaignObjective.AppPromotion]: {
    icon: Box,
    label: "App Promotion",
    color: "bg-orange-100",
    description:
      "The App Promotion objective is specifically designed to increase app installations and engagement. It targets users who are likely to download and use your app, focusing on app store visits and direct installs. This objective is perfect for businesses launching new apps or looking to grow their existing app user base.",
    shortDescription: "Find people likely to install your app.",
    disabled: true,
  },
  [PaidCampaignObjective.Sales]: {
    icon: Briefcase,
    label: "Sales",
    color: "bg-green-100",
    description:
      "The Sales objective is focused on driving conversions and purchases. It aims to target users who are most likely to complete a transaction, whether it's buying a product online, making a reservation, or subscribing to a service. This objective is ideal for e-commerce businesses or any company looking to directly increase revenue through advertising.",
    shortDescription:
      "Find people likely to make purchases or take other important actions online or in store.",
    disabled: true,
  },
};

export type ObjectiveDetails = {
  icon: LucideIcon;
  label: string;
  color: string;
  description: string;
  shortDescription: string;
  disabled?: boolean;
};

export const getObjective = (
  objective: PaidCampaignObjective | undefined,
): ObjectiveDetails | undefined => {
  if (!objective) return undefined;
  return OBJECTIVES?.[objective];
};

export const SPECIAL_AD_CATEGORIES = {
  [SpecialAdCategory.Credit]: {
    label: "Credit",
    icon: CreditCard,
    color: "bg-green-100",
    description:
      "Ads for credit card offers, auto loans, long-term financing or other related opportunities.",
  },
  [SpecialAdCategory.Employment]: {
    label: "Employment",
    icon: Briefcase,
    color: "bg-blue-100",
    description:
      "Ads for job offers, internships, professional certification programs or other related opportunities.",
  },
  [SpecialAdCategory.Housing]: {
    label: "Housing",
    icon: Home,
    color: "bg-yellow-100",
    description:
      "Ads for real estate listings, homeowners insurance, mortgage loans or other related opportunities.",
  },
  [SpecialAdCategory.SocialIssuesElectionsPolitics]: {
    label: "Social issues, elections or politics",
    icon: Vote,
    color: "bg-purple-100",
    description:
      "Ads about social issues (such as the economy, or civil and social rights), elections, or political figures or campaigns.",
  },
};
