export interface B64 {
  b64: string | ArrayBuffer;
  ext: string;
}

export const extFromDataUri = (dataUri: string): string =>
  dataUri.substring("data:image/".length, dataUri.indexOf(";base64"));

// SOURCE OF TRUTH FOR SUPPORTED IMAGE AND VIDEO TYPES ON THE CLIENT
export const imageTypes = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/webp",
  "image/heic",
  "image/avif",
];
export const fileIsImage = (file: File) => imageTypes.includes(file.type);

export const videoTypes = ["video/quicktime", "video/mp4", "video/webm"];
export const fileIsVideo = (file: File) => videoTypes.includes(file.type);

export const urlToB64 = async (url: string): Promise<B64> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve({
          b64: reader.result,
          ext: extFromDataUri(reader.result as string),
        });
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = (err) => {
      reject(err);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });
};

export const fileToB64 = async (f: File): Promise<B64> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve({
        b64: reader.result,
        ext: extFromDataUri(reader.result as string),
      });
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsDataURL(f);
  });
};

export const read = async (f: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(f);
    reader.addEventListener("load", () => {
      resolve(reader.result);
    });
    reader.addEventListener("error", () => {
      reject();
    });
  });
};

export const unpackQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });
  return params as any;
};

export const packQueryString = (q?: { [key: string]: any }) =>
  q
    ? "?" +
      Object.keys(q)
        .map(
          (e) =>
            `${e}=${typeof q[e] !== "object" ? q[e] : JSON.stringify(q[e])}`,
        )
        .join("&")
    : "";

const createChunk = (file: File, start: number, end: number) => {
  const chunkEnd = Math.min(end, file.size);
  const chunk = file.slice(start, chunkEnd);

  const form = new FormData();
  form.append("file", chunk);

  return form;
};

// const uploadChunk = async (options: {
//   file: File;
//   form: FormData;
//   start: number;
//   end: number;
//   to: string;
//   onProgress: (data: any) => void;
//   onChunk: (start: number, end: number) => void;
// }) => {
//   const req = new XMLHttpRequest();
//   req.upload.addEventListener("progress", options.onProgress);
//   req.open("POST", options.to, true);
//   const blobEnd = options.end - 1;
//   const range = `bytes ${options.start}-${blobEnd}/${options.file.size}`;
//   req.setRequestHeader("Content-Range", range);

//   req.onload = (event) => {
//     console.log(`Uploaded chunk`);
//   };
// };

export const chunksTo = async (file: File, to: string) => {
  const size = 3000000; //3mb ;)
  const nChunks = file.size / size;

  let start = 0;

  const req = new XMLHttpRequest();
  req.open("POST", to, true);

  for (let i = 0; i < nChunks; i++) {
    const form = createChunk(file, start, start + size);
    const range = `bytes ${start}-${start + size - 1}/${file.size}`;

    req.setRequestHeader("Content-Range", range);
    req.send(form);

    await new Promise<void>((resolve, reject) => {
      req.onload = () => {
        req.open("POST", to, true);
        resolve();
      };
      req.onerror = () => reject();
    });

    start += size;
  }
};

export const metadataFromUri = async (
  uri: string,
  headers?: any,
): Promise<any> => {
  const info = await fetch(uri, {
    method: "HEAD",
    headers,
  }).then((data) => data.headers);

  const video = document.createElement("video");
  video.crossOrigin = "anonymous";
  video.src = uri;
  await new Promise<void>((resolve: () => void) => {
    video.onloadedmetadata = resolve;
  });

  return {
    size: parseInt(info.get("content-length")),
    width: video.videoWidth,
    height: video.videoHeight,
    duration: video.duration,
  };
};
