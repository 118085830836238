import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../primitives/popover";
import { cn, NewSocialToLogo, TooltipButton } from "../../util/reusables";
import { Button } from "../../primitives/button";
import {
  Bell,
  BellMinus,
  BellOff,
  BellPlus,
  ClipboardCopy,
  MoreHorizontal,
  Save,
  Tag,
  Tags,
} from "lucide-react";
import { Skeleton } from "../../primitives/skeleton";
import FadeIn from "../../partials/Transitions/FadeIn";
import { useNavigate } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../primitives/dropdown-menu";
import { setError, setSuccess } from "../../reducers/toolkit";
import dayjs from "../../util/dayjs";
import { useDispatch } from "react-redux";
import { INotification } from "../../api/notification.api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import API from "../../api/notification.api";
import { findSentimentValue } from "../../views/CommunityManagement/CommentManagement";
import { Badge } from "../../primitives/badge";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "../../primitives/sheet";
import Tagger from "../../partials/Tagger/Tagger";

export default function Notifications({ isPaidMode }: { isPaidMode: boolean }) {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [labelsOpen, setLabelsOpen] = useState<{
    labels: string[];
    _id: string;
  }>();

  const { data: notifications, isFetching } = useQuery<INotification[]>({
    queryKey: ["notifications", user?.workspace?._id],
    queryFn: () => API.getNotifications(),
  });

  const unseenCount = notifications?.reduce(
    (acc, curr) => (curr.markedRead ? acc : acc + 1),
    0,
  );

  const queryClient = useQueryClient();

  const markNotificationAsReadMutation = useMutation({
    mutationFn: (_id: string) => API.markNotificationRead(_id),
    onSuccess: async (_, _id) => {
      queryClient.setQueryData<INotification[]>(
        ["notifications", user?.workspace?._id].filter(Boolean),
        (old) => {
          const notifications = old ?? [];
          const updatedNotifications =
            notifications?.map((n) =>
              n._id === _id ? { ...n, markedRead: true } : n,
            ) ?? [];
          return updatedNotifications;
        },
      );
    },
    onError: (err) => {
      console.log(err.message);
      dispatch(setError("Error marking notification as read"));
    },
  });

  const setNotificationLabelsMutation = useMutation({
    mutationFn: ({ _id, labels }: { _id: string; labels: string[] }) =>
      API.assignLabelsToNotification(_id, labels),
    onSuccess: async (_, { _id, labels }) => {
      setLabelsOpen(undefined);
      queryClient.setQueryData<INotification[]>(
        ["notifications", user?.workspace?._id].filter(Boolean),
        (old) => {
          const notifications = old ?? [];
          const updatedNotifications =
            notifications?.map((n) => (n._id === _id ? { ...n, labels } : n)) ??
            [];
          return updatedNotifications;
        },
      );
      dispatch(setSuccess("Notification labels set"));
    },
    onError: (err) => {
      console.log(err.message);
      dispatch(setError("Error setting notification labels"));
    },
  });

  if (!user?.workspace?.flags?.includes("cm")) return null;

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            className="relative"
            variant={isPaidMode ? "ghostDark" : "ghost"}
            size="icon"
          >
            <Bell className="size-6" />
            <div
              className={cn(
                "absolute right-1.5 top-1.5 hidden h-3.5 w-3.5 rounded-full bg-[#397FEF] text-2xs font-semibold text-white",
                Boolean(unseenCount) && "flex items-center justify-center",
              )}
            >
              {unseenCount}
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-80 p-0" align="end">
          <div className="flex max-h-[70vh] flex-col overflow-hidden">
            <div className="flex items-center justify-between border-b p-4 shadow">
              <h3 className="text-lg font-semibold">Notifications</h3>
              {Boolean(unseenCount) && (
                <Button
                  variant="outline"
                  size="tiny"
                  onClick={() =>
                    notifications?.map((n) =>
                      markNotificationAsReadMutation.mutate(n._id),
                    )
                  }
                >
                  Mark all read
                </Button>
              )}
            </div>
            <div className="flex flex-col overflow-y-auto bg-muted p-2">
              {/* Secondary column (hidden on smaller screens) */}
              {isFetching ? (
                <FadeIn
                  show
                  className="flex flex-col gap-2 p-2 text-sm text-gray-600"
                >
                  <Skeleton className="h-[108px] w-full bg-[#DFDFDF]" />
                  <Skeleton className="h-[108px] w-full bg-[#DFDFDF]" />
                  <Skeleton className="h-[108px] w-full bg-[#DFDFDF]" />
                  <Skeleton className="h-[108px] w-full bg-[#DFDFDF]" />
                </FadeIn>
              ) : notifications?.length ? (
                notifications.map((notification: INotification) => (
                  <NotificationItem
                    key={notification._id}
                    notification={notification}
                    setLabelsOpen={setLabelsOpen}
                  />
                ))
              ) : (
                <FadeIn
                  show
                  timeout={200}
                  className="px-2 text-sm text-gray-600"
                >
                  No results.
                </FadeIn>
              )}
            </div>
          </div>
        </PopoverContent>
      </Popover>
      {/* Extras */}
      <Sheet
        open={!!labelsOpen}
        onOpenChange={(open) => !open && setLabelsOpen(undefined)}
      >
        <SheetContent className="flex flex-col justify-between">
          <div className="flex flex-col gap-4">
            <SheetHeader>
              <SheetTitle>Manage notification labels</SheetTitle>
              <SheetDescription>
                Add or remove labels. Click save when you&apos;re done.
              </SheetDescription>
            </SheetHeader>
            <Tagger<string>
              placeholder="Add labels..."
              tags={labelsOpen?.labels}
              getTag={(t) => t}
              onCreate={(t) =>
                labelsOpen &&
                !labelsOpen?.labels.includes(t) &&
                setLabelsOpen({
                  ...labelsOpen,
                  labels: [...(labelsOpen?.labels ?? []), t],
                })
              }
              onDelete={(t) => {
                if (!labelsOpen) return;
                const tmp = [...(labelsOpen?.labels ?? [])];
                const i = tmp.indexOf(t);
                if (i >= 0) {
                  tmp.splice(i, 1);
                  setLabelsOpen({ ...labelsOpen, labels: tmp });
                }
              }}
            />
          </div>
          <SheetFooter>
            <SheetClose asChild>
              <Button
                onClick={() =>
                  setNotificationLabelsMutation.mutate({ ...labelsOpen })
                }
              >
                <Save className="mr-2 size-4" />
                Save
              </Button>
            </SheetClose>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    </>
  );
}

interface NotificationItemProps {
  notification: INotification;
  setLabelsOpen: (value: { labels: string[]; _id: string } | undefined) => void;
}
const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  setLabelsOpen,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const isComment = !!notification.flamelComment;
  const item = isComment ? notification.flamelComment : notification.flamelPost;
  if (!item) return null;

  const markNotificationAsReadMutation = useMutation({
    mutationFn: (_id: string) => API.markNotificationRead(_id),
    onSuccess: async (_, _id) => {
      queryClient.setQueryData<INotification[]>(
        ["notifications"],
        (old) =>
          old?.map((n) => (n._id === _id ? { ...n, markedRead: true } : n)) ??
          [],
      );
    },
    onError: (err) => {
      console.log(err.message);
      dispatch(setError("Error marking notification as read"));
    },
  });

  const markNotificationAsUnreadMutation = useMutation({
    mutationFn: (_id: string) => API.markNotificationUnread(_id),
    onSuccess: async (_, _id) => {
      queryClient.setQueryData<INotification[]>(
        ["notifications"],
        (old) =>
          old?.map((n) => (n._id === _id ? { ...n, markedRead: false } : n)) ??
          [],
      );
    },
    onError: (err) => {
      console.log(err.message);
      dispatch(setError("Error marking notification as unread"));
    },
  });

  const hideNotificationMutation = useMutation({
    mutationFn: (_id: string) => API.deleteNotification(_id),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
      dispatch(setSuccess("Notification hidden!"));
    },
    onError: (err) => {
      console.log(err.message);
      dispatch(setError("Error hiding notification"));
    },
  });

  return (
    <div className="relative" key={notification._id}>
      <FadeIn
        show
        className={cn(
          "relative flex cursor-pointer flex-col gap-3 rounded-lg px-8 py-5 transition-all hover:bg-[#D9E7FC]",
        )}
        onClick={() => {
          navigate(
            isComment
              ? `/engage?pid=${item.platformPostId}`
              : `/engage?tab=posts&pid=${item.platformPostId}`,
          );
          markNotificationAsReadMutation.mutate(notification._id);
        }}
      >
        {!notification.markedRead && (
          <div
            className={cn(
              "absolute left-3 top-1/2 h-2.5 w-2.5 -translate-y-1/2 rounded-full bg-[#397FEF]",
            )}
          ></div>
        )}
        <FadeIn
          show
          className="flex w-full items-center gap-1 truncate text-xs"
        >
          <NewSocialToLogo
            platform={notification.platform}
            className="shrink-0"
          />
          <p className="w-auto truncate">
            {isComment
              ? `Commented on ${
                  notification.isTagged
                    ? "a post you're tagged in"
                    : "your post"
                }`
              : "You've been tagged in a post"}
          </p>
        </FadeIn>
        <FadeIn show className="flex gap-3">
          <div className="min-w-0 flex-1">
            <p className="flex items-center gap-2 text-sm font-medium">
              {isComment
                ? item.fromPlatformAccountName
                : item.platformAccountUsername ?? item.platformAccountName}
              <span className={cn("text-2xs text-gray-500")}>
                {dayjs(item.timestamp).fromNow()}
              </span>
            </p>
            <p className={cn("truncate text-sm opacity-60")}>
              {isComment ? item.content : item.caption}
            </p>
          </div>
        </FadeIn>
        {item.sentiment && (
          <TooltipButton
            side="left"
            text={`Sentiment: ${findSentimentValue(item.sentiment)?.label}`}
          >
            <div
              className={cn(
                "absolute right-6 top-1/2 h-2.5 w-2.5 -translate-y-1/2 rounded-full",
                findSentimentValue(item.sentiment)?.className,
              )}
            ></div>
          </TooltipButton>
        )}
        <div className="absolute bottom-0.5 left-1/2 flex -translate-x-1/2 gap-1">
          {notification.labels?.map((tag) => (
            <Badge key={tag} size="tiny" variant="outline">
              {tag}
            </Badge>
          ))}
        </div>
      </FadeIn>
      {notification?.isTagged && (
        <FadeIn show timeout={400} className="absolute left-3 top-4 rounded">
          <TooltipButton
            text="Comment on a post you were tagged in"
            align="start"
          >
            <Button variant="ghost" size="xs">
              <Tag className="h-3 w-3 shrink-0" />
            </Button>
          </TooltipButton>
        </FadeIn>
      )}
      <FadeIn show timeout={400} className="absolute right-3 top-4 rounded">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="xs">
              <span className="sr-only">Actions</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {notification.markedRead ? (
              <DropdownMenuItem
                onSelect={async (e) => {
                  e.stopPropagation();
                  markNotificationAsUnreadMutation.mutate(notification._id);
                }}
                className="text-xs"
              >
                <BellPlus className="mr-2 h-4 w-4" /> Mark as unread
              </DropdownMenuItem>
            ) : (
              <DropdownMenuItem
                onSelect={async (e) => {
                  e.stopPropagation();
                  markNotificationAsReadMutation.mutate(notification._id);
                }}
                className="text-xs"
              >
                <BellMinus className="mr-2 h-4 w-4" /> Mark as read
              </DropdownMenuItem>
            )}

            <DropdownMenuItem
              onSelect={() =>
                setLabelsOpen({
                  _id: notification._id,
                  labels: notification.labels ?? [],
                })
              }
              className="text-xs"
            >
              <Tags className="mr-2 h-4 w-4" /> Manage labels
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => hideNotificationMutation.mutate(notification._id)}
              className="text-xs text-red-600"
            >
              <BellOff className="mr-2 h-4 w-4" /> Hide notification
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onSelect={() => {
                navigator.clipboard.writeText(
                  isComment ? item.platformCommentId : item.platformPostId,
                );
                dispatch(
                  setSuccess(
                    `${isComment ? "Comment" : "Post"} ID copied to clipboard!`,
                  ),
                );
              }}
              className="text-xs"
            >
              <ClipboardCopy className="mr-2 h-3 w-3" /> Copy
              {isComment ? " comment" : " post"} ID
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </FadeIn>
    </div>
  );
};
