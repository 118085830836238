// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-platform .platforms .platform {
  font-size: 30px;
  opacity: 0.8;
  cursor: pointer;
  transition: all 100ms ease-in;
  color: #999;
}

.select-platform .platforms .platform:hover {
  opacity: 1;
}

.select-platform .platforms .platform.disabled {
  opacity: 0.4;
  cursor: default;
}

.select-platform .platforms .platform.disabled:hover {
  opacity: 0.4;
}

.select-platform .platforms .platform.selected {
  opacity: 1;
}

.select-platform .platforms .platform img {
  height: 2rem;
  width: 2rem;
  border-radius: 9999px;
}

.select-platform .platforms .platform.selected img {
  box-shadow: 0 0 0 2px #e6e6e6, 0 0 0 4px #5eb7f7;
}
`, "",{"version":3,"sources":["webpack://./ui/src/partials/SelectPlatform/select-platform.style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,eAAe;EACf,6BAA6B;EAC7B,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAGE;EAAA,YAA2B;EAA3B,WAA2B;EAA3B;AAA2B;;AAG7B;EACE,gDAAgD;AAClD","sourcesContent":[".select-platform .platforms .platform {\n  font-size: 30px;\n  opacity: 0.8;\n  cursor: pointer;\n  transition: all 100ms ease-in;\n  color: #999;\n}\n\n.select-platform .platforms .platform:hover {\n  opacity: 1;\n}\n\n.select-platform .platforms .platform.disabled {\n  opacity: 0.4;\n  cursor: default;\n}\n\n.select-platform .platforms .platform.disabled:hover {\n  opacity: 0.4;\n}\n\n.select-platform .platforms .platform.selected {\n  opacity: 1;\n}\n\n.select-platform .platforms .platform img {\n  @apply h-8 w-8 rounded-full;\n}\n\n.select-platform .platforms .platform.selected img {\n  box-shadow: 0 0 0 2px #e6e6e6, 0 0 0 4px #5eb7f7;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
