import axios from "axios";
import { PlatformType } from "../util/platforms";
import { IPlatformComment } from "./engage.api";

export type INotification<TPost = any> = {
  _id: string;
  platform: PlatformType;
  team: string;
  flamelPost?: TPost;
  flamelComment?: IPlatformComment;
  isTagged?: boolean;
  deleted?: boolean; // Track soft deletes
  markedRead?: boolean;
  labels?: string[];
  createdAt: string;
  updatedAt: string;
};

export const getNotifications = async () => {
  const requestUrl = new URL("/api/notifications", window.location.origin);
  const result = await axios.get(requestUrl.toString());
  return result.data;
};

export const deleteNotification = async (id: string) => {
  await fetch(`/api/notifications/${id}`, {
    method: "DELETE",
  });
};

export const markNotificationRead = async (id: string) =>
  await fetch(`/api/notifications/${id}/read`, {
    method: "PATCH",
  });

export const markNotificationUnread = async (id: string) =>
  await fetch(`/api/notifications/${id}/unread`, {
    method: "PATCH",
  });

export const assignLabelsToNotification = async (
  id: string,
  labels: string[],
) =>
  await fetch(`/api/notifications/${id}/labels`, {
    method: "PATCH",
    body: JSON.stringify({ labels }),
    headers: {
      "Content-Type": "application/json",
    },
  });

export interface NotificationAPI {
  getNotifications: () => Promise<INotification[]>;
  deleteNotification: (id: string) => Promise<void>;
  markNotificationRead: (id: string) => Promise<Response>;
  markNotificationUnread: (id: string) => Promise<Response>;
  assignLabelsToNotification: (
    id: string,
    labels: string[],
  ) => Promise<Response>;
}

export default {
  getNotifications,
  deleteNotification,
  markNotificationRead,
  markNotificationUnread,
  assignLabelsToNotification,
} as NotificationAPI;
