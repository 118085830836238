import React, { SVGProps } from "react";
const WizardIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="uuid-96e01837-1fab-4565-82f5-fb8541387292"
    data-name="Layer 2"
    viewBox="0 0 1301.85 1309.35"
    {...props}
  >
    <defs>
      <linearGradient
        id="uuid-2d148c3f-1ba1-4bae-bb49-7fb2af539571"
        x1={185.98}
        x2={1083.97}
        y1={225.38}
        y2={1123.37}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9000" />
        <stop offset={0.53} stopColor="#ff4f22" />
        <stop offset={1} stopColor="#ff2b76" />
      </linearGradient>
      <style>
        {
          ".uuid-9eae723f-484b-49fa-afc6-c81a31cd9aaa{fill:#fbcc39;stroke-width:0}.uuid-01ce812d-8ecd-4ce2-808d-6d04fb5e55f4,.uuid-1392608b-bc4d-4025-a156-f0cbd438f6c6,.uuid-1c48eb85-fa28-42ff-bff4-77dbd4e68470,.uuid-325ae3db-7028-4f0d-ae9c-0a43cf114a84,.uuid-399a9958-7763-4edb-bb5f-d144d1742c2f,.uuid-434dc0ae-bf55-4da4-8c0d-463f14412c2f,.uuid-54d50b01-89b8-4350-856a-8afcf2a5b8ee,.uuid-64b8e9c3-857d-4776-bda2-45aed5a7808a,.uuid-771ce63e-de02-41db-9b45-e3dcde1e8f48,.uuid-8d241f72-364a-4be8-9a0d-85715b330eb6,.uuid-97602db2-c62a-438d-81d9-aa8ddc257c80,.uuid-a66e6227-1762-4038-9835-a109143b8006,.uuid-c15de25a-99f0-4c78-96a5-9b86ab7cc43b,.uuid-da5c04cc-33d9-4959-84cd-e49e8b75d6f3{stroke-width:0}.uuid-8d241f72-364a-4be8-9a0d-85715b330eb6{fill:#6b171f}.uuid-325ae3db-7028-4f0d-ae9c-0a43cf114a84{fill:#14243d}.uuid-399a9958-7763-4edb-bb5f-d144d1742c2f{fill:#c6464f}.uuid-54d50b01-89b8-4350-856a-8afcf2a5b8ee{fill:#4a5171}.uuid-771ce63e-de02-41db-9b45-e3dcde1e8f48{fill:#0d172b}.uuid-64b8e9c3-857d-4776-bda2-45aed5a7808a{fill:#f8a882}.uuid-01ce812d-8ecd-4ce2-808d-6d04fb5e55f4{fill:#f4f5f9}.uuid-a66e6227-1762-4038-9835-a109143b8006{fill:#f9f9fa}.uuid-1c48eb85-fa28-42ff-bff4-77dbd4e68470{fill:#030a2b}.uuid-434dc0ae-bf55-4da4-8c0d-463f14412c2f{fill:#b22b45}.uuid-1392608b-bc4d-4025-a156-f0cbd438f6c6{fill:#fcfdfd}.uuid-c15de25a-99f0-4c78-96a5-9b86ab7cc43b{fill:#fff}.uuid-da5c04cc-33d9-4959-84cd-e49e8b75d6f3{fill:#fec79b}.uuid-97602db2-c62a-438d-81d9-aa8ddc257c80{fill:#822037}"
        }
      </style>
    </defs>
    <g id="uuid-8c2da99f-4c05-4431-ac17-d0fddd900759" data-name="Laptop">
      <g id="uuid-b61eece0-7afa-4fb8-9d18-902b7275a361" data-name="Magic">
        <circle
          cx={634.97}
          cy={674.38}
          r={634.97}
          style={{
            fill: "url(#uuid-2d148c3f-1ba1-4bae-bb49-7fb2af539571)",
            strokeWidth: 0,
          }}
        />
        <path
          d="m77.78 1158.54-51.28 26.49 171.48 18.95 133.4-14.66-42.52-64.5-211.08 33.72z"
          className="uuid-54d50b01-89b8-4350-856a-8afcf2a5b8ee"
        />
        <ellipse
          cx={233.17}
          cy={943.18}
          rx={42.53}
          ry={33.79}
          style={{
            fill: "#521c99",
            strokeWidth: 0,
          }}
        />
        <path
          d="m71.09 695.78-52.22 445.5 54.47-61-2.25-384.5z"
          className="uuid-399a9958-7763-4edb-bb5f-d144d1742c2f"
        />
        <path
          d="m138.55 967.85-65.21 112.43-2.25-384.5 67.46 272.07z"
          className="uuid-434dc0ae-bf55-4da4-8c0d-463f14412c2f"
        />
        <path
          d="m211.62 939.74-73.07 28.11-67.46-272.07 140.53 243.96z"
          className="uuid-97602db2-c62a-438d-81d9-aa8ddc257c80"
        />
        <path
          d="m275.7 909.39-64.08 30.35L71.09 695.78 289.2 884.66l-13.5 24.73"
          className="uuid-8d241f72-364a-4be8-9a0d-85715b330eb6"
        />
        <path
          d="m264.35 1180.04-245.48-38.76 54.47-61 191.01 99.76z"
          className="uuid-97602db2-c62a-438d-81d9-aa8ddc257c80"
        />
        <path
          d="m263.65 1166.03-27.33 2.1-162.98-87.85 65.21-112.43 106.18 184.17 18.92 14.01z"
          className="uuid-399a9958-7763-4edb-bb5f-d144d1742c2f"
        />
        <path
          d="m236.32 945.32-24.7-5.58-73.07 28.11 106.18 184.17-8.41-206.7z"
          className="uuid-434dc0ae-bf55-4da4-8c0d-463f14412c2f"
        />
        <path
          d="m63.87 1074.22-10.27 5.62c-.26.14-.26.52 0 .67l9.77 5.34.54.3c3.91 2.15 6.57 6.04 7.14 10.47l4.72 36.13c.08.59.93.59 1.01 0l5.03-37.19c.52-3.85 2.83-7.22 6.23-9.09l.62-.34 10.3-5.63c.25-.14.25-.49 0-.63l-10.39-5.68a15.96 15.96 0 0 1-6.31-6.31l-5.78-20.69a.227.227 0 0 0-.44 0l-5.73 20.61a16.158 16.158 0 0 1-6.44 6.44Z"
          className="uuid-c15de25a-99f0-4c78-96a5-9b86ab7cc43b"
        />
        <path
          d="m1158.21 1208.88 66.01 43.73-86.8 4.71-61.8-14.66 19.7-64.5 62.89 30.72z"
          className="uuid-54d50b01-89b8-4350-856a-8afcf2a5b8ee"
        />
        <path
          d="m441.48 698.15-97.31 26.09-37.37 115.64-14.11 107.89-25.38-164.3L375.9 603.65l65.58 94.5z"
          style={{
            fill: "#c63c3c",
            strokeWidth: 0,
          }}
        />
        <ellipse
          cx={830.94}
          cy={850.49}
          className="uuid-325ae3db-7028-4f0d-ae9c-0a43cf114a84"
          rx={63.82}
          ry={49.45}
        />
        <path
          d="M239.14 983.84h217.53V698.33l-205.01 5.81-12.52 279.7zM797.87 882.92l-231.42 4.03 106.44-212.07 134.65 187.07-9.67 20.97z"
          className="uuid-771ce63e-de02-41db-9b45-e3dcde1e8f48"
        />
        <path
          d="m687.89 562.36-61.1 361.86-215.78-5.73 82.11-415.33 194.77 59.2"
          className="uuid-771ce63e-de02-41db-9b45-e3dcde1e8f48"
        />
        <path
          d="M826.49 743.66h71.18v91.59h-71.18z"
          style={{
            fill: "#faab7a",
            strokeWidth: 0,
          }}
          transform="rotate(-180 862.075 789.45)"
        />
        <path
          d="m858.38 736.73-29.85 99.21-30.76 48.43-45.16-28.8-39.93-38.61 57.6-85.74 88.1 5.51"
          className="uuid-1392608b-bc4d-4025-a156-f0cbd438f6c6"
        />
        <path
          d="m796.24 694.07 29.9 10.41 32.25 32.25-20.83 5.37-27.1 13.14a86.662 86.662 0 0 0-32.65 27.53l-41.95 58.6-23.17-24.41-6.8-53.36 90.36-69.53Z"
          className="uuid-771ce63e-de02-41db-9b45-e3dcde1e8f48"
        />
        <path
          d="m407.53 432.57-47 36.9a174.585 174.585 0 0 0-56.49 78.27l-72.28 200.99a263.15 263.15 0 0 0-15.51 91.23l.66 77.7 12.49 264.8 59.48 24.17 10.22-205.41-32.53-91.09 26.57-169.54 114.39-308.03Z"
          style={{
            fill: "#5226a8",
            strokeWidth: 0,
          }}
        />
        <path
          d="m821.97 679.32-18 34.84c-8.7 16.84-19.99 32.2-33.46 45.54l-57.83 57.26-54.42-83.47 21.87-155.96 42.59-77.69 65.61 131.21 32 35.57c3.14 3.48 3.79 8.55 1.63 12.71ZM449.24 364.87l-11.11 14.56-29.05 32.14-99.38 247-16.56 82.03-6.91 44 13.44-25.34 11.66-13.03a99.122 99.122 0 0 1 84.03-32.49l51.41 5.3 25.4 38.66 41.12-219.07-64.05-173.77"
          className="uuid-9eae723f-484b-49fa-afc6-c81a31cd9aaa"
        />
        <path
          d="m476.13 813.92-4.84 24.15a119.427 119.427 0 0 1-38.6 66.53s-37.74.71-37.74.71l-36.67-65.86 118.04-145.56 3.81 37.64a277.55 277.55 0 0 1-4 82.4Z"
          className="uuid-325ae3db-7028-4f0d-ae9c-0a43cf114a84"
        />
        <path
          d="m451.4 715.35 128.09-34.48 37.75 5.43c6.5.94 12 5.27 14.44 11.36l13.6 34.01c1.17 2.91 1.38 6.12.62 9.17l-1.87 7.47c-1.12 4.5-6.64 6.17-10.07 3.05l-2.54-2.31-6.91-22.56-12.43-11.51 4.99 16.23-2.02 25.31c.16 2.27-.72 4.48-2.39 6.02l-7.89 7.28a40.937 40.937 0 0 1-30.5 10.74l-9.6-.65-29.04-7.17a26.416 26.416 0 0 1-13.77-8.5l-5.48-6.43a5.457 5.457 0 0 0-5.81-1.66l-68.83 21.92-51.57 10.59 61.23-73.3Z"
          className="uuid-da5c04cc-33d9-4959-84cd-e49e8b75d6f3"
        />
        <path
          d="m476.32 693.89-102.6 19.38-23.39 37.11a117.28 117.28 0 0 0-18.06 62.69c.03 18.78 5.07 37.2 14.6 53.38l24.81 42.09 30.88-3.86-11.81-38.56a61.863 61.863 0 0 1 1.82-41.36l12.23-30.19 71.5-100.67Z"
          className="uuid-1392608b-bc4d-4025-a156-f0cbd438f6c6"
        />
        <path
          id="uuid-0697556d-60e9-426a-9ccf-aafe7ccbcd5c"
          d="M1124.82 921.69v279.69l-681.74 29.13V937.23l681.74-15.54z"
          className="uuid-a66e6227-1762-4038-9835-a109143b8006"
          data-name="front"
        />
        <path
          d="m283.59 924.27-.02 276.8 159.51 29.44V937.23l-159.49-12.96z"
          style={{
            fill: "#f0f0f2",
            strokeWidth: 0,
          }}
        />
        <path
          id="uuid-24c578b9-32ee-40dc-b4ee-01c64a40907a"
          d="M1124.82 921.69v48.01l-681.74 25.77v-69.73l681.74-4.05z"
          data-name="front"
          style={{
            fill: "#e0e0e0",
            strokeWidth: 0,
          }}
        />
        <path
          d="m1300.4 914.89-857.32 22.34-314.07-16.24.63-14.3 315.41 15.17 856.8-17.57-1.45 10.6z"
          className="uuid-a66e6227-1762-4038-9835-a109143b8006"
        />
        <path
          d="m129.64 906.69 966.96-8.17 205.25 5.77-856.8 17.57-315.41-15.17z"
          style={{
            fill: "#e5e5e5",
            strokeWidth: 0,
          }}
        />
        <path
          d="m977.66 1135.95 64.55-2.28 140.13-175.29-204.68 177.57z"
          className="uuid-8d241f72-364a-4be8-9a0d-85715b330eb6"
        />
        <path
          d="m1170.29 1176.8-58.52-21.52 70.57-196.9-12.05 218.42z"
          className="uuid-434dc0ae-bf55-4da4-8c0d-463f14412c2f"
        />
        <path
          d="m1208.14 1221.82-37.85-45.02 12.05-218.42 25.8 263.44z"
          className="uuid-399a9958-7763-4edb-bb5f-d144d1742c2f"
        />
        <path
          d="m1076.32 1243.89 131.82-22.07-37.85-45.02-93.97 67.09z"
          className="uuid-97602db2-c62a-438d-81d9-aa8ddc257c80"
        />
        <path
          d="m1076.32 1243.89 35.45-88.61 58.52 21.52-93.97 67.09z"
          className="uuid-399a9958-7763-4edb-bb5f-d144d1742c2f"
        />
        <path
          d="m1042.21 1133.67 34.11 110.22 35.45-88.61-69.56-21.61z"
          className="uuid-8d241f72-364a-4be8-9a0d-85715b330eb6"
        />
        <path
          d="m977.66 1135.95 98.66 107.94-34.11-110.22-64.55 2.28z"
          className="uuid-434dc0ae-bf55-4da4-8c0d-463f14412c2f"
        />
        <path
          id="uuid-4f752981-0222-4694-a968-97cadebbfff3"
          d="m1182.34 958.38-70.57 196.9-69.56-21.61 140.13-175.29z"
          className="uuid-97602db2-c62a-438d-81d9-aa8ddc257c80"
          data-name="stone"
        />
        <path
          d="m1035.67 1131.49-4.72 2.58c-.12.07-.12.24 0 .31l4.49 2.46.25.14c1.8.99 3.02 2.78 3.28 4.81l2.17 16.6c.04.27.43.27.46 0l2.31-17.09c.24-1.77 1.3-3.32 2.86-4.18l.28-.16 4.73-2.59c.11-.06.11-.23 0-.29l-4.78-2.61a7.354 7.354 0 0 1-2.9-2.9l-2.65-9.51c-.03-.1-.18-.1-.2 0l-2.63 9.47a7.397 7.397 0 0 1-2.96 2.96Z"
          className="uuid-c15de25a-99f0-4c78-96a5-9b86ab7cc43b"
        />
        <path
          d="m1205.89 637.75-114.51 261.06-262.15 1.7 98.41-291.85 268.13 12.77c8.16.39 13.4 8.84 10.12 16.32Z"
          style={{
            fill: "#74829b",
            strokeWidth: 0,
          }}
        />
        <path
          d="m627.49 894.58 209.23 5.93 7.96-33.94-215.31 9.34-1.88 18.67z"
          style={{
            fill: "#606b8a",
            strokeWidth: 0,
          }}
        />
        <path
          d="m1091.38 898.81-262.15 1.7 98.41-291.85"
          style={{
            fill: "#687689",
            strokeWidth: 0,
          }}
        />
        <path
          d="m448.9 369.85-67.28-119.77c-2.88-5.13 1.4-11.34 7.22-10.47l306.82 45.95c11.59 1.74 22.95 4.72 33.9 8.9l96.75 36.94a37.143 37.143 0 0 1 17.25 13.52l35.22 50.79c3.29 4.74.97 11.32-4.57 12.95l-233.36 47.75-191.96-86.54Z"
          className="uuid-325ae3db-7028-4f0d-ae9c-0a43cf114a84"
        />
        <path
          d="m528.93 249.35-23.56 38.59c-.17.27-.34.54-.53.81l-29.59 42.35-43.24 65.38a15.583 15.583 0 0 0-1.16 15.1l11.67 25.41c.74 1.61 1.75 3.08 2.98 4.35l19.61 20.22c7.82 8.07 21.35 5.45 25.61-4.95l26.69-65.2 57.81-130.76c1.61-3.64 1.76-7.76.43-11.51-2.53-7.12-9.8-11.4-17.25-10.16l-18.73 3.12c-4.46.74-8.38 3.39-10.74 7.25Z"
          style={{
            fill: "#eff0fb",
            strokeWidth: 0,
          }}
        />
        <path
          id="uuid-23710b74-98ed-4934-9bcd-1049511cff4c"
          d="m699.75 287.95 3.87 13.1c2.14 7.24 3.74 14.63 4.79 22.11l3.32 23.64-3.45 7.89c-2.75 6.28-4 13.11-3.66 19.96l.02.49 2.72 25.61-10.35 21.8-28.88 38.14-46.86 28.34-62.67-19.62-67.03-97.54 38.69-69.21 16.89-34.33 9.81-22.34 122.06 16.35 20.71 25.61Z"
          className="uuid-da5c04cc-33d9-4959-84cd-e49e8b75d6f3"
          data-name="Skin"
        />
        <rect
          width={79.61}
          height={26}
          x={542.23}
          y={306.67}
          className="uuid-01ce812d-8ecd-4ce2-808d-6d04fb5e55f4"
          rx={3.46}
          ry={3.46}
          transform="rotate(-180 582.04 319.67)"
        />
        <path
          d="m712.427 348.4-54.285-10.06a2.96 2.96 0 0 1-2.371-3.45l3.304-17.827a2.96 2.96 0 0 1 3.45-2.371l45.888 8.505c6.244 1.157 10.369 7.16 9.212 13.403l-1.748 9.43a2.96 2.96 0 0 1-3.45 2.37Z"
          className="uuid-01ce812d-8ecd-4ce2-808d-6d04fb5e55f4"
        />
        <circle
          cx={675.88}
          cy={370.23}
          r={14.35}
          className="uuid-1c48eb85-fa28-42ff-bff4-77dbd4e68470"
        />
        <circle
          cx={584.65}
          cy={357.59}
          r={14.35}
          className="uuid-1c48eb85-fa28-42ff-bff4-77dbd4e68470"
        />
        <circle
          cx={674.83}
          cy={387.59}
          r={15.89}
          className="uuid-da5c04cc-33d9-4959-84cd-e49e8b75d6f3"
        />
        <circle
          cx={580.18}
          cy={375.97}
          r={15.89}
          className="uuid-da5c04cc-33d9-4959-84cd-e49e8b75d6f3"
        />
        <rect
          width={24.94}
          height={82}
          x={626.66}
          y={337.57}
          rx={3.41}
          ry={3.41}
          style={{
            fill: "#f5ad83",
            strokeWidth: 0,
          }}
          transform="rotate(-180 639.13 378.57)"
        />
        <path
          d="m668.97 440.47-11.43 12.73a18.98 18.98 0 0 1-6.95 4.89 75.335 75.335 0 0 1-24.82 5.49l-2.02.1c-3.75.18-7.51.01-11.23-.5l-4.43-.61a35.374 35.374 0 0 1-22.57-12.64s-11.74-16.3-11.74-16.3l30.32 5.54 32.92.65 31.95.65Z"
          style={{
            fill: "#c62e2e",
            strokeWidth: 0,
          }}
        />
        <circle
          cx={674.83}
          cy={400.19}
          r={15.89}
          className="uuid-64b8e9c3-857d-4776-bda2-45aed5a7808a"
        />
        <circle
          cx={580.18}
          cy={388.58}
          r={15.89}
          className="uuid-64b8e9c3-857d-4776-bda2-45aed5a7808a"
        />
        <path
          d="m707.02 398.35 1.17 2.95c4.7 11.82 7.96 24.17 9.71 36.77l3.02 21.85c.77 5.55 1.27 11.13 1.52 16.72l1.25 28.71a74.115 74.115 0 0 1-6.81 34.42s-28.37 38.76-28.37 38.76l-78.25 89.27a12.146 12.146 0 0 1-15.66 2.24s-39-36.3-39-36.3c-4.63-4.31-8.9-8.97-12.79-13.95a319.056 319.056 0 0 1-45.08-78.58l-6.9-17.35-8.48-24.8c-6.51-19.04-12.86-27.63-12.61-53.26l2.9-24.18c1.74-13.16 4.64-25.52 10.88-37.23l18.13-30.95 30.18 43.93c5.3 9.44 11.87 18.11 19.52 25.77l18.4 18.4 19.45 19.97c3.8 3.9 8.14 7.22 12.9 9.86 7.34 4.08 15.6 6.22 24 6.22 8.05 0 15.92-2.44 22.56-6.99a92.415 92.415 0 0 0 23.16-22.8l16.98-23.95 18.22-25.5Z"
          className="uuid-01ce812d-8ecd-4ce2-808d-6d04fb5e55f4"
        />
        <path
          d="m571.29 235.22-20.97 24.94-10.84 23.5 166.63 25.66-10.48-38.67-124.34-35.43z"
          style={{
            fill: "#efbb97",
            strokeWidth: 0,
          }}
        />
        <path
          d="m641.89 423.51 19.25.91c.39.02.77.08 1.14.19l21.62 6.31c1.08.32 2.02 1 2.66 1.93l11.12 16.26c.75 1.1 1.02 2.45.75 3.75a4.872 4.872 0 0 1-5.98 3.73l-9.61-2.44-23.67-6.26-21.59-6.56a4.887 4.887 0 0 1-3.38-3.78l-.54-2.9c-.22-1.16 0-2.36.6-3.38l3.2-5.39a4.882 4.882 0 0 1 4.42-2.38Z"
          className="uuid-1392608b-bc4d-4025-a156-f0cbd438f6c6"
        />
        <path
          d="m631.6 422.37-22.12-2.38c-.33-.04-.67-.04-1.01 0l-25.72 2.68c-1.02.11-1.98.54-2.73 1.23l-15.72 14.38c-1.03.94-1.6 2.29-1.55 3.69.09 2.71 2.42 4.8 5.12 4.61l11.88-.86 27.45-2.23 25.73-2.93c1.72-.2 3.2-1.3 3.87-2.9l1.19-2.83a4.8 4.8 0 0 0 0-3.72l-2.49-5.85a4.805 4.805 0 0 0-3.89-2.88Z"
          className="uuid-1392608b-bc4d-4025-a156-f0cbd438f6c6"
        />
        <path
          d="m525.73 252.93-11.77 21.02 25.52 9.71 10.84-23.5-24.59-7.23z"
          style={{
            fill: "#dddfed",
            strokeWidth: 0,
          }}
        />
        <path
          d="m726.18 247.97 103.79 85.14-117.2-43.07-325.21-50.48 111.41-2.93L537.79 206 760.37 6c9.12-8.2 23.02-7.96 31.86.53l64.84 62.3c2.21 2.12 1.01 5.86-2.02 6.3l-71.77 10.42a6.596 6.596 0 0 0-5.31 4.46l-51.78 157.95"
          className="uuid-771ce63e-de02-41db-9b45-e3dcde1e8f48"
        />
        <path
          d="m855.04 75.13-71.48 13.49-53.32 158.28 96.07 84.5-140.42-87.32 87.46-176.07 81.69 7.12z"
          style={{
            fill: "#42238e",
            strokeWidth: 0,
          }}
        />
        <path
          d="m1136.47 525.44-10.27 5.62c-.26.14-.26.52 0 .67l9.77 5.34.54.3c3.91 2.15 6.57 6.04 7.14 10.47l4.72 36.13c.08.59.93.59 1.01 0l5.03-37.19c.52-3.85 2.83-7.22 6.23-9.09l.62-.34 10.3-5.63c.25-.14.25-.49 0-.63l-10.39-5.68a15.96 15.96 0 0 1-6.31-6.31l-5.78-20.69a.227.227 0 0 0-.44 0l-5.73 20.61a16.158 16.158 0 0 1-6.44 6.44ZM1215.83 669.85l-7.94 4.34c-.2.11-.2.4 0 .52l7.56 4.13.42.23c3.03 1.67 5.08 4.67 5.52 8.1l3.65 27.94c.06.46.72.46.78 0l3.89-28.76c.4-2.98 2.19-5.58 4.82-7.03l.48-.26 7.97-4.36c.19-.11.19-.38 0-.49l-8.04-4.4a12.318 12.318 0 0 1-4.88-4.88l-4.47-16c-.05-.17-.3-.17-.34 0l-4.43 15.93c-1.15 2.1-2.88 3.83-4.98 4.98Z"
          className="uuid-9eae723f-484b-49fa-afc6-c81a31cd9aaa"
        />
        <path
          d="m933.26 468.15-10.27 5.62c-.26.14-.26.52 0 .67l9.77 5.34.54.3c3.91 2.15 6.57 6.04 7.14 10.47l4.72 36.13c.08.59.93.59 1.01 0l5.03-37.19c.52-3.85 2.83-7.22 6.23-9.09l.62-.34 10.3-5.63c.25-.14.25-.49 0-.63l-10.39-5.68a15.96 15.96 0 0 1-6.31-6.31l-5.78-20.69a.227.227 0 0 0-.44 0l-5.73 20.61a16.158 16.158 0 0 1-6.44 6.44Z"
          style={{
            fill: "#ffdf85",
            strokeWidth: 0,
          }}
        />
        <path
          d="m1080.91 406.62-5.99 3.27c-.15.08-.15.3 0 .39l5.69 3.11.32.17a8.159 8.159 0 0 1 4.16 6.1l2.75 21.05c.04.34.54.34.59 0l2.93-21.67c.3-2.24 1.65-4.21 3.63-5.3l.36-.2 6-3.28c.15-.08.15-.29 0-.37l-6.06-3.31a9.306 9.306 0 0 1-3.68-3.68l-3.37-12.06a.135.135 0 0 0-.26 0l-3.34 12.01a9.447 9.447 0 0 1-3.76 3.76Z"
          className="uuid-1392608b-bc4d-4025-a156-f0cbd438f6c6"
        />
        <path
          d="m876.16 600.52-7.8 4.26c-.2.11-.2.4 0 .51l7.41 4.06.41.23c2.97 1.63 4.98 4.59 5.42 7.95l3.58 27.42c.06.45.7.45.77 0l3.82-28.22c.4-2.92 2.15-5.48 4.73-6.9l.47-.26 7.82-4.28c.19-.1.19-.38 0-.48l-7.89-4.31a12.127 12.127 0 0 1-4.79-4.79l-4.38-15.7a.177.177 0 0 0-.34 0l-4.35 15.64a12.367 12.367 0 0 1-4.89 4.89Z"
          className="uuid-9eae723f-484b-49fa-afc6-c81a31cd9aaa"
        />
        <path
          d="m1038.56 527.66-5.69 3.11c-.15.08-.15.29 0 .37l5.41 2.96.3.17a7.79 7.79 0 0 1 3.96 5.8l2.61 20c.04.33.51.33.56 0l2.79-20.59c.29-2.13 1.57-4 3.45-5.04l.34-.19 5.7-3.12c.14-.08.14-.27 0-.35l-5.75-3.15a8.81 8.81 0 0 1-3.49-3.49l-3.2-11.46c-.03-.12-.21-.12-.25 0l-3.17 11.41a8.952 8.952 0 0 1-3.57 3.57ZM854.85 688.34l-3.92 2.14c-.1.05-.1.2 0 .25l3.72 2.04.21.11c1.49.82 2.5 2.3 2.72 3.99l1.8 13.77c.03.22.35.23.38 0l1.92-14.18c.2-1.47 1.08-2.75 2.38-3.47l.24-.13 3.93-2.15c.1-.05.1-.19 0-.24l-3.96-2.17a6.074 6.074 0 0 1-2.41-2.41l-2.2-7.89c-.02-.09-.15-.09-.17 0l-2.18 7.85a6.194 6.194 0 0 1-2.46 2.46ZM1157.54 787.71l-3.92 2.14c-.1.05-.1.2 0 .25l3.72 2.04.21.11c1.49.82 2.5 2.3 2.72 3.99l1.8 13.77c.03.22.35.23.38 0l1.92-14.18c.2-1.47 1.08-2.75 2.38-3.47l.24-.13 3.93-2.15c.1-.05.1-.19 0-.24l-3.96-2.17a6.074 6.074 0 0 1-2.41-2.41l-2.2-7.89c-.02-.09-.15-.09-.17 0l-2.18 7.85a6.194 6.194 0 0 1-2.46 2.46Z"
          className="uuid-1392608b-bc4d-4025-a156-f0cbd438f6c6"
        />
      </g>
    </g>
  </svg>
);
export default WizardIllustration;
