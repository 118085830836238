import React from "react";
import { motion, HTMLMotionProps } from "framer-motion";

interface IFadeInProps extends HTMLMotionProps<"div"> {
  children: React.ReactNode;
  show?: boolean;
  timeout?: number;
  delay?: number;
  direction?: "up" | "down" | "left" | "right";
  className?: string;
}

const FadeIn: React.FC<IFadeInProps> = ({
  children,
  show = true,
  timeout = 300,
  delay = 0,
  direction,
  className,
  ...rest
}) => {
  const getDirectionOffset = () => {
    switch (direction) {
      case "up":
        return { y: 30 };
      case "down":
        return { y: -30 };
      case "left":
        return { x: -30 };
      case "right":
        return { x: 30 };
      default:
        return {};
    }
  };

  const variants = {
    hidden: {
      opacity: 0,
      ...getDirectionOffset(),
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
    },
    exit: {
      opacity: 0,
      display: "none",
    },
  };

  return (
    <motion.div
      initial="hidden"
      animate={show ? "visible" : "exit"}
      exit="exit"
      variants={variants}
      transition={{
        duration: timeout / 1000, // Convert to seconds
        delay: delay / 1000,
        ease: "easeOut",
      }}
      className={className}
      {...rest}
    >
      {children}
    </motion.div>
  );
};

export default FadeIn;
