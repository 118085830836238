export interface Copy {
  _id: string;
  text: string;
  team: string;
  path: string[];
  createdAt?: string;
  updatedAt?: string;
}

const getCopy = () => fetch("/api/copy");

const createCopy = (text: string) =>
  fetch("/api/copy", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ text }),
  });

const updateCopy = (copyId: string, diff: Partial<Copy>) =>
  fetch(`/api/copy/${copyId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(diff),
  });

const deleteCopy = (copyId: string) =>
  fetch(`/api/copy/${copyId}`, {
    method: "DELETE",
  });

export interface CopyAPI {
  getCopy: () => Promise<Response>;
  createCopy: (text: string) => Promise<Response>;
  updateCopy: (copyId: string, diff: Partial<Copy>) => Promise<Response>;
  deleteCopy: (copyId: string) => Promise<Response>;
}

export default {
  getCopy,
  createCopy,
  updateCopy,
  deleteCopy,
};
