import API, { Media } from "../api/withApi";
import { generateImageThumbnail, generateVideoThumbnail } from "./conversion";
import { imageTypes, videoTypes } from "./loaders";

/**
 * Backfill things we have missed, using the client
 */

// const pruneMediaWithoutUri = async (media: Media[]) => {
//   const mediaWithoutUri = media.filter((e) => !e.uri);
//   const expectedDeletes = mediaWithoutUri.map((e: Media) =>
//     API.deleteMedia(e._id).then((data) => {
//       if (data.status !== 204) throw new Error("Delete failed");
//     }),
//   );

//   const deleteResult = await Promise.allSettled(expectedDeletes);
//   const numRejected = deleteResult.filter((prom) => prom.status === "rejected");

//   return {
//     pass: mediaWithoutUri.length - numRejected.length,
//     fail: numRejected.length,
//   };
// };
const backfillableTypes = [...imageTypes, ...videoTypes];

const backfillMediaThumbnails = async (media: Media[]) => {
  const mediaWithMissingThumbnails = media.filter(
    (e) => !e.thumbnail && backfillableTypes.includes(e.mimeType),
  );
  console.log(
    `${mediaWithMissingThumbnails.length} media items missing thumbnails`,
  );

  const expectedThumbUploads = mediaWithMissingThumbnails.map(async (e) => {
    if (imageTypes.includes(e.mimeType)) {
      return await generateImageThumbnail(e?.editUri ?? e?.uri)
        .then(
          async ({ thumbnail, height, width }) =>
            await API.createMediaThumbnail(e?._id, thumbnail, height, width),
        )
        .then((data) => {
          if (data.status !== 200) throw new Error("Thumbnail create failed");
        });
    } else if (videoTypes.includes(e.mimeType)) {
      return await generateVideoThumbnail(e?.editUri ?? e?.uri)
        .then(async ({ thumbnail, height, width }) => {
          return await API.createMediaThumbnail(
            e?._id,
            thumbnail,
            height,
            width,
          );
        })
        .then((data) => {
          if (data.status !== 200) throw new Error("Thumbnail create failed");
        })
        .catch(() => ({}));
    }
    return;
  });

  const uploadResult = await Promise.allSettled(expectedThumbUploads);
  const numRejected = uploadResult.filter((prom) => prom.status === "rejected");

  return {
    pass: mediaWithMissingThumbnails.length - numRejected.length,
    fail: numRejected.length,
  };
};

export default async (options: {
  before: (message?: string) => void;
  success: (message?: string) => void;
  fail: (message?: string) => void;
}) => {
  try {
    console.log("Backfilling...");
    const { before, success, fail } = options;

    const media: Media[] = await API.getMedia();

    // before?.("Pruning media without URI");
    // await pruneMediaWithoutUri(media)
    //   .then(({ pass, fail }) => success?.(`${pass} Pruned ${fail} Failed`))
    //   .catch(() => fail?.("Failed to prune media"));

    before?.("Creating thumbnails");
    await backfillMediaThumbnails(media)
      .then(({ pass, fail }) =>
        success?.(`${pass} Added Thumbnails, ${fail} Failed`),
      )
      .catch(() => fail?.("Failed to create thumbnails"));
  } catch (err) {
    console.log(err);
  }
};
