// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tagger {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.tagger .tagger-input {
  font-size: 12px;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.tagger .tag {
  border-radius: 10px;
  border: 1px #efefef solid;
  background-color: white;
  font-weight: 700;
  font-size: 12px;
  padding: 5px;
  padding-left: 7px;
  padding-right: 7px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 5px;
  cursor: pointer;
  transition: all 150ms ease-in;
}

.tagger .tag.selected {
  /* background-color: var(--color-accent-blue); */
  border: 1px solid var(--color-accent-blue);
  /* color: white; */
}

.tagger .tag:hover {
  border: 1px solid var(--color-accent-red);
}

.tagger .tag.blue:hover {
  border: 1px solid var(--color-accent-blue);
}
`, "",{"version":3,"sources":["webpack://./ui/src/partials/Tagger/tagger.style.css"],"names":[],"mappings":"AAAA;EACE,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,uBAAuB;EACvB,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,gDAAgD;EAChD,0CAA0C;EAC1C,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":[".tagger {\n  user-select: none;\n}\n\n.tagger .tagger-input {\n  font-size: 12px;\n  padding: 5px;\n  padding-left: 8px;\n  padding-right: 8px;\n  margin-right: 10px;\n  margin-bottom: 10px;\n}\n\n.tagger .tag {\n  border-radius: 10px;\n  border: 1px #efefef solid;\n  background-color: white;\n  font-weight: 700;\n  font-size: 12px;\n  padding: 5px;\n  padding-left: 7px;\n  padding-right: 7px;\n  display: inline-flex;\n  align-items: center;\n  margin-bottom: 10px;\n  margin-right: 5px;\n  cursor: pointer;\n  transition: all 150ms ease-in;\n}\n\n.tagger .tag.selected {\n  /* background-color: var(--color-accent-blue); */\n  border: 1px solid var(--color-accent-blue);\n  /* color: white; */\n}\n\n.tagger .tag:hover {\n  border: 1px solid var(--color-accent-red);\n}\n\n.tagger .tag.blue:hover {\n  border: 1px solid var(--color-accent-blue);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
