import React, { ComponentPropsWithoutRef } from "react";
const InsightfulIcon = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMinYMin slice"
  >
    <path
      fill="none"
      d="M12 0a12 12 0 0 1 12 12 12 12 0 0 1-12 12A12 12 0 0 1 0 12 12 12 0 0 1 12 0z"
    />
    <circle cx={12} cy={12} r={11} fill="#f5bb5c" />
    <path
      fill="#ffe1b2"
      fillRule="evenodd"
      d="M13.29 20.48h-2.52a.86.86 0 0 1-.84-.84v-2.1h4.2v2.1a.86.86 0 0 1-.84.84z"
    />
    <path
      fill="#fcf0de"
      fillRule="evenodd"
      d="M9.93 18v-.42A4.7 4.7 0 0 0 9.69 16a5.21 5.21 0 0 0-.81-1.26A5.14 5.14 0 0 1 7 10.84a5 5 0 1 1 10.08 0 5.27 5.27 0 0 1-2 4l-.06.05a1.75 1.75 0 0 0-.29.32 2.49 2.49 0 0 0-.36.73 5.15 5.15 0 0 0-.24 1.61V18"
    />
    <path
      fill="none"
      stroke="#5d3b01"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m7 3.68 1.05 1.39m9.44-1.39-1.06 1.39M12 2v2.1"
    />
    <path
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.15 6.93A3.51 3.51 0 0 0 9.23 8a3.93 3.93 0 0 0-1.07 1.9"
    />
    <path
      fill="none"
      d="M11.26 15.06a4 4 0 0 0 3.5-1 4.31 4.31 0 0 0-.22-6.5m-.41 7.04a3.62 3.62 0 0 0-.77 2.51"
    />
    <path
      fill="none"
      stroke="#5d3b01"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.29 20.48h-2.52a.86.86 0 0 1-.84-.84v-2.1h4.2v2.1a.86.86 0 0 1-.84.84z"
    />
    <path
      fill="none"
      stroke="#5d3b01"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.93 18v-.42A4.7 4.7 0 0 0 9.69 16a2.34 2.34 0 0 0-.41-.74 4.1 4.1 0 0 0-.58-.67 5.39 5.39 0 0 1-.58-.59 4.93 4.93 0 0 1-.83-1.46 4.68 4.68 0 0 1-.29-1.7h0A5 5 0 1 1 15.93 14a6.45 6.45 0 0 1-.9.91 1.93 1.93 0 0 0-.31.33 2.73 2.73 0 0 0-.35.73 4.88 4.88 0 0 0-.24 1.61V18"
    />
  </svg>
);
export default InsightfulIcon;
