import axios from "axios";
import {
  BriefcaseBusiness,
  Earth,
  MessagesSquare,
  UserRound,
  UsersRound,
} from "lucide-react";
import { z } from "zod";

export const IMetaForm = z.object({
  _id: z.string().optional().describe("Mongo ID"),
  id: z.string().describe("Lead Form ID"),
  name: z.string().min(1, "Form name is required").describe("Form name"),
  status: z.enum(["ACTIVE", "ARCHIVED", "DELETED"]).describe("Form status"),
  questions: z
    .array(
      z.object({
        key: z.string(),
        type: z.enum([
          "CUSTOM",
          "EMAIL",
          "FIRST_NAME",
          "FULL_NAME",
          "LAST_NAME",
          "PHONE",
          "DATE_TIME",
          "ID_CPF",
          "ID_AR_DNI",
          "ID_CL_RUT",
          "ID_CO_CC",
          "ID_EC_CI",
          "ID_PE_DNI",
          "STORE_LOOKUP",
          "CITY",
          "COMPANY_NAME",
          "COUNTRY",
          "DOB",
          "GENDER",
          "JOB_TITLE",
          "MARITIAL_STATUS",
          "PHONE_OTP",
          "POST_CODE",
          "PROVINCE",
          "RELATIONSHIP_STATUS",
          "STATE",
          "STREET_ADDRESS",
          "ZIP",
          "WORK_EMAIL",
          "MILITARY_STATUS",
          "WORK_PHONE_NUMBER",
          "SLIDER",
          "STORE_LOOKUP_WITH_TYPEAHEAD",
          "ID_MX_RFC",
          "JOIN_CODE",
          "USER_PROVIDED_PHONE_NUMBER",
          "FACEBOOK_LEAD_ID",
          "EMAIL_ALIAS",
          "MESSENGER",
        ]),
        label: z.string().optional(),
        options: z
          .array(z.object({ value: z.string(), key: z.string() }))
          .optional(),
        inline_context: z.string().optional(),
        context_provider_type: z.enum(["LOCATION_MANAGER"]).optional(),
        dependent_conditional_questions: z.array(z.object({})).optional(),
        conditional_questions_group_id: z.string().optional(),
      }),
    )
    .min(1, "At least one question is required"),
  allow_organic_lead_retrieval: z.boolean().optional().default(true),
  block_display_for_non_targeted_viewer: z.boolean().optional().default(false),
  context_card: z
    .object({
      title: z.string(),
      style: z.enum(["LIST_STYLE", "PARAGRAPH_STYLE"]),
      content: z.array(z.string()),
      button_text: z.string(),
      cover_photo_id: z.string().regex(/^\d+$/, "Must be a numeric string"),
    })
    .optional(),
  cover_photo: z.any().optional(),
  custom_disclaimer: z.object({}).optional(),
  follow_up_action_url: z.string().url().optional(),
  is_for_canvas: z.boolean().optional().default(false),
  is_optimized_for_quality: z.boolean().optional().default(false),
  locale: z
    .enum([
      "AR_AR",
      "CS_CZ",
      "DA_DK",
      "DE_DE",
      "EL_GR",
      "EN_GB",
      "EN_US",
      "ES_ES",
      "ES_LA",
      "FI_FI",
      "FR_FR",
      "HE_IL",
      "HI_IN",
      "HU_HU",
      "ID_ID",
      "IT_IT",
      "JA_JP",
      "KO_KR",
      "NB_NO",
      "NL_NL",
      "PL_PL",
      "PT_BR",
      "PT_PT",
      "RO_RO",
      "RU_RU",
      "SV_SE",
      "TH_TH",
      "TR_TR",
      "VI_VN",
      "ZH_CN",
      "ZH_HK",
      "ZH_TW",
    ])
    .optional(),
  privacy_policy: z
    .object({
      url: z.string().url(),
      link_text: z
        .string()
        .max(70, "Privacy policy link text must be 70 characters or less"),
    })
    .optional(),
  question_page_custom_headline: z.string().optional(),
  thank_you_page: z.object({}).optional(),
  tracking_parameters: z.record(z.string()).optional(),
  created_time: z.string().datetime().optional(),
  page_id: z.string().optional(),
  qualifiers: z.array(z.string()).optional(),
});

export const PREFILL_QUESTION_TYPES = [
  {
    icon: MessagesSquare,
    category: "Contact fields",
    items: [
      { value: "EMAIL", label: "Email" },
      { value: "PHONE", label: "Phone number" },
      { value: "STREET_ADDRESS", label: "Street address" },
      { value: "CITY", label: "City" },
      { value: "STATE", label: "State" },
      { value: "PROVINCE", label: "Province" },
      { value: "COUNTRY", label: "Country" },
      { value: "POST_CODE", label: "Post code" },
      { value: "ZIP", label: "Zip code" },
    ],
  },
  {
    icon: UserRound,
    category: "User information",
    items: [
      { value: "FULL_NAME", label: "Full name" },
      { value: "FIRST_NAME", label: "First name" },
      { value: "LAST_NAME", label: "Last name" },
    ],
  },
  {
    icon: UsersRound,
    category: "Demographic questions",
    items: [
      { value: "DOB", label: "Date of birth" },
      { value: "GENDER", label: "Gender" },
      { value: "MARITIAL_STATUS", label: "Marital status" },
      { value: "RELATIONSHIP_STATUS", label: "Relationship status" },
      { value: "MILITARY_STATUS", label: "Military status" },
    ],
  },
  {
    icon: BriefcaseBusiness,
    category: "Work information",
    items: [
      { value: "JOB_TITLE", label: "Job title" },
      { value: "WORK_PHONE_NUMBER", label: "Work phone number" },
      { value: "WORK_EMAIL", label: "Work email" },
      { value: "COMPANY_NAME", label: "Company name" },
    ],
  },
  {
    icon: Earth,
    category: "National ID number",
    info: "Include a question that asks for a national ID number. Only one national ID question can be included in each form, and your ad will only be delivered to people in the country using the national ID you selected.",
    items: [
      { value: "ID_CPF", label: "CPF (Brazil)" },
      { value: "ID_AR_DNI", label: "DNI (Argentina)" },
      { value: "ID_CL_RUT", label: "RUT (Chile)" },
      { value: "ID_CO_CC", label: "Cédula de Ciudadanía (Colombia)" },
      { value: "ID_EC_CI", label: "Cédula de Identidad (Ecuador)" },
      { value: "ID_PE_DNI", label: "DNI (Peru)" },
      { value: "ID_MX_RFC", label: "RFC (Mexico)" },
    ],
  },
];

export type IMetaForm = z.infer<typeof IMetaForm>;

export const CreateLeadFormData = IMetaForm.pick({
  name: true,
  questions: true,
  allow_organic_lead_retrieval: true,
  block_display_for_non_targeted_viewer: true,
  context_card: true,
  cover_photo: true,
  custom_disclaimer: true,
  follow_up_action_url: true,
  is_for_canvas: true,
  is_optimized_for_quality: true,
  locale: true,
  privacy_policy: true,
  question_page_custom_headline: true,
  thank_you_page: true,
  tracking_parameters: true,
}).extend({
  name: IMetaForm.shape.name.min(1, "Form name is required"),
  questions: IMetaForm.shape.questions.min(
    1,
    "At least one question is required",
  ),
});

export type CreateLeadFormData = z.infer<typeof CreateLeadFormData>;

export async function listPaidForms(): Promise<IMetaForm[]> {
  const response = await axios.get("/api/paid/forms");
  return response.data;
}

export async function getPaidForm(formId: string): Promise<IMetaForm> {
  const response = await axios.get(`/api/paid/forms/${formId}`);
  return response.data;
}

export async function createPaidForm(
  formData: CreateLeadFormData,
): Promise<IMetaForm> {
  const response = await axios.post("/api/paid/forms", formData);
  return response.data;
}

export async function syncPaidForms(): Promise<{
  message: string;
  matchedCount: number;
  modifiedCount: number;
  upsertedCount: number;
}> {
  const response = await axios.post("/api/paid/forms/sync");
  return response.data;
}

export async function deletePaidForm(
  formId: string,
): Promise<{ message: string }> {
  const response = await axios.delete(`/api/paid/forms/${formId}`);
  return response.data;
}

export async function updatePaidForm(
  formId: string,
  updateData: Partial<IMetaForm>,
): Promise<IMetaForm> {
  const response = await axios.patch(`/api/paid/forms/${formId}`, updateData);
  return response.data;
}
