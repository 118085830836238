import React from "react";
import { Heart, MessageCircle, Send, Bookmark } from "lucide-react";
import { BsPatchCheckFill } from "react-icons/bs";
import "./instagram-template.css";
import LoadMedia from "../../Load/LoadMedia";
import { useSelector } from "react-redux";
import { RootState } from "~/src/store";
import {
  withPfp,
  username as getUsername,
  pluralize,
  cn,
} from "~/src/util/reusables";
import { getActiveAccountForPlatform } from "~/src/views/CommunityManagement/lib/utils";
import { PlatformType } from "~/src/util/platforms";
import { InstagramPost } from "~/src/api/inbox.api";
import dayjs from "~/src/util/dayjs";
import { Team } from "~/src/api/team.api";
import ViewOnlyCaptionWithFallback from "../../../views/Post/components/editors/ViewOnlyCaptionWithFallback";

export interface InstagramTemplateProps {
  className?: string;
  display?: React.ReactNode;
  when?: dayjs.Dayjs;
  media?: string;
  mimeType?: string;
  caption: string;
  selected?: boolean;
  style?: any;
  onSelectAccount?: () => void;
  raw?: InstagramPost;
  /**
   * @deprecated use direct `forceDisplayPfp` and `forceDisplayName` instead
   */
  forceWorkspace?: Team; // Allows you to override the PFP and Name per: https://gitlab.com/flamel-ai/flamel-ai/-/issues/905
  likeCount?: number;
  forceDisplayPfp?: string; // Allows absolute control over pfp
  forceDisplayName?: string; // Allows absolute control over name
}

const InstagramTemplate = ({
  className,
  display,
  media,
  mimeType,
  caption,
  selected,
  style,
  when,
  onSelectAccount,
  raw,
  forceWorkspace,
  likeCount,
  forceDisplayPfp,
  forceDisplayName,
}: InstagramTemplateProps) => {
  const user = useSelector((state: RootState) => state.user);
  const { pfp: profile_picture_url, name: username } =
    getActiveAccountForPlatform(
      forceWorkspace ?? user?.workspace,
      PlatformType.Instagram,
    );

  const { timestamp, owner, comments_count } = raw ?? {};
  const { profile_picture_url: dynamicPfp, username: dynamicUsername } =
    owner ?? {};

  if (!when && timestamp) when = dayjs(timestamp);

  return (
    <div
      className={cn(
        "flex h-auto w-full animate-fadein overflow-hidden rounded-lg border border-[#efefef] bg-white shadow-md",
        className,
      )}
    >
      <div
        className="instagram post-template flex h-auto w-full flex-col overflow-y-auto"
        style={style ?? {}}
      >
        <div className="post-header" onClick={onSelectAccount}>
          <img
            crossOrigin="anonymous"
            src={withPfp(
              forceDisplayName
                ? forceDisplayPfp
                : dynamicPfp ?? profile_picture_url,
              forceDisplayName ??
                dynamicUsername ??
                username ??
                getUsername(user),
            )}
            alt=""
            className="user-icon"
          />
          <div className="user-name">
            {forceDisplayName ??
              dynamicUsername ??
              username ??
              getUsername(user)}
          </div>
        </div>
        {display && <div className="post-media display">{display}</div>}
        {!display && media && (
          <LoadMedia
            mimeType={mimeType}
            src={media}
            className="w-full rounded-none"
          />
        )}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 p-2 text-lg">
            <Heart />
            <MessageCircle />
            <Send />
          </div>
          <div className="p-2 text-lg">
            <Bookmark />
          </div>
        </div>
        {Boolean(likeCount) && (
          <div className="px-[10px] py-0.5 text-xs font-semibold">
            {pluralize(likeCount, "like")}
          </div>
        )}
        <div className="post-caption grid gap-1 break-words">
          <div className="username" onClick={onSelectAccount}>
            {forceDisplayName ??
              dynamicUsername ??
              username ??
              getUsername(user)}
          </div>
          <ViewOnlyCaptionWithFallback
            platform={PlatformType.Instagram}
            stringContent={caption}
            className="break-words"
          />
          {!!comments_count && (
            <p className="text-2xs text-[#999]">
              View all {comments_count} comments
            </p>
          )}
          <p className="time">
            {when
              ? when.year() === dayjs().year()
                ? when.format("MMMM D")
                : when.format("MMMM D, YYYY")
              : "Just Now"}
          </p>
        </div>

        <BsPatchCheckFill
          width={40}
          height={40}
          className={`selected-checkmark ${selected ? "" : "hidden"}`}
        />
      </div>
    </div>
  );
};

export default InstagramTemplate;
