import { ShipWheel } from "lucide-react";
import React from "react";
import { Media } from "../../../api/media.api";
import { cn } from "../../../util/reusables";

interface HubIconProps {
  icon?: Media;
  className?: string;
  dharmaClassName?: string;
}

export default function HubIcon({
  icon,
  className,
  dharmaClassName,
}: HubIconProps) {
  return icon ? (
    <img
      src={icon.uri}
      crossOrigin="anonymous"
      className={cn("mr-3 block h-8 w-8 rounded-sm object-contain", className)}
    />
  ) : (
    <ShipWheel
      className={cn("mr-3 block h-8 w-8", className, dharmaClassName)}
    />
  );
}
