import axios from "axios";
import { z } from "zod";

export const IMetaAdCreative = z.object({
  _id: z.string().describe("Mongo Object ID"),
  id: z.string().describe("Ad Creative's ID"),
  name: z.string().describe("Ad Creative's name"),
  object_story_spec: z
    .object({
      page_id: z.string().describe("ID of the Facebook Page"),
      instagram_actor_id: z
        .string()
        .optional()
        .describe("The Instagram actor that the story will be posted to"),
      link_data: z
        .object({
          link: z.string().url().describe("URL for the ad"),
          message: z.string().describe("The main body of the post"),
          name: z
            .string()
            .optional()
            .describe(
              "Name of the link. Overwrites the title of the link when you preview the ad",
            ),
          caption: z
            .string()
            .optional()
            .describe(
              "Link caption. Overwrites the caption under the title in the link",
            ),
          description: z
            .string()
            .optional()
            .describe(
              "Link description. Overwrites the description in the link when your ad displays",
            ),
          image_hash: z
            .string()
            .optional()
            .describe("Hash of an image in your ad account's image library"),
          picture: z
            .string()
            .optional()
            .describe("URL of a picture to use in the post"),
          call_to_action: z
            .object({
              type: z.string(),
              value: z
                .object({
                  lead_gen_form_id: z
                    .string()
                    .optional()
                    .describe(
                      "ID of the lead generation form to use with this call-to-action",
                    ),
                })
                .passthrough(),
            })
            .optional()
            .describe("An optional call to action button"),
          child_attachments: z
            .array(z.object({}).passthrough())
            .optional()
            .describe(
              "A 2-5 element array of link objects required for carousel ads",
            ),
          multi_share_optimized: z
            .boolean()
            .optional()
            .describe(
              "If set to true, automatically select and order images and links",
            ),
          multi_share_end_card: z
            .boolean()
            .optional()
            .describe(
              "If set to false, removes the end card which displays the page icon",
            ),
        })
        .passthrough()
        .describe("Data for a link ad"),
    })
    .describe("Specifications for the ad creative"),

  // Optional fields
  asset_feed_spec: z
    .object({
      images: z
        .array(
          z.object({
            adlabels: z
              .array(z.any())
              .optional()
              .describe("Ad Label spec of the asset used for your ad"),
            hash: z
              .string()
              .optional()
              .describe("Hash of the image used for your ad"),
            image_crops: z
              .any()
              .optional()
              .describe("Crops that will be applied to your image"),
            url: z
              .string()
              .optional()
              .describe("URL of the image used for your ad"),
            url_tags: z
              .string()
              .optional()
              .describe("URL tags spec of the asset used for your ad"),
          }),
        )
        .optional(),
      bodies: z
        .array(
          z.object({
            text: z.string().describe("Primary text for the ad"),
          }),
        )
        .optional(),
      titles: z
        .array(
          z.object({
            text: z.string().describe("Headline text for the ad"),
          }),
        )
        .optional(),
      descriptions: z
        .array(
          z.object({
            text: z.string().describe("Description text for the ad"),
          }),
        )
        .optional(),
      ad_formats: z
        .array(z.string())
        .optional()
        .describe("Format of the ad (e.g. SINGLE_IMAGE)"),
      call_to_action_types: z
        .array(z.string())
        .optional()
        .describe("Types of call to action buttons"),
      link_urls: z
        .array(
          z.object({
            website_url: z.string().url(),
          }),
        )
        .optional(),
      videos: z.array(z.any()).optional(),
    })
    .optional()
    .describe("Asset feed specifications for dynamic creative ads"),
  thumbnail_url: z
    .string()
    .url()
    .optional()
    .describe(
      "URL for a thumbnail image for this ad creative. You can provide dimensions for this with thumbnail_width and thumbnail_height.",
    ),
  object_type: z.string().optional(),
  image_url: z
    .string()
    .url()
    .optional()
    .describe("URL of the image used in the ad"),
  video_id: z.string().optional().describe("ID of the video used in the ad"),
  title: z.string().optional().describe("Title of the ad"),
  body: z.string().optional().describe("Body text of the ad"),
  call_to_action_type: z
    .string()
    .optional()
    .describe("Type of call-to-action button"),
  created_time: z.string().datetime().optional().describe("Created time"),
  updated_time: z.string().datetime().optional().describe("Updated time"),
  team: z
    .string()
    .optional()
    .describe("The team associated with the ad creative"),
  hub: z
    .string()
    .optional()
    .describe("The hub associated with the ad creative"),
});

export type IMetaAdCreative = z.infer<typeof IMetaAdCreative>;

export const CreateAdCreativeSchema = z.object({
  name: z
    .string()
    .min(1, "Ad creative name is required")
    .describe("Name of the ad creative, required field"),
  config: z
    .array(
      z.object({
        mediaId: z
          .string()
          .optional()
          .describe("ID of the media asset to use for this creative"),
        headline: z
          .string()
          .optional()
          .describe(
            "Headline of the ad creative, required field (object_story_spec.name field in Meta)",
          ),
        primary_text: z
          .string()
          .optional()
          .describe(
            "Primary text that appears in the ad, tells people what the ad is about (object_story_spec.message field in Meta)",
          ),
        description: z
          .string()
          .optional()
          .describe(
            "Description that shows in placements where it's likely to resonate with viewers (object_story_spec.description field in Meta)",
          ),
        link: z
          .string()
          .url()
          .describe(
            "Destination URL for the ad when clicked (object_story_spec.link field in Meta)",
          ),
        call_to_action: z.object({
          type: z
            .string()
            .describe(
              "Type of call-to-action button like 'APPLY_NOW', 'DOWNLOAD', 'GET_QUOTE', 'LEARN_MORE', 'SIGN_UP', 'SUBSCRIBE'",
            ),
          value: z
            .object({
              link: z
                .string()
                .url()
                .optional()
                .describe("URL where the call-to-action button leads to"),
              lead_gen_form_id: z
                .string()
                .optional()
                .describe(
                  "ID of the instant form to use when destination_type is ON_AD",
                ),
            })
            .optional(),
        }),
      }),
    )
    .describe(
      "Call to action configuration including button type and destination",
    ),
});

export type CreateAdCreativeData = z.infer<typeof CreateAdCreativeSchema>;

export const createPaidAdCreative = async (
  adCreativeData: CreateAdCreativeData,
): Promise<IMetaAdCreative> => {
  const response = await axios.post<IMetaAdCreative>(
    "/api/paid/adcreatives",
    adCreativeData,
  );
  return response.data;
};

export const updatePaidAdCreative = async (
  adCreativeId: string,
  updateData: Partial<CreateAdCreativeData>,
): Promise<IMetaAdCreative> => {
  const response = await axios.patch<IMetaAdCreative>(
    `/api/paid/adcreatives/${adCreativeId}`,
    updateData,
  );
  return response.data;
};

export const getPaidAdCreative = async (
  adCreativeId: string,
): Promise<IMetaAdCreative> => {
  const response = await axios.get<IMetaAdCreative>(
    `/api/paid/adcreatives/${adCreativeId}`,
  );
  return response.data;
};

export const getPaidAdCreativeByMetaId = async (
  creative_id: string,
): Promise<IMetaAdCreative> => {
  const response = await axios.get<IMetaAdCreative>(
    `/api/paid/adcreatives/meta/${creative_id}`,
  );
  return response.data;
};

export const listPaidAdCreatives = async (): Promise<IMetaAdCreative[]> => {
  const response = await axios.get<IMetaAdCreative[]>("/api/paid/adcreatives");
  return response.data;
};

export const deletePaidAdCreative = async (
  adCreativeId: string,
): Promise<{ message: string }> => {
  const response = await axios.delete<{ message: string }>(
    `/api/paid/adcreatives/${adCreativeId}`,
  );
  return response.data;
};

export const getPaidAdCreativePreview = async (
  adCreativeId: string,
  ad_format?: string,
): Promise<string> => {
  const response = await axios.get<string>(
    `/api/paid/adcreatives/${adCreativeId}/preview`,
    { params: { ad_format } },
  );
  return response.data;
};

export const getPaidAdCreativePreviewByMetaId = async (
  creative_id: string,
  ad_format?: string,
): Promise<string> => {
  const response = await axios.get<string>(
    `/api/paid/adcreatives/meta/${creative_id}/preview`,
    { params: { ad_format } },
  );
  return response.data;
};

export const syncPaidAdCreatives = async (): Promise<{
  message: string;
  matchedCount: number;
  modifiedCount: number;
  upsertedCount: number;
}> => {
  const response = await axios.post<{
    message: string;
    matchedCount: number;
    modifiedCount: number;
    upsertedCount: number;
  }>("/api/paid/adcreatives/sync");
  return response.data;
};

export const getPaidAdCreativePreviewFormats = async (
  object_type?: string,
): Promise<string[]> => {
  const response = await axios.get<string[]>(
    `/api/paid/adcreatives/preview/formats`,
    {
      params: { object_type },
    },
  );
  return response.data;
};
