// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linkedin.post-template {
  align-items: flex-start;
}

.linkedin.post-template .user {
  display: flex;
  align-items: center;
  padding: 12px;
}

.linkedin.post-template .user .name {
  font-size: 16px;
  color: #333;
  font-weight: 600;
  display: inline-block;
}

.linkedin.post-template .user .name .details {
  font-weight: 400;
  font-size: 12px;
  color: #888;
}

.linkedin.post-template .caption {
  padding: 12px;
  padding-top: 0px;
}

.linkedin.post-template .text-edit {
  padding-left: 12px;
  margin-bottom: 12px;
}

.linkedin.post-template .text-edit:focus {
  margin-left: 12px;
  margin-right: 12px;
}

.selected-checkmark {
  position: absolute;
  width: 40px;
  height: 40px;
  top: -20px;
  right: -20px;
  color: var(--color-accent-blue);
  transition: all 150ms ease-in-out;
  opacity: 1;
}

.selected-checkmark.hidden {
  opacity: 0;
  top: -40px;
}
`, "",{"version":3,"sources":["webpack://./ui/src/partials/Templates/LinkedInTemplate/linkedin-template.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,UAAU;EACV,YAAY;EACZ,+BAA+B;EAC/B,iCAAiC;EACjC,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,UAAU;AACZ","sourcesContent":[".linkedin.post-template {\n  align-items: flex-start;\n}\n\n.linkedin.post-template .user {\n  display: flex;\n  align-items: center;\n  padding: 12px;\n}\n\n.linkedin.post-template .user .name {\n  font-size: 16px;\n  color: #333;\n  font-weight: 600;\n  display: inline-block;\n}\n\n.linkedin.post-template .user .name .details {\n  font-weight: 400;\n  font-size: 12px;\n  color: #888;\n}\n\n.linkedin.post-template .caption {\n  padding: 12px;\n  padding-top: 0px;\n}\n\n.linkedin.post-template .text-edit {\n  padding-left: 12px;\n  margin-bottom: 12px;\n}\n\n.linkedin.post-template .text-edit:focus {\n  margin-left: 12px;\n  margin-right: 12px;\n}\n\n.selected-checkmark {\n  position: absolute;\n  width: 40px;\n  height: 40px;\n  top: -20px;\n  right: -20px;\n  color: var(--color-accent-blue);\n  transition: all 150ms ease-in-out;\n  opacity: 1;\n}\n\n.selected-checkmark.hidden {\n  opacity: 0;\n  top: -40px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
