import { ScrollArea } from "@radix-ui/react-scroll-area";
import { Check } from "lucide-react";
import React from "react";
import { Role, UserAccount } from "~/src/api/auth.api";
import { username, withPfp } from "~/src/util/reusables";

interface UserSearchProps {
  seats: Partial<UserAccount>[];
  selected: string[];
  includeSuperAdmins?: boolean;
  onSelect: (user: Partial<UserAccount>) => void;
  onDeselect: (user: Partial<UserAccount>) => void;
}

const UserSearch = ({
  seats,
  selected,
  includeSuperAdmins,
  onSelect,
  onDeselect,
}: UserSearchProps) => {
  return (
    <div>
      <ScrollArea>
        <div className="flex flex-col text-xs">
          {seats?.map((e) => (
            <div
              className="flex cursor-pointer select-none flex-row items-center space-x-2 rounded-md p-3 transition-colors hover:bg-gray-100"
              onClick={() => {
                if (
                  includeSuperAdmins &&
                  e.organizationRole === Role.SUPER_ADMIN
                )
                  return;
                selected?.includes(e._id) ? onDeselect(e) : onSelect(e);
              }}
              key={e._id}
            >
              <img
                src={withPfp(e.pfp, `${e?.firstname} ${e?.lastname}`)}
                className="block h-5 w-5 rounded-full shadow-lg"
                crossOrigin="anonymous"
              />
              <div>{username(e)}</div>
              {(selected?.includes(e._id) ||
                (includeSuperAdmins &&
                  e.organizationRole === Role.SUPER_ADMIN)) && (
                <Check className="h-4 w-4 text-green-400" />
              )}
            </div>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
};

export default UserSearch;
