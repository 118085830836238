import React from "react";
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "~/src/primitives/toast";
import { useToast } from "~/src/primitives/use-toast";

export function Toaster({
  className,
  swipeDirection = "right",
  viewPortClassName,
}: {
  className?: string;
  swipeDirection?: "left" | "right" | "up" | "down";
  viewPortClassName?: string;
}) {
  const { toasts } = useToast();

  return (
    <ToastProvider swipeDirection={swipeDirection}>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} {...props} className={className}>
            <div className="flex flex-col gap-2">
              {title && (
                <ToastTitle className="text-xs font-semibold">
                  {title}
                </ToastTitle>
              )}
              {description && (
                <ToastDescription className="text-sm">
                  {description}
                </ToastDescription>
              )}
              {action && <div className="mt-2 flex">{action}</div>}
            </div>
            <ToastClose className="absolute right-2 top-2" />
          </Toast>
        );
      })}
      <ToastViewport className={viewPortClassName} />
    </ToastProvider>
  );
}
