import React, { useState } from "react";
import { useSelector } from "react-redux";
import API, { Hub, Team } from "~/src/api/withApi";
import { Button } from "~/src/primitives/button";
import { RootState } from "~/src/store";
import { useDispatch } from "react-redux";
import { setUser } from "~/src/reducers/user";
import { setError, setLoading, setSuccess } from "~/src/reducers/toolkit";
import { useNavigate } from "react-router-dom";
import mindbodyIcon from "~/src/assets/MindbodyIcon.png";
import luminIcon from "~/src/assets/LuminIcon.png";
import canvaIcon from "~/src/assets/CanvaIcon.png";
import { Check, Send, ExternalLink, Copy } from "lucide-react";
import { Input } from "~/src/primitives/input";
import { cn, TooltipButton } from "../../../util/reusables";
import { connectLumin, disconnectLumin } from "../../../api/lumin.api";

type IntegrationsProps = {
  className?: string;
  workspace: Team;
  onUpdate?: () => void | Promise<void>; // Custom logic to be called after an update occurs
};

export default function Integrations({
  className,
  workspace,
  onUpdate = () => {},
}: IntegrationsProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const [siteId, setSiteId] = useState<string>();
  const [locationId, setLocationId] = useState<string>();

  if (!user?.manifest?.integrations) return null;

  const handleActivateMindbody = async () => {
    if (!workspace?.mindbody?.siteId && !siteId)
      return dispatch(setError("Site ID cannot be empty"));
    try {
      dispatch(setLoading("Sending activation email"));
      const updatedUser = await fetch(
        `/api/mindbody/activate?siteId=${
          siteId ?? workspace?.mindbody?.siteId
        }`,
      ).then((data) => data.json());
      await onUpdate();
      dispatch(setUser(updatedUser));
      dispatch(setSuccess("Sent! Check your email to connect Mindbody"));
    } catch {
      dispatch(setError("An error occured while activating Mindbody"));
    }
  };

  const handleCompleteActivation = () => {
    dispatch(setLoading("Completing the Mindbody Integration"));
    fetch("/api/mindbody/connect")
      .then((data) => data.json())
      .then(async (updatedUser) => {
        if (updatedUser?.error) {
          if (updatedUser.error === "Mindbody site not found.")
            throw new Error(
              "Site setup incomplete. Follow the steps sent to your email.",
            );
          throw new Error(updatedUser.error);
        }
        await onUpdate();
        dispatch(setUser(updatedUser));
        dispatch(setSuccess("Connected Mindbody"));
      })
      .catch((err) => {
        dispatch(
          setError(
            err?.message ?? "An error occured while connecting Mindbody",
          ),
        );
      });
  };

  const handleNavigateToMindbodyAutomation = async () => {
    const blank = await API.createPortfolioItem({}).then((data) => data.json());
    navigate(`/drafts/${blank._id}?ctab=ai&att=caption&mb=1`);
  };

  const handleDisconnectMindbody = async () => {
    try {
      const updatedUser = await fetch("/api/mindbody/disconnect").then((data) =>
        data.json(),
      );
      dispatch(setUser(updatedUser));
      dispatch(setSuccess("Disconnected Mindbody"));
    } catch {
      dispatch(setError("An error occured while disconnecting Mindbody"));
    }
  };

  const handleDisconnectCanva = async () => {
    try {
      const updatedUser = await API.deleteCanvaConfiguration(user._id).then(
        (data) => data.json(),
      );
      dispatch(setUser(updatedUser));
      dispatch(setSuccess("Disconnected Canva"));
    } catch {
      dispatch(setError("An error occured while disconnecting Canva"));
    }
  };

  const handleConnectLumin = async () => {
    if (!workspace?.lumin?.locationId && !locationId)
      return dispatch(setError("Location ID cannot be empty"));
    try {
      dispatch(setLoading("Connecting Lumin..."));
      const updatedUser = await connectLumin(
        locationId ?? workspace?.lumin?.locationId,
      );
      await onUpdate();
      dispatch(setUser(updatedUser));
      dispatch(setSuccess("Lumin connected"));
    } catch {
      dispatch(setError("An error occured while connecting Lumin"));
    }
  };

  const handleDisconnectLumin = async () => {
    try {
      const updatedUser = await disconnectLumin();
      dispatch(setUser(updatedUser));
      dispatch(setSuccess("Lumin disconnected"));
    } catch {
      dispatch(setError("An error occured while disconnecting Lumin"));
    }
  };

  const workspaceHub =
    (workspace?.primaryHub as Hub) ?? (workspace?.hubs?.[0] as Hub);
  const isHubMissingKey = workspaceHub && !workspaceHub?.apiKeys?.length;

  return (
    <div className={cn("section", className)}>
      <h5 className="section-label">
        Integrations
        <span className="ml-2 font-light italic">
          (Workspace: {workspace?.name})
        </span>
      </h5>
      {/* Mindbody */}
      {user?.manifest?.integrations ? (
        <>
          <div className="value">
            <div className="flex items-center space-x-2">
              <img
                crossOrigin="anonymous"
                src={mindbodyIcon}
                alt=""
                className="social-account"
              />
              <span>Mindbody</span>
            </div>
            {workspace?.mindbody?.isConnected ? (
              <div className="inline-flex flex-row items-center space-x-2">
                <Button
                  className="fl-button pill small"
                  onClick={handleNavigateToMindbodyAutomation}
                  size="xs"
                >
                  Start Automation
                </Button>
                <Button
                  className="h-7 rounded-full text-2xs font-semibold"
                  variant="secondary"
                  size="sm"
                  onClick={handleDisconnectMindbody}
                >
                  Disconnect
                </Button>
              </div>
            ) : workspace?.mindbody?.isPendingActivation ? (
              <div className="flex items-center space-x-2">
                <span className="mr-2 text-xs font-semibold text-red-500">
                  Check your email to activate
                </span>
                <TooltipButton text="Re-send Activation Email">
                  <Button
                    onClick={handleActivateMindbody}
                    variant="outline"
                    size="icon-sm"
                  >
                    <Send className="h-4 w-4" />
                  </Button>
                </TooltipButton>
                <TooltipButton text="Complete Activation">
                  <Button
                    className="bg-green-600 hover:bg-green-700"
                    onClick={handleCompleteActivation}
                    size="icon-sm"
                  >
                    <Check className="h-4 w-4" />
                  </Button>
                  ,
                </TooltipButton>
              </div>
            ) : (
              <div className="flex w-full max-w-[200px] items-center space-x-2">
                <Input
                  type="text"
                  minLength={1}
                  maxLength={16}
                  onChange={(e) => {
                    setSiteId(e.target.value);
                  }}
                  placeholder="Site ID"
                />
                <Button
                  onClick={handleActivateMindbody}
                  className="text-xs font-bold"
                  size="sm"
                >
                  Activate
                </Button>
              </div>
            )}
          </div>
          {user?.canva?.userId && (
            <div className="value">
              <div className="flex items-center space-x-2">
                <img
                  crossOrigin="anonymous"
                  src={canvaIcon}
                  alt=""
                  className="social-account"
                />
                <span>Canva</span>
              </div>
              {user?.canva?.userId ? (
                <div className="inline-flex flex-row items-center space-x-2">
                  <Button
                    asChild
                    className="fl-button small h-7 rounded-full text-2xs font-semibold"
                  >
                    <a
                      className="fl-button"
                      href="https://www.canva.com/login/?redirect=%2Fdesign%3Fcreate%26type%3DTABQqs5Kbyc%26ui%3DeyJFIjp7IkE_IjoiTiIsIkEiOiJBQUZzTXNoMDFjQV9nUG9IV0sifX0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Go to Canva{" "}
                      <ExternalLink strokeWidth={3} className="ml-2 h-3 w-3" />
                    </a>
                  </Button>
                  <Button
                    className="h-7 rounded-full text-2xs font-semibold"
                    variant="secondary"
                    size="sm"
                    onClick={handleDisconnectCanva}
                  >
                    Disconnect
                  </Button>
                </div>
              ) : (
                <Button
                  asChild
                  className="connect-social h-7 rounded-full text-2xs font-semibold"
                >
                  <a
                    className="fl-button pill small"
                    href="https://www.canva.com/login/?redirect=%2Fdesign%3Fcreate%26type%3DTABQqs5Kbyc%26ui%3DeyJFIjp7IkE_IjoiTiIsIkEiOiJBQUZzTXNoMDFjQV9nUG9IV0sifX0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Connect Canva
                  </a>
                </Button>
              )}
            </div>
          )}

          {/* Lumin */}
          <div className="value">
            <div className="flex items-center space-x-2">
              <img
                crossOrigin="anonymous"
                src={luminIcon}
                alt=""
                className="social-account"
              />
              <span>Lumin</span>
            </div>
            {workspace?.lumin?.locationId ? (
              <div className="inline-flex flex-row items-center gap-4">
                <span className="text-sm font-semibold">
                  {workspace?.lumin?.locationId}
                </span>
                {workspaceHub ? (
                  isHubMissingKey ? (
                    <p className="text-2xs text-destructive">
                      Please generate an API key for hub: {workspaceHub.name}...
                    </p>
                  ) : (
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${window.location.origin}/api/lumin/login/${workspace?.lumin?.locationId}/${workspaceHub?.apiKeys?.[0]?.key}`,
                        );
                        dispatch(setSuccess("Embed URL copied to clipboard!"));
                      }}
                      className="fl-button small h-7 rounded-full text-2xs font-semibold"
                    >
                      Copy Embed URL
                      <Copy strokeWidth={3} className="ml-2 h-3 w-3" />
                    </Button>
                  )
                ) : (
                  <p className="text-2xs text-destructive">
                    Please connect this workspace to a hub...
                  </p>
                )}
                <Button
                  className="h-7 rounded-full text-2xs font-semibold"
                  variant="secondary"
                  size="sm"
                  onClick={handleDisconnectLumin}
                >
                  Disconnect
                </Button>
              </div>
            ) : (
              <div className="flex w-full max-w-[200px] items-center space-x-2">
                <Input
                  type="text"
                  minLength={1}
                  onChange={(e) => {
                    setLocationId(e.target.value);
                  }}
                  placeholder="Location ID"
                />
                <Button
                  onClick={handleConnectLumin}
                  className="text-xs font-bold"
                  size="sm"
                >
                  Connect
                </Button>
              </div>
            )}
          </div>
        </>
      ) : (
        <p>No integrations are configurable with your account.</p>
      )}
    </div>
  );
}
