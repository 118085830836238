import React from "react";
import API from "~/src/api/withApi";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";
import { Button } from "~/src/primitives/button";
import { UserMinus } from "lucide-react";
import { UserAccount } from "~/src/api/auth.api";
import { useDispatch } from "react-redux";
import { setUser } from "~/src/reducers/user";
import { Alert } from "~/src/primitives/alert";

interface KickUserModalProps {
  show: boolean;
  userSeat: Partial<UserAccount>;
  onHide: () => void;
}

const KickUserModal = ({ show, userSeat, onHide }: KickUserModalProps) => {
  const dispatch = useDispatch();

  const onKickUser = async (userId: string) =>
    API.kickUserFromOrganization(userId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)));

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-row items-center justify-center">
            <div>
              <UserMinus className="mr-2 h-4 w-4" />
            </div>
            <div>{userSeat?.email}</div>
          </div>
        </DialogHeader>
        <div>
          <Alert>
            Are you sure you want to remove this user from the organization?
          </Alert>
        </div>
        <DialogFooter>
          <Button variant="outline" size="sm" onClick={onHide}>
            Cancel
          </Button>
          <Button
            variant="default"
            size="sm"
            onClick={() => {
              onHide();
              onKickUser(userSeat._id);
            }}
          >
            Confirm & Kick
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default KickUserModal;
