import React, { useState } from "react";
import API from "~/src/api/withApi";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";
import { Button } from "~/src/primitives/button";
import { UserCog } from "lucide-react";
import { Role, UserAccount } from "~/src/api/auth.api";
import { useDispatch } from "react-redux";
import { setUser } from "~/src/reducers/user";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/src/primitives/select";
import { ScrollArea } from "~/src/primitives/scroll-area";
import { unenumify } from "../UserSeats";
import { setSuccess } from "~/src/reducers/toolkit";

interface UpdateUserModalProps {
  show: boolean;
  userSeat: Partial<UserAccount>;
  onHide: () => void;
}

const UpdateUserModal = ({ show, userSeat, onHide }: UpdateUserModalProps) => {
  const dispatch = useDispatch();
  const [role, setRole] = useState<Role>();

  const onUpdateUser = async (userId: string, diff: Partial<UserAccount>) =>
    API.updateOrganizationUser(userId, diff)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)));

  const editReady = role !== userSeat?.organizationRole && role !== undefined;

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-row items-center justify-center">
            <div>
              <UserCog className="mr-2 h-4 w-4" />
            </div>
            <div>{userSeat?.email}</div>
          </div>
        </DialogHeader>
        <div>
          <div className="flex flex-row items-center justify-start space-x-4">
            <div className="mx-4 text-xs font-bold">Role:</div>
            <Select
              onValueChange={(v: Role) => setRole(v)}
              defaultValue={userSeat?.organizationRole}
            >
              <SelectTrigger>
                <SelectValue placeholder="Hour" className="w-16" />
              </SelectTrigger>
              <SelectContent>
                <ScrollArea className="max-h-[200px]">
                  <SelectGroup>
                    {Object.values(Role).map((rv) => (
                      <SelectItem value={rv} key={rv}>
                        {unenumify(rv)}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </ScrollArea>
              </SelectContent>
            </Select>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" size="sm" onClick={onHide}>
            Close
          </Button>
          <Button
            variant="default"
            size="sm"
            disabled={!editReady}
            onClick={async () => {
              if (editReady) {
                const diff = { organizationRole: role };
                await onUpdateUser(userSeat._id, diff);
                setRole(undefined);
                dispatch(setSuccess("Saved!"));
                onHide();
              }
            }}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateUserModal;
