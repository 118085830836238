import { ChevronDown } from "lucide-react";
import React, { Fragment, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { Button } from "../../primitives/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../primitives/dropdown-menu";
import Tinybadge from "../../primitives/tinybadge";
import Main, { type MainRoute } from "../../routes";
import { RootState } from "../../store";
import { cn } from "../../util/reusables";

export const focusedNavItemStyles = (isPaidMode: boolean) =>
  cn(
    isPaidMode
      ? "bg-white/10 hover:hover:bg-[#0D4949]/80 text-[#C5E99F] hover:text-[#C5E99F]"
      : "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground",
    "transition-colors duration-200 ease-in-out shadow-lg",
  );

export default function DesktopNav({ isPaidMode }: { isPaidMode: boolean }) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user);
  const [openDropdowns, setOpenDropdowns] = useState<Record<string, boolean>>(
    {},
  );
  const [searchParams] = useSearchParams();

  const MainRoutes = useMemo(
    () => Main(user, navigate, searchParams),
    [user, navigate, searchParams],
  );

  const memoizedRoutes = useMemo(() => {
    return Object.entries(MainRoutes).map(([name, route]) => {
      let childPaths: string[] = [];
      if (route.routes) {
        const intermediate: MainRoute = Object.assign({}, ...route.routes);
        childPaths = Object.values(intermediate)
          .map((childRoute) =>
            typeof childRoute !== "string" ? childRoute.to : undefined,
          )
          .filter(Boolean) as string[];
      }
      return { name, route, childPaths };
    });
  }, [MainRoutes]);

  return (
    <div
      id="desktop-nav"
      className={cn(
        "hidden flex-row items-center gap-2 xl:flex",
        isPaidMode ? "text-primary-foreground" : "text-primary",
      )}
    >
      {memoizedRoutes.map(({ name, route, childPaths }, i) => {
        const icon = route.icon;
        const isOpen = openDropdowns[name] || false;
        const setIsOpen = (open: boolean) => {
          setOpenDropdowns((prev) => ({ ...prev, [name]: open }));
        };

        const isActive =
          route.isActive?.(location.pathname, location.search) ||
          childPaths.some((cp) =>
            `${location.pathname}${location.search}`.startsWith(cp),
          );

        const buttonClassName = cn(
          "h-8 px-4 py-2 font-bold transition-colors duration-200 ease-in-out",
          isPaidMode
            ? "bg-[#093C3E] text-primary-foreground/90 hover:bg-white/10 hover:text-primary-foreground"
            : "bg-primary-foreground/10 text-primary/90 hover:bg-black/10 hover:text-primary",
          isActive && focusedNavItemStyles(isPaidMode),
        );

        const chevronClassName = cn(
          "ml-1 h-3 w-3 rotate-0 transition-transform",
          isOpen && "rotate-180 transition-transform duration-200 ease-in-out",
        );

        const dropdownMenuItems =
          route.routes?.flatMap(({ withSeparator, title, ...rest }) => {
            const elems = [];
            if (typeof title === "string") {
              elems.push(
                <Fragment key={`desktop-${title}`}>
                  {withSeparator && <DropdownMenuSeparator />}
                  <DropdownMenuLabel className="text-2xs">
                    {title}
                  </DropdownMenuLabel>
                </Fragment>,
              );
            }
            Object.keys(rest).forEach((s, idx) => {
              const routeItem = (rest as Record<string, MainRoute>)[s];
              if (routeItem.disabled) {
                elems.push(
                  <Fragment key={`desktop-${name}-${s}-disabled`}>
                    {idx !== 0 && <DropdownMenuSeparator />}
                    <DropdownMenuItem
                      disabled
                      className="flex w-full items-center justify-between gap-3"
                    >
                      <span className="flex items-center">
                        {routeItem?.icon} {s}
                      </span>{" "}
                      {routeItem?.badge && (
                        <Tinybadge
                          text={routeItem.badge}
                          className={routeItem.badgeClassName}
                        />
                      )}
                    </DropdownMenuItem>
                  </Fragment>,
                );
              } else {
                if (routeItem?.onClick) {
                  elems.push(
                    <Fragment key={`desktop-${name}-${s}-button`}>
                      {idx !== 0 && <DropdownMenuSeparator />}
                      <DropdownMenuItem
                        className="flex w-full items-center justify-between gap-3"
                        onClick={async () => await routeItem.onClick()}
                      >
                        <span className="flex items-center">
                          {routeItem?.icon} {s}
                        </span>{" "}
                        {routeItem?.badge && (
                          <Tinybadge
                            text={routeItem.badge}
                            className={routeItem.badgeClassName}
                          />
                        )}
                      </DropdownMenuItem>
                    </Fragment>,
                  );
                } else if (routeItem?.to) {
                  elems.push(
                    <Fragment key={`desktop-${name}-${s}`}>
                      {idx !== 0 && <DropdownMenuSeparator />}
                      <Link to={routeItem.to} draggable="false">
                        <DropdownMenuItem className="flex w-full items-center justify-between gap-3">
                          <span className="flex items-center">
                            {routeItem?.icon} {s}
                          </span>{" "}
                          {routeItem?.badge && (
                            <Tinybadge
                              text={routeItem.badge}
                              className={routeItem.badgeClassName}
                            />
                          )}
                        </DropdownMenuItem>
                      </Link>
                    </Fragment>,
                  );
                }
              }
            });
            return elems;
          }) || [];

        return route.routes ? (
          <DropdownMenu onOpenChange={setIsOpen} key={`desktop-menu-${i}`}>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className={buttonClassName}>
                {icon} {name} <ChevronDown className={chevronClassName} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="min-w-48">
              {dropdownMenuItems}
            </DropdownMenuContent>
          </DropdownMenu>
        ) : (
          <Link to={route.to} key={`desktop-menu-item-${i}`} draggable="false">
            <Button variant="ghost" className={buttonClassName}>
              {icon} {name}
            </Button>
          </Link>
        );
      })}
    </div>
  );
}
