import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { APIComponent, Module, UserAccount } from "../../api/withApi";
import { setUser } from "../../reducers/user";

import AuthLayout from "~/src/layouts/AuthLayout";
import { useToast } from "~/src/primitives/use-toast";
import { Label } from "~/src/primitives/label";
import { Input } from "~/src/primitives/input";
import { Button } from "~/src/primitives/button";
import { FaFacebookSquare } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { Checkbox } from "~/src/primitives/checkbox";
import { formatEmail } from "~/src/util/reusables";
import {
  MailchimpTag,
  getMailchimpEntryTagFromPathname,
} from "~/src/util/chimp";
import { validateEmail } from "../Admin/components/Onboarding/AssistedSignUp";

type SignupProps = APIComponent;

const Signup = ({ registerUser, loginUser }: SignupProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toast } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  const canSignUp = !isLoading && acceptedTerms;

  let tags: MailchimpTag[] = [];
  const referrerPathname = sessionStorage.getItem("flamelai-referrer-pathname");
  if (referrerPathname) {
    sessionStorage.removeItem("flamelai-referrer-pathname");
    const inferredEntry = getMailchimpEntryTagFromPathname(referrerPathname);
    if (inferredEntry) tags = inferredEntry;
  }

  const handleCreateAccount = async (event: FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    event.preventDefault();
    const { target } = event ?? {};
    const [
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      div,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      terms,
      email,
      firstName,
      lastName,
      businessName,
      password,
      verify,
    ] = (target as any) ?? [];

    try {
      if (!validateEmail(email?.value)) {
        throw new Error("Please use a valid email.");
      }

      const passwordMatch = password?.value === verify?.value;
      if (!passwordMatch) {
        throw new Error("Make sure your passwords match.");
      }

      const passwordLength = password?.value.length >= 8;
      if (!passwordLength) {
        throw new Error("Passwords must be at least 8 characters.");
      }

      const formattedEmail = formatEmail(email?.value);

      const newUser: UserAccount = {
        email: formattedEmail,
        password: password?.value,
        firstname: firstName?.value,
        lastname: lastName?.value,
        metadata: { businessName: businessName?.value },
        pfp: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      };

      const registerUserResp = await registerUser(newUser, tags);
      if (!registerUserResp.ok) {
        if (registerUserResp.status === 409)
          throw new Error("An account with this email already exists.");
        throw new Error("Signup failed.");
      }

      const loginUserResp = await loginUser(newUser.email, newUser.password);
      if (!loginUserResp.ok) throw new Error("Login after signup failed.");

      const userJson = await loginUserResp.json();

      dispatch(setUser(userJson));
      const basePath = userJson?.module === Module.Paid ? "/paid" : "/";
      navigate(basePath);
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout
      heading="Create an account"
      description="Enter the information below to create your account!"
      bottomLink="/login"
      bottomLinkText="Already a member? Sign in"
      imageSrc="https://flamel-misc.s3.amazonaws.com/SignUpTestimonial.webp"
    >
      <form onSubmit={async (e) => await handleCreateAccount(e)}>
        <div className="grid gap-4">
          <div className="mb-2 flex items-center space-x-2">
            <Checkbox
              id="terms"
              onCheckedChange={(e) =>
                setAcceptedTerms(e === "indeterminate" ? false : e)
              }
            />
            <label
              htmlFor="terms"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              I agree to the{" "}
              <a
                target="_blank"
                href="https://flamel.ai/terms"
                className="text-primary underline-offset-4 hover:underline"
                rel="noreferrer"
              >
                terms & conditions
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                href="https://flamel.ai/privacy"
                className="text-primary underline-offset-4 hover:underline"
                rel="noreferrer"
              >
                privacy policy
              </a>
              .
            </label>
          </div>
          <div className="grid gap-1.5">
            <Label htmlFor="email">Business Email</Label>
            <Input
              required
              id="email"
              placeholder="name@example.com"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={!canSignUp}
              onChange={(e) => setIsEmail(Boolean(e?.target?.value))}
            />
          </div>
          {isEmail && (
            <>
              <div className="grid gap-1.5">
                <Label htmlFor="firstName">First Name</Label>
                <Input
                  required
                  id="firstName"
                  placeholder="Luna"
                  type="text"
                  autoCapitalize="none"
                  autoComplete="given-name"
                  autoCorrect="off"
                  disabled={!canSignUp}
                />
              </div>
              <div className="grid gap-1.5">
                <Label htmlFor="lastName">Last Name</Label>
                <Input
                  required
                  id="lastName"
                  placeholder="Lovelace"
                  type="text"
                  autoCapitalize="none"
                  autoComplete="family-name"
                  autoCorrect="off"
                  disabled={!canSignUp}
                />
              </div>
              <div className="grid gap-1.5">
                <Label htmlFor="businessName">Business Name</Label>
                <Input
                  required
                  id="businessName"
                  placeholder="Business & Co."
                  type="text"
                  autoCapitalize="none"
                  autoCorrect="off"
                  disabled={!canSignUp}
                />
              </div>
              <div className="grid gap-1.5">
                <Label htmlFor="password">Password</Label>
                <Input
                  required
                  id="password"
                  placeholder="********"
                  type="password"
                  autoCapitalize="none"
                  autoComplete="new-password"
                  autoCorrect="off"
                  disabled={!canSignUp}
                />
              </div>
              <div className="grid gap-1.5">
                <Label htmlFor="confirmPassword">Confirm Password</Label>
                <Input
                  required
                  id="confirmPassword"
                  placeholder="********"
                  type="password"
                  autoCapitalize="none"
                  autoComplete="new-password"
                  autoCorrect="off"
                  disabled={!canSignUp}
                />
              </div>
            </>
          )}
          <Button disabled={!isEmail || !canSignUp}>
            {isLoading && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="mr-2 h-4 w-4 animate-spin"
              >
                <path d="M21 12a9 9 0 1 1-6.219-8.56" />
              </svg>
            )}
            Sign Up with Email
          </Button>
        </div>
      </form>
      <div className="relative flex items-center gap-1">
        <span className="h-0 w-full flex-1 border-t" />
        <div className="relative flex w-fit justify-center text-xs uppercase">
          <span className="px-2 text-muted-foreground">Or continue with</span>
        </div>
        <span className="h-0 w-full flex-1 border-t" />
      </div>
      <div className="grid gap-2">
        <Button
          variant="outline"
          type="button"
          disabled={!canSignUp}
          onClick={() => {
            const googleUrl = new URL(
              "/api/google/login",
              window.location.origin,
            );
            tags.forEach((t) => googleUrl.searchParams.append("tags", t));
            window.top.location.href = googleUrl.toString();
          }}
        >
          {isLoading ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mr-2 h-4 w-4 animate-spin"
            >
              <path d="M21 12a9 9 0 1 1-6.219-8.56" />
            </svg>
          ) : (
            <FcGoogle className="mr-2 h-4 w-4" />
          )}{" "}
          Google
        </Button>
        <Button
          variant="outline"
          type="button"
          disabled={!canSignUp}
          onClick={() => {
            const fbUrl = new URL(
              "/api/facebook/login-oauth",
              window.location.origin,
            );
            tags.forEach((t) => fbUrl.searchParams.append("tags", t));
            window.top.location.href = fbUrl.toString();
          }}
        >
          {isLoading ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mr-2 h-4 w-4 animate-spin"
            >
              <path d="M21 12a9 9 0 1 1-6.219-8.56" />
            </svg>
          ) : (
            <FaFacebookSquare className="mr-2 h-4 w-4" />
          )}{" "}
          Facebook
        </Button>
      </div>
      <p className="px-8 text-center text-sm text-muted-foreground">
        By signing up, you agree to our{" "}
        <a
          href="https://flamel.ai/terms"
          target="_blank"
          rel="noreferrer"
          className="underline underline-offset-4 hover:text-primary"
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href="https://flamel.ai/privacy"
          target="_blank"
          rel="noreferrer"
          className="underline underline-offset-4 hover:text-primary"
        >
          Privacy Policy
        </a>
        .
      </p>
    </AuthLayout>
  );
};

export default Signup;
