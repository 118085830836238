import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const enum Role {
  USER = "user",
  ASSISTANT = "assistant",
}

export type Chat = {
  content: string;
  role: Role;
  // post: string;
};

export type LunaStateSlice = {
  history: Chat[];
  loading: boolean;
};

const initialState: LunaStateSlice = {
  history: [],
  loading: false,
};

const lunaSlice = createSlice({
  name: "luna",
  initialState,
  reducers: {
    addToHistory(state, action: PayloadAction<Chat>) {
      state.history.push(action.payload);
    },
    popFromHistory(state) {
      state.history.pop();
    },
    clearHistory(state) {
      return { ...state, history: [] };
    },
    setLoading(state, action: PayloadAction<boolean>) {
      return { ...state, loading: action.payload };
    },
  },
});

const { actions, reducer } = lunaSlice;
export const { addToHistory, popFromHistory, clearHistory, setLoading } =
  actions;
export default reducer;
