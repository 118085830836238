import React, { ComponentPropsWithoutRef } from "react";
const LikeIcon = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMinYMin slice"
  >
    <path
      fill="none"
      d="M12 0a12 12 0 0 1 12 12 12 12 0 0 1-12 12A12 12 0 0 1 0 12 12 12 0 0 1 12 0z"
    />
    <circle cx={12} cy={12} r={11} fill="#378fe9" />
    <path
      fill="#d0e8ff"
      fillRule="evenodd"
      d="M11.71 9.54H5.88A1.37 1.37 0 0 0 4.5 11 1.43 1.43 0 0 0 6 12.34h.25a1.25 1.25 0 0 0-.1 2.5 1.25 1.25 0 0 0 .52 2.23 1.23 1.23 0 0 0-.13.88 1.33 1.33 0 0 0 1.33 1h3.6a5.54 5.54 0 0 0 1.4-.18l2.26-.66h3c1.58-.06 2-7.29 0-7.29h-.86c-.14 0-.23-.3-.62-.72-.58-.62-1.23-1.42-1.69-1.88a11.19 11.19 0 0 1-2.68-3.46c-.37-.8-.41-1.17-1.18-1.17a1.22 1.22 0 0 0-1 1.28c0 .42.09.84.16 1.26a12.52 12.52 0 0 0 1.55 3.46"
    />
    <path
      fill="none"
      stroke="#004182"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.71 9.54H5.88a1.43 1.43 0 0 0-1 .43A1.43 1.43 0 0 0 6 12.36h.25A1.23 1.23 0 0 0 5 13.61a1.25 1.25 0 0 0 1.15 1.25 1.22 1.22 0 0 0-.47 1.28 1.24 1.24 0 0 0 1 .94 1.23 1.23 0 0 0-.13.88 1.33 1.33 0 0 0 1.33 1h3.6a6 6 0 0 0 1.4-.18l2.26-.66h3c1.58-.05 2-7.28 0-7.28h-.86c-.14 0-.23-.3-.62-.72-.59-.62-1.24-1.43-1.66-1.88a11.19 11.19 0 0 1-2.68-3.46c-.37-.81-.41-1.2-1.18-1.17a1.15 1.15 0 0 0-1 1.28c0 .4.05.81.11 1.21a12.12 12.12 0 0 0 1.55 3.44"
    />
  </svg>
);
export default LikeIcon;
