import { ReactNode } from "react";
import { ObjectValues } from "~/src/util/reusables";
import { DeltaType } from "@tremor/react";
import { PlatformType } from "~/src/util/platforms";

export const MetricType = {
  Impressions: "impressions",
  Audience: "audience",
  Engagements: "engagements",
  Reach: "reach",
} as const;
export type MetricType = ObjectValues<typeof MetricType>;

export type MetricData = {
  title: string;
  id: MetricType;
  metric: number;
  progress: number;
  target: string;
  delta?: string;
  deltaType?: DeltaType;
};

export type TimeSeriesPerMetricData = {
  impressions?: any;
  reach?: any;
  profile_views?: any;
};

export type SinglePostMetric = {
  icon: () => ReactNode;
  name: string;
  platform: PlatformType;
  value: number;
  timestamp: Date | string;
};

export const sumMetrics = (data: any): number =>
  data?.length
    ? data?.reduce((total: number, item: any) => {
        for (const key in item) {
          if (typeof item[key] === "number") {
            total += item[key];
          }
        }
        return total;
      }, 0)
    : 0;

export const getPercentChange = (current: number, target: number) => {
  const result = Number(((current / target) * 100).toFixed(2));
  return isNaN(result) ? 0 : result;
};

export const currentDate = new Date();

export const oneMonthAgoDate = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth() - 1,
  currentDate.getDate(),
);

// MOCKS
export const getRandomNumber = () => Math.floor(Math.random() * 100) + 1;

export const getRandomDate = () => {
  const currentDate = new Date();
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  const randomTimestamp =
    Math.random() * (currentDate.getTime() - oneYearAgo.getTime()) +
    oneYearAgo.getTime();
  const randomDate = new Date(randomTimestamp);

  return randomDate;
};

export const dataFormatter = (number: number) =>
  Intl.NumberFormat("us").format(number).toString();

export const dateFormatter = (date: string | number | Date) => {
  return new Date(date).toLocaleDateString("en-US");
};

/**
 * Given an array of metrics tagged with a platform, dedupe and combine values per platform
 * @param metricArray an array of analytics metrics (i.e. likes accross all platforms)
 * @returns resulting array, with the metric values added per platform
 */
export const combineMetricsByPlatform = (metricArray: SinglePostMetric[]) =>
  metricArray.reduce<SinglePostMetric[]>((results, metric) => {
    let existingObj: SinglePostMetric | undefined = results.find(
      (resultMetric) => resultMetric.platform === metric.platform,
    );

    if (existingObj) {
      existingObj = {
        ...existingObj,
        value: existingObj.value + metric.value,
      };
    } else {
      results.push(metric);
    }

    return results;
  }, []);
