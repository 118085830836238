import React, { Dispatch, SetStateAction, useState } from "react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/src/primitives/table";
import {
  getPagination,
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "~/src/primitives/pagination";
import { Input } from "~/src/primitives/input";
import { Search } from "lucide-react";
import { cn } from "../../../../util/reusables";
import { Button } from "../../../../primitives/button";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  onDownload?: (rows: Row<TData>[]) => void;
}

export type PaginationParams = {
  rowCount?: number;
  pagination?: PaginationState;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
};

export type SearchParams = {
  searchColumns?: string[];
  search?: string;
  setSearch?: Dispatch<SetStateAction<string>>;
};

interface DataTableStyleProps {
  containerClassName?: string;
  headerClassName?: string;
  headerRowClassName?: string;
  headerCellClassName?: string;
  bodyClassName?: string;
  rowClassName?: string;
  cellClassName?: string;
  alternateRowClassName?: string;
  paginationContainerClassName?: string;
  paginationContentClassName?: string;
  paginationTextClassName?: string;
  searchContainerClassName?: string;
  searchInputClassName?: string;
  paginationButtonClassName?: string;
  paginationActiveButtonClassName?: string;
  positiveValueClassName?: string;
  negativeValueClassName?: string;
}

const FALLBACK_PAGINATION = { pageIndex: 0, pageSize: 0 };

function formatPercent(value: number, isChange?: boolean): string {
  return (isChange && value > 0 ? "+" : "") + value.toFixed(2) + "%";
}

export function DataTable<TData, TValue>({
  columns = [],
  data = [],
  onDownload,
  rowCount = 0,
  pagination = FALLBACK_PAGINATION,
  setPagination,
  search = "",
  setSearch,
  searchColumns,
  containerClassName = "flex h-auto flex-col overflow-hidden rounded-lg border border-gray-200 bg-white shadow-md",
  headerClassName = "bg-gray-50",
  headerRowClassName = "border-b border-gray-200",
  headerCellClassName = "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
  bodyClassName = "",
  rowClassName = "hover:bg-gray-100 transition-colors",
  cellClassName = "px-6 py-4 whitespace-nowrap text-sm text-gray-900",
  alternateRowClassName = "bg-gray-50",
  paginationContainerClassName = "h-fit border-t border-gray-200 bg-gray-50",
  paginationContentClassName = "w-full justify-between gap-4 px-6 py-3",
  paginationTextClassName = "text-sm text-gray-700",
  searchContainerClassName = "relative h-fit w-fit",
  searchInputClassName = "max-w-sm bg-white pl-10 pr-4 text-gray-900 border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
  paginationButtonClassName = "px-3 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50",
  paginationActiveButtonClassName = "bg-orange-50 border-orange-500 text-orange-600",
  positiveValueClassName = "text-green-600",
  negativeValueClassName = "text-red-600",
}: DataTableProps<TData, TValue> &
  PaginationParams &
  SearchParams &
  DataTableStyleProps) {
  const [sorting, setSorting] = useState<SortingState>([]);

  // Deconstructors
  const { pageIndex, pageSize } = pagination;

  // Table Setup
  const table = useReactTable({
    data,
    columns,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualFiltering: true,
    rowCount,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      pagination,
      sorting,
    },
  });

  const totalPages = Math.ceil(rowCount / pageSize);
  const rowsInPage = (pageIndex + 1) * pageSize;
  const pageNumbering: ("..." | number)[] = getPagination(
    pageIndex + 1,
    totalPages,
  );

  return (
    <>
      <div className={containerClassName}>
        <Table className="h-full" containerClassName="max-h-[80vh]">
          <TableHeader className={headerClassName}>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className={headerRowClassName}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className={headerCellClassName}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className={bodyClassName}>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, i) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  className={cn(
                    rowClassName,
                    i % 2 === 0 ? "" : alternateRowClassName,
                  )}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className={cellClassName}>
                      {(cell.column.id.endsWith("_change") ||
                        cell.column.id.includes("rate")) &&
                      typeof cell.getValue() === "number" ? (
                        <span
                          className={cn(
                            cell.column.id.endsWith("_change") &&
                              (cell.getValue() as number) > 0 &&
                              positiveValueClassName,
                            cell.column.id.endsWith("_change") &&
                              (cell.getValue() as number) < 0 &&
                              negativeValueClassName,
                          )}
                        >
                          {formatPercent(
                            cell.getValue() as number,
                            cell.column.id.endsWith("_change"),
                          )}
                        </span>
                      ) : typeof cell.getValue() === "number" ? (
                        Number.isInteger(cell.getValue() as number) ? (
                          (Number(cell.getValue())?.toLocaleString(
                            undefined,
                          ) as any)
                        ) : (
                          (cell.getValue() as number).toFixed(2)
                        )
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {Boolean(pagination?.pageSize) && (
          <Pagination className={paginationContainerClassName}>
            <PaginationContent className={paginationContentClassName}>
              <div className={paginationTextClassName}>
                {rowCount > 0 && (
                  <>
                    Showing{" "}
                    <span className="font-medium">
                      {rowsInPage - pageSize + 1}
                    </span>{" "}
                    to{" "}
                    <span className="font-medium">
                      {Math.min(rowsInPage, rowCount)}
                    </span>{" "}
                    of{" "}
                  </>
                )}
                <span className="font-medium">{rowCount}</span> results
              </div>
              {!!searchColumns?.length && (
                <div className={searchContainerClassName}>
                  <Search className="absolute left-3 top-1/2 z-20 h-4 w-4 -translate-y-1/2 transform text-muted-foreground" />
                  <Input
                    placeholder={`Search by ${searchColumns.join("/")}...`}
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                    className={searchInputClassName}
                  />
                </div>
              )}
              <div className="flex items-center gap-1 p-2">
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                    className={paginationButtonClassName}
                  />
                </PaginationItem>
                {pageNumbering.map((num) => {
                  if (num === "...")
                    return (
                      <PaginationItem key={num}>
                        <PaginationEllipsis
                          className={paginationButtonClassName}
                        />
                      </PaginationItem>
                    );

                  return (
                    <PaginationItem key={num}>
                      <PaginationLink
                        isActive={num === pageIndex + 1}
                        onClick={() =>
                          setPagination((curr) => ({
                            ...curr,
                            pageIndex: num - 1,
                          }))
                        }
                        disabled={
                          (num < pageIndex + 1 &&
                            !table.getCanPreviousPage()) ||
                          (num > pageIndex + 1 && !table.getCanNextPage())
                        }
                        className={cn(
                          paginationButtonClassName,
                          num === pageIndex + 1 &&
                            paginationActiveButtonClassName,
                        )}
                      >
                        {num}
                      </PaginationLink>
                    </PaginationItem>
                  );
                })}
                <PaginationItem>
                  <PaginationNext
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                    className={paginationButtonClassName}
                  />
                </PaginationItem>
              </div>
            </PaginationContent>
          </Pagination>
        )}
      </div>
      {onDownload && table.getRowModel().rows?.length > 0 && (
        <Button
          className="whitespace-nowrap font-semibold"
          size="sm"
          onClick={() => onDownload(table.getSortedRowModel().rows)}
        >
          Download CSV
        </Button>
      )}
    </>
  );
}

export function convertPropertyToReadableFormat(str: string) {
  // Split the string by underscores
  const words = str?.split("_");

  // Capitalize the first letter of the first word
  const capitalizedFirstWord =
    words?.[0]?.charAt(0)?.toUpperCase() + words?.[0]?.slice(1);

  // Join the words with spaces
  const readableString = [
    capitalizedFirstWord,
    ...(words?.slice(1) ?? []),
  ].join(" ");

  return readableString;
}
