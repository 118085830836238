import React, { ComponentPropsWithoutRef } from "react";
const SupportIcon = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMinYMin slice"
  >
    <defs>
      <mask id="a" x={0} y={0} maskUnits="userSpaceOnUse">
        <path fill="#fff" d="M12 23A11 11 0 1 0 1 12a11 11 0 0 0 11 11z" />
      </mask>
    </defs>
    <g mask="url(#a)">
      <circle cx={12} cy={12} r={12} fill="none" />
      <path fill="#d8d8d8" d="M12 23A11 11 0 1 0 1 12a11 11 0 0 0 11 11z" />
      <path fill="#bba9d1" d="M12 23A11 11 0 1 0 1 12a11 11 0 0 0 11 11z" />
      <path
        fill="#fde7ff"
        d="M8.36 14.28H8c-.36-.13-2.16-.82-3.38-1.16a.39.39 0 0 1-.28-.33 1.06 1.06 0 0 1 .21-.79.65.65 0 0 1 .52-.26 1.06 1.06 0 0 1 .31 0 2.73 2.73 0 0 1 .66.39l.09.07.56.39.52.37 1.31.52c.18.08.92.42.87.6s-.83.17-1 .17z"
      />
      <path
        fill="#fce2ba"
        d="M5.09 11.93a1 1 0 0 1 .24.05 3.1 3.1 0 0 1 .7.42l1.11.77 2.19.94a5.18 5.18 0 0 1-1.18 0H8.1c-.49-.19-2.2-.83-3.39-1.16a.18.18 0 0 1-.13-.15.87.87 0 0 1 .13-.63.42.42 0 0 1 .37-.17zm0-.39a.88.88 0 0 0-.7.34 1.3 1.3 0 0 0-.2.92.6.6 0 0 0 .43.52c1.22.34 3 1 3.36 1.15a.58.58 0 0 0 .19 0h.25c.27 0 2.46.28 2.52 0-.43-.25-2-1-2.29-1.18l-1.3-.52-.52-.36a5 5 0 0 1-.53-.38L6.2 12a2.47 2.47 0 0 0-.72-.42 1.15 1.15 0 0 0-.37-.06z"
        opacity={0.23}
        style={{
          isolation: "isolate",
        }}
      />
      <path
        fill="#eae2f3"
        d="M21.23 19.91a33.64 33.64 0 0 1-5.3-.53h-.1a29.14 29.14 0 0 1-3.93-.81c-1.15-.35-2.28-.8-3.37-1.23L8 17.16c-1-.41-1.87-.75-2.64-1.08L5.11 16a10.91 10.91 0 0 1-1.34-.63C3.17 15 3 14.53 3.25 14a.87.87 0 0 1 .86-.5h.07a1.13 1.13 0 0 1 .26 0 28.83 28.83 0 0 1 4.25 1.36l1.78.06 4 .14a7.3 7.3 0 0 0-3-1.28c-.37-.1-.71-.2-.78-.47a1.06 1.06 0 0 1 .35-1.13 1.44 1.44 0 0 1 .84-.2 5.19 5.19 0 0 1 1 .13 4.24 4.24 0 0 1 .53.16 5.6 5.6 0 0 0 1.29.28 15.14 15.14 0 0 1 2.2.45c2.24.6 2.68 1.72 3 2.4-.09-.27 0-.07 0-.17l.06-.09h1.48c.18 0 1-.21 1 0a9.33 9.33 0 0 1-1 4.7.29.29 0 0 1-.21.16z"
      />
      <path fill="#d67676" d="M20.18 15.13a.17.17 0 0 0 .14 0z" />
      <path
        fill="#ecaa96"
        fillRule="evenodd"
        d="M8.93 4.64a2.23 2.23 0 0 0-3.08 0 2.15 2.15 0 0 0 0 3.07L9.17 11l3.36-3.3a2.14 2.14 0 0 0 0-3.06A2.2 2.2 0 0 0 11 4a2.23 2.23 0 0 0-1.55.63l-.24.25z"
      />
      <path
        fill="none"
        stroke="#493d57"
        d="M22.89 15.31a4.45 4.45 0 0 1-.19 2.55A5.34 5.34 0 0 1 21.42 20a85.21 85.21 0 0 1-8.62-1c-1.58-.27-9.31-3.65-9.61-3.82a1.13 1.13 0 0 1 .52-1.67c.85-.25 3 1.12 4.41 1.25s4.5.25 5.65.25-1-.8-1.59-1-1.48-.4-1.67-1 .42-1 .93-1a14.1 14.1 0 0 1 2.72.67 9.22 9.22 0 0 1 3.61.61 4.2 4.2 0 0 1 2.16 2c.16.34 2.79-.11 2.96.02z"
      />
      <path
        fill="none"
        stroke="#493d57"
        d="M4.11 13.47C3.75 12.5 4.33 12 5 12s1.35.61 2.45 1.3A27.28 27.28 0 0 0 11 15"
      />
      <path
        fill="none"
        stroke="#77280c"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.74 5.13a2.23 2.23 0 0 0-3.09 0 2.15 2.15 0 0 0 0 3L9 11.5l3.37-3.3A2.15 2.15 0 0 0 13 6.31a2.19 2.19 0 0 0-1.21-1.59 2.14 2.14 0 0 0-1-.22 2.22 2.22 0 0 0-1.56.64L9 5.38z"
      />
    </g>
  </svg>
);
export default SupportIcon;
