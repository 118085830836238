import { googleLogout } from "@react-oauth/google";
import { BadgeDollarSign, LogOut } from "lucide-react";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../primitives/dropdown-menu";
import Tinybadge from "../../primitives/tinybadge";
import { Account } from "../../routes";
import { RootState } from "../../store";
import { username, withPfp } from "../../util/reusables";

export default function UserMenu() {
  const user = useSelector((state: RootState) => state.user);
  const AccountRoutes = useMemo(() => Account(user).routes, [user]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="shrink-0 focus:outline-none"
        draggable="false"
      >
        <div className="relative flex flex-row items-center gap-4 text-sm focus:outline-none">
          <img
            src={withPfp(user?.pfp, username(user))}
            className="h-9 w-9 rounded-full shadow"
            crossOrigin="anonymous"
          />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="min-w-[200px]"
        align="end"
        alignOffset={-8}
      >
        <DropdownMenuLabel>{username(user)}</DropdownMenuLabel>

        {Object.keys(AccountRoutes).map((e, i) => (
          <Link
            to={AccountRoutes[e].to}
            key={`account-${e}-${i}`}
            draggable="false"
          >
            <DropdownMenuItem>
              {AccountRoutes[e].icon} {e}
            </DropdownMenuItem>
          </Link>
        ))}
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => window.open("https://affiliates.flamel.ai", "_blank")}
          className="flex gap-2"
        >
          <BadgeDollarSign className="h-4 w-4 text-green-600" />
          <span>Affiliates</span>
          <Tinybadge text="NEW" />
        </DropdownMenuItem>
        {user?.manifest?.sign_out && (
          <DropdownMenuItem
            onClick={() => {
              googleLogout();
              window.top.location.href = "/api/auth/account/logout";
            }}
          >
            <LogOut className="mr-2 h-4 w-4" />
            <span>Sign Out</span>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
