import React, { useEffect } from "react";
import { Button } from "~/src/primitives/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";
import { platforms } from "./SelectPlatform";
import { useSelector } from "react-redux";
import { RootState } from "~/src/store";
import { PlatformType } from "~/src/util/platforms";

interface ConnectPlatformModalProps {
  platform: PlatformType;
  show: boolean;
  onHide: () => void;
}

const ConnectPlatformModal = ({
  platform,
  show,
  onHide,
}: ConnectPlatformModalProps) => {
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    user.workspace?.[platform] && onHide();
  }, [user.workspace?.[platform]]);

  const p = platforms[platform];

  if (p === undefined) return;

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-row items-center gap-2">
            <div className="h-8 w-8">{p.icon}</div>{" "}
            <strong>Connect to {p.text}</strong>
          </div>
        </DialogHeader>
        <p>
          In order to post your content to {p.text}, you must connect your
          account first.
        </p>
        <DialogFooter>
          <Button variant="outline" onClick={onHide}>
            Close
          </Button>
          <Button onClick={p.connect}>Connect</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConnectPlatformModal;
