import React, { useContext, useEffect, useState } from "react";
import API from "~/src/api/withApi";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";
import { useDispatch } from "react-redux";
import { Input } from "~/src/primitives/input";
import { Button } from "~/src/primitives/button";
import { ArrowRight, User, UserCheck } from "lucide-react";
import { UserAccount } from "~/src/api/auth.api";
import { username, withPfp, withTooltip } from "~/src/util/reusables";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/src/primitives/popover";
import UserSearch from "./UserSearch";
import { OrganizationContext } from "../../Organization";
import { Separator } from "~/src/primitives/separator";
import { ApprovalWorkflow } from "~/src/api/organization.api";
import Selector from "~/src/partials/Tagger/Selector";
import { Team } from "~/src/api/team.api";
import { setUser } from "~/src/reducers/user";
import { setLoading } from "~/src/reducers/toolkit";

interface UpdateApprovalWorkflowModalProps {
  show: boolean;
  approvalWorkflow: ApprovalWorkflow;
  onUpdate: (
    approvalWorkflowId: string,
    diff: Partial<ApprovalWorkflow>,
  ) => void;
  onHide: () => void;
}

const UpdateApprovalWorkflowModal = ({
  show,
  approvalWorkflow,
  onUpdate,
  onHide,
}: UpdateApprovalWorkflowModalProps) => {
  const dispatch = useDispatch();
  const organization = useContext(OrganizationContext);
  const [name, setName] = useState<string>("");
  const [approvers, setApprovers] = useState<Partial<UserAccount>[]>([]);
  const [appliedWorkspaces, setAppliedWorkspaces] = useState<Partial<Team>[]>();

  const onApplyApprovalWorkflowToWorkspace = (
    workspaceId: string,
    approvalWorkflowId: string,
  ) =>
    API.applyApprovalWorkflowToWorkspace(workspaceId, approvalWorkflowId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)));

  const onRemoveApprovalWorkflowFromWorkspace = (workspaceId: string) =>
    API.removeApprovalWorkflowFromWorkspace(workspaceId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)));

  useEffect(() => {
    if (approvalWorkflow) {
      setName(approvalWorkflow.name);
      setApprovers(approvalWorkflow.approvers as UserAccount[]);
    }
    if (organization?.workspaces && approvalWorkflow) {
      const appliedWorkspaces = (
        organization.workspaces as Partial<Team>[]
      ).filter((e) => e.approvalWorkflow === approvalWorkflow._id);
      setAppliedWorkspaces(appliedWorkspaces);
    }
  }, [approvalWorkflow, organization, show]);

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-row items-center justify-center">
            <div>
              <UserCheck className="mr-2 h-4 w-4" />
            </div>
            <div>&lsquo;{approvalWorkflow?.name}&lsquo;</div>
          </div>
        </DialogHeader>
        <div>
          <div className="flex flex-row items-center justify-start space-x-4">
            <div className="text-xs font-bold">Name:</div>
            <div>
              <Input
                value={name}
                placeholder="My Approval Workflow"
                spellCheck={false}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>

          <Separator className="my-6" />

          <div className="flex flex-row items-center space-x-4">
            <div className="text-xs font-bold">Flow: </div>
            <div className="flex grow flex-row items-center space-x-2">
              {approvers?.map((user) => (
                <div
                  className="flex shrink-0 flex-row items-center"
                  key={user._id}
                >
                  {withTooltip(
                    <img
                      src={withPfp(user?.pfp, username(user))}
                      crossOrigin="anonymous"
                      className="h-8 w-8 rounded-full border border-white shadow-md"
                    />,
                    username(user),
                  )}

                  <ArrowRight className="ml-2 h-4 w-4" />
                </div>
              ))}

              <Popover>
                <PopoverTrigger>
                  <div className="flex h-8 w-8 cursor-pointer flex-row items-center justify-center rounded-full border border-white bg-gray-200 shadow-lg hover:bg-gray-300">
                    <User className="h-4 w-4" />
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <UserSearch
                    seats={organization?.seats}
                    selected={approvers?.map((e) => e._id)}
                    onSelect={(user) => {
                      const tmp = [...approvers];
                      tmp.push(user);
                      setApprovers(tmp);
                    }}
                    onDeselect={(user) => {
                      let tmp = [...approvers];
                      tmp = tmp.filter((e) => e._id !== user._id);
                      setApprovers(tmp);
                    }}
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>

          <Separator className="my-6" />

          {appliedWorkspaces && (
            <div className="flex flex-row items-center space-x-4">
              <div className="text-xs font-bold">Workspaces: </div>
              <Selector
                options={(organization.workspaces as Partial<Team>[]).map(
                  (e) => e._id,
                )}
                current={appliedWorkspaces.map((e) => e._id)}
                mutator={(_id) =>
                  (organization.workspaces as Partial<Team>[]).find(
                    (e) => e._id === _id,
                  )?.name
                }
                onChange={(_id) => {
                  const awsp = appliedWorkspaces.map((e) => e._id);
                  if (awsp.includes(_id)) {
                    dispatch(setLoading("Updating workspace..."));
                    onRemoveApprovalWorkflowFromWorkspace(_id).finally(() =>
                      dispatch(setLoading(undefined)),
                    );
                  } else {
                    dispatch(setLoading("Updating workspace..."));
                    onApplyApprovalWorkflowToWorkspace(
                      _id,
                      approvalWorkflow._id,
                    ).finally(() => dispatch(setLoading(undefined)));
                  }
                }}
              />
            </div>
          )}
        </div>
        <DialogFooter>
          <Button variant="outline" size="sm" onClick={onHide}>
            Close
          </Button>
          <Button
            variant="default"
            size="sm"
            disabled={!name}
            onClick={() => {
              const diff: Partial<ApprovalWorkflow> = {
                name,
                approvers: approvers.map((e) => e._id),
              };

              onHide();
              onUpdate(approvalWorkflow?._id, diff);
            }}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateApprovalWorkflowModal;
