import React, { ComponentPropsWithoutRef } from "react";
import FunnyIcon from "./FunnyIcon";
import CelebrateIcon from "./CelebrateIcon";
import SupportIcon from "./SupportIcon";
import LoveIcon from "./LoveIcon";
import InsightfulIcon from "./InsightfulIcon";
import LikeIcon from "./LikeIcon";
import { LinkedInReactionType } from "../utils";

export function ReactionIcon({
  reaction,
  ...rest
}: { reaction: LinkedInReactionType } & ComponentPropsWithoutRef<"svg">) {
  return {
    LIKE: <LikeIcon {...rest} />,
    PRAISE: <CelebrateIcon {...rest} />,
    EMPATHY: <LoveIcon {...rest} />,
    INTEREST: <InsightfulIcon {...rest} />,
    APPRECIATION: <SupportIcon {...rest} />,
    ENTERTAINMENT: <FunnyIcon {...rest} />,
  }[reaction];
}
