import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateRangePickerValue } from "@tremor/react";
import {
  TimeSeriesPerMetricData,
  currentDate,
  oneMonthAgoDate,
} from "../views/Analytics/utils";
import { RootState } from "../store";
import { isOrg } from "../util/reusables";
import dayjs from "../util/dayjs";
import { PlatformType } from "../util/platforms";

// SELECTORS –––––––––––––––––––––––––––––––––––––––––––
/**
 * Convers `dateRangeValue` to JS Date types
 */
export const selectDateRangeValues = createSelector(
  (state: RootState) => state.analytics.dateRangeValue,
  ({ from, to }) => ({
    from: dayjs(from)?.toDate(),
    to: to ? dayjs(to)?.toDate() : dayjs(from).add(1, "day")?.toDate(),
  }),
);
export const selectAvailablePlatforms = createSelector(
  (state: RootState) => state.user?.workspace,
  ({ instagram, facebook /* twitter*/, linkedin }) => [
    ...(instagram ? [PlatformType.Instagram] : []),
    ...(facebook ? [PlatformType.Facebook] : []),
    ...(isOrg(linkedin) ? [PlatformType.LinkedIn] : []),
    // ...(twitter ? [PlatformType.Twitter] : []),
  ],
);
export const selectEngagementSum = createSelector(
  (state: RootState) => ({
    instagram: state.analytics.instagramTotalValueMetrics,
    facebook: state.analytics.facebookTotalValueMetrics,
    twitter: state.analytics.twitterTotalValueMetrics,
    linkedin: state.analytics.linkedInTotalValueMetrics,
  }),
  ({ instagram, facebook, twitter, linkedin }) =>
    Number(instagram?.likes?.value || 0) +
    Number(instagram?.comments?.value || 0) +
    Number(instagram?.shares?.value || 0) +
    Number(facebook?.page_post_engagements?.value || 0) +
    Number(twitter?.likes?.value || 0) +
    Number(twitter?.comments?.value || 0) +
    Number(twitter?.shares?.value || 0) +
    Number(linkedin?.likes || 0) +
    Number(linkedin?.comments || 0) +
    Number(linkedin?.shares || 0),
);
// –––––––––––––––––––––––––––––––––––––––––––––––––––––

export type AnalyticsStateSlice = {
  dateRangeValue: DateRangePickerValue;
  selectedPlatforms: PlatformType[];
  selectedPlatformsTimeSeriesData: TimeSeriesPerMetricData;
  timeSeriesAnalysis: Record<string, any>;
  instagramTotalValueMetrics: Record<string, any>;
  twitterTotalValueMetrics: Record<string, any>;
  facebookTotalValueMetrics: Record<string, any>;
  linkedInTotalValueMetrics: Record<string, any>;
};

const initialState: AnalyticsStateSlice = {
  dateRangeValue: {
    from: oneMonthAgoDate,
    to: currentDate,
  },
  selectedPlatforms: [],

  // Time Series Data
  selectedPlatformsTimeSeriesData: {},
  timeSeriesAnalysis: {},

  // Total Values Data
  instagramTotalValueMetrics: {},
  facebookTotalValueMetrics: {},
  twitterTotalValueMetrics: {},
  linkedInTotalValueMetrics: {},
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setDateRangeValue(state, action: PayloadAction<DateRangePickerValue>) {
      const dateRange = action.payload;
      // Note: Needed to fix errors causing from and to to be the same value...
      if (dateRange?.selectValue === "tdy")
        dateRange.from = dayjs(dateRange.from).subtract(1, "day").toDate();
      return { ...state, dateRangeValue: dateRange };
    },
    setSelectedPlatforms(state, action: PayloadAction<PlatformType[]>) {
      return {
        ...state,
        selectedPlatforms: action.payload ?? [],
      };
    },
    setSelectedPlatformsTimeSeriesData(
      state,
      action: PayloadAction<TimeSeriesPerMetricData>,
    ) {
      return {
        ...state,
        selectedPlatformsTimeSeriesData: action.payload,
      };
    },
    setTimeSeriesAnalysis(state, action: PayloadAction<Record<string, any>>) {
      return {
        ...state,
        timeSeriesAnalysis: action.payload,
      };
    },
    setInstagramTotalValueMetrics(
      state,
      action: PayloadAction<Record<string, any>>,
    ) {
      return {
        ...state,
        instagramTotalValueMetrics: action.payload,
      };
    },
    setFacebookTotalValueMetrics(
      state,
      action: PayloadAction<Record<string, any>>,
    ) {
      return {
        ...state,
        facebookTotalValueMetrics: action.payload,
      };
    },
    setTwitterTotalValueMetrics(
      state,
      action: PayloadAction<Record<string, any>>,
    ) {
      return {
        ...state,
        twitterTotalValueMetrics: action.payload,
      };
    },
    setLinkedInTotalValueMetrics(
      state,
      action: PayloadAction<Record<string, any>>,
    ) {
      return {
        ...state,
        linkedInTotalValueMetrics: action.payload,
      };
    },
    resetMetrics(state) {
      return {
        ...state,
        selectedPlatformsTimeSeriesData: {},
        timeSeriesAnalysis: {},
        instagramTotalValueMetrics: {},
        facebookTotalValueMetrics: {},
        twitterTotalValueMetrics: {},
        linkedInTotalValueMetrics: {},
      };
    },
  },
});

const { actions, reducer } = analyticsSlice;
export const {
  setDateRangeValue,
  setSelectedPlatforms,
  setSelectedPlatformsTimeSeriesData,
  setTimeSeriesAnalysis,
  setInstagramTotalValueMetrics,
  setFacebookTotalValueMetrics,
  setTwitterTotalValueMetrics,
  setLinkedInTotalValueMetrics,
  resetMetrics,
} = actions;
export default reducer;
