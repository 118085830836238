import type Stripe from "stripe";

export const CUSTOMER_ID_RESERVED_VALUES = ["flamel-trial", "trial"];

const getCustomer = () =>
  fetch(`/api/stripe/customer`).then((res) => res.json());
const getInvoices = () =>
  fetch(`/api/stripe/invoices`).then((res) => res.json());
const getCustomerSubscriptions = () =>
  fetch(`/api/stripe/customer/subscriptions`).then((res) => res.json());

const customerPortal = () => (window.top.location.href = `/api/stripe/portal`);

const purchase = (price: string) =>
  (window.top.location.href = `/api/stripe/purchase?price=${price}&referral=${
    (window as any)?.tolt_referral
  }`);

const purchaseAgency = (
  acwPriceId: string,
  acw: number,
  auPriceId: string,
  au: number,
) => {
  const url = new URL("/api/stripe/purchase/agency", window.location.origin);
  url.searchParams.set("agency_client_workspace_price_id", acwPriceId);
  url.searchParams.set("agency_client_workspace", acw.toString());
  url.searchParams.set("agency_user", au.toString());
  url.searchParams.set("agency_user_price_id", auPriceId);
  url.searchParams.set("referral", (window as any)?.tolt_referral);

  window.top.location.href = url.toString();
};

const updatePermissionsFromStripe = () =>
  fetch(`/api/stripe/update-permissions`);

import axios from "axios";

interface SimplifiedPrice {
  id: string;
  currency: string;
  unit_amount: number | null;
  recurring: Stripe.Price.Recurring | null;
  lookup_key: string | null;
}

export interface SimplifiedProduct {
  id: string;
  name: string;
  description: string | null;
  metadata: Stripe.Metadata;
  prices: SimplifiedPrice[];
  isSubscribed: boolean;
  features: string[];
}

interface ResponseData {
  products: SimplifiedProduct[];
  currentSubscriptions: Stripe.Subscription[] | null;
}

export const getSimplifiedProducts = (): Promise<ResponseData> =>
  axios
    .get("/api/stripe/simplified-products")
    .then((response) => response.data);

type ShallowHub = { _id: string; name: string; iconUri: string };
export interface OrganizationStats {
  // Organization metadata
  _id: string;
  name?: string;
  tier: number;
  isTrial: boolean;

  // Seats
  seatsCount: number;

  // Workspaces
  workspacesCount: number;
  workspacesMax: number;
  workspaces: Array<{ _id: string; name: string }>;

  // Hubs
  hubsCount: number;
  hubs: Array<ShallowHub>;

  // Other
  approvalWorkflowsCount: number;
  guestsCount: number;
}
const getOrganizationStats = () =>
  fetch(`/api/stripe/organization-stats`).then((res) => res.json());

export interface StripeAPI {
  getProducts: () => Promise<Response>;
  getSubscribed: () => Promise<Response>;
  getCustomer: () => Promise<{
    customer: Stripe.Customer;
    products: Stripe.Product[];
  }>;
  getInvoices: () => Promise<Stripe.ApiList<Stripe.Invoice>>;
  getOrganizationStats: () => Promise<OrganizationStats>;
  getCustomerSubscriptions: () => Promise<Stripe.SubscriptionItem[]>;
  customerPortal: () => any;
  purchase: (price: string) => any;
  purchaseAgency: (
    acwPriceId: string,
    acw: number,
    auPriceId: string,
    au: number,
  ) => void;
  updatePermissionsFromStripe: () => Promise<Response>;
}

export default {
  getCustomer,
  getInvoices,
  getCustomerSubscriptions,
  customerPortal,
  purchase,
  purchaseAgency,
  updatePermissionsFromStripe,
  getOrganizationStats,
} as StripeAPI;
