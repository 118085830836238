import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PlatformType } from "../util/platforms";
import { ObjectValues } from "../util/reusables";

export const AGGREGATE_VIEWS = [
  {
    id: "cumulative",
    label: "Cumulative",
    description:
      "Cumulative insights measure your performance in running total over time..",
  },
  {
    id: "daily",
    label: "Daily",
    description:
      "Daily insights measure your performance for each day in the time period selected.",
  },
] as const;

type GetSelectedPlatformsTimeSeriesDataRequest = {
  platforms: string[];
  startDate: string;
  endDate: string;
};

type GetTotalValueDataRequest = Omit<
  GetSelectedPlatformsTimeSeriesDataRequest,
  "platforms"
>;

type GetAnalyticsAggregates = {
  since: string;
  until: string;
  workspaceIds: string[];
  platform: PlatformType | "all";
};

export type ContentSortValue =
  | "engagement_rate"
  | "total_engagements"
  | "total_impressions";
export const PostType = {
  All: "all",
  Image: "image",
  Video: "video",
  Carousel: "carousel",
  Text: "text",
} as const;
export const getPostTypeLabel = (type: string) =>
  ({
    all: "All Post Types",
    image: "Image",
    video: "Video",
    carousel: "Carousel",
    text: "Text Only",
  }[type] ?? "Unknown");
export type PostType = ObjectValues<typeof PostType>;

// Define your metric APIs
const analyticsApi = createApi({
  reducerPath: "analyticsApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api" }),
  endpoints: (builder) => ({
    getSelectedPlatformsTimeSeriesData: builder.query({
      query: (params: GetSelectedPlatformsTimeSeriesDataRequest) => ({
        url: `/analytics/metrics`,
        method: "GET",
        params: {
          startDate: params.startDate,
          endDate: params.endDate,
          platforms: params.platforms.filter((_) => _).join(","),
        },
      }),
    }),
    getInstagramTotalValueData: builder.query({
      query: (params: GetTotalValueDataRequest) => ({
        url: `/facebook/instagram/metrics`,
        method: "GET",
        params: {
          asTotalValues: "true",
          startDate: params.startDate,
          endDate: params.endDate,
        },
      }),
    }),
    getTwitterTotalValueData: builder.query({
      query: (params: GetTotalValueDataRequest) => ({
        url: `/twitter/metrics`,
        method: "GET",
        params: {
          asTotalValues: "true",
          startDate: params.startDate,
          endDate: params.endDate,
        },
      }),
    }),
    getFacebookTotalValueData: builder.query({
      query: (params: GetTotalValueDataRequest) => ({
        url: `/facebook/metrics`,
        method: "GET",
        params: {
          asTotalValues: "true",
          startDate: params.startDate,
          endDate: params.endDate,
        },
      }),
    }),
    getLinkedInTotalValueData: builder.query({
      query: (params: GetTotalValueDataRequest) => ({
        url: `/linkedin/metrics`,
        method: "GET",
        params: {
          asTotalValues: "true",
          startDate: params.startDate,
          endDate: params.endDate,
        },
      }),
    }),
    analyzeTimeSeriesData: builder.mutation({
      query: (data) => ({
        url: "/studio/openai/gpt4/analytics/time-series",
        method: "POST",
        body: data,
      }),
    }),
    backfillAnalytics: builder.query({
      query: () => ({
        url: `/analytics/backfill`,
        method: "GET",
      }),
    }),
    getAnalyticsAggregates: builder.query({
      query: (params: GetAnalyticsAggregates) => ({
        url: `/analytics/aggregates`,
        method: "GET",
        params: {
          since: params.since,
          until: params.until,
          workspaceIds: params.workspaceIds,
          platform: params.platform,
        },
      }),
    }),
    getAnalyticsRaw: builder.query({
      query: (params: GetAnalyticsAggregates) => ({
        url: `/analytics/raw`,
        method: "GET",
        params: {
          since: params.since,
          until: params.until,
          workspaceIds: params.workspaceIds,
          platform: params.platform,
        },
      }),
    }),
    getAnalyticsTotals: builder.query({
      query: (params: GetAnalyticsAggregates) => ({
        url: `/analytics/totals`,
        method: "GET",
        params: {
          since: params.since,
          until: params.until,
          workspaceIds: params.workspaceIds,
          platform: params.platform,
        },
      }),
    }),
    getAnalyticsContent: builder.query({
      query: (
        params: GetAnalyticsAggregates & {
          sortBy: ContentSortValue;
          filterByType: PostType;
        },
      ) => ({
        url: `/analytics/content`,
        method: "GET",
        params: {
          since: params.since,
          until: params.until,
          workspaceIds: params.workspaceIds,
          platform: params.platform,
          sortBy: params.sortBy,
          filterByType: params.filterByType,
        },
      }),
    }),
  }),
});

// Export the generated hooks for accessing the APIs
export const {
  useGetSelectedPlatformsTimeSeriesDataQuery,
  useGetInstagramTotalValueDataQuery,
  useGetTwitterTotalValueDataQuery,
  useGetFacebookTotalValueDataQuery,
  useGetLinkedInTotalValueDataQuery,
  useAnalyzeTimeSeriesDataMutation,
  useLazyBackfillAnalyticsQuery,
  useGetAnalyticsAggregatesQuery,
  useGetAnalyticsRawQuery,
  useGetAnalyticsTotalsQuery,
  useGetAnalyticsContentQuery,
} = analyticsApi;

export default analyticsApi;
