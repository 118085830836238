import { UserAccount } from "../api/withApi";

const diffDays = (start: Date, end: Date) =>
  Math.floor((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));

export const studioCallsByDay = (user: UserAccount) => {
  const trialStart = new Date(user.createdAt);
  const now = new Date();
  const dayMap: {
    [key: number]: number;
  } = {};

  if (user.metadata?.studioCalls && Array.isArray(user.metadata?.studioCalls)) {
    user.metadata.studioCalls.forEach((e: string) => {
      const call = new Date(e);
      const calledOn = diffDays(trialStart, call);
      if (dayMap[calledOn]) {
        dayMap[calledOn] += 1;
      } else {
        dayMap[calledOn] = 1;
      }
    });
  }

  const currentDay = diffDays(trialStart, now);
  return dayMap[currentDay] || 0;
};

export const trialDaysLeft = (user: UserAccount) => {
  if (user.createdAt !== undefined) {
    const now = new Date();
    const trialStart = new Date(user.createdAt);
    return 7 - diffDays(trialStart, now);
  } else {
    return 7;
  }
};

const isIndependentlySubscribed = (user: UserAccount) =>
  user?.subscription?.customerId &&
  (parseInt(user?.subscription?.tier) > 0 || user?.subscription?.plan);

const isOrganizationMember = (user: UserAccount) =>
  user?.organization && !user?.organization.locked && true;

export const isSubscribed = (user: UserAccount) =>
  isOrganizationMember(user) || isIndependentlySubscribed(user);

export const disable = (user: UserAccount, callsPerDay?: number) => {
  if (isSubscribed(user)) return false;
  if (!callsPerDay) callsPerDay = 10;

  if (trialDaysLeft(user) <= 0) {
    return true;
  }

  const daysLeft = trialDaysLeft(user);
  const calls = studioCallsByDay(user);
  const callsToday = daysLeft > 0 ? calls : 0;

  if (callsPerDay - callsToday <= 0) return true;

  return false;
};
