import React, { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import API from "../../api/withApi";

import AuthLayout from "~/src/layouts/AuthLayout";
import { Button } from "~/src/primitives/button";
import { useToast } from "~/src/primitives/use-toast";
import { Checkbox } from "~/src/primitives/checkbox";
import { useDispatch, useSelector } from "react-redux";
import { setError, setSuccess } from "~/src/reducers/toolkit";
import { setUser } from "~/src/reducers/user";
import { RootState } from "~/src/store";
import { Label } from "~/src/primitives/label";
import { Input } from "~/src/primitives/input";

const SetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const { toast } = useToast();
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const canSignUp = !isLoading && acceptedTerms;

  const handleSetPassword = async (e: FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();
    const { target } = e ?? {};
    const [
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      div,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      terms,
      password,
      verify,
    ] = (target as any) ?? [];

    try {
      const passwordMatch = password?.value === verify?.value;
      if (!passwordMatch) {
        throw new Error("Make sure your passwords match.");
      }

      const passwordLength = password?.value.length >= 8;
      if (!passwordLength) {
        throw new Error("Passwords must be at least 8 characters.");
      }

      await API.resetPassword(password.value).then(async (data) => {
        if (data.status) {
          dispatch(setSuccess("Password Set!"));
          const userJson = await data.json();
          dispatch(setUser(userJson));
          navigate("/");
        } else {
          dispatch(setError("An error occured while setting password."));
        }
      });
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout
      heading="Set a Password"
      description={`Set a password for your ${user?.email} account.`}
      className="overflow-hidden"
      imageSrc="https://flamel-misc.s3.amazonaws.com/SignInBackground.webp"
    >
      <form onSubmit={async (e) => await handleSetPassword(e)}>
        <div className="grid gap-4">
          <div className="mb-2 flex items-center space-x-2">
            <Checkbox
              id="terms"
              checked={acceptedTerms}
              onCheckedChange={(e) =>
                setAcceptedTerms(e === "indeterminate" ? false : e)
              }
            />
            <label
              htmlFor="terms"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              I agree to the{" "}
              <a
                target="_blank"
                href="https://flamel.ai/terms"
                className="text-primary underline-offset-4 hover:underline"
                rel="noreferrer"
              >
                terms & conditions
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                href="https://flamel.ai/privacy"
                className="text-primary underline-offset-4 hover:underline"
                rel="noreferrer"
              >
                privacy policy
              </a>
              .
            </label>
          </div>
          <div className="grid gap-1.5">
            <Label htmlFor="password">Password</Label>
            <Input
              required
              id="password"
              placeholder="********"
              type="password"
              autoCapitalize="none"
              autoComplete="new-password"
              autoCorrect="off"
              disabled={!canSignUp}
            />
          </div>
          <div className="grid gap-1.5">
            <Label htmlFor="confirmPassword">Confirm Password</Label>
            <Input
              required
              id="confirmPassword"
              placeholder="********"
              type="password"
              autoCapitalize="none"
              autoComplete="new-password"
              autoCorrect="off"
              disabled={!canSignUp}
            />
          </div>
          <Button disabled={!canSignUp}>
            {isLoading && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="mr-2 h-4 w-4 animate-spin"
              >
                <path d="M21 12a9 9 0 1 1-6.219-8.56" />
              </svg>
            )}
            Set Password
          </Button>
        </div>
      </form>
    </AuthLayout>
  );
};

export default SetPassword;
