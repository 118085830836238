import {
  type Campaign,
  type LarvalPost,
  type Media,
  type PortfolioItem,
  type Post,
} from "~/src/api/withApi";
import { Event } from "~/src/lib/Calendar/Calendar";
import { Presets } from "~/src/reducers/toolkit";
import { PlatformType } from "../../util/platforms";
import { Tag } from "./components/ImageTagger";

type PostProperties = {
  name?: string;
  topics?: string[];
  caption?: string;
  media?: Media[];
  platformContentType: string;
  prompt?: string;
  presets?: Presets;
  campaign?: Campaign;
  accountTags?: Record<PlatformType, Record<string, Tag[]>>;
};

/**
 *
 * @param item
 * @param platform
 * @param isWorkingItemLarval - VERY IMPORTANT!!! - without this, there can be false fallbacks to portfolio item media when not expected
 */
export const getPostProperties = (
  item: PortfolioItem | LarvalPost | Event | Post,
  platform?: PlatformType,
  isWorkingItemLarval?: boolean,
): PostProperties => {
  if (isWorkingItemLarval) {
    const larvalItem = item as LarvalPost;
    return {
      caption:
        larvalItem.overrides?.[platform]?.caption ?? larvalItem.caption ?? "",
      media:
        !larvalItem.media?.length && larvalItem.portfolioItem?.campaign
          ? (larvalItem.portfolioItem?.media as Media[]) ?? []
          : larvalItem.media ?? [],
      platformContentType:
        larvalItem.overrides?.[platform]?.platformContentType ??
        larvalItem.platformContentType ??
        "post",
      prompt: larvalItem.overrides?.[platform]?.prompt ?? larvalItem.prompt,
      presets: larvalItem.overrides?.[platform]?.presets ?? larvalItem.presets,

      // Portfolio related
      name:
        (item as PortfolioItem)?.name ??
        (item as LarvalPost)?.portfolioItem?.name ??
        "",

      topics:
        (item as PortfolioItem)?.topics ??
        (item as LarvalPost)?.portfolioItem?.topics ??
        [],
      campaign: (item?.campaign ??
        (item as LarvalPost)?.portfolioItem?.campaign) as Campaign,
      accountTags:
        (item as LarvalPost)?.portfolioItem?.accountTags ??
        (item as PortfolioItem)?.accountTags ??
        ({} as Record<PlatformType, Record<string, Tag[]>>),
    };
  }

  const name =
    (item as PortfolioItem)?.name ??
    (item as LarvalPost)?.portfolioItem?.name ??
    "";

  const topics =
    (item as PortfolioItem)?.topics ??
    (item as LarvalPost)?.portfolioItem?.topics ??
    [];

  // [Backwards Compatability - 4/4/24] - fall back to parent pfi
  const campaign = (item?.campaign ??
    (item as LarvalPost)?.portfolioItem?.campaign) as Campaign;
  const media = ((item?.media?.length
    ? item.media
    : (item as LarvalPost)?.portfolioItem?.media) ?? []) as Media[];
  const caption =
    ((item as PortfolioItem)?.overrides?.[platform]?.caption ||
      item?.caption ||
      (item as LarvalPost)?.portfolioItem?.caption) ??
    "";

  const platformContentType =
    (item as LarvalPost)?.overrides?.[platform]?.platformContentType ??
    (item as PortfolioItem)?.overrides?.[platform]?.platformContentType ??
    (item as LarvalPost)?.platformContentType ??
    (item as LarvalPost)?.portfolioItem?.platformContentType ??
    "post";

  const prompt =
    (item as PortfolioItem)?.overrides?.[platform]?.prompt ??
    item?.prompt ??
    (item as LarvalPost)?.portfolioItem?.prompt;
  const presets =
    (item as PortfolioItem)?.overrides?.[platform]?.presets ??
    (item as unknown as LarvalPost)?.presets ??
    (item as LarvalPost)?.portfolioItem?.presets;

  const accountTags =
    (item as LarvalPost)?.portfolioItem?.accountTags ??
    (item as PortfolioItem)?.accountTags ??
    ({} as Record<PlatformType, Record<string, Tag[]>>);

  return {
    name,
    topics,
    caption,
    media,
    platformContentType,
    prompt,
    presets,
    campaign,
    accountTags,
  };
};
