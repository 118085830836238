import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Module } from "../../api/auth.api";
import logo from "../../assets/flamel-logo-horiz.png";
import paidLogo from "../../assets/flamel-logo-paid.png";
import { RootState } from "../../store";
import { cn } from "../../util/reusables";

export default function Logo({
  className,
  isPaidMode,
}: {
  className?: string;
  isPaidMode?: boolean;
}) {
  const user = useSelector((state: RootState) => state.user);

  // TODO: Add a user?.overrides?.logo for dark mode!
  const mainLogo = user?.overrides?.logo ?? (isPaidMode ? paidLogo : logo);

  const basePath = user?.module === Module.Paid ? "/paid" : "/";

  return (
    <div
      draggable="false"
      className={cn("h-auto w-36 min-w-[9rem]", className)}
    >
      <Link
        to={user?.hub ? `/hub/${user?.hub?._id}${basePath}` : basePath}
        className="flex gap-2"
      >
        <img src={mainLogo} className="h-full w-full" crossOrigin="anonymous" />
      </Link>
    </div>
  );
}
