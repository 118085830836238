import {
  DateRangePicker,
  DateRangePickerItem,
  DateRangePickerProps,
} from "@tremor/react";
import React from "react";
import dayjs from "../util/dayjs";
import { cn } from "../util/reusables";

export function CustomDateRangePicker({
  className,
  ...props
}: DateRangePickerProps) {
  return (
    <DateRangePicker
      className={cn("z-40 max-w-md", className)}
      selectPlaceholder="Select range..."
      {...props}
    >
      {/* <DateRangePickerItem key="tdy" value="tdy" from={dayjs().startOf('day').toDate()}>
        Today
      </DateRangePickerItem>
      <DateRangePickerItem key="ydy" value="ydy" from={dayjs().subtract(1, 'days').toDate()}>
        Yesterday
      </DateRangePickerItem> */}
      <DateRangePickerItem
        key="7d"
        value="7d"
        from={dayjs().subtract(7, "days").toDate()}
        to={dayjs().subtract(1, "days").endOf("day").toDate()}
      >
        Last 7 days
      </DateRangePickerItem>
      <DateRangePickerItem
        key="30d"
        value="30d"
        from={dayjs().subtract(30, "days").toDate()}
        to={dayjs().subtract(1, "days").endOf("day").toDate()}
      >
        Last 30 days
      </DateRangePickerItem>
      <DateRangePickerItem
        key="60d"
        value="60d"
        from={dayjs().subtract(60, "days").toDate()}
        to={dayjs().subtract(1, "days").endOf("day").toDate()}
      >
        Last 60 days
      </DateRangePickerItem>
      <DateRangePickerItem
        key="90d"
        value="90d"
        from={dayjs().subtract(90, "days").toDate()}
        to={dayjs().subtract(1, "days").endOf("day").toDate()}
      >
        Last 90 days
      </DateRangePickerItem>
      <DateRangePickerItem
        key="tm"
        value="tm"
        from={dayjs().startOf("month").toDate()}
        to={dayjs().subtract(1, "days").endOf("day").toDate()}
      >
        This month
      </DateRangePickerItem>
      <DateRangePickerItem
        key="pm"
        value="pm"
        from={dayjs().subtract(1, "month").startOf("month").toDate()}
        to={dayjs().subtract(1, "month").endOf("month").toDate()}
      >
        Previous month
      </DateRangePickerItem>
      <DateRangePickerItem
        key="tq"
        value="tq"
        from={dayjs().startOf("quarter").toDate()}
        to={dayjs().subtract(1, "days").endOf("day").toDate()}
      >
        This quarter
      </DateRangePickerItem>
      <DateRangePickerItem
        key="pq"
        value="pq"
        from={dayjs().subtract(1, "quarter").startOf("quarter").toDate()}
        to={dayjs().subtract(1, "quarter").endOf("quarter").toDate()}
      >
        Previous quarter
      </DateRangePickerItem>
      <DateRangePickerItem
        key="ty"
        value="ty"
        from={dayjs().startOf("year").toDate()}
        to={dayjs().subtract(1, "days").endOf("day").toDate()}
      >
        This year
      </DateRangePickerItem>
      <DateRangePickerItem
        key="py"
        value="py"
        from={dayjs().subtract(1, "year").startOf("year").toDate()}
        to={dayjs().subtract(1, "year").endOf("year").toDate()}
      >
        Previous year
      </DateRangePickerItem>
    </DateRangePicker>
  );
}
