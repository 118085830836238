import axios from "axios";
import { z } from "zod";

const OperationStatus = z.object({
  code: z.number().describe("Status code of the operation"),
  description: z.string().describe("Description of the operation status"),
});

const PermissionForActions = z.object({
  can_edit: z.boolean().describe("Whether the audience can be edited"),
  can_see_insight: z.boolean().describe("Whether insights can be viewed"),
  can_share: z.boolean().describe("Whether the audience can be shared"),
  subtype_supports_lookalike: z
    .boolean()
    .optional()
    .describe("Whether lookalike audiences can be created"),
  supports_recipient_lookalike: z
    .boolean()
    .optional()
    .describe("Whether recipient lookalike audiences are supported"),
});

const BaseAudience = z.object({
  _id: z.string().describe("MongoDB document ID"),
  id: z.string().describe("Meta Audience ID"),
  name: z.string().describe("Name of the audience"),
  description: z.string().optional().describe("Description of the audience"),
  approximate_count_lower_bound: z
    .number()
    .optional()
    .describe("Minimum estimated audience size"),
  approximate_count_upper_bound: z
    .number()
    .optional()
    .describe("Maximum estimated audience size"),
  time_created: z
    .string()
    .datetime()
    .optional()
    .describe("When the audience was created on Meta"),
  time_updated: z
    .string()
    .datetime()
    .optional()
    .describe("When the audience was last updated on Meta"),
  createdAt: z
    .string()
    .datetime()
    .optional()
    .describe("When the document was created in our database"),
  updatedAt: z
    .string()
    .datetime()
    .optional()
    .describe("When the document was last updated in our database"),
  team: z.string().optional().describe("Associated team ID"),
  hub: z.string().optional().describe("Associated hub ID"),
  account_id: z.string().optional().describe("Associated Meta Ad Account ID"),
  operation_status: OperationStatus.optional().describe(
    "Current operational status",
  ),
  permission_for_actions: PermissionForActions.optional().describe(
    "Available actions and permissions",
  ),
});

export const IMetaCustomAudience = BaseAudience.extend({
  subtype: z.string().optional().describe("Subtype of the custom audience"),
  customer_file_source: z
    .string()
    .optional()
    .describe("Source of the customer data"),
  retention_days: z
    .number()
    .optional()
    .describe("Days to retain audience data"),
  rule: z.string().optional().describe("Rule for dynamic audience creation"),
  pixel_id: z.string().optional().describe("Associated Meta Pixel ID"),
  exclusions: z.array(z.unknown()).optional().describe("Excluded audiences"),
  inclusions: z.array(z.unknown()).optional().describe("Included audiences"),
});

export const IMetaSavedAudience = BaseAudience.extend({
  delete_time: z
    .string()
    .datetime()
    .optional()
    .describe("When the audience was deleted"),
  page_deletion_marked_delete_time: z
    .string()
    .datetime()
    .optional()
    .describe("When the audience was marked for deletion"),
  run_status: z.string().optional().describe("Current run status"),
  sentence_lines: z
    .array(z.string())
    .optional()
    .describe("Targeting sentence lines"),
  targeting: z
    .record(z.unknown())
    .optional()
    .describe("Targeting specifications"),
  owner_business: z
    .object({
      id: z.string().describe("Business ID"),
    })
    .optional()
    .describe("Owner business details"),
});

export type IMetaCustomAudience = z.infer<typeof IMetaCustomAudience>;
export type IMetaSavedAudience = z.infer<typeof IMetaSavedAudience>;

export const CreateCustomAudienceSchema = IMetaCustomAudience.pick({
  name: true,
  description: true,
  subtype: true,
  customer_file_source: true,
  retention_days: true,
  rule: true,
  pixel_id: true,
}).extend({
  name: z.string().min(1, "Audience name is required"),
  subtype: z.string().min(1, "Audience subtype is required"),
});

export type CreateCustomAudienceData = z.infer<
  typeof CreateCustomAudienceSchema
>;

// Custom Audience Endpoints
export const createCustomAudience = async (
  audienceData: CreateCustomAudienceData,
): Promise<IMetaCustomAudience> => {
  const response = await axios.post<IMetaCustomAudience>(
    "/api/paid/audiences/custom/create",
    audienceData,
  );
  return response.data;
};

export const getCustomAudience = async (
  audienceId: string,
): Promise<IMetaCustomAudience> => {
  const response = await axios.get<IMetaCustomAudience>(
    `/api/paid/audiences/custom/${audienceId}`,
  );
  return response.data;
};

export const listCustomAudiences = async (): Promise<IMetaCustomAudience[]> => {
  const response = await axios.get<IMetaCustomAudience[]>(
    "/api/paid/audiences/custom/list",
  );
  return response.data;
};

export const deleteCustomAudience = async (
  audienceId: string,
): Promise<{ message: string }> => {
  const response = await axios.delete<{ message: string }>(
    `/api/paid/audiences/custom/${audienceId}`,
  );
  return response.data;
};

export const syncCustomAudiences = async (): Promise<{
  message: string;
  matchedCount: number;
  modifiedCount: number;
  upsertedCount: number;
  audiences: IMetaCustomAudience[];
}> => {
  const response = await axios.post<{
    message: string;
    matchedCount: number;
    modifiedCount: number;
    upsertedCount: number;
    audiences: IMetaCustomAudience[];
  }>("/api/paid/audiences/custom/sync");
  return response.data;
};

// Saved Audience Endpoints
export const createSavedAudience = async (
  audienceData: Partial<IMetaSavedAudience>,
): Promise<IMetaSavedAudience> => {
  const response = await axios.post<IMetaSavedAudience>(
    "/api/paid/audiences/saved/create",
    audienceData,
  );
  return response.data;
};

export const getSavedAudience = async (
  audienceId: string,
): Promise<IMetaSavedAudience> => {
  const response = await axios.get<IMetaSavedAudience>(
    `/api/paid/audiences/saved/${audienceId}`,
  );
  return response.data;
};

export const listSavedAudiences = async (): Promise<IMetaSavedAudience[]> => {
  const response = await axios.get<IMetaSavedAudience[]>(
    "/api/paid/audiences/saved/list",
  );
  return response.data;
};

export const deleteSavedAudience = async (
  audienceId: string,
): Promise<{ message: string }> => {
  const response = await axios.delete<{ message: string }>(
    `/api/paid/audiences/saved/${audienceId}`,
  );
  return response.data;
};

export const syncSavedAudiences = async (): Promise<{
  message: string;
  matchedCount: number;
  modifiedCount: number;
  upsertedCount: number;
  audiences: IMetaSavedAudience[];
}> => {
  const response = await axios.post<{
    message: string;
    matchedCount: number;
    modifiedCount: number;
    upsertedCount: number;
    audiences: IMetaSavedAudience[];
  }>("/api/paid/audiences/saved/sync");
  return response.data;
};
