import React from "react";
import API, { Guest } from "~/src/api/withApi";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";
import { Button } from "~/src/primitives/button";
import { Trash } from "lucide-react";
import { useDispatch } from "react-redux";
import { setUser } from "~/src/reducers/user";
import { Alert } from "~/src/primitives/alert";

interface DeleteGuestModalProps {
  show: boolean;
  guest: Partial<Guest>;
  onHide: () => void;
}

const DeleteGuestModal = ({ show, guest, onHide }: DeleteGuestModalProps) => {
  const dispatch = useDispatch();

  const onDeleteGuest = async (guestId: string) =>
    API.deleteGuest(guestId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)));

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-row items-center justify-center">
            <div>
              <Trash className="mr-2 h-4 w-4" />
            </div>
            <div>{guest?.name}</div>
          </div>
        </DialogHeader>
        <div>
          <Alert>
            <div className="text-xs">
              Are you sure you want to delete this guest?
            </div>
          </Alert>
        </div>
        <DialogFooter>
          <Button variant="outline" size="sm" onClick={onHide}>
            Cancel
          </Button>
          <Button
            variant="default"
            size="sm"
            onClick={() => {
              onHide();
              onDeleteGuest(guest._id);
            }}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteGuestModal;
