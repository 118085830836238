import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserAccount } from "./api/auth.api";
import { disable } from "./util/trial";
import { Role } from "./api/auth.api";

export const currentRole = (user: UserAccount): Role | undefined => {
  return user.organization ? user.organizationRole : Role.SUPER_ADMIN;
};

export const isDefaultWorkspace = (user: UserAccount) =>
  user.workspace?._id === user.defaultTeam;

/**
 * Cleans the pathname for proper gating with deep URLs
 * @param pathname - the current pathname
 * @returns cleaned pathname for gating
 */
// function removeLastSlashAndId(pathname: string) {
//   // Remove any trailing slashes
//   pathname = pathname.replace(/\/+$/, "");

//   // If there are no IDs in the classname, return it as it is
//   if (!pathname.slice(1).includes("/")) {
//     return pathname;
//   }

//   // Extract the path before the ID
//   const path = pathname.split("/").slice(0, -1).join("/");

//   // Append the trailing slash if necessary
//   return path + (path.length > 0 ? "" : "/");
// }

export class Gate {
  static all(user: UserAccount) {
    return (user && isDefaultWorkspace(user)) || currentRole(user);
  }

  static isSubscribedOrTrial(user: UserAccount) {
    return !disable(user);
  }

  static isAtLeastEditor(user: UserAccount) {
    return (
      this.isOwner(user) ||
      (this.isWorkspaceInOrgScope(user) &&
        ([Role.SUPER_ADMIN, Role.ADMIN, Role.EDITOR] as Role[]).includes(
          currentRole(user),
        ))
    );
  }

  static isAtLeastAdmin(user: UserAccount) {
    return (
      this.isOwner(user) ||
      (this.isWorkspaceInOrgScope(user) &&
        ([Role.SUPER_ADMIN, Role.ADMIN] as Role[]).includes(currentRole(user)))
    );
  }

  static isAtLeastSuperAdmin(user: UserAccount) {
    return (
      this.isOwner(user) ||
      (this.isWorkspaceInOrgScope(user) &&
        currentRole(user) === Role.SUPER_ADMIN)
    );
  }

  static isOwner(user: UserAccount) {
    return (
      this.isPersonalScope(user) ||
      user._id === user.organization?.owner ||
      isDefaultWorkspace(user)
    );
  }

  static isOwnerSubscribed(user: UserAccount) {
    return (
      (this.isPersonalScope(user) && this.isIndependentlySubscribed(user)) ||
      user._id === user.organization?.owner
    );
  }

  // ORGANIZATION SCOPE CHECKS -----------------------------------------------------------
  // User is in an organization account
  static isOrganizationScope(user: UserAccount) {
    return (
      user.organization && user.organizationRole && !user.organization.locked
    );
  }

  // User is in an organization account
  static isOrganizationOwner(user: UserAccount) {
    return user._id === user.organization?.owner;
  }

  // User is in an organization account
  static isOrganizationMembersDefaultWorkspace(user: UserAccount) {
    return (
      this.isOrganizationScope(user) &&
      user._id !== user.organization?.owner &&
      isDefaultWorkspace(user)
    );
  }

  // User is in an organization and using an organizations workspace
  static isWorkspaceInOrgScope(user: UserAccount) {
    return (
      this.isOrganizationScope(user) &&
      (user?.organization?.workspaces as string[])?.includes(user.workspace._id)
    );
  }

  // PERSONAL SCOPE CHECKS -----------------------------------------------------------
  // User is a personal account only
  static isPersonalScope(user: UserAccount) {
    return !user.organization && !user.organizationRole;
  }
  static isIndependentlySubscribed = (user: UserAccount) =>
    user?.subscription?.customerId &&
    (parseInt(user?.subscription?.tier) > 0 || user?.subscription?.plan);
}

export class DashboardGate extends Gate {
  static approval(user: UserAccount) {
    return this.isOrganizationScope(user);
  }
}

export class CalendarGate extends Gate {
  static any(user: UserAccount) {
    return this.isAtLeastEditor(user);
  }
}

export class MediaGate extends Gate {
  static any(user: UserAccount) {
    return this.all(user);
  }

  static edit(user: UserAccount) {
    return this.isAtLeastEditor(user);
  }
}

export class EditGate extends Gate {
  static any(user: UserAccount) {
    return this.isAtLeastEditor(user);
  }

  static generate(user: UserAccount) {
    return this.isAtLeastEditor(user) && this.isSubscribedOrTrial(user);
  }
}

export class DraftsGate extends Gate {
  static any(user: UserAccount) {
    return this.all(user);
  }

  static edit(user: UserAccount) {
    return this.isAtLeastEditor(user);
  }
}

export class AnalyticsGate extends Gate {
  static any(user: UserAccount) {
    return this.isAtLeastEditor(user);
  }
}

export class CampaignsGate extends Gate {
  static any(user: UserAccount) {
    return this.isAtLeastEditor(user);
  }
}

export class OrganizationGate extends Gate {
  static any(user: UserAccount) {
    return this.isWorkspaceInOrgScope(user) && this.isAtLeastAdmin(user);
  }
}

export class ProfileGate extends Gate {
  static socials(user: UserAccount) {
    return (
      this.isAtLeastAdmin(user) &&
      !this.isOrganizationMembersDefaultWorkspace(user)
    );
  }

  static integrations(user: UserAccount) {
    return (
      this.isAtLeastAdmin(user) &&
      !this.isOrganizationMembersDefaultWorkspace(user)
    );
  }

  static mindbody(user: UserAccount) {
    return this.isOwnerSubscribed(user);
  }
}

export class TeamsGate extends Gate {
  static any(user: UserAccount) {
    return this.all(user);
  }
}

export class BrandGate extends Gate {
  static any(user: UserAccount) {
    return (
      this.isAtLeastEditor(user) &&
      !this.isOrganizationMembersDefaultWorkspace(user)
    );
  }
}

export class SubscriptionGate extends Gate {
  static any(user: UserAccount) {
    return (
      this.isOwner(user) && !this.isOrganizationMembersDefaultWorkspace(user)
    );
  }
}

export class NavigationGate extends Gate {
  static workspaces(user: UserAccount) {
    return this.isOrganizationScope(user);
  }
}

export class OnboardingGate extends Gate {
  static any(user: UserAccount) {
    return user.metadata?.isOnboarding === true;
  }
}

export class AffiliatesGate extends Gate {
  static isEligibleForReferral(user: UserAccount) {
    const userTier = user?.subscription?.tier ?? 0;
    return userTier <= 0;
  }
}

export const useBounce = (user: UserAccount) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // const bounce = () => {
  //   navigate("/");
  // };

  useEffect(() => {
    if (!user.workspace?._id || !user.manifest) return;

    if (user.metadata?.isOnboarding) {
      navigate("/welcome");
    }

    // switch (removeLastSlashAndId(pathname)) {
    //   case "/calendar":
    //     user.manifest.calendar || bounce();
    //     break;
    //   case "/media":
    //   case "/design":
    //   case "/drafts":
    //   case "/post":
    //   case "/post/discussion":
    //     user.manifest.view_media || bounce();
    //     break;
    //   case "/dashboard":
    //     break;
    //   case "/organization":
    //     user.manifest.view_organization || bounce();
    //     break;
    //   case "/reports":
    //     user.manifest.reports || bounce();
    //     break;
    //   case "/campaigns":
    //     user.manifest.campaigns || bounce();
    //     break;
    //   case "/billing":
    //     user.manifest.subscription || bounce();
    //     break;
    //   case "/welcome":
    //     OnboardingGate.any(user) || bounce();
    //     break;
    //   case "/brand":
    //     user.manifest.brand || bounce();
    //     break;
    //   case "/brain":
    //     user.manifest.brain || bounce();
    //     break;
    //   case "/admin":
    //     user.manifest.admin || bounce();
    //     break;
    // }
  }, [user, pathname]);
};
