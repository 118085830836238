import axios from "axios";
import { UserAccount } from "./withApi";

export interface FacebookAccount {
  id: string;
  name: string;
  pfp: string;
  connectedWorkspaceId: string;
}

type FacebookAccountsResponse = {
  accounts: FacebookAccount[];
  selectedAccountId: string | null;
};

export const getFacebookAccounts =
  async (): Promise<FacebookAccountsResponse> => {
    const response = await axios.get<FacebookAccountsResponse>(
      "/api/facebook/fbaccounts",
    );
    return response.data;
  };

export const disconnectMetaAdsAccount = async (): Promise<UserAccount> => {
  const response = await axios.post<UserAccount>(
    "/api/facebook/paid/disconnect",
  );
  return response.data;
};
