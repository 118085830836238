import React from "react";

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";
import { Button } from "~/src/primitives/button";
import { UserCheck } from "lucide-react";
import { Alert, AlertDescription } from "~/src/primitives/alert";

interface DeleteApprovalWorkflowModalProps {
  show: boolean;
  onDelete: () => void;
  onHide: () => void;
}

const DeleteApprovalWorkflowModal = ({
  show,
  onDelete,
  onHide,
}: DeleteApprovalWorkflowModalProps) => {
  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-row items-center justify-center">
            <div>
              <UserCheck className="mr-2 h-4 w-4" />
            </div>
            <div>Delete workflow?</div>
          </div>
        </DialogHeader>
        <div>
          <Alert>
            <AlertDescription>
              Are you sure you want to delete this workflow?
              <br />
              This will remove the approval workflow from all workspaces and
              posts, immediatly approving them.
            </AlertDescription>
          </Alert>
        </div>
        <DialogFooter>
          <Button variant="outline" size="sm" onClick={onHide}>
            Close
          </Button>
          <Button
            variant="destructive"
            size="sm"
            onClick={() => {
              onHide();
              onDelete();
            }}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteApprovalWorkflowModal;
