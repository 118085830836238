import { Dispatch, SetStateAction, createContext } from "react";

type StickersDispatch = {
  isEmojiPickerOpen: boolean;
  setIsEmojiPickerOpen: Dispatch<SetStateAction<boolean>>;
  pendingEmoji: string;
  setPendingEmoji: Dispatch<SetStateAction<string>>;
  isStickerPickerOpen: boolean;
  setIsStickerPickerOpen: Dispatch<SetStateAction<boolean>>;
  pendingSticker: any;
  setPendingSticker: Dispatch<SetStateAction<any>>;
};

export const StickersContext = createContext<StickersDispatch>(undefined);
