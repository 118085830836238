import React, { useEffect, useState } from "react";
import facebookIcon from "../../assets/FacebookIcon.png";
import instagramIcon from "../../assets/InstagramIcon.png";
import linkedinIcon from "../../assets/LinkedInIcon.png";
import twitterIcon from "../../assets/TwitterIcon.png";
import "./select-platform.style.css";
import FadeIn from "../Transitions/FadeIn";
import { cn } from "../../util/reusables";
import { useDispatch } from "react-redux";
import { setError } from "~/src/reducers/toolkit";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/src/primitives/dropdown-menu";
import { PlatformType, ALL_PLATFORMS } from "~/src/util/platforms";

export type Platforms = Record<
  PlatformType,
  {
    icon: JSX.Element;
    enabled: boolean;
    text: string;
    contentTypes?: string[];
    color: Color;
    connect: () => void;
  }
>;

// Color Values from Tremor
declare const colorValues: readonly [
  "slate",
  "gray",
  "zinc",
  "neutral",
  "stone",
  "red",
  "orange",
  "amber",
  "yellow",
  "lime",
  "green",
  "emerald",
  "teal",
  "cyan",
  "sky",
  "blue",
  "indigo",
  "violet",
  "purple",
  "fuchsia",
  "pink",
  "rose",
];
export type Color = (typeof colorValues)[number];

export const platforms: Platforms = {
  facebook: {
    icon: (
      <img
        className="inline-block h-full w-full"
        src={facebookIcon}
        draggable={false}
      />
    ),
    enabled: true,
    text: "Facebook",
    color: "blue",
    connect: () => (window.top.location.href = "/api/facebook/login-oauth"),
  },
  instagram: {
    icon: (
      <img
        className="inline-block h-full w-full"
        src={instagramIcon}
        draggable={false}
      />
    ),
    enabled: true,
    text: "Instagram",
    contentTypes: ["Post", "Reel", "Story"],
    color: "pink",
    connect: () =>
      (window.top.location.href = "/api/facebook/instagram/login-oauth"),
  },
  linkedin: {
    icon: (
      <img
        className="inline-block h-full w-full"
        src={linkedinIcon}
        draggable={false}
      />
    ),
    enabled: true,
    text: "LinkedIn",
    color: "indigo",
    connect: () => (window.top.location.href = "/api/linkedin/login-oauth"),
  },
  twitter: {
    icon: (
      <img
        className="inline-block h-full w-full"
        src={twitterIcon}
        draggable={false}
      />
    ),
    enabled: true,
    text: "X",
    color: "sky",
    connect: () => (window.top.location.href = "/api/twitter/login-oauth"),
  },
};

type SelectPlatformProps = {
  current: string;
  disableContentTypes?: boolean;
  inline?: boolean;
  onSelected: (
    platform: PlatformType,
    platformContentType?: string,
  ) => Promise<void>;
  className?: string;
  hideList?: string[]; // hide platforms from the selector. (e.g. ["linkedin"])
};

const SelectPlatform = ({
  current,
  disableContentTypes,
  inline,
  onSelected,
  className,
  hideList = [],
}: SelectPlatformProps) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<string>(current);

  useEffect(() => {
    if (current) setSelected(current);
  }, [current]);

  return (
    <div className={cn("select-platform", className)}>
      <div
        className={cn(
          "platforms",
          inline
            ? "inline-flex items-center space-x-3"
            : "flex flex-col items-center justify-center gap-1",
        )}
      >
        {ALL_PLATFORMS.filter((p) => !hideList.includes(p)).map((e, i) =>
          !disableContentTypes && platforms[e].contentTypes ? (
            <DropdownMenu key={`platform-select-option-${i}`}>
              <DropdownMenuTrigger
                className="focus:outline-none"
                onClick={() => {
                  if (platforms[e].enabled) {
                    setSelected(e);
                  }
                }}
              >
                <div
                  className={cn(
                    "platform",
                    selected === e ? "selected" : "ignore-canvas",
                    !platforms[e].enabled && "disabled",
                    `select-platform-${e}`,
                  )}
                  data-testid={`platform-${i}`}
                >
                  <FadeIn show timeout={100 + 20 * i} direction="left">
                    {platforms[e].icon}
                  </FadeIn>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuGroup>
                  {platforms[e].contentTypes.map((t, j) => (
                    <DropdownMenuItem
                      key={`content-type-${j}`}
                      onClick={async () => {
                        if (platforms[e].enabled) {
                          await onSelected(e, t.toLowerCase()).catch((err) =>
                            dispatch(setError(err?.toString())),
                          );
                        }
                      }}
                    >
                      {t}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <div
              className={cn(
                "platform",
                selected === e ? "selected" : "ignore-canvas",
                !platforms[e].enabled && "disabled",
                `select-platform-${e}`,
              )}
              key={`platform-select-option-${i}`}
              onClick={async () => {
                if (platforms[e].enabled) {
                  await onSelected(e)
                    .then(() => setSelected(e))
                    .catch((err) => dispatch(setError(err?.toString())));
                }
              }}
              data-testid={`platform-${i}`}
            >
              <FadeIn show timeout={100 + 20 * i} direction="left">
                {platforms[e].icon}
              </FadeIn>
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default SelectPlatform;
