import { PlatformType } from "../util/platforms";

export type IPlatformComment = {
  _id: string;
  platform: PlatformType;
  platformAccountId: string;
  platformPostId: string;
  platformCommentId: string;
  platformCommentParentId?: string;
  content: string;
  timestamp: number;
  fromPlatformAccountId?: string;
  fromPlatformAccountName?: string;
  likesCount?: number;
  childCommentsCount?: number;
  hidden?: boolean; // Instagram
  deleted?: boolean;
  markedRead?: boolean;
  sentiment?: number;
  labels?: string[];
};

export type IPlatformAccountTag = {
  _id: string;
  platform: PlatformType;
  platformAccountPfp: string;
  platformAccountId: string;
  platformAccountName: string;
  platformAccountUsername?: string;
  followersCount?: number;
  followsCount?: number;
  postCount?: number;
  biography?: string;
  website?: string;
  headline?: string;
};

export const getComments = async () => {
  const requestUrl = new URL(
    "/api/engage/community/comments",
    window.location.origin,
  );
  return (await fetch(requestUrl).then((res) => res.json())) as {
    comments: IPlatformComment[];
    accounts: IPlatformAccountTag[];
    posts: any[];
    commentPostHierarchy: any[];
  };
};

const ingestCommunityManagementForPost = (
  platform: PlatformType,
  platformPostId: string,
) => fetch(`/api/engage/community/ingest/${platform}/${platformPostId}`);

export const ingestCommunityManagement = () => {
  const requestUrl = new URL(
    "/api/engage/community/ingest",
    window.location.origin,
  );
  return fetch(requestUrl);
};

const markPlatformCommentRead = async (_id: string) =>
  await fetch(`/api/engage/comments/${_id}/read`, {
    method: "PATCH",
  });

const markPlatformCommentUnread = async (_id: string) =>
  await fetch(`/api/engage/comments/${_id}/unread`, {
    method: "PATCH",
  });

const markPlatformPostRead = async (platform: PlatformType, _id: string) =>
  await fetch(`/api/engage/posts/${platform}/${_id}/read`, {
    method: "PATCH",
  });

const markPlatformPostUnread = async (platform: PlatformType, _id: string) =>
  await fetch(`/api/engage/posts/${platform}/${_id}/unread`, {
    method: "PATCH",
  });

const markPostCommentsAsRead = async (
  platform: PlatformType,
  platformPostId: string,
) =>
  await fetch(`/api/engage/${platform}/posts/${platformPostId}/comments/read`, {
    method: "PATCH",
  });

const setPlatformCommentLabels = async (_id: string, labels: string[]) =>
  await fetch(`/api/engage/comments/${_id}`, {
    method: "PATCH",
    body: JSON.stringify({ labels }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const setPlatformPostLabels = async (
  platform: PlatformType,
  _id: string,
  labels: string[],
) =>
  await fetch(`/api/engage/posts/${platform}/${_id}`, {
    method: "PATCH",
    body: JSON.stringify({ labels }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const generateCommentSuggestions = async (prompt: string, n?: number) =>
  await fetch(`/api/engage/comments/suggestions`, {
    method: "POST",
    body: JSON.stringify({ prompt, n }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (res) => (await res?.json()) as { suggestions: string[] });

export interface EngageAPI {
  getComments: () => Promise<{
    comments: IPlatformComment[];
    accounts: IPlatformAccountTag[];
    posts: any[];
    commentPostHierarchy: any[];
  }>;
  ingestCommunityManagementForPost: (
    platform: PlatformType,
    platformPostId: string,
  ) => Promise<Response>;
  ingestCommunityManagement: () => Promise<Response>;
  markPlatformCommentRead: (_id: string) => Promise<Response>;
  markPlatformCommentUnread: (_id: string) => Promise<Response>;
  markPlatformPostRead: (
    platform: PlatformType,
    _id: string,
  ) => Promise<Response>;
  markPlatformPostUnread: (
    platform: PlatformType,
    _id: string,
  ) => Promise<Response>;
  markPostCommentsAsRead: (
    platform: PlatformType,
    platformPostId: string,
  ) => Promise<Response>;
  setPlatformCommentLabels: (
    _id: string,
    labels: string[],
  ) => Promise<Response>;
  setPlatformPostLabels: (
    platform: PlatformType,
    _id: string,
    labels: string[],
  ) => Promise<Response>;
  generateCommentSuggestions: (
    prompt: string,
    n?: number,
  ) => Promise<{ suggestions: string[] }>;
}

export default {
  getComments,
  ingestCommunityManagementForPost,
  ingestCommunityManagement,
  markPlatformCommentRead,
  markPlatformCommentUnread,
  markPlatformPostRead,
  markPlatformPostUnread,
  markPostCommentsAsRead,
  setPlatformCommentLabels,
  setPlatformPostLabels,
  generateCommentSuggestions,
} as EngageAPI;

export function buildCommentHierarchy(comments: IPlatformComment[]) {
  const commentMap = new Map(); // Map to store comments by their platformPostId

  // Populate the comment map
  comments.forEach((comment) => {
    const platformPostId = comment.platformPostId;
    if (!commentMap.has(platformPostId)) {
      commentMap.set(platformPostId, []);
    }
    commentMap.get(platformPostId).push(comment);
  });

  // Function to recursively build comment hierarchy
  function buildHierarchy(
    parentId: string | undefined,
    comments: IPlatformComment[],
  ) {
    return comments
      .filter((comment) => comment.platformCommentParentId === parentId)
      .sort((a, b) => a.timestamp - b.timestamp)
      .map((comment) => {
        const children = buildHierarchy(comment.platformCommentId, comments);
        if (children.length > 0) {
          return { ...comment, children };
        } else {
          return comment;
        }
      });
  }

  // Rebuild the hierarchy for each post
  const result = Array.from(commentMap.entries()).map(
    ([platformPostId, postComments]) => ({
      platformPostId,
      comments: buildHierarchy(undefined, postComments),
    }),
  );

  return result;
}
