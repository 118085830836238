import { Loader } from "lucide-react";
import React from "react";
import { cn } from "~/src/util/reusables";

const Load = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn(
        "flex h-full w-full items-center justify-center gap-2",
        className,
      )}
    >
      <div className="animate-pulse">
        <Loader className="size-10 animate-spin text-muted-foreground" />
      </div>
      <span className="sr-only animate-pulse text-base font-semibold uppercase tracking-wide text-muted-foreground">
        Loading...
      </span>
    </div>
  );
};

export default Load;
