import { ChevronLeft, ChevronRight, Expand } from "lucide-react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Media } from "~/src/api/withApi";
import { cn } from "~/src/util/reusables";
import DisplayMedia from "~/src/views/Library/components/DisplayMedia";
import LoadMedia from "../../partials/Load/LoadMedia";

interface GalleryProps {
  media: Media[];
  className?: string;
  mediaClassName?: string;
  carouselIndex: number;
  setCarouselIndex: Dispatch<SetStateAction<number>>;
  hideVideoControls?: boolean;
  showVideoIndicator?: boolean; // Force the video icon on Facebook ingest (only image allowed at the moment)
}

const Gallery = ({
  media,
  className,
  mediaClassName = "max-w-80",
  carouselIndex = 0,
  setCarouselIndex,
  hideVideoControls,
  showVideoIndicator,
}: GalleryProps) => {
  const [showDisplay, setShowDisplay] = useState<boolean>(false);

  if (!media.length || !media?.[0]?.uri) return null;
  const [page, setPage] = useState<number>(carouselIndex);

  const updatePage = (elements: Media[], pageIndex: number) => {
    if (pageIndex < 0 || pageIndex >= elements.length) return;

    setCarouselIndex?.(pageIndex);
  };

  const hasNext = page < media.length - 1;
  const next = () => {
    if (hasNext) {
      setPage(page + 1);
      updatePage(media, page + 1);
    }
  };

  const hasPrev = page > 0;
  const prev = () => {
    if (hasPrev) {
      setPage(page - 1);
      updatePage(media, page - 1);
    }
  };

  return (
    <div
      className={cn("relative h-auto", className)}
      key={media?.[carouselIndex]?.editUri ?? media?.[carouselIndex]?.uri}
    >
      <LoadMedia
        showVideoIndicator={showVideoIndicator}
        mimeType={media?.[carouselIndex]?.mimeType}
        src={media?.[carouselIndex]?.editUri ?? media?.[carouselIndex]?.uri}
        className={cn(
          "h-auto w-full rounded-none object-cover object-top",
          mediaClassName,
        )}
        hideVideoControls={hideVideoControls}
      />
      {hasPrev && (
        <div className="absolute left-2 top-1/2 z-30 flex h-6 w-6 cursor-pointer flex-row items-center justify-center rounded-full bg-gray-500/30 text-white shadow-md backdrop-blur-sm transition-colors hover:bg-gray-500/50">
          <ChevronLeft
            className="h-4 w-4"
            onClick={(e) => {
              e.stopPropagation();
              prev();
            }}
          />
        </div>
      )}
      {hasNext && (
        <div className="absolute right-2 top-1/2 z-30 flex h-6 w-6 cursor-pointer flex-row items-center justify-center rounded-full bg-gray-500/30 text-white shadow-md backdrop-blur-sm transition-colors hover:bg-gray-500/50">
          <ChevronRight
            className="h-4 w-4"
            onClick={(e) => {
              e.stopPropagation();
              next();
            }}
          />
        </div>
      )}
      <div className="absolute left-2 top-2 z-30 flex h-6 w-6 cursor-pointer flex-row items-center justify-center rounded-full bg-gray-500/30 text-white shadow-md backdrop-blur-sm transition-colors hover:bg-gray-500/50">
        <Expand
          className="h-4 w-4"
          onClick={(e) => {
            e.stopPropagation();
            setShowDisplay(true);
          }}
        />
      </div>
      <DisplayMedia
        show={showDisplay}
        onHide={() => setShowDisplay(false)}
        media={media?.[carouselIndex]}
      />
    </div>
  );
};

export default Gallery;
