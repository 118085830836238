import { Outlet } from "react-router-dom";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useParams } from "react-router-dom";
import API, { Media, Media as MediaType, Template } from "~/src/api/withApi";
import { Hub } from "~/src/api/hub.api";
import { StickersContext } from "../views/Edit/components/StickersContext";
import { ContentSettings } from "../views/Hubs/types";
import Load from "../partials/Load/Load";
import { cn } from "../util/reusables";
import { useQuery } from "@tanstack/react-query";
import Error404 from "~/src/views/Error/404";

export type HubContext = {
  hub: Hub;
  getHub: () => Promise<any>;
  showEditor: "Template" | "Media" | undefined;
  handleShowEditor: (type: "Template" | "Media") => void;
  editorContent: Media | Template | undefined;
  setEditorContent: Dispatch<SetStateAction<Media | Template | undefined>>;
  resetEditor: () => void;
  contentSettings: ContentSettings;
  setContentSettings: Dispatch<SetStateAction<ContentSettings>>;
};

export default function HubLayout() {
  const { hubId } = useParams();
  const [showEditor, setShowEditor] = useState<
    "Template" | "Media" | undefined
  >(undefined);
  const [editorContent, setEditorContent] = useState<
    MediaType | Template | undefined
  >(undefined);
  const [contentSettings, setContentSettings] = useState<ContentSettings>();

  const resetEditor = () => {
    setShowEditor(undefined);
    setEditorContent(undefined);
  };

  // Sticker state
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState<boolean>(false);
  const [pendingEmoji, setPendingEmoji] = useState<string>();
  const [isStickerPickerOpen, setIsStickerPickerOpen] =
    useState<boolean>(false);
  const [pendingSticker, setPendingSticker] = useState<any>();

  const {
    data: hub,
    refetch: getHub,
    isPending,
  } = useQuery({
    queryKey: ["hub", hubId],
    queryFn: () => API.getHub(hubId),
    enabled: !!hubId,
  });

  if (isPending) return <Load />;

  if (!hub) return <Error404 />;

  return (
    <StickersContext.Provider
      value={{
        isEmojiPickerOpen,
        setIsEmojiPickerOpen,
        pendingEmoji,
        setPendingEmoji,
        isStickerPickerOpen,
        setIsStickerPickerOpen,
        pendingSticker,
        setPendingSticker,
      }}
    >
      {/* Analytics handles it's own padding... */}
      <div
        className={cn(
          "flex h-full max-h-full flex-col gap-4",
          // !pathname.includes("/analytics") && "[&>*:last-child]:pb-8",
        )}
      >
        <Outlet
          context={
            {
              hub,
              getHub,
              showEditor,
              handleShowEditor: (type) => {
                setShowEditor(type);
              },
              editorContent,
              setEditorContent,
              contentSettings,
              setContentSettings,
              resetEditor,
            } as HubContext
          }
        />
      </div>
    </StickersContext.Provider>
  );
}
