import * as React from "react";
const FlamelFlame = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 500 500",
    }}
    viewBox="0 0 500 500"
    {...props}
  >
    <style>
      {
        ".st1{fill:url(#SVGID_00000055708624171631794410000004374301101906527925_)}"
      }
    </style>
    <linearGradient
      id="SVGID_1_"
      x1={224.579}
      x2={224.579}
      y1={57.021}
      y2={442.979}
      gradientUnits="userSpaceOnUse"
    >
      <stop
        offset={0.001}
        style={{
          stopColor: "#ec9128",
        }}
      />
      <stop
        offset={0.812}
        style={{
          stopColor: "#ee4544",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#ec4780",
        }}
      />
    </linearGradient>
    <path
      d="M155.2 154.5c-3.2 9.9-4.6 19.4-4.2 29.2.2 7.5 1.3 14.8 4.1 21.7 4.4 10.8 11.8 18.5 23 22.1 15.3 5 30.7 4.6 45.5-1.6 17.1-7 27.2-20.7 32.4-38 7.6-25 5.6-49.9-1.2-74.6-4.9-17.8-12.4-34.4-24.4-48.7-2.3-2.7-5-5-7.2-7.7 6.1 1.7 11.3 4.9 16.6 7.9 16.7 9.6 32.4 20.7 46.1 34.4 23.7 23.5 39.8 51.2 44.7 84.7 3.2 21.8.6 43.2-5.7 64.1-8.8 28.9-22.7 55.1-41.7 78.7-9.3 11.6-17.7 23.8-23.7 37.5-4.7 10.7-7.4 21.8-8.3 33.6-1.1 14.2 1.4 27.8 5.2 41.3.3 1.1 1.3 2.4.3 3.4s-2.3.1-3.4-.2c-31.4-10.8-60.8-25.3-86.6-46.6-12.6-10.4-23.4-22.5-32-36.5-9.3-15.1-14-31.6-16.1-49-5.4-45.4 4.4-88.1 22.4-129.5 3.4-7.8 7.2-15.4 11.4-22.7.6-1 1.1-2.3 2.8-3.5z"
      style={{
        fill: "url(#SVGID_1_)",
      }}
    />
    <linearGradient
      id="SVGID_00000064316844848004742660000017137169285862642075_"
      x1={326.122}
      x2={326.122}
      y1={205.776}
      y2={438.433}
      gradientUnits="userSpaceOnUse"
    >
      <stop
        offset={0.001}
        style={{
          stopColor: "#ec9128",
        }}
      />
      <stop
        offset={0.812}
        style={{
          stopColor: "#ee4544",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#ec4780",
        }}
      />
    </linearGradient>
    <path
      d="M347.1 205.8c4.7 5.3 8.1 11.5 11.5 17.7 11.1 20.1 19.4 41.3 22.8 64.2 5.5 37.5-4.9 69.5-32.3 95.7-9.8 9.4-21.4 16.5-32.3 24.3-13.5 9.7-27.5 18.7-39.7 29.9-1.9 1.7-2.2.6-2.8-1-5.7-16.5-6.6-33.1-1.1-49.9 6.4-19.7 17.2-36.9 30.3-52.5 18.8-22.4 30.1-48.4 38-76.1 4-13.9 7-28.1 6.7-42.7-.2-3.2-.8-6.3-1.1-9.6z"
      style={{
        fill: "url(#SVGID_00000064316844848004742660000017137169285862642075_)",
      }}
    />
  </svg>
);
export default FlamelFlame;
