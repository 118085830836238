import React, { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../reducers/user";
import API from "../../api/withApi";
import ReactGA from "../../util/analytics";
import Load from "../Load/Load";
import { AffiliatesGate } from "~/src/gates";

interface CheckSessionProps {
  children: React.ReactNode;
}

const CheckSession = ({ children }: CheckSessionProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [valid, setValid] = useState<boolean>();
  const { pathname, search } = useLocation();
  const [q] = useSearchParams();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: pathname });

    const wipe = () => {
      console.log("Bye!");
      dispatch(setUser({}));
      setValid(false);
    };

    if (q.get("after"))
      sessionStorage.setItem("flamelai-after-login", q.get("after"));

    API.getSession("?update=true")
      .then((data) => {
        const { user, guest, error } = data;
        let { path } = data;

        if (guest) {
          const guestPaths = [/\/post\/discussion\/*/, /\/guest/];

          for (const p of guestPaths) {
            const match = p.test(pathname);
            if (match) {
              setValid(true);
              dispatch(setUser(user));
              return;
            }
          }

          return navigate("/guest");
        }

        if (user) {
          dispatch(setUser(user));
          setValid(true);

          // Add affiliate referral if applicable
          if (
            (window as any)?.tolt_referral &&
            user.email &&
            AffiliatesGate.isEligibleForReferral(user)
          ) {
            (window as any)?.tolt?.signup(user.email);
          }
        } else if (error && path) {
          const query = `${pathname}${search}`;
          if (query && query !== "/") path = `${path}?after=${query}`;
          navigate(path);
        } else {
          wipe();
        }
      })
      .catch((err) => {
        console.log(err);
        wipe();
      });
  }, []);

  if (valid === undefined) return <Load />;

  if (valid === false) {
    const query = `${pathname}${search}`;
    let path = "/login";
    if (query && query !== "/") path = `${path}?after=${query}`;
    return <Navigate to={path} />;
  }

  const after = sessionStorage.getItem("flamelai-after-login");

  if (after) {
    sessionStorage.removeItem("flamelai-after-login");
    return <Navigate to={after} />;
  }

  return <>{children}</>;
};

export default CheckSession;
