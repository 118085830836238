import { capitalize, inRange } from "lodash";
import { LarvalPost, Media, PortfolioItem, Team } from "../api/withApi";
import { imageTypes, metadataFromUri, videoTypes } from "./loaders";
import { toNth } from "./reusables";
import { reduceSize, reducePixels } from "./conversion";
import { PlatformType } from "./platforms";
import { getPostProperties } from "../views/Post/utils";
import { Editor } from "@tiptap/core";
import {
  captionExtensions,
  parseTiptapContent,
} from "../views/Post/components/editors/CaptionEditor";
import { Node as ProseMirrorNode } from "@tiptap/pm/model";

export const characterLimits = {
  twitter: 280,
  linkedin: 3000,
  instagram: 2200,
  facebook: 63206,
} as const;

export const getPlatformWithLowestCharacterLimit = (
  platformsToEvaluate: PlatformType[],
): PlatformType | undefined => {
  if (!platformsToEvaluate?.length) return PlatformType.Twitter;
  // Filter the limits based on platforms to evaluate
  const filteredLimits = Object.entries(characterLimits).filter(([platform]) =>
    platformsToEvaluate.includes(platform as PlatformType),
  );

  if (filteredLimits.length === 0) {
    return undefined; // Return undefined if no platforms match
  }

  // Find the platform with the minimum character limit
  const minLimitEntry = filteredLimits.reduce((minEntry, currentEntry) =>
    currentEntry[1] < minEntry[1] ? currentEntry : minEntry,
  );

  return minLimitEntry[0] as PlatformType;
};

const pluralContentTypes = {
  reel: "reels",
  story: "stories",
};

/**
 * Get the plain text version of a caption
 * @param caption - any caption, plain text or json stringified
 * @returns the parsed version with mentions and such as plain text
 */
export const captionToPlainText = (caption: string) => {
  const parsedContent = parseTiptapContent(caption);
  const editor = new Editor({
    extensions: captionExtensions({ viewOnly: true }),
    content: parsedContent,
  });

  const plainText = editor.getText({
    textSerializers: {
      mention: ({ node }: { node: ProseMirrorNode }) => {
        // Customize how you want to represent mentions in plain text
        return `@${node.attrs.label || node.attrs.id}`;
      },
    },
  });

  editor.destroy();

  return plainText;
};

/**
 * Runs a workingItem through a series of checks to ensure that it meets general and
 * platform-specific requirements. If the item fails any of the checks, an error message is
 * returned, otherwise null is returned.
 */
export const postCheck = (
  item: PortfolioItem | LarvalPost,
  platform: PlatformType,
  options = {
    textOnly: false,
  },
): string | null => {
  const postProperties = getPostProperties(item, platform);
  const { media, platformContentType } = postProperties;
  let { caption } = postProperties;
  caption = captionToPlainText(caption);

  // General checks
  if (!caption && media.length === 0)
    return "Post is missing both media and caption.";

  // Character limit
  if (caption && caption.length > characterLimits[platform])
    return `Your caption is ${
      caption?.length - characterLimits[platform]
    } characters over the ${capitalize(platform)} limit`;

  // Platform-specific checks
  switch (platform) {
    case "twitter":
      // Media limit
      if (media.length > 4)
        return "Tweets cannot include more than 4 images or videos";
      break;
    case "instagram":
      // Media requirement
      if (!options.textOnly && media.length === 0)
        return "Instagram posts must include media";
      // Media limit
      if (media.length > 10)
        return "Instagram carousel posts cannot include more than 10 images or videos";
      // Reels and stories single media requirement
      if (
        Object.keys(pluralContentTypes)?.includes(platformContentType) &&
        media.length > 1
      )
        return `Instagram ${pluralContentTypes[platformContentType]} cannot include more than one media item.`;
      // Reels video requirement
      if (
        platformContentType === "reel" &&
        imageTypes?.includes(media[0].mimeType)
      )
        return "Instagram reels must be videos.";
      break;
    case "facebook":
      // Multimedia video check
      if (
        media.length > 1 &&
        media.some(({ mimeType }) => mimeType && videoTypes?.includes(mimeType))
      )
        return "Facebook multimedia posts cannot include videos";
      break;
    case "linkedin":
      // Multimedia video check
      if (
        media.length > 1 &&
        media.some(({ mimeType }) => mimeType && videoTypes?.includes(mimeType))
      )
        return "LinkedIn multimedia posts cannot include videos";
      // Media limit
      if (media.length > 20)
        return "LinkedIn posts cannot include more than 20 images.";
      break;
    default:
      break;
  }

  return null;
};

export const getConnectedPlatforms = (workspace: Team) =>
  ["instagram", "facebook", "twitter", "linkedin"].filter(
    (p) => workspace[p] !== undefined,
  );

export class CheckMedia {
  media: Media;
  mediaIndex?: number;
  isImage: boolean;
  blob: Blob;
  bitmap: ImageBitmap;
  metadata: any;
  platform: PlatformType;
  autoDownscale?: (blob: Blob) => Promise<void>;
  onSizeMismatch?: () => void;
  onArMismatch?: () => void;
  onFail?: (error: string) => void;
  pass: boolean;

  constructor(media: Media, mediaIndex?: number) {
    this.media = media;
    this.mediaIndex = mediaIndex;
    this.isImage = imageTypes.includes(media.mimeType);
  }

  with(config: {
    autodownScale?: (blob: Blob) => Promise<void>;
    onSizeMismatch?: () => void;
    onArMismatch?: () => void;
    onFail?: (error: string) => void;
  }) {
    this.autoDownscale = config.autodownScale;
    this.onSizeMismatch = config.onSizeMismatch;
    this.onArMismatch = config.onArMismatch;
    this.onFail = config.onFail;
    return this;
  }

  async #handleImage(spec: {
    maxBytes: number;
    armin: number;
    armax: number;
    maxPixels?: number;
  }) {
    if (this.blob.size > spec.maxBytes && this.autoDownscale) {
      // NOTE: NOT WORKING AS EXPECTED AS OF 12/21/23
      // reducePixels works much better...
      const b = await reduceSize(this.blob, spec.maxBytes);
      const bmp = await createImageBitmap(b);
      this.blob = b;
      this.bitmap = bmp;
      await this.autoDownscale(this.blob);
    } else if (this.blob.size > spec.maxBytes) {
      this.onSizeMismatch?.();
      this.pass = false;
    }

    // LinkedIn has a pixel maximum...
    if (
      !!spec.maxPixels &&
      this.bitmap.width * this.bitmap.height > spec.maxPixels
    ) {
      const message = `Pixels exceed limit by ${
        this.bitmap.width * this.bitmap.height - spec.maxPixels
      }. Reducing image size...`;
      console.log(message);
      const b = await reducePixels(this.blob, spec.maxBytes, this.blob?.type);
      const bmp = await createImageBitmap(b);
      this.blob = b;
      this.bitmap = bmp;
      await this.autoDownscale(this.blob);
    }

    const arpass = inRange(
      this.bitmap.width / this.bitmap.height,
      spec.armin,
      spec.armax,
    );
    if (!arpass) {
      this.onArMismatch?.();
      this.pass = false;
    }
  }

  async #handleVideo(spec: {
    maxBytes: number;
    armin: number;
    armax: number;
    durmin: number;
    durmax: number;
    wmin?: number;
    wmax?: number;
  }) {
    const { size, width, height, duration } = this.metadata;
    const mediaRepr = this.mediaIndex
      ? toNth(this.mediaIndex + 1) + " video"
      : "Video";

    if (size > spec.maxBytes) {
      this.onSizeMismatch?.();
      this.pass = false;
    }

    // console.log(width / height);

    if (!inRange(width / height, spec.armin, spec.armax)) {
      this.onArMismatch?.();
      this.pass = false;
    }

    if (duration > spec.durmax) {
      const diff = Math.ceil(duration - spec.durmax);
      this.onFail?.(
        `${mediaRepr} is ${diff} second${diff !== 1 ? "s" : ""} too long`,
      );
      this.pass = false;
    }

    if (duration < spec.durmin) {
      const diff = Math.ceil(spec.durmin - duration);
      this.onFail?.(
        `${mediaRepr} is ${diff} second${diff !== 1 ? "s" : ""} too short`,
      );
      this.pass = false;
    }

    if (spec.wmax && width > spec.wmax) {
      this.onFail?.(`${mediaRepr} cannot be more than ${spec.wmax}px wide.`);
    }
  }

  async for(platform: PlatformType, platformContentType?: string) {
    if (this.isImage) {
      const blob = await fetch(this.media?.editUri ?? this.media?.uri).then(
        (data) => data.blob(),
      );

      this.blob = blob;
      this.bitmap = await createImageBitmap(blob);
    } else {
      this.metadata = await metadataFromUri(
        this.media?.editUri ?? this.media.uri,
      );
    }

    switch (platform) {
      case PlatformType.Instagram:
        return await this.instagram(platformContentType);
      case PlatformType.Facebook:
        return await this.facebook();
      case PlatformType.LinkedIn:
        return await this.linkedin();
      case PlatformType.Twitter:
        return await this.twitter();
    }
  }

  async instagram(mediaType?: string) {
    const isReel = mediaType === "reel";
    const isStory = mediaType === "story";

    await (this.isImage
      ? this.#handleImage({
          maxBytes: 8e6,
          armin: isStory ? 0.1 : 0.8,
          armax: 1.91,
        })
      : this.#handleVideo({
          maxBytes: isReel ? 1e9 : 1e8,
          armin: isReel || isStory ? 0.01 : 0.8,
          armax: isReel || isStory ? 10 : 1.78,
          durmin: 3,
          durmax: isReel ? 15 * 60 : 60,
          wmax: 1920,
        }));
    return this;
  }

  async facebook() {
    await (this.isImage
      ? this.#handleImage({
          maxBytes: 4e6,
          armin: 0.525,
          armax: 1.93,
        })
      : this.#handleVideo({
          maxBytes: 1e10,
          armin: 0.525,
          armax: 1.93,
          durmin: 0,
          durmax: 4 * 60 * 60,
        }));
    return this;
  }

  async linkedin() {
    await (this.isImage
      ? this.#handleImage({
          maxBytes: 4e6,
          armin: 0.52,
          armax: 3,
          maxPixels: 36152320,
        })
      : this.#handleVideo({
          maxBytes: 2e8,
          armin: 0.41,
          armax: 2.4,
          durmin: 3,
          durmax: 30 * 60,
        }));
    return this;
  }

  async twitter() {
    await (this.isImage
      ? this.#handleImage({
          maxBytes: 5e6,
          armin: 0.34,
          armax: 3,
          maxPixels: 36152320,
        })
      : this.#handleVideo({
          maxBytes: 512e6,
          armin: 0.34,
          armax: 3,
          durmin: 0.5,
          durmax: 140,
        }));
    return this;
  }
}
