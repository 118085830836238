import { Video } from "lucide-react";
import React from "react";
import { useInView } from "react-intersection-observer";
import { useQuery } from "@tanstack/react-query";
import { videoTypes } from "~/src/util/loaders";
import { cn } from "~/src/util/reusables";
import { isSafari } from "react-device-detect";

// Use interface instead of type for props as per best practices
interface LoadMediaProps {
  alt?: string;
  src: string;
  thumbnail?: string;
  mimeType: string;
  className?: string;
  showVideoIndicator?: boolean;
  hideVideoControls?: boolean;
}

// Extract reusable constants
const FALLBACK_SOURCES = {
  video: "https://flamel-misc.s3.amazonaws.com/video-error-placeholder.mp4",
  image: "https://flamel-misc.s3.amazonaws.com/image-error-placeholder.png",
} as const;

// New helper function to preload media
const preloadMedia = (src: string, isVideo: boolean): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(src)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);

        if (isVideo) {
          const video = document.createElement("video");
          video.onloadeddata = () => resolve(blobUrl);
          video.onerror = () => {
            URL.revokeObjectURL(blobUrl);
            reject(new Error("Failed to load video"));
          };
          video.src = blobUrl;
        } else {
          const img = new Image();
          img.onload = () => resolve(blobUrl);
          img.onerror = () => {
            URL.revokeObjectURL(blobUrl);
            reject(new Error("Failed to load image"));
          };
          img.src = blobUrl;
        }
      })
      .catch((err) => reject(err));
  });
};

function LoadMedia({
  alt,
  src,
  thumbnail,
  mimeType,
  className,
  showVideoIndicator = false,
  hideVideoControls = false,
}: LoadMediaProps) {
  const isVideo = React.useMemo(
    () => videoTypes.includes(mimeType),
    [mimeType],
  );

  // Memoize error handlers
  const handleVideoError = React.useCallback(
    (e: React.SyntheticEvent<HTMLVideoElement>) => {
      e.currentTarget.src = FALLBACK_SOURCES.video;
    },
    [],
  );

  const handleImageError = React.useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      e.currentTarget.src = FALLBACK_SOURCES.image;
    },
    [],
  );

  // Add ref to access video element
  const videoRef = React.useRef<HTMLVideoElement>(null);

  // Setup intersection observer
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  // Query for media loading
  const { data: loadedSrc, isLoading } = useQuery({
    queryKey: ["media", src],
    queryFn: () => preloadMedia(src, isVideo),
    enabled: inView,
    staleTime: Infinity, // Never mark the data as stale
    refetchOnMount: false, // Don't refetch when component remounts
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });

  // Render loading placeholder when not in view or loading
  if (!inView || isLoading) {
    return (
      <div
        ref={inViewRef}
        className={cn(
          "relative h-full min-h-56 w-full animate-pulse rounded-md bg-primary/10",
          className,
        )}
      />
    );
  }

  return (
    <>
      {isVideo ? (
        <video
          ref={videoRef}
          className={cn(
            "relative h-full w-full rounded-md bg-muted object-cover",
            className,
          )}
          autoPlay={!isSafari}
          loop={showVideoIndicator}
          playsInline
          muted
          controls={!showVideoIndicator && !hideVideoControls}
          // crossOrigin="anonymous"
          poster={thumbnail}
          onError={handleVideoError}
          preload="metadata"
        >
          <source src={loadedSrc} type={mimeType} />
          <p>Your browser doesn&apos;t support HTML video.</p>
        </video>
      ) : (
        <img
          alt={alt}
          src={loadedSrc ?? thumbnail}
          className={cn(
            "relative h-full w-full rounded-md bg-muted object-cover",
            className,
          )}
          // crossOrigin="anonymous"
          loading="lazy"
          decoding="async"
          onError={handleImageError}
        />
      )}

      {showVideoIndicator && (
        <span className="absolute right-2 top-2 flex min-h-6 min-w-6 items-center justify-center gap-1 rounded-md bg-secondary-foreground/40 p-1">
          <Video className="relative h-4 w-4 text-accent" aria-hidden="true" />
          {!isVideo && (
            <span className="text-2xs text-white">Preview unavailable.</span>
          )}
        </span>
      )}
    </>
  );
}

// Consider using memo if parent often re-renders with same props
export default React.memo(LoadMedia);
