import { mergeAttributes, Node } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

export interface HashtagOptions {
  HTMLAttributes: Record<string, any>;
}

export const HashtagPluginKey = new PluginKey("hashtag");

export const Hashtag = Node.create<HashtagOptions>({
  name: "hashtag",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: "inline",

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      hashtag: {
        default: null,
        parseHTML: (element) => element.textContent?.slice(1),
        renderHTML: (attributes) => {
          if (!attributes.hashtag) {
            return {};
          }

          return {
            "data-hashtag": attributes.hashtag,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span[data-hashtag]",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      `#${HTMLAttributes["data-hashtag"]}`,
    ];
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: HashtagPluginKey,
        props: {
          decorations: (state) => {
            const decorations: Decoration[] = [];
            const regex = /#[\w\u00C0-\u017F]+/g;

            state.doc.descendants((node, pos) => {
              if (node.isText && node.text) {
                let match;
                while ((match = regex.exec(node.text)) !== null) {
                  const from = pos + match.index;
                  const to = from + match[0].length;
                  decorations.push(
                    Decoration.inline(from, to, {
                      nodeName: "span",
                      class:
                        this.options.HTMLAttributes.class || "text-accent-blue",
                    }),
                  );
                }
              }
            });

            return DecorationSet.create(state.doc, decorations);
          },
        },
      }),
    ];
  },
});
