import authApi, { AuthAPI } from "./auth.api";
import clientApi, { ClientAPI } from "./client.api";
import campaignApi, { CampaignAPI } from "./campaign.api";
import portfolioApi, { PortfolioAPI } from "./portfolio.api";
import calendarApi, { CalendarAPI } from "./calendar.api";
import studioApi, { StudioAPI } from "./studio.api";
import teamApi, { TeamAPI } from "./team.api";
import templateApi, { TemplateAPI } from "./template.api";
import uploadApi, { UploadAPI } from "./upload.api";
import mediaApi, { MediaAPI } from "./media.api";
import stripeApi, { StripeAPI } from "./stripe.api";
import organizationApi, { OrganizationAPI } from "./organization.api";
import canvaApi, { CanvaAPI } from "./canva.api";
import guestApi, { GuestAPI } from "./guest.api";
import copyApi, { CopyAPI } from "./copy.api";
import adminApi, { AdminAPI } from "./admin.api";
import inboxApi, { InboxAPI } from "./inbox.api";
import hubApi, { HubAPI } from "./hub.api";
import brandApi, { BrandAPI } from "./brand.api";
import folderingApi, { FolderingAPI } from "./foldering.api";
import engageApi, { EngageAPI } from "./engage.api";
import notificationApi, { NotificationAPI } from "./notification.api";
import locationsApi, { LocationsAPI } from "./locations.api";
import conceptsApi from "./concepts.api";

export * from "./auth.api";
export * from "./client.api";
export * from "./media.api";
export * from "./portfolio.api";
export * from "./campaign.api";
export * from "./upload.api";
export * from "./calendar.api";
export * from "./studio.api";
export * from "./team.api";
export * from "./template.api";
export * from "./stripe.api";
export * from "./organization.api";
export * from "./canva.api";
export * from "./guest.api";
export * from "./copy.api";
export * from "./admin.api";
export * from "./inbox.api";
export * from "./hub.api";
export * from "./brand.api";
export * from "./foldering.api";
export * from "./notification.api";
export * from "./locations.api";

export type APIComponent = AuthAPI &
  ClientAPI &
  MediaAPI &
  PortfolioAPI &
  UploadAPI &
  StudioAPI &
  CalendarAPI &
  CampaignAPI &
  TeamAPI &
  TemplateAPI &
  StripeAPI &
  OrganizationAPI &
  CanvaAPI &
  GuestAPI &
  CopyAPI &
  AdminAPI &
  InboxAPI &
  HubAPI &
  BrandAPI &
  FolderingAPI &
  EngageAPI &
  NotificationAPI &
  LocationsAPI &
  typeof conceptsApi;

export default {
  ...authApi,
  ...mediaApi,
  ...clientApi,
  ...portfolioApi,
  ...uploadApi,
  ...studioApi,
  ...calendarApi,
  ...campaignApi,
  ...teamApi,
  ...templateApi,
  ...stripeApi,
  ...organizationApi,
  ...canvaApi,
  ...guestApi,
  ...copyApi,
  ...adminApi,
  ...inboxApi,
  ...hubApi,
  ...brandApi,
  ...folderingApi,
  ...engageApi,
  ...notificationApi,
  ...locationsApi,
  ...conceptsApi,
} as APIComponent;
