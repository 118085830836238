import axios from "axios";
import { Chat } from "../reducers/luna";
import { Presets } from "../reducers/toolkit";
import { B64 } from "../util/loaders";
import { PlatformType } from "../util/platforms";
import { IPlatformHashtag } from "../views/Admin/components/lib/types";
import { IPlatformAccountTag } from "./engage.api";
import { Concept } from "./concepts.api";

export interface GenerateParams {
  prompt?: string;
  _id?: string;
  uri?: string;
  mask?: B64;
  n?: number;
  preset?: Presets;
  platform?: PlatformType;
  useTemplate?: boolean;
}

const promptRecommendation = () =>
  fetch(`/api/studio/openai/recommend-prompt`, {
    method: "POST",
  });

const invokeDalleB64 = (params: GenerateParams) =>
  fetch(`/api/studio/openai/dalle/b64`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
  });

const invokeStatelessDalle = (params: GenerateParams) =>
  fetch(`/api/studio/openai/dalle/stateless`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
  });

const invokeGPT4 = (params: GenerateParams) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const req = fetch(`/api/studio/openai/gpt4`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
    signal,
  });

  return {
    req,
    controller,
  };
};

const invokeGPT4Loose = (prompt: string) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const req = fetch(`/api/studio/openai/gpt4/loose`, {
    method: "POST",
    body: JSON.stringify({
      prompt,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    signal,
  });

  return {
    req,
    controller,
  };
};

const invokeChat = (history: Chat[]) =>
  fetch(`/api/studio/openai/chat`, {
    method: "POST",
    body: JSON.stringify({
      history,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const invokeLuna = (history: FormData) =>
  fetch(`https://luna.flamel.ai:8000/command`, {
    method: "POST",
    body: history,
  });

const invokeInpaintB64 = (prompt: string, mask: Blob) => {
  const fed = new FormData();
  fed.append("file", mask);
  return fetch(`/api/studio/openai/dalle/inpaint/b64?prompt=${prompt}`, {
    method: "POST",
    body: mask,
    headers: {
      "Content-Length": "" + mask.size,
      "Content-Type": "image/png",
    },
  });
};

const invokeStatelessInpaint = (prompt: string, mask: Blob) => {
  const fed = new FormData();
  fed.append("file", mask);
  return fetch(`/api/studio/openai/dalle/stateless/inpaint?prompt=${prompt}`, {
    method: "POST",
    body: mask,
    headers: {
      "Content-Length": "" + mask.size,
      "Content-Type": "image/png",
    },
  });
};

const getPostTypes = () => fetch(`/api/studio/openai/post-types`);

const fetchHashtags = async ({
  search,
  limit,
  pageParam,
}: FetchPlatformDataParams) => {
  const res = await axios.get("/api/studio/hashtags", {
    params: {
      cursor: pageParam ?? 0,
      limit,
      search,
    },
  });
  return res.data as { hashtags: IPlatformHashtag[]; nextCursor: number };
};

const fetchAccounts = async ({
  search,
  limit,
  pageParam,
  platform,
  hubId,
}: FetchPlatformDataParams) => {
  const res = await axios.get("/api/studio/accounts", {
    params: {
      cursor: pageParam ?? 0,
      limit,
      search,
      platform,
      hubId,
    },
  });
  return res.data as { accounts: IPlatformAccountTag[]; nextCursor: number };
};

const fetchAccountForPlatform = async (
  platform: PlatformType,
  username: string,
) => {
  const res = await axios.post(`/api/studio/accounts/${platform}/${username}`);
  return res.data as IPlatformAccountTag;
};

const setRecentAccount = async ({
  teamId,
  hubId,
  accountId,
}: {
  teamId?: string;
  hubId?: string;
  accountId: string;
}) => {
  const res = await axios.post(
    `/api/studio/accounts/${accountId}/recent`,
    {
      teamId,
      hubId,
    },
    { headers: { "Content-Type": "application/json" } },
  );
  return res.data;
};

export const generateAltText = async (_id: string, uri: string) => {
  const res = await axios.post("/api/studio/alt-text", { _id, uri });
  return res.data as Promise<{
    _id: string;
    uri: string;
    alt: string;
    tags: string[];
  }>;
};

export const generateAltTextStateless = async (base64: string) => {
  const res = await axios.post("/api/studio/alt-text/base64", { base64 });
  return res.data as Promise<{
    alt: string;
    tags: string[];
  }>;
};

export const generateConcepts = async (prompt: string, n?: number) => {
  const res = await axios.post("/api/studio/concepts/generate", { prompt, n });
  return res.data as { concepts: Concept[]; prompt: string };
};

type FetchPlatformDataParams = {
  search: string;
  limit: number;
  pageParam?: number;
  platform?: PlatformType;
  hubId?: string;
};

export interface StudioAPI {
  promptRecommendation: () => Promise<Response>;
  invokeDalleB64: (params: GenerateParams | any) => Promise<Response>;
  invokeStatelessDalle: (params: GenerateParams | any) => Promise<Response>;
  invokeGPT4: (params: GenerateParams | any) => {
    req: Promise<Response>;
    controller: AbortController;
  };
  invokeGPT4Loose: (prompt: string) => {
    req: Promise<Response>;
    controller: AbortController;
  };
  invokeChat: (history: Chat[]) => Promise<Response>;
  invokeInpaintB64: (prompt: string, mask: Blob) => Promise<Response>;
  invokeStatelessInpaint: (prompt: string, mask: Blob) => Promise<Response>;
  invokeLuna: (history: FormData) => Promise<Response>;
  getPostTypes: () => Promise<Response>;
  fetchHashtags: (
    params: FetchPlatformDataParams,
  ) => Promise<{ hashtags: IPlatformHashtag[]; nextCursor: number }>;
  fetchAccounts: (
    params: FetchPlatformDataParams,
  ) => Promise<{ accounts: IPlatformAccountTag[]; nextCursor: number }>;
  fetchAccountForPlatform: (
    platform: PlatformType,
    username: string,
  ) => Promise<IPlatformAccountTag>;
  setRecentAccount: (params: {
    teamId?: string;
    hubId?: string;
    accountId: string;
  }) => Promise<void>;
  generateAltText: (
    _id: string,
    uri: string,
  ) => Promise<{
    _id: string;
    uri: string;
    alt: string;
  }>;
  generateConcepts: (
    prompt: string,
    n?: number,
  ) => Promise<{ concepts: Concept[]; prompt: string }>;
}

export default {
  promptRecommendation,
  invokeDalleB64,
  invokeStatelessDalle,
  invokeGPT4,
  invokeGPT4Loose,
  invokeChat,
  invokeLuna,
  invokeInpaintB64,
  invokeStatelessInpaint,
  getPostTypes,
  fetchHashtags,
  fetchAccounts,
  fetchAccountForPlatform,
  setRecentAccount,
  generateAltText,
  generateConcepts,
} as StudioAPI;
