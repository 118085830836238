import axios from "axios";
import { ContentPillar } from "./brand.api";
import { Media } from "./media.api";
import { Template } from "./template.api";

export interface Concept {
  _id?: string;
  name: string;
  description: string;
  assets?: string;
  suggestions: string[];
  media: string[] | Media[];
  templates?: string[] | Template[];
  timestamp?: number;
  path?: string[];
  createdAt?: string;
  updatedAt?: string;
  tags?: string[];
  contentPillar?: string | ContentPillar;

  /**
   * @deprecated use `suggestions` instead
   */
  suggestion?: string;
}

const api = axios.create({
  baseURL: "/api/concepts",
});

const getConcepts = async (hubId?: string) => {
  const params = hubId ? { hubId } : {};
  const response = await api.get<Concept[]>("", { params });
  return response.data;
};

const getCalendarConcepts = async (params: {
  hubOnly?: boolean;
  hubId?: string;
}) => {
  const response = await api.get<Concept[]>("/calendar", { params });
  return response.data;
};

const getConceptById = async (conceptId: string) => {
  const response = await api.get<Concept>(`/${conceptId}`);
  return response.data;
};

const createConcept = async (concept: Partial<Concept>, hubId?: string) => {
  const params = hubId ? { hubId } : {};
  const response = await api.post<Concept>("", concept, { params });
  return response.data;
};

const updateConcept = async (
  conceptId: string,
  diff: Partial<Concept>,
  hubId?: string,
) => {
  const response = await api.patch<Concept>(`/${conceptId}`, diff, {
    params: { hubId },
  });
  return response.data;
};

const deleteConcept = async (conceptId: string, hubId?: string) => {
  await api.delete<void>(`/${conceptId}`, {
    params: { hubId },
  });
};

const deleteConceptFolder = async (
  conceptIds: string[],
  pseudoPaths: string[][],
  hubId?: string,
) => {
  const params = new URLSearchParams();
  conceptIds.forEach((id) => params.append("conceptId", id));
  pseudoPaths.forEach((pseudo) => params.append("pseudo", pseudo.join("@@@")));

  if (hubId) {
    params.append("hubId", hubId);
  }

  const response = await api.delete("/bulk", {
    params,
    paramsSerializer: (params) => params.toString(),
  });
  return response.data;
};

const changeConceptFolder = async (
  c: Concept,
  path: string[],
  hubId?: string,
) => {
  const response = await api.patch<Concept>(
    `/${c._id}/path`,
    { path },
    {
      params: { hubId },
    },
  );
  return response.data;
};

export default {
  getConcepts,
  getCalendarConcepts,
  getConceptById,
  createConcept,
  updateConcept,
  deleteConcept,
  changeConceptFolder,
  deleteConceptFolder,
};
