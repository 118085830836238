import dayjs, { UnitType } from "dayjs";
import Timezone from "dayjs/plugin/timezone";
import Utc from "dayjs/plugin/utc";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import IsBetween from "dayjs/plugin/isBetween";
import IsoWeek from "dayjs/plugin/isoWeek";
import Weekday from "dayjs/plugin/weekday";
import RelativeTime from "dayjs/plugin/relativeTime";
import IsToday from "dayjs/plugin/isToday";
import Duration from "dayjs/plugin/duration";
import IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import QuarterOfYear from "dayjs/plugin/quarterOfYear";

dayjs.extend(Timezone);
dayjs.extend(Utc);
dayjs.extend(CustomParseFormat);
dayjs.extend(IsBetween);
dayjs.extend(IsoWeek);
dayjs.extend(Weekday);
dayjs.extend(RelativeTime);
dayjs.extend(IsToday);
dayjs.extend(Duration);
dayjs.extend(IsSameOrAfter);
dayjs.extend(AdvancedFormat);
dayjs.extend(LocalizedFormat);
dayjs.extend(QuarterOfYear);

declare module "dayjs" {
  interface Dayjs {
    ceil(unit: UnitType, amount: number): dayjs.Dayjs;
  }
}

dayjs.prototype.ceil = function (unit: UnitType, amount: number) {
  return this.add(amount - (this.get(unit) % amount), unit).startOf(unit);
};

export default dayjs;
