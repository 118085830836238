import React from "react";
import { cn } from "../util/reusables";

type tinybadgeProps = {
  text: string;
  className?: string;
};
export default function tinybadge({ text, className }: tinybadgeProps) {
  return (
    <span
      className={cn(
        "inline-flex items-center rounded px-1 py-0 text-[6px] font-semibold leading-[12px] ring-1 ring-inset",
        "bg-gray-50 text-gray-700 ring-gray-600/20",
        className,
      )}
    >
      {text}
    </span>
  );
}
