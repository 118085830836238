import React, { forwardRef } from "react";
import { LucideProps } from "lucide-react";

export const MessageCircleZap = forwardRef<
  SVGSVGElement,
  Omit<LucideProps, "ref">
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1.576 10.304a.778.778 0 0 1-.406-.121.779.779 0 0 1-.281-.327.815.815 0 0 1 .095-.837L8.485.982a.366.366 0 0 1 .466-.09.39.39 0 0 1 .169.192.41.41 0 0 1 .017.26l-1.455 4.744a.817.817 0 0 0 .087.726c.071.105.164.19.273.249.11.058.23.09.352.089h5.303a.778.778 0 0 1 .406.121c.122.08.218.193.281.327a.816.816 0 0 1-.095.837l-7.501 8.047a.367.367 0 0 1-.466.09.39.39 0 0 1-.169-.192.41.41 0 0 1-.017-.26l1.455-4.744a.817.817 0 0 0-.087-.726.768.768 0 0 0-.273-.249.735.735 0 0 0-.352-.089H1.576Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.779 20.485a6.273 6.273 0 1 0-2.718-2.718l-1.394 4.112 4.112-1.394Z"
    />
  </svg>
));

MessageCircleZap.displayName = "MessageCircleZap";
