import React, { useRef, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector, useDispatch } from "react-redux";
import API, { Team } from "~/src/api/withApi";
import { Button } from "~/src/primitives/button";
import {
  Command,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "~/src/primitives/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/src/primitives/popover";
import { RootState } from "~/src/store";
import {
  cn,
  TooltipButton,
  withPfp,
  withQueryParams,
} from "~/src/util/reusables";
import xIcon from "~/src/assets/XIcon.png";
import linkedinIcon from "~/src/assets/LinkedInIcon.png";
import instagramIcon from "~/src/assets/InstagramIcon.png";
import facebookIcon from "~/src/assets/FacebookIcon.png";
import metaIcon from "~/src/assets/MetaIcon.png";
import { setUser } from "~/src/reducers/user";
import { Link } from "react-router-dom";
import { Gate } from "~/src/gates";
import { setError } from "~/src/reducers/toolkit";
import {
  AlertTriangle,
  ExternalLink,
  Check,
  ChevronsUpDown,
} from "lucide-react";
import {
  ALL_PLATFORMS,
  getPlatformName,
  PlatformType,
} from "~/src/util/platforms";
import { Label } from "../../../primitives/label";
import { useResizeObserver } from "usehooks-ts";
import { getCurrentWorkspaceAdAccounts } from "~/src/api/team.api";
import { getCurrentHubAdAccounts, Hub } from "~/src/api/hub.api";
import Load from "../../../partials/Load/Load";
import {
  disconnectMetaAdsAccount,
  getFacebookAccounts,
} from "../../../api/meta.api";
import { openMetaAdsManagerEditor } from "../../../api/paid/meta";

type SocialAccountsProps = {
  className?: string;
  workspace: Team;
  onUpdate?: () => void | Promise<void>;
  platforms?: PlatformType[];
};

export default function SocialAccounts({
  className,
  workspace,
  onUpdate = () => {},
  platforms = ALL_PLATFORMS,
}: SocialAccountsProps) {
  const dispatch = useDispatch();
  const canManageSocials = useSelector(
    (state: RootState) => state.user?.manifest?.manage_socials,
  );
  const hasFullSocialAccess = useSelector(
    (state: RootState) => state.user?.manifest?.full_social,
  );
  const hasPaidAccess = useSelector(
    (state: RootState) =>
      state.user?.manifest?.paid && state.user?.module === "paid",
  );
  const embedBaseUrl = useSelector(
    (state: RootState) => state.user?.embedBaseUrl,
  );
  const isOrganizationScope = useSelector((state: RootState) =>
    Gate.isOrganizationScope(state.user),
  );
  const isHubScope = useSelector((state: RootState) =>
    Boolean(state.user?.hub),
  );
  const hub = useSelector((state: RootState) => state.user?.hub);
  const ref = useRef<HTMLDivElement>(null);
  const { width = 1000 } = useResizeObserver({ ref });
  const isRowCapable = width >= 640;

  const [adAccountOpen, setAdAccountOpen] = useState(false);

  const updateWorkspaceMutation = useMutation({
    mutationFn: (diff: Partial<Team>) =>
      isOrganizationScope
        ? API.updateOrganizationWorkspace(workspace?._id, diff)
        : API.updateIndividualWorkspace(workspace?._id, diff),
    onSuccess: () => {
      API.getSession("?update=true").then(({ user }) =>
        dispatch(setUser(user)),
      );
    },
    onError: () => {
      dispatch(setError("You're not allowed to manage this workspace."));
    },
  });

  const updateHubMutation = useMutation({
    mutationFn: (diff: Partial<Hub>) => API.updateHub(hub?._id, diff),
    onSuccess: () => {
      API.getSession("?update=true").then(({ user }) =>
        dispatch(setUser(user)),
      );
    },
    onError: () => {
      dispatch(setError("You're not allowed to manage this hub."));
    },
  });

  const handleConnectSocial = async (platform: PlatformType) => {
    const endpoints = {
      [PlatformType.Twitter]: "/api/twitter/login-oauth",
      [PlatformType.LinkedIn]: "/api/linkedin/login-oauth",
      [PlatformType.Instagram]: "/api/facebook/instagram/login-oauth",
      [PlatformType.Facebook]: "/api/facebook/login-oauth",
    };
    window.top.location.href = withQueryParams(endpoints[platform], {
      redirect: embedBaseUrl,
    });
    await onUpdate();
  };

  const handleDisconnectSocial = async (platform: PlatformType) => {
    const endpoints = {
      [PlatformType.Twitter]: "/api/twitter/disconnect",
      [PlatformType.LinkedIn]: "/api/linkedin/disconnect",
      [PlatformType.Instagram]: "/api/facebook/instagram/disconnect",
      [PlatformType.Facebook]: "/api/facebook/disconnect",
    };
    const updatedUser = await fetch(endpoints[platform]).then((data) =>
      data.json(),
    );
    dispatch(setUser(updatedUser));
    await onUpdate();
  };

  const handleChangeAccount = async (
    platform: PlatformType,
    accountIndex: number,
  ) => {
    await updateWorkspaceMutation.mutateAsync({
      [platform]: {
        ...workspace[platform],
        selectedAccount:
          platform === PlatformType.LinkedIn && accountIndex === -1
            ? null
            : accountIndex,
      },
    });
    API.ingestPosts(platform).catch(() => {});
  };

  const socialPlatforms = useMemo(() => {
    if (isHubScope) return null;

    return platforms.map((platform) => (
      <SocialPlatform
        key={`${platform}-${(
          (workspace?.[platform] as any)?.selectedAccount ?? -1
        )?.toString()}`}
        platform={platform}
        workspace={workspace}
        isRowCapable={isRowCapable}
        handleConnectSocial={handleConnectSocial}
        handleDisconnectSocial={handleDisconnectSocial}
        handleChangeAccount={handleChangeAccount}
      />
    ));
  }, [workspace, isRowCapable, hasPaidAccess, isHubScope]);

  const { data: adaccounts, isLoading: isLoadingAdAccounts } = useQuery({
    queryKey: ["adaccounts", isHubScope, workspace?._id],
    queryFn: () =>
      isHubScope ? getCurrentHubAdAccounts() : getCurrentWorkspaceAdAccounts(),
    enabled: isHubScope || !!workspace?._id,
  });

  const { data: fbAccounts, isLoading: isLoadingFbAccounts } = useQuery({
    queryKey: ["fbaccounts"],
    queryFn: () => getFacebookAccounts(),
    enabled: isHubScope,
  });

  const disconnectMetaAdAccountMutation = useMutation({
    mutationFn: disconnectMetaAdsAccount,
    onSuccess: async (updatedUser) => {
      dispatch(setUser(updatedUser));
      await onUpdate();
    },
    onError: (error) => {
      dispatch(
        setError(error.message ?? "Failed to disconnect Meta Ad Account"),
      );
    },
  });

  if (isLoadingAdAccounts || isLoadingFbAccounts) return <Load />;

  if (!canManageSocials) {
    return (
      <div className={cn("w-full p-6", isRowCapable && "p-12")}>
        <div className="rounded-lg border border-yellow-400 bg-yellow-100 p-4">
          <div className="flex gap-3">
            <AlertTriangle className="mt-1 h-5 w-5 shrink-0 text-yellow-600 sm:mt-0" />
            <p className="text-yellow-700">
              You don&apos;t have permission to manage social accounts for this
              workspace. Please contact your administrator for access.
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (!workspace) return null;

  const selectedMetaAdAccountId = isHubScope
    ? hub?.selectedMetaAdAccountId
    : workspace?.selectedMetaAdAccountId;

  return (
    <div
      ref={ref}
      className={cn(
        "flex h-fit min-h-max w-full flex-col gap-4 rounded-xl border bg-muted p-4 shadow-lg",
        className,
      )}
    >
      <h5 className="text-lg font-semibold text-gray-800">
        {isHubScope ? "Paid Social Accounts" : "Social Accounts"}
        {!isHubScope && (
          <span className="ml-2 text-sm font-normal italic text-gray-600">
            (Workspace: {workspace?.name})
          </span>
        )}
      </h5>
      {!hasFullSocialAccess && (
        <p className="text-xs text-gray-700">
          You&apos;re limited to one social connection!{" "}
          <Link to="/billing" className="text-blue-600 hover:underline">
            Upgrade now
          </Link>{" "}
          for a full social set.
        </p>
      )}
      <div className="flex flex-col gap-4">
        {hasPaidAccess && (
          <>
            <div
              className={cn(
                "flex w-full flex-col justify-between gap-4 rounded-lg border bg-background p-4 shadow-sm",
                isRowCapable && "flex-row flex-wrap items-center",
              )}
            >
              <div className="flex items-center gap-2">
                <img
                  crossOrigin="anonymous"
                  src={metaIcon}
                  alt=""
                  className="h-8 w-8 shrink-0 rounded-full border object-contain shadow"
                />
                <span className="font-semibold">Meta Ads Manager</span>
              </div>
              {adaccounts?.length === 0 ? (
                <p className="max-w-sm text-balance text-right text-xs text-yellow-600">
                  {isHubScope
                    ? "No Meta ad accounts found. Reconnect Instagram and Facebook on a workspace wihtin the hub to see the options here."
                    : "No Meta ad accounts found. Reconnect Instagram and Facebook to see ad accounts."}
                </p>
              ) : (
                <div className="w-full sm:w-auto">
                  <Label
                    htmlFor="adaccount-select"
                    className="mb-1 block text-xs font-semibold text-gray-600"
                  >
                    Ad Account
                  </Label>
                  <div className="flex items-center gap-1">
                    <Popover
                      open={adAccountOpen}
                      onOpenChange={setAdAccountOpen}
                    >
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          role="combobox"
                          aria-expanded={adAccountOpen}
                          className="w-full justify-between truncate bg-background text-xs sm:w-64"
                          disabled={!adaccounts?.length}
                        >
                          {selectedMetaAdAccountId
                            ? adaccounts.find(
                                (account) =>
                                  account.account_id ===
                                  selectedMetaAdAccountId,
                              )?.name || "Select ad account..."
                            : "Select ad account..."}
                          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-full p-0 sm:w-64">
                        <Command
                          filter={(value, search) => {
                            const adaccount = adaccounts.find(
                              (a) => a.account_id === value,
                            );
                            const searchString =
                              `${adaccount?.name} (${adaccount?.account_id})`.toLowerCase();
                            if (searchString.includes(search.toLowerCase()))
                              return 1;
                            return 0;
                          }}
                        >
                          <CommandInput
                            placeholder="Search ad accounts..."
                            className="h-9"
                          />
                          <CommandList>
                            <CommandEmpty>No ad account found.</CommandEmpty>
                            <CommandGroup>
                              {adaccounts?.map((adaccount) => (
                                <CommandItem
                                  key={adaccount.account_id}
                                  value={adaccount.account_id}
                                  onSelect={(value) => {
                                    if (isHubScope) {
                                      updateHubMutation.mutateAsync({
                                        selectedMetaAdAccountId: value,
                                      });
                                    } else {
                                      updateWorkspaceMutation.mutateAsync({
                                        selectedMetaAdAccountId: value,
                                      });
                                    }
                                    setAdAccountOpen(false);
                                  }}
                                >
                                  {adaccount.name} ({adaccount.account_id})
                                  {selectedMetaAdAccountId ===
                                    adaccount.account_id && (
                                    <Check className="ml-auto h-4 w-4" />
                                  )}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                    {!!selectedMetaAdAccountId && (
                      <>
                        <TooltipButton text="Open in Meta Ads Manager">
                          <Button
                            variant="ghost"
                            size="icon-sm"
                            onClick={() =>
                              openMetaAdsManagerEditor("campaigns", {
                                act: selectedMetaAdAccountId,
                              })
                            }
                          >
                            <ExternalLink className="h-4 w-4" />
                            <span className="sr-only">
                              Open in Meta Ads Manager
                            </span>
                          </Button>
                        </TooltipButton>
                        <Button
                          className="h-8 shrink-0 px-3 text-xs font-semibold"
                          variant="secondary"
                          size="sm"
                          onClick={async () => {
                            await disconnectMetaAdAccountMutation.mutateAsync();
                          }}
                        >
                          Disconnect
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            {isHubScope && (
              <div
                className={cn(
                  "flex w-full flex-col justify-between gap-4 rounded-lg border bg-background p-4 shadow-sm",
                  isRowCapable && "flex-row flex-wrap items-center",
                )}
              >
                <div className="flex items-center gap-2">
                  <img
                    crossOrigin="anonymous"
                    src={facebookIcon}
                    alt=""
                    className="h-8 w-8 shrink-0 rounded-full border object-contain shadow"
                  />
                  <span className="font-semibold">Facebook Page</span>
                </div>
                {fbAccounts?.accounts?.length === 0 && (
                  <p className="max-w-sm text-balance text-xs text-yellow-600">
                    No Facebook pages found. Reconnect Facebook on a workspace
                    within the hub to see the options here.
                  </p>
                )}
                <div className="w-full sm:w-auto">
                  <Label
                    htmlFor="fbaccount-select"
                    className="mb-1 block text-xs font-semibold text-gray-600"
                  >
                    Facebook Page
                  </Label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        role="combobox"
                        className="w-full justify-between bg-background text-xs sm:w-64"
                        disabled={!fbAccounts?.accounts?.length}
                      >
                        {hub?.selectedFacebookPage ? (
                          <div className="flex items-center">
                            <img
                              crossOrigin="anonymous"
                              src={withPfp(
                                hub.selectedFacebookPage.pfp,
                                hub.selectedFacebookPage.name || "Placeholder",
                              )}
                              className="mr-2 h-6 w-6 rounded-full border object-cover shadow"
                            />
                            <span className="truncate">
                              {hub.selectedFacebookPage.name || "Placeholder"}
                            </span>
                          </div>
                        ) : (
                          "Select Facebook page..."
                        )}
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-[--radix-popover-trigger-width] p-0">
                      <Command>
                        <CommandInput
                          placeholder={`Search Facebook pages...`}
                        />
                        <CommandList>
                          <CommandEmpty>No Facebook pages found.</CommandEmpty>
                          <CommandGroup>
                            {fbAccounts?.accounts?.map((fbAccount) => (
                              <CommandItem
                                key={fbAccount.id}
                                onSelect={() => {
                                  updateHubMutation.mutateAsync({
                                    selectedFacebookPage: fbAccount,
                                  });
                                }}
                              >
                                <div className="flex items-center">
                                  <img
                                    crossOrigin="anonymous"
                                    src={withPfp(
                                      fbAccount.pfp,
                                      fbAccount.name || "Placeholder",
                                    )}
                                    className="mr-2 h-6 w-6 rounded-full border object-cover shadow"
                                  />
                                  <span className="text-xs">
                                    {fbAccount.name || "Placeholder"}
                                  </span>
                                </div>
                                {hub?.selectedFacebookPage?.id ===
                                  fbAccount.id && (
                                  <Check className="ml-auto h-4 w-4" />
                                )}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
            )}
          </>
        )}
        {socialPlatforms}
      </div>
    </div>
  );
}

type SocialPlatformProps = {
  platform: PlatformType;
  workspace: Team;
  isRowCapable: boolean;
  handleConnectSocial: (platform: PlatformType) => Promise<void>;
  handleDisconnectSocial: (platform: PlatformType) => Promise<void>;
  handleChangeAccount: (
    platform: PlatformType,
    accountIndex: number,
  ) => Promise<void>;
};

const SocialPlatform = React.memo(
  ({
    platform,
    workspace,
    isRowCapable,
    handleConnectSocial,
    handleDisconnectSocial,
    handleChangeAccount,
  }: SocialPlatformProps) => {
    const icons = {
      [PlatformType.Twitter]: xIcon,
      [PlatformType.LinkedIn]: linkedinIcon,
      [PlatformType.Instagram]: instagramIcon,
      [PlatformType.Facebook]: facebookIcon,
    };

    const platformData = workspace?.[platform] as any;
    const accounts =
      platform === PlatformType.LinkedIn
        ? platformData?.orgs || []
        : platform === PlatformType.Twitter
        ? [
            {
              id: platformData?.id ?? platformData?.handle,
              name: platformData?.name,
              username: platformData?.handle,
              pfp: platformData?.pfp,
            },
          ]
        : platformData?.accounts || [];

    const selectedAccount =
      platformData?.selectedAccount ??
      (platform === PlatformType.LinkedIn ? -1 : 0);
    const isConnectionExpired =
      workspace?.expiredConnections?.includes(platform);

    const getAccountInfo = (index: number) => {
      if (platform === PlatformType.LinkedIn && index === -1) {
        return { pfp: platformData?.pfp, name: platformData?.name };
      }
      return accounts[index] || {};
    };

    const currentAccount = getAccountInfo(selectedAccount);

    return (
      <div
        className={cn(
          "flex w-full flex-col justify-between gap-4 rounded-lg border bg-background p-4 shadow-sm",
          isConnectionExpired && "border-destructive bg-red-100",
          isRowCapable && "flex-row flex-wrap",
        )}
      >
        {isConnectionExpired && (
          <div className="flex items-center gap-2 text-sm text-destructive">
            <AlertTriangle className="h-4 w-4" />
            <span>Expired</span>
          </div>
        )}
        <div className="flex items-center gap-2">
          <img
            crossOrigin="anonymous"
            src={icons[platform]}
            alt=""
            className="h-8 w-8 shrink-0 rounded-full border object-contain shadow"
          />
          <span className="font-semibold">{getPlatformName(platform)}</span>
        </div>
        {Boolean(platformData) && (
          <div
            className={cn(
              "flex flex-grow flex-col items-start justify-start gap-4",
              isRowCapable && "flex-row flex-wrap items-center justify-end",
            )}
          >
            <div className="w-full sm:w-auto">
              <Label
                htmlFor="account-select"
                className="mb-1 block text-xs font-semibold text-gray-600"
              >
                Account
              </Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    className="w-full justify-between bg-background text-xs sm:w-64"
                  >
                    <div className="flex items-center">
                      <img
                        crossOrigin="anonymous"
                        src={withPfp(
                          currentAccount.profile_picture_url ??
                            currentAccount.pfp,
                          currentAccount.name || "Placeholder",
                        )}
                        className="mr-2 h-6 w-6 rounded-full border object-cover shadow"
                      />
                      <span className="truncate text-xs">
                        {currentAccount.username ||
                          currentAccount.name ||
                          "Placeholder"}
                      </span>
                    </div>
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[--radix-popover-trigger-width] p-0">
                  <Command>
                    <CommandInput
                      placeholder={`Search ${getPlatformName(
                        platform,
                      )} accounts...`}
                    />
                    <CommandList>
                      <CommandEmpty>No accounts found.</CommandEmpty>
                      <CommandGroup>
                        {accounts.map((account, index) => (
                          <CommandItem
                            key={index}
                            onSelect={() =>
                              handleChangeAccount(platform, index)
                            }
                          >
                            <div className="flex items-center">
                              <img
                                crossOrigin="anonymous"
                                src={withPfp(
                                  account.profile_picture_url ?? account.pfp,
                                  account.name || "Placeholder",
                                )}
                                className="mr-2 h-6 w-6 rounded-full border object-cover shadow"
                              />
                              <span className="text-xs">
                                {account.username ||
                                  account.name ||
                                  "Placeholder"}
                              </span>
                            </div>
                            {selectedAccount === index && (
                              <Check className="ml-auto h-4 w-4" />
                            )}
                          </CommandItem>
                        ))}
                        {platform === PlatformType.LinkedIn && (
                          <CommandItem
                            onSelect={() => handleChangeAccount(platform, -1)}
                          >
                            <div className="flex items-center">
                              <img
                                crossOrigin="anonymous"
                                src={withPfp(
                                  platformData?.pfp,
                                  platformData?.name,
                                )}
                                className="mr-2 h-6 w-6 rounded-full border object-cover shadow"
                              />
                              <span className="text-xs">
                                {platformData?.name || "Personal Account"}
                              </span>
                            </div>
                            {selectedAccount === -1 && (
                              <Check className="ml-auto h-4 w-4" />
                            )}
                          </CommandItem>
                        )}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        )}
        <div className="flex w-auto items-center justify-end gap-2">
          {platformData ? (
            <>
              <Button
                className="h-8 shrink-0 px-3 text-xs font-semibold"
                size="sm"
                onClick={() => {
                  handleDisconnectSocial(platform);
                  handleConnectSocial(platform);
                }}
              >
                Refresh Login
              </Button>
              <Button
                className="h-8 shrink-0 px-3 text-xs font-semibold"
                variant="secondary"
                size="sm"
                onClick={() => handleDisconnectSocial(platform)}
                data-testid={`${platform}-disconnect`}
              >
                Disconnect
              </Button>
            </>
          ) : (
            <Button
              className="h-8 shrink-0 px-3 text-xs font-semibold"
              onClick={() => handleConnectSocial(platform)}
              data-testid={`${platform}-connect`}
            >
              Connect {getPlatformName(platform)}
            </Button>
          )}
        </div>
      </div>
    );
  },
);

SocialPlatform.displayName = "SocialPlatform";
