import React, { useEffect, useState } from "react";
import API, { UserAccount } from "~/src/api/withApi";
import { useSelector, useDispatch } from "react-redux";
import { OrganizationType } from "~/src/api/organization.api";
import FadeIn from "~/src/partials/Transitions/FadeIn";
import { Button } from "~/src/primitives/button";
import { Card } from "~/src/primitives/card";
import { Input } from "~/src/primitives/input";
import { RootState } from "~/src/store";
import { setUser } from "~/src/reducers/user";
import { setSuccess } from "~/src/reducers/toolkit";
import { username, withPfp } from "~/src/util/reusables";
import TransferOwnershipModal from "./components/TransferOwnershipModal";

interface BasicSettingsProps {
  organization?: OrganizationType & {
    owner: Partial<UserAccount>;
  };
}

const BasicSettings = ({ organization }: BasicSettingsProps) => {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [name, setName] = useState<string>("");
  const [showTransfer, setShowTransfer] = useState<boolean>(false);

  const onUpdateOrganization = (diff: Partial<OrganizationType>) =>
    API.updateOrganization(diff)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)));

  useEffect(() => {
    if (user) {
      setName(user.organization?.name ?? "My Organization");
    }
  }, [user]);

  return (
    <FadeIn show timeout={100}>
      <Card className="mt-2 w-full p-4">
        <div className="grid w-full gap-5 py-4 md:w-[75vw] lg:w-[50vw]">
          <div className="grid grid-cols-4 items-center gap-x-4">
            <label className="text-right text-sm">Name:</label>
            <Input
              id="org-name"
              disabled={!user.manifest.manage_organization}
              className="col-span-2"
              value={name}
              placeholder="My Organization"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-x-4">
            <label className="text-right text-sm">Owner:</label>
            <Button
              variant="outline"
              className="col-span-2 justify-start px-3"
              disabled={
                !user.manifest.organization_owner || user.manifest.trial
              }
              onClick={() => {
                setShowTransfer(true);
              }}
            >
              <img
                src={withPfp(
                  organization?.owner?.pfp,
                  `${organization?.owner?.firstname} ${organization?.owner?.lastname}`,
                )}
                className="mr-3 h-6 w-6 rounded-full"
                alt=""
                crossOrigin="anonymous"
              />
              <div className="whitespace-nowrap text-sm font-bold">
                {username(organization?.owner)}
              </div>
            </Button>
          </div>
          <div className="grid grid-cols-4 items-center gap-x-4">
            <label className="text-right text-sm">Workspaces:</label>
            <div className="flex-wrap rounded-md border bg-gray-50 px-4 py-2 text-sm font-bold">
              {organization?.maxWorkspacesQuantity ??
                organization?.owner?.subscription?.workspaces}
            </div>
          </div>
        </div>
        {/* Actions */}
        {user.manifest.manage_organization && (
          <div className="flex w-full flex-row items-center justify-end space-x-4">
            <Button
              variant="default"
              size="sm"
              onClick={async () => {
                await onUpdateOrganization({
                  name,
                });
                dispatch(setSuccess("Saved!"));
              }}
            >
              Save
            </Button>
          </div>
        )}
      </Card>

      <TransferOwnershipModal
        show={showTransfer}
        onHide={() => setShowTransfer(false)}
      />
    </FadeIn>
  );
};

export default BasicSettings;
