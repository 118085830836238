import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { UserRoundPlus } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntersectionObserver } from "usehooks-ts";
import { Skeleton } from "../../../primitives/skeleton";
import { setError, setLoading } from "../../../reducers/toolkit";
import { getPlatformName, PlatformType } from "../../../util/platforms";
import { cn, withPfp } from "../../../util/reusables";
import API from "~/src/api/withApi";
import { IPlatformAccountTag } from "../../../api/engage.api";
import { useParams } from "react-router-dom";
import { RootState } from "../../../store";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../../../primitives/command";

type AccountsSearchProps = {
  className?: string;
  platform: PlatformType;
  onSelectAccount: (account: IPlatformAccountTag) => void;
};
export default function AccountsSearch({
  className,
  platform,
  onSelectAccount,
}: AccountsSearchProps) {
  const user = useSelector((state: RootState) => state.user);
  const { hubId } = useParams();
  const dispatch = useDispatch();
  const [tagAccountsSearch, setTagAccountsSearch] = useState<string>("");
  const {
    data: tagAccountsData,
    fetchNextPage: tagAccountsFetchNextPage,
    hasNextPage: tagAccountsHasNextPage,
    isPending,
  } = useInfiniteQuery({
    queryKey: ["accounts", platform, tagAccountsSearch],
    queryFn: ({ pageParam }) =>
      API.fetchAccounts({
        search: tagAccountsSearch,
        limit: 20,
        pageParam,
        platform,
        hubId,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
  const { isIntersecting: tagAccountsIsIntersecting, ref: tagAccountsRef } =
    useIntersectionObserver({ threshold: 0.5 });
  useEffect(() => {
    if (tagAccountsIsIntersecting && tagAccountsHasNextPage)
      tagAccountsFetchNextPage();
  }, [tagAccountsIsIntersecting]);

  const fetchManualAccountMutation = useMutation({
    mutationFn: ({
      platform,
      username,
    }: {
      platform: PlatformType;
      username: string; // username or id based on platform...
    }) => API.fetchAccountForPlatform(platform, username),
    onMutate: () => dispatch(setLoading("Initializing...")),
    onSuccess: () => {
      dispatch(setLoading(undefined));
    },
  });

  const setRecentAccountMutation = useMutation({
    mutationFn: ({
      teamId,
      hubId,
      accountId,
    }: {
      teamId?: string;
      hubId?: string;
      accountId: string;
    }) => API.setRecentAccount({ teamId, hubId, accountId }),
  });

  const handleSelectAccount = (account: IPlatformAccountTag) => {
    onSelectAccount(account);
    setTagAccountsSearch("");
    if (account?._id)
      setRecentAccountMutation.mutate({
        accountId: account._id,
        hubId,
        teamId: hubId ? undefined : user?.workspace?._id,
      });
  };

  const handleUseAccountManually = async () => {
    let result: IPlatformAccountTag;
    try {
      result = await fetchManualAccountMutation.mutateAsync({
        platform: platform,
        username: tagAccountsSearch,
      });
    } catch (err) {
      // LinkedIn impossible in this state...
      if (platform === PlatformType.LinkedIn) {
        dispatch(
          setError(
            `Unable to tag ${getPlatformName(
              platform,
            )} account: ${tagAccountsSearch}`,
          ),
        );
        return;
      }

      // Facebook must manually tag page id...
      else if (platform === PlatformType.Facebook) {
        const checkFacebookId = parseInt(tagAccountsSearch);
        if (isNaN(checkFacebookId)) {
          dispatch(
            setError(
              `Unable to tag ${getPlatformName(
                platform,
              )} account: ${tagAccountsSearch}. Please use a ${getPlatformName(
                platform,
              )} Page ID (e.g. 100926349589092)`,
            ),
          );
          return;
        }
      }

      result = {
        _id: undefined,
        platform,
        platformAccountId: tagAccountsSearch,
        platformAccountUsername: tagAccountsSearch,
        platformAccountPfp: undefined,
        platformAccountName: undefined,
      };
      dispatch(setLoading(undefined));
    }

    onSelectAccount(result);
    setTagAccountsSearch("");
    if (result?._id)
      setRecentAccountMutation.mutate({
        accountId: result._id,
        hubId,
        teamId: hubId ? undefined : user?.workspace?._id,
      });
  };

  return (
    <Command shouldFilter={false} className={cn("min-w-72", className)}>
      <CommandInput
        value={tagAccountsSearch}
        autoFocus
        onValueChange={setTagAccountsSearch}
        placeholder={`Search ${getPlatformName(platform)} accounts...`}
        className="h-8 w-full rounded-full bg-muted"
      />
      <CommandList>
        {tagAccountsSearch?.length > 2 && (
          <>
            <CommandGroup heading="Quick actions">
              <CommandItem onSelect={() => handleUseAccountManually()}>
                <UserRoundPlus className="size-4" />
                <span>Tag account manually</span>
              </CommandItem>
            </CommandGroup>
            <CommandSeparator />
          </>
        )}
        <CommandGroup
          heading={tagAccountsSearch?.length ? "Search results" : "Recent"}
        >
          {isPending ? (
            <div className="flex w-full flex-col gap-2 px-3">
              <Skeleton className="h-6 w-full rounded-full" />
              <Skeleton className="h-6 w-full rounded-full" />
              <Skeleton className="h-6 w-full rounded-full" />
              <Skeleton className="h-6 w-full rounded-full p-1" />
            </div>
          ) : (
            tagAccountsData?.pages?.map((page, i) => (
              <React.Fragment key={i}>
                {!page?.accounts?.length ? (
                  <div className="py-6 text-center text-sm">
                    No accounts found.
                  </div>
                ) : (
                  page?.accounts?.map((account) => {
                    return (
                      <CommandItem
                        value={account.platformAccountId}
                        key={`account-${account.platformAccountId}`}
                        onSelect={() => handleSelectAccount(account)}
                      >
                        <img
                          loading="lazy"
                          className={cn(
                            "inline-block h-6 w-6 shrink-0 overflow-hidden rounded-full border p-0",
                          )}
                          crossOrigin="anonymous"
                          src={withPfp(
                            account.platformAccountPfp,
                            account.platformAccountId,
                          )}
                          onError={(event) => {
                            // Fallback to a placeholder if loading fails
                            (event.target as any).src = withPfp(
                              "",
                              account.platformAccountId,
                            );
                          }}
                          alt={`${
                            account.platformAccountUsername ??
                            account.platformAccountName
                          } on ${account.platform}`}
                        />
                        <span>
                          {[
                            PlatformType.Instagram,
                            PlatformType.Twitter,
                          ].includes(platform as any)
                            ? account.platformAccountUsername ??
                              account.platformAccountName
                            : account.platformAccountName ??
                              account.platformAccountUsername}
                        </span>
                      </CommandItem>
                    );
                  })
                )}
              </React.Fragment>
            ))
          )}
          {Boolean(tagAccountsData?.pages?.length) &&
            tagAccountsHasNextPage && (
              <div
                ref={tagAccountsRef}
                className={cn(
                  "flex animate-pulse items-center justify-center gap-2 p-2",
                )}
              >
                <div className="size-2 rounded-full bg-gray-500"></div>
                <div className="size-2 rounded-full bg-gray-500"></div>
                <div className="size-2 rounded-full bg-gray-500"></div>
              </div>
            )}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}
