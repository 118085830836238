const createClient = (name: string, email: string) =>
  fetch("/api/clients/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      email,
    }),
  });

const getClients = () => fetch("/api/clients/");

const getClientAccessLink = (clientId: string) =>
  fetch(`/api/clients/access-link/${clientId}`);

const focusClient = (clientId: string) =>
  fetch(`/api/clients/focus/${clientId}`, {
    method: "POST",
  });

const unfocusClient = () =>
  fetch("/api/clients/unfocus", {
    method: "POST",
  });

const dissociateClient = (clientId: string) =>
  fetch(`/api/clients/dissociate/${clientId}`, {
    method: "DELETE",
  });

export interface ClientAPI {
  createClient: (name: string, email: string) => Promise<Response>;
  getClients: () => Promise<Response>;
  getClientAccessLink: (clientId: string) => Promise<Response>;
  focusClient: (clientId: string) => Promise<Response>;
  unfocusClient: () => Promise<Response>;
  dissociateClient: (clientId: string) => Promise<Response>;
}

export default {
  createClient,
  getClients,
  getClientAccessLink,
  focusClient,
  unfocusClient,
  dissociateClient,
} as ClientAPI;
