import axios from "axios";
import { ObjectValues } from "../util/reusables";
import { Role } from "./auth.api";
import { OrganizationType } from "./organization.api";
import { LarvalPost } from "./portfolio.api";

export interface Guest {
  _id: string;
  organization: string | OrganizationType;
  organizationRole: Role;
  workspaces: string[];
  dash: GuestDashboardOption[];
  name: string;
  token: string;
  campaigns?: string[];
  months?: number[];
  source?: GuestDashboardOption;
}

export const GuestDashboardOption = {
  calendar: "calendar",
  posts: "posts",
  media: "media",
  upload: "upload",
  analytics: "analytics",
} as const;
export type GuestDashboardOption = ObjectValues<typeof GuestDashboardOption>;

export type GuestOptions = {
  name: string;
  dashboard: GuestDashboardOption[];
  workspaces: string[];
  campaigns: string[];
  months: number[]; // 0-11 to indicate month in dayjs standards
};

const createGuest = async (options: GuestOptions): Promise<Guest> =>
  (await axios.post<Guest>("/api/guest", options)).data;

const updateGuest = (guestId: string, diff: Partial<GuestOptions>) =>
  fetch(`/api/guest/${guestId}`, {
    method: "PATCH",
    body: JSON.stringify(diff),
    headers: {
      "Content-Type": "application/json",
    },
  });

const deleteGuest = (guestId: string) =>
  fetch(`/api/guest/${guestId}`, {
    method: "DELETE",
  });

const guestLink = (guestId: string) => fetch(`/api/guest/link/${guestId}`);

const guestGetSelf = () => fetch(`/api/guest/self`);

const guestSwitchWorkspaces = (workspaceId: string) =>
  fetch(`/api/guest/workspace/${workspaceId}`, {
    method: "POST",
  });

const guestApproveLarvalPosts = (larvalPostIds: string[]) =>
  fetch("/api/guest/posts/approve", {
    method: "POST",
    body: JSON.stringify({ larvalIds: larvalPostIds }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());

const guestDenyLarvalPosts = (larvalPostIds: string[]) =>
  fetch("/api/guest/posts/deny", {
    method: "POST",
    body: JSON.stringify({ larvalIds: larvalPostIds }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());

const guestUndoApprovalStatus = (larvalPostId: string) =>
  fetch(`/api/guest/posts/${larvalPostId}/clear`, {
    method: "POST",
  }).then((res) => res.json());

const generateGuestLink = async (
  source: GuestDashboardOption,
): Promise<string> => {
  const response = await axios.post<{ link: string }>(
    `/api/guest/generate?type=${source}`,
  );
  return response.data.link;
};

export interface GuestAPI {
  createGuest: (options: GuestOptions) => Promise<Guest>;
  updateGuest: (
    guestId: string,
    diff: Partial<GuestOptions>,
  ) => Promise<Response>;
  deleteGuest: (guestId: string) => Promise<Response>;
  guestLink: (guestId: string) => Promise<Response>;
  guestGetSelf: () => Promise<Response>;
  guestSwitchWorkspaces: (workspaceId: string) => Promise<Response>;
  guestApproveLarvalPosts: (larvalPostIds: string[]) => Promise<LarvalPost[]>;
  guestDenyLarvalPosts: (larvalPostIds: string[]) => Promise<LarvalPost[]>;
  guestUndoApprovalStatus: (larvalPostId: string) => Promise<LarvalPost>;
  generateGuestLink: (source: GuestDashboardOption) => Promise<string>;
}

export default {
  createGuest,
  updateGuest,
  deleteGuest,
  guestLink,
  guestGetSelf,
  guestSwitchWorkspaces,
  guestApproveLarvalPosts,
  guestDenyLarvalPosts,
  guestUndoApprovalStatus,
  generateGuestLink,
} as GuestAPI;
