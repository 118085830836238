import React, { useState } from "react";
import "./tagger.style.css";
import { cn } from "~/src/util/reusables";
import { Input } from "~/src/primitives/input";
import { Button } from "~/src/primitives/button";
import { Plus, X } from "lucide-react";

interface TaggerProps<TItem> {
  tags: TItem[];
  getTag: (i: TItem) => string;
  placeholder?: string;
  onDelete: (tag: TItem) => void;
  onCreate: (tag: string) => void;
}

function Tagger<TItem>({
  tags,
  getTag,
  placeholder,
  onDelete,
  onCreate,
}: TaggerProps<TItem>) {
  const [newTag, setNewTag] = useState<string>("");

  const handleCreate = () => {
    if (newTag.length > 0) {
      onCreate(newTag);
      setNewTag("");
    }
  };

  const handleCreateOnEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleCreate();
    }
  };

  return (
    <div className={cn("tagger flex-col items-center space-y-1")}>
      <div className="flex flex-row items-center gap-2">
        <Input
          value={newTag}
          placeholder={placeholder ?? "Add tags"}
          id="tagger-input"
          className="bg-background text-xs"
          onChange={(e) => {
            setNewTag(e.target.value);
          }}
          onKeyDown={(e: any) => handleCreateOnEnter(e)}
        />
        <Button
          size="sm"
          id="tagger-button"
          className="bg-slate-700 text-xs text-white"
          onClick={handleCreate}
          type="button"
        >
          <Plus className="mr-1 h-3 w-3" /> Add
        </Button>
      </div>

      <div className="flex flex-row flex-wrap items-center">
        {tags?.map((e, i) => (
          <div
            className={
              "mb-1 mr-1 inline-flex h-[32px] flex-row items-center justify-start space-x-1 rounded-md border px-1 text-xs hover:border-gray-500"
            }
            data-testid={`tag-${i}`}
            key={`tagger-tag-${i}`}
          >
            <X
              className="h-3 w-3 rounded-full text-black hover:bg-primary hover:text-white"
              onClick={() => onDelete(e)}
            />
            <div>{getTag(e)}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tagger;
