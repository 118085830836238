import React, { useState, useEffect } from "react";
import FacebookTemplate from "./FacebookTemplate/FacebookTemplate";
import InstagramTemplate from "./InstagramTemplate/InstagramTemplate";
import LinkedInTemplate from "./LinkedInTemplate/LinkedInTemplate";
import TwitterTemplate from "./TwitterTemplate/TwitterTemplate";
import { typewriter } from "../../util/reusables";
import { Dayjs } from "dayjs";
import InstagramReelTemplate from "./InstagramTemplate/InstagramReelTemplate";
import InstagramStoryTemplate from "./InstagramTemplate/InstagramStoryTemplate";
import type { Team } from "~/src/api/team.api";
import { PlatformType } from "~/src/util/platforms";

export type PreviewProps = {
  platform: PlatformType;
  platformContentType?: string;
  display?: React.ReactNode;
  media?: string;
  when?: Dayjs;
  mimeType?: string;
  caption: string;
  style?: any;
  selected?: boolean;
  typewriter?: boolean;
  onClick?: () => void;
  onSelectMedia?: () => void;
  onSelectAccount?: () => void;
  raw?: any;
  forceWorkspace?: Team; // Allows you to override the PFP and Name per: https://gitlab.com/flamel-ai/flamel-ai/-/issues/905
  likeCount?: number;
  forceDisplayPfp?: string; // Allows absolute control over pfp
  forceDisplayName?: string; // Allows absolute control over name
};

const Preview = (props: PreviewProps) => {
  const [typewriterProgress, setTypewriterProgress] = useState<string>();

  useEffect(() => {
    if (props.typewriter) {
      typewriter(props.caption, 10, (s) => setTypewriterProgress(s));
    } else {
      setTypewriterProgress(props.caption);
    }
  }, [props.caption]);

  const templateProps = {
    ...props,
    caption: typewriterProgress ?? "",
  };

  delete templateProps.platform;

  const PlatformTemplateMap = {
    instagram: {
      post: <InstagramTemplate {...templateProps} />,
      reel: <InstagramReelTemplate {...templateProps} />,
      story: <InstagramStoryTemplate {...templateProps} />,
    },
    twitter: {
      post: <TwitterTemplate {...templateProps} />,
    },
    linkedin: {
      post: <LinkedInTemplate {...templateProps} />,
    },
    facebook: {
      post: <FacebookTemplate {...templateProps} />,
    },
  };

  return (
    PlatformTemplateMap?.[props.platform]?.[
      props.platformContentType ?? "post"
    ] ?? PlatformTemplateMap?.[props.platform]?.["post"]
  );
};

export default Preview;
