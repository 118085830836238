import React, { useEffect, useState } from "react";
import { Button } from "~/src/primitives/button";
import { StepItem, useMediaQuery } from "~/src/primitives/stepper";
import { Step, Stepper, useStepper } from "~/src/primitives/stepper";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "~/src/primitives/input";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/src/primitives/form";
import { Switch } from "~/src/primitives/switch";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/src/primitives/select";
import dayjs from "../../../../util/dayjs";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../primitives/popover";
import { Check, ChevronsUpDown, Save, Send } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../../primitives/command";
import { cn } from "../../../../util/reusables";
import { convertPropertyToReadableFormat } from "../../../Admin/components/lib/data-table";
import {
  LocationFormData,
  locationSchema,
} from "../../../../api/locations.api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import API from "~/src/api/withApi";
import { setError, setSuccess } from "../../../../reducers/toolkit";
import { useQuery } from "@tanstack/react-query";
import Load from "../../../../partials/Load/Load";

const steps: StepItem[] = [
  {
    label: "Basic Information",
    description: "Enter location name and administrative details",
  },
  {
    label: "Location Details",
    description: "Provide specific information about the location",
  },
  {
    label: "Operating Hours",
    description: "Set the hours of operation for this location",
  },
  {
    label: "Review and Submit",
    description: "Review all entered information before submission",
  },
];

const timeOptions = Array.from({ length: 48 }, (_, i) => {
  const hour = Math.floor(i / 2);
  const minute = i % 2 === 0 ? "00" : "30";
  const ampm = hour < 12 ? "AM" : "PM";
  const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
  return `${formattedHour}:${minute} ${ampm}`;
});

const FORM_CONTAINER_CLASS =
  "flex flex-col gap-4 h-full overflow-y-auto max-w-5xl mx-auto p-4 bg-muted/10 backdrop-blur-3xl rounded-lg shadow-lg border-2 border-white";
const FORM_LABEL_CLASS = "text-primary-foreground";
const FORM_MESSAGE_CLASS =
  "w-fit text-red-50 border border-red-50 bg-red-500 text-xs shadow-sm h-fit rounded-full px-2 py-1 flex leading-none items-center justify-center text-center";

const DEFAULT_HOURS = {
  hours: {
    sunday: { is_open: false, open: "9:00 AM", close: "5:00 PM" },
    monday: { is_open: true, open: "9:00 AM", close: "5:00 PM" },
    tuesday: { is_open: true, open: "9:00 AM", close: "5:00 PM" },
    wednesday: { is_open: true, open: "9:00 AM", close: "5:00 PM" },
    thursday: { is_open: true, open: "9:00 AM", close: "5:00 PM" },
    friday: { is_open: true, open: "9:00 AM", close: "5:00 PM" },
    saturday: { is_open: false, open: "9:00 AM", close: "5:00 PM" },
  },
  time_zone: dayjs.tz.guess(),
};

export default function LocationDetailsForm({
  teamId,
  organizations,
  onSuccess,
}: {
  teamId?: string;
  organizations?: Array<{ _id: string; name: string; owner_email: string }>;
  onSuccess?: () => void;
}) {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const [selectedOrganization, setSelectedOrganization] = useState<
    { _id: string; name: string; owner_email: string } | undefined
  >(organizations?.find((org) => org._id === user?.organization?._id));

  const methods = useForm<LocationFormData>({
    resolver: zodResolver(locationSchema),
    defaultValues: DEFAULT_HOURS,
  });

  const { data, isLoading } = useQuery<LocationFormData>({
    queryKey: ["teamLocation", teamId],
    queryFn: () =>
      API.getTeamLocation(teamId).then((res) => ({
        ...res.location,
        name: res.name,
      })),
    enabled: !!teamId,
  });

  useEffect(() => {
    if (data) {
      const existingData = {
        ...data,
        hours: data.hours || DEFAULT_HOURS.hours,
        time_zone: data.time_zone || DEFAULT_HOURS.time_zone,
      };
      methods.reset(existingData);
    }
  }, [data, methods]);

  const onSubmit = async (data: LocationFormData, teamId?: string) => {
    try {
      if (teamId) {
        await API.upsertLocation(teamId, data, true);
        dispatch(setSuccess("Location updated!"));
      } else {
        const organizationId = selectedOrganization._id;
        await API.createNewLocation(organizationId, data, user.hub?._id);
        dispatch(
          setSuccess("New location created and invite has been sent to admin!"),
        );
      }
      onSuccess?.();
    } catch (error) {
      dispatch(
        setError(
          teamId ? "Error managing location" : "Error creating new location",
        ),
      );
    }
  };

  const isMobile = useMediaQuery(`(max-width: 768px)`);

  if (isLoading) {
    return <Load />;
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => e.preventDefault()}
        className={cn(
          "flex h-full flex-col gap-4 rounded-lg bg-gradient-to-br from-fl-mandy via-fl-cinnabar to-fl-firebush shadow-lg ring-2 ring-inset ring-white",
          isMobile ? "overflow-y-auto" : "overflow-hidden",
        )}
      >
        <Stepper
          className="bg-white p-4"
          initialStep={0}
          steps={steps}
          onClickStep={async (step, setStep) => {
            let fieldsToValidate: (keyof LocationFormData)[] = [];
            let currentStep = 0;

            while (currentStep < step) {
              switch (currentStep) {
                case 0:
                  fieldsToValidate = ["name", "admin_name", "admin_email"];
                  break;
                case 1:
                  fieldsToValidate = [
                    "address",
                    "city",
                    "state",
                    "zip",
                    "contact_email",
                    "phone_number",
                    "website_url",
                  ];
                  break;
                case 2:
                  fieldsToValidate = ["hours", "time_zone"];
                  break;
                default:
                  break;
              }

              const isStepValid = await methods.trigger(fieldsToValidate);
              if (!isStepValid) {
                setStep(currentStep);
                return;
              }
              currentStep++;
            }

            setStep(step);
          }}
        >
          <Step {...steps[0]}>
            <BasicInformationStep
              teamId={teamId}
              onSuccess={onSuccess}
              organizations={organizations}
              selectedOrganization={selectedOrganization}
              setSelectedOrganization={setSelectedOrganization}
            />
          </Step>
          <Step {...steps[1]}>
            <LocationDetailsStep />
          </Step>
          <Step {...steps[2]}>
            <OperatingHoursStep />
          </Step>
          <Step {...steps[3]}>
            <ReviewStep />
          </Step>
          <Footer teamId={teamId} onSubmit={onSubmit} />
        </Stepper>
      </form>
    </FormProvider>
  );
}

function BasicInformationStep({
  teamId,
  organizations,
  onSuccess,
  selectedOrganization,
  setSelectedOrganization,
}: {
  teamId?: string;
  organizations?: Array<{ _id: string; name: string; owner_email: string }>;
  onSuccess?: () => void;
  selectedOrganization:
    | { _id: string; name: string; owner_email: string }
    | undefined;
  setSelectedOrganization: React.Dispatch<
    React.SetStateAction<
      { _id: string; name: string; owner_email: string } | undefined
    >
  >;
}) {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const { getValues, control, trigger, watch } =
    useFormContext<LocationFormData>();

  // Watch the values to ensure they're always defined
  const name = watch("name") || "";
  const adminName = watch("admin_name") || "";
  const adminEmail = watch("admin_email") || "";

  return (
    <div className={cn(FORM_CONTAINER_CLASS, "justify-between")}>
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <FormField
            control={control}
            name="name"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel className={FORM_LABEL_CLASS}>
                  Location Name
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter location name"
                    {...field}
                    value={name}
                    onChange={(e) => field.onChange(e.target.value)}
                    onBlur={() => trigger("name")}
                    autoComplete="organization"
                    className="bg-background"
                  />
                </FormControl>
                <FormDescription className="text-xs text-muted/80">
                  The name of your business location
                </FormDescription>
                <FormMessage className={FORM_MESSAGE_CLASS} />
              </FormItem>
            )}
          />
        </div>
        <div className="flex gap-4">
          <FormField
            control={control}
            name="admin_name"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel className={FORM_LABEL_CLASS}>Admin Name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter admin name"
                    {...field}
                    value={adminName}
                    onChange={(e) => field.onChange(e.target.value)}
                    onBlur={() => trigger("admin_name")}
                    autoComplete="name"
                    className="bg-background"
                  />
                </FormControl>
                <FormDescription className="text-xs text-muted/80">
                  Name of the location administrator
                </FormDescription>
                <FormMessage className={FORM_MESSAGE_CLASS} />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="admin_email"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel className={FORM_LABEL_CLASS}>Admin Email</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    placeholder="Enter admin email"
                    {...field}
                    value={adminEmail}
                    onChange={(e) => field.onChange(e.target.value)}
                    onBlur={() => trigger("admin_email")}
                    autoComplete="email"
                    className="bg-background"
                  />
                </FormControl>
                <FormDescription className="text-xs text-muted/80">
                  Email address for administrative communications
                </FormDescription>
                <FormMessage className={FORM_MESSAGE_CLASS} />
              </FormItem>
            )}
          />
        </div>
      </div>
      {!teamId && (
        <div className="flex flex-col gap-4">
          <FormItem>
            <FormLabel className={FORM_LABEL_CLASS}>Organization</FormLabel>
            <Select
              onValueChange={(value) => {
                setSelectedOrganization(
                  organizations.find((org) => org._id === value),
                );
              }}
              value={selectedOrganization?._id}
            >
              <FormControl>
                <SelectTrigger className="bg-background">
                  <SelectValue placeholder="Select an organization..." />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {organizations?.map((org) => (
                  <SelectItem key={org._id} value={org._id}>
                    {org.name} - {org.owner_email}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormDescription className="text-xs text-muted/80">
              Select the organization this location belongs to
            </FormDescription>
            <FormMessage className={FORM_MESSAGE_CLASS} />
          </FormItem>
          <div className="flex gap-4">
            <Button
              className="shrink-0 text-primary-foreground hover:text-primary"
              variant="outline"
              onClick={async (e) => {
                e.preventDefault();
                try {
                  const isValid = await trigger([
                    "name",
                    "admin_name",
                    "admin_email",
                  ]);
                  if (isValid && selectedOrganization?._id) {
                    const organizationId = selectedOrganization._id;
                    await API.createNewLocation(
                      organizationId,
                      getValues(),
                      user.hub?._id,
                      true,
                      true,
                    );
                    onSuccess?.();
                    dispatch(
                      setSuccess(
                        "New location created. You can invite the admin later.",
                      ),
                    );
                  }
                } catch (error) {
                  dispatch(
                    setError(
                      error?.response?.data?.message ??
                        "Error creating new location",
                    ),
                  );
                }
              }}
              disabled={
                !name || !adminName || !adminEmail || !selectedOrganization?._id
              }
            >
              Save Progress & Invite Later
              <Save className="ml-1 size-3 shrink-0" />
            </Button>
            <Button
              className="shrink-0"
              variant="secondary"
              onClick={async (e) => {
                e.preventDefault();
                try {
                  const isValid = await trigger([
                    "name",
                    "admin_name",
                    "admin_email",
                  ]);
                  if (isValid && selectedOrganization?._id) {
                    const organizationId = selectedOrganization._id;
                    await API.createNewLocation(
                      organizationId,
                      getValues(),
                      user.hub?._id,
                      true,
                      false,
                    );
                    onSuccess?.();
                    dispatch(
                      setSuccess(
                        "New location created and invite has been sent to admin!",
                      ),
                    );
                  }
                } catch (error) {
                  dispatch(
                    setError(
                      error?.response?.data?.message ??
                        "Error creating new location and inviting admin",
                    ),
                  );
                }
              }}
              disabled={
                !name || !adminName || !adminEmail || !selectedOrganization?._id
              }
            >
              Invite Admin & Skip Onboarding
              <Send className="ml-1 size-3 shrink-0" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

function LocationDetailsStep() {
  const { control, trigger, watch } = useFormContext<LocationFormData>();

  // Watch the values to ensure they're always defined
  const address = watch("address") || "";
  const city = watch("city") || "";
  const state = watch("state") || "";
  const zip = watch("zip") || "";
  const contactEmail = watch("contact_email") || "";
  const phoneNumber = watch("phone_number") || "";
  const websiteUrl = watch("website_url") || "";

  return (
    <div className={FORM_CONTAINER_CLASS}>
      <FormField
        control={control}
        name="address"
        render={({ field }) => (
          <FormItem>
            <FormLabel className={FORM_LABEL_CLASS}>Address</FormLabel>
            <FormControl>
              <Input
                placeholder="Enter street address"
                {...field}
                value={address}
                onChange={(e) => field.onChange(e.target.value)}
                onBlur={() => trigger("address")}
                autoComplete="street-address"
                className="bg-background"
              />
            </FormControl>
            <FormMessage className={FORM_MESSAGE_CLASS} />
          </FormItem>
        )}
      />
      <div className="flex gap-4">
        <FormField
          control={control}
          name="city"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel className={FORM_LABEL_CLASS}>City</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter city"
                  {...field}
                  value={city}
                  onChange={(e) => field.onChange(e.target.value)}
                  onBlur={() => trigger("city")}
                  autoComplete="address-level2"
                  className="bg-background"
                />
              </FormControl>
              <FormMessage className={FORM_MESSAGE_CLASS} />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="state"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel className={FORM_LABEL_CLASS}>State</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter state"
                  {...field}
                  value={state}
                  onChange={(e) => field.onChange(e.target.value)}
                  onBlur={() => trigger("state")}
                  autoComplete="address-level1"
                  className="bg-background"
                />
              </FormControl>
              <FormMessage className={FORM_MESSAGE_CLASS} />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="zip"
          render={({ field }) => (
            <FormItem className="w-1/4">
              <FormLabel className={FORM_LABEL_CLASS}>ZIP Code</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter ZIP code"
                  {...field}
                  value={zip}
                  onChange={(e) => field.onChange(e.target.value)}
                  onBlur={() => trigger("zip")}
                  autoComplete="postal-code"
                  className="bg-background"
                />
              </FormControl>
              <FormMessage className={FORM_MESSAGE_CLASS} />
            </FormItem>
          )}
        />
      </div>
      <div className="flex gap-4">
        <FormField
          control={control}
          name="contact_email"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel className={FORM_LABEL_CLASS}>Contact Email</FormLabel>
              <FormControl>
                <Input
                  type="email"
                  placeholder="Enter contact email"
                  {...field}
                  value={contactEmail}
                  onChange={(e) => field.onChange(e.target.value)}
                  onBlur={() => trigger("contact_email")}
                  autoComplete="email"
                  className="bg-background"
                />
              </FormControl>
              <FormDescription className="text-xs text-muted/80">
                Contact email for customer support (optional)
              </FormDescription>
              <FormMessage className={FORM_MESSAGE_CLASS} />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="phone_number"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel className={FORM_LABEL_CLASS}>Phone Number</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter phone number"
                  {...field}
                  value={phoneNumber}
                  onChange={(e) => field.onChange(e.target.value)}
                  onBlur={() => trigger("phone_number")}
                  autoComplete="tel"
                  className="bg-background"
                />
              </FormControl>
              <FormMessage className={FORM_MESSAGE_CLASS} />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="website_url"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel className={FORM_LABEL_CLASS}>Website URL</FormLabel>
              <FormControl>
                <Input
                  type="url"
                  placeholder="https://www.example.com"
                  {...field}
                  value={websiteUrl}
                  onChange={(e) => field.onChange(e.target.value)}
                  onBlur={() => trigger("website_url")}
                  autoComplete="url"
                  className="bg-background"
                />
              </FormControl>
              <FormDescription className="text-xs text-muted/80">
                Official website for this location (optional)
              </FormDescription>
              <FormMessage className={FORM_MESSAGE_CLASS} />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
}

function OperatingHoursStep() {
  const { control, trigger, watch } = useFormContext<LocationFormData>();
  const [open, setOpen] = React.useState(false);
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ] as const;

  // Watch the values to ensure they're always defined
  const hours = watch("hours") || {};
  const timeZone = watch("time_zone") || "";

  return (
    <div className={FORM_CONTAINER_CLASS}>
      {days.map((day) => (
        <div
          key={day}
          className={cn(
            "flex w-full items-center justify-center gap-4 opacity-80",
            hours?.[day]?.is_open && "opacity-100",
          )}
        >
          <FormField
            control={control}
            name={`hours.${day}.is_open`}
            render={({ field }) => (
              <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={(value) => {
                      field.onChange(value);
                      trigger(`hours.${day}`);
                    }}
                  />
                </FormControl>
                <FormLabel className={cn(FORM_LABEL_CLASS, "w-24 capitalize")}>
                  {day}
                </FormLabel>
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name={`hours.${day}.open`}
            render={({ field }) => (
              <FormItem>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                    trigger(`hours.${day}`);
                  }}
                  value={hours[day]?.open || ""}
                  disabled={!hours?.[day]?.is_open}
                >
                  <FormControl>
                    <SelectTrigger className="w-32 bg-background">
                      <SelectValue placeholder="Open" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent className="max-h-60 overflow-y-auto">
                    {timeOptions.map((time) => (
                      <SelectItem key={time} value={time}>
                        {time}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
          <span className="text-sm font-semibold tracking-wider text-muted/70">
            TO
          </span>
          <FormField
            control={control}
            name={`hours.${day}.close`}
            render={({ field }) => (
              <FormItem>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                    trigger(`hours.${day}`);
                  }}
                  value={hours[day]?.close || ""}
                  disabled={!hours?.[day]?.is_open}
                >
                  <FormControl>
                    <SelectTrigger className="w-32 bg-background">
                      <SelectValue placeholder="Close" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent className="max-h-60 overflow-y-auto">
                    {timeOptions.map((time) => (
                      <SelectItem key={time} value={time}>
                        {time}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
        </div>
      ))}
      <FormField
        control={control}
        name="time_zone"
        render={({ field }) => (
          <FormItem className="w-full max-w-md">
            <FormLabel className={FORM_LABEL_CLASS}>Time Zone</FormLabel>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outlineFill"
                  role="combobox"
                  aria-expanded={open}
                  className="w-full justify-between transition-all hover:bg-muted"
                >
                  {timeZone
                    ? `${timeZone} (${
                        new Intl.DateTimeFormat("en-US", {
                          timeZone: timeZone,
                          timeZoneName: "short",
                        })
                          .formatToParts()
                          .find((part) => part.type === "timeZoneName")?.value
                      })`
                    : dayjs.tz.guess()}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[300px] p-0" side="top">
                <Command>
                  <CommandInput placeholder="Search time zone..." />
                  <CommandList>
                    <CommandEmpty>No time zone found.</CommandEmpty>
                    {(() => {
                      const timeZones = (Intl as any).supportedValuesOf(
                        "timeZone",
                      );
                      const regions = Array.from(
                        new Set(
                          timeZones.map((tz: string) => tz.split("/")[0]),
                        ),
                      );

                      return regions.map((region: string) => (
                        <CommandGroup key={region} heading={region}>
                          {timeZones
                            .filter((tz: string) => tz.startsWith(region))
                            .map((tz: string) => {
                              const shortTz = dayjs().tz(tz).format("z");
                              const offset = dayjs().tz(tz).format("Z");
                              return (
                                <CommandItem
                                  key={tz}
                                  value={tz}
                                  onSelect={(currentValue) => {
                                    field.onChange(currentValue);
                                    trigger("time_zone");
                                    setOpen(false);
                                  }}
                                >
                                  <Check
                                    className={cn(
                                      "mr-2 h-4 w-4",
                                      timeZone === tz
                                        ? "opacity-100"
                                        : "opacity-0",
                                    )}
                                  />
                                  {`${tz} (${shortTz}, ${offset})`}
                                </CommandItem>
                              );
                            })}
                        </CommandGroup>
                      ));
                    })()}
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
            <FormMessage className={FORM_MESSAGE_CLASS} />
          </FormItem>
        )}
      />
    </div>
  );
}

function ReviewStep() {
  const { getValues } = useFormContext<LocationFormData>();
  const values = getValues();

  return (
    <div className={cn(FORM_CONTAINER_CLASS, "w-full max-w-[90%]")}>
      <h3 className="border-b pb-2 text-2xl font-semibold text-white">
        Review Your Information
      </h3>

      <div className="grid gap-8 lg:grid-cols-3">
        <section>
          <h4 className={cn("mb-4 text-xl font-semibold", FORM_LABEL_CLASS)}>
            Basic Information
          </h4>
          <div className="space-y-3">
            <Field label="Location Name" value={values.name} />
            <Field label="Admin Name" value={values.admin_name} />
            <Field label="Admin Email" value={values.admin_email} />
          </div>
        </section>

        <section>
          <h4 className={cn("mb-4 text-xl font-semibold", FORM_LABEL_CLASS)}>
            Location Details
          </h4>
          <div className="space-y-3">
            <Field label="Address" value={values.address} />
            <Field label="City" value={values.city} />
            <Field label="State" value={values.state} />
            <Field label="ZIP" value={values.zip} />
            <Field label="Contact Email" value={values.contact_email} />
            <Field label="Phone Number" value={values.phone_number} />
            <Field label="Website URL" value={values.website_url} />
          </div>
        </section>

        <section>
          <h4 className={cn("mb-4 text-xl font-semibold", FORM_LABEL_CLASS)}>
            Operating Hours
          </h4>
          <Field label="Time Zone" value={values.time_zone} className="mb-4" />
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1">
            {Object.entries(values.hours).map(([day, hours]) => (
              <div key={day} className="rounded-md bg-muted p-3">
                <p className="font-medium capitalize text-primary">{day}</p>
                <p className="text-sm text-muted-foreground">
                  {hours.is_open ? `${hours.open} - ${hours.close}` : "Closed"}
                </p>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

function Field({
  label,
  value,
  className = "",
}: {
  label: string;
  value: string;
  className?: string;
}) {
  return (
    <div className={cn("flex flex-col", className)}>
      <span className={cn("text-sm font-semibold", FORM_LABEL_CLASS)}>
        {label}
      </span>
      <span className="ml-4 text-base text-white">{value || "N/A"}</span>
    </div>
  );
}

function Footer({
  teamId,
  onSubmit,
}: {
  teamId?: string;
  onSubmit: (data: LocationFormData, teamId?: string) => Promise<void>;
}) {
  const {
    nextStep,
    prevStep,
    isLastStep,
    isOptionalStep,
    isDisabledStep,
    activeStep,
  } = useStepper();
  const {
    formState: { errors },
    trigger,
    handleSubmit,
  } = useFormContext<LocationFormData>();

  const getErrorMessage = () => {
    const errorEntries = Object.entries(errors);
    if (errorEntries.length === 0) return null;

    const [fieldName, error] = errorEntries[0];
    const errorMessage = error?.message || "An error occurred";
    const fieldLabel = convertPropertyToReadableFormat(fieldName);

    return `${errorMessage} (Field: ${fieldLabel})`;
  };

  const handleNextStep = async (e: React.MouseEvent) => {
    e.preventDefault();
    let fieldsToValidate: (keyof LocationFormData)[] = [];

    switch (activeStep) {
      case 0:
        fieldsToValidate = ["name", "admin_name", "admin_email"];
        break;
      case 1:
        fieldsToValidate = [
          "address",
          "city",
          "state",
          "zip",
          "contact_email",
          "phone_number",
          "website_url",
        ];
        break;
      case 2:
        fieldsToValidate = ["hours", "time_zone"];
        break;
      default:
        break;
    }

    const isStepValid = await trigger(fieldsToValidate);
    if (isStepValid && !isLastStep) {
      nextStep();
    }
  };

  return (
    <div className="flex w-full items-center justify-end gap-4 p-4 pt-0">
      {errors && Object.keys(errors).length > 0 && (
        <p className={FORM_MESSAGE_CLASS}>Error: {getErrorMessage()}</p>
      )}

      <div className="flex items-center gap-2">
        <Button
          disabled={isDisabledStep}
          onClick={prevStep}
          size="sm"
          variant="secondary"
        >
          Prev
        </Button>
        {!isLastStep && (
          <Button size="sm" onClick={handleNextStep}>
            {isOptionalStep ? "Skip" : "Next"}
          </Button>
        )}
        {isLastStep && (
          <Button
            size="sm"
            onClick={async (e) => {
              e.preventDefault();
              await handleSubmit(async (data) => {
                await onSubmit(data, teamId);
              })();
            }}
            disabled={Object.keys(errors).length > 0}
          >
            {teamId ? "Save" : "Finish"}
          </Button>
        )}
      </div>
    </div>
  );
}
